import { CacheLoader } from "../../../core/cache/cache-loader";
import { Paginated } from "../../../utils/pagination";
import { AuthenticationManager } from "../../authentication/authentication-manager";
import { ClientManager } from "../../client/client-manager";
import { AccountingTransaction } from "./transaction";
import { TransactionDirection, TransactionFilters } from "./transaction-filter";
import { TransactionsLoader } from "./transaction-loader";
import { TransactionService } from "./transaction-service";

export class TransactionsManager {
	public defaultTransactionLoader: TransactionsLoader;
	public transactionLoaders = new Map<string, TransactionsLoader>();

	public constructor(
		private accountingTransactionService: TransactionService,
		private authenticationManager: AuthenticationManager,
		private clientManager: ClientManager,
		private cacheLoader: CacheLoader<Paginated<AccountingTransaction>>
	) {
		this.defaultTransactionLoader = new TransactionsLoader(
			null,
			this.accountingTransactionService,
			this.cacheLoader,
			TransactionDirection.ALL
		);
		this.authenticationManager.isConnected.onChange.add(async isConnected => {
			if (!isConnected) {
				await this.clear();
			}
		});
		this.clientManager.onLanguageChange.add(async () => {
			await this.clear();
		});
	}

	private getLoaderKey(accountId: string, transactionDirection = TransactionDirection.ALL) {
		return `${accountId}_${transactionDirection}`;
	}

	public getDefaultLoader(): TransactionsLoader {
		return this.defaultTransactionLoader;
	}

	public getSearchTransactionsLoader(
		accountId: string,
		transactionDirection = TransactionDirection.ALL,
		transactionFilters: TransactionFilters
	) {
		return new TransactionsLoader(
			accountId,
			this.accountingTransactionService,
			this.cacheLoader,
			transactionDirection,
			transactionFilters
		);
	}

	public getTransactionsLoader(accountId: string, transactionDirection = TransactionDirection.ALL): TransactionsLoader {
		const loaderKey = this.getLoaderKey(accountId, transactionDirection);
		let cache = this.transactionLoaders.get(loaderKey);
		if (!cache) {
			cache = new TransactionsLoader(
				accountId,
				this.accountingTransactionService,
				this.cacheLoader,
				transactionDirection
			);
			this.transactionLoaders.set(loaderKey, cache);
		}
		return cache;
	}

	public async clear() {
		for (const key of this.transactionLoaders.keys()) {
			await this.transactionLoaders.get(key)?.clear();
		}
	}

	public async refresh(accountIds: string[]) {
		for (const accountId of accountIds) {
			const loader = this.getTransactionsLoader(accountId);
			await loader.refresh();
		}
	}
}
