import { AccountingEntry } from "../../../../shared/domains/accounting-transaction/accounting-entry";
import { MarkdownText } from "../forms/markdown-text";
import React from "react";
import { useSideEntryLabel } from "../../../../shared/domains/transactions/use-side-entry-label";

export const SideEntryLabel: React.FC<{
	entry: AccountingEntry;
	translationKeyPrefix: string;
	absolute?: boolean;
}> = ({ entry, translationKeyPrefix, absolute = true }) => {
	const sideEntryText = useSideEntryLabel(entry, translationKeyPrefix, absolute);
	return <MarkdownText>{sideEntryText}</MarkdownText>;
};
