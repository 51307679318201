import { Amount } from "../../../core/amount/amount";
import { isDefined } from "../../../utils/assert";
import { BaseUrl } from "../../BaseUrl";
import { BaseSpendingCategory } from "./categorization";

export interface AdditionalDataValue {
	key: string;
	value: string | null;
	label?: string;
}

export interface Spending extends BaseUrl {
	id: string;
	category: BaseSpendingCategory;
	subcategory: BaseSpendingCategory;
	label: string;
	amount?: Amount;
	additionalData?: AdditionalDataValue[];
}

export function getAmounts(spendings: Spending[]) {
	const filteredAmounts: Amount[] = [];
	for (const spending of spendings) {
		if (isDefined(spending.amount)) {
			filteredAmounts.push(spending.amount);
		}
	}
	return filteredAmounts;
}
