import { CardFeatureType } from "../../../../../../shared/domains/cards/card";

export const cardFeatures = {
	[CardFeatureType.Nfc]: "card.feature.nfc",
	[CardFeatureType.CashOperation]: "card.feature.cashOperation",
	[CardFeatureType.ForeignPayment]: "card.feature.foreignPayment",
	[CardFeatureType.OnlinePayment]: "card.feature.onlinePayment",
	[CardFeatureType.EurWithdrawal]: "card.feature.eurWithdrawal",
	[CardFeatureType.ForeignWithdrawal]: "card.feature.foreignWithdrawal",
	[CardFeatureType.PaymentAuthorized]: "card.feature.paymentAuthorized",
	[CardFeatureType.EurPayment]: "card.feature.eurPayment",
	[CardFeatureType.ForeignAll]: "card.feature.foreignAll",
	[CardFeatureType.ForeignOnlinePayment]: "card.feature.foreignOnlinePayment",
};
