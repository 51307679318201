import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account, AccountType } from "../../../../../shared/domains/account/account";
import { accountTypeLabels } from "../../../../../shared/domains/account/account-types-labels";
import { Client, useClientDisplayName } from "../../../../../shared/domains/client/client";
import { Gender } from "../../../../../shared/domains/client/gender";
import { nameWithGenderLabels } from "../../../../../shared/domains/client/gender-labels";
import { getAvailableRechargeMethod } from "../../../../../shared/domains/recharge/get-available-recharge-methods";
import { useExternalAccounts } from "../../../../../shared/domains/recipients/use-external-accounts";
import { ACCOUNT_DETAIL_MODAL_ID } from "../../../../core/modal/modal-id";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AccountStatusView } from "../../../common/account-status-view";
import { AmountText } from "../../../common/amount-text";
import { TertiaryButton } from "../../../common/buttons/tertiary-button";
import { Modal } from "../../../common/modal/modal";
import { RechargeBankToWalletIcon } from "../../../common/svg/recharge-bank-to-wallet-icon";
import { RechargeCardIcon } from "../../../common/svg/recharge-card-icon";
import { SmallChevronIcon } from "../../../common/svg/small-chevron-icon";
import { AnimatedSkeletonStyle } from "../../../styles/animated-skeleton";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { AccountDetailsModal } from "../../account/account-details-modal";

interface AccountTileProps extends HTMLAttributes<HTMLDivElement> {
	owner: Client | null;
	account: Account;
	isActive?: boolean;
	onAccountClick: (account: Account) => void;
	withDetail?: boolean;
}

export function AccountTile(props: AccountTileProps) {
	const { owner, account, isActive, onAccountClick, withDetail, ...rest } = props;
	const displayName = useClientDisplayName(owner, true);
	const gender = owner?.gender ?? Gender.None;
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();

	return (
		<ClickableTile onClick={() => onAccountClick(account)} $isRTL={isRTL} {...rest}>
			{isActive && <ActiveMark $isRTL={isRTL} />}
			<OwnerName>
				{account.type == AccountType.Dedicated
					? account.merchant ?? ""
					: formatMessage(nameWithGenderLabels[gender], {
							name: displayName,
					  })}
			</OwnerName>
			<AccountBalance amount={account.availableBalance} />
			<StyledAccountType>{formatMessage(`account.${accountTypeLabels[account.type]}`)}</StyledAccountType>
			<AccountNumber>{formatMessage("account.accountId", { id: account.id })}</AccountNumber>
			<AccountStatus account={account} $isRTL={isRTL} />
			{withDetail && (
				<DetailButton
					onClick={() => {
						Modal.present(ACCOUNT_DETAIL_MODAL_ID, () => <AccountDetailsModal account={account} />, {
							canBeDismissed: false,
						});
					}}
					$isRTL={isRTL}>
					{formatMessage("account.detail")}
				</DetailButton>
			)}
		</ClickableTile>
	);
}

interface RechargeableAccountTileProps extends HTMLAttributes<HTMLDivElement> {
	owner: Client | null;
	account: Account;
}
export function RechargeableAccountTile(props: RechargeableAccountTileProps) {
	const { owner, account, onClick, ...rest } = props;
	const displayName = useClientDisplayName(owner, true);
	const gender = owner?.gender ?? Gender.None;
	const { formatMessage } = useIntl();
	const { data } = useExternalAccounts();
	const externalAccount = data?.[0];
	const externalAccountIBAN = externalAccount?.iban;
	const { hasRechargeByCard, hasBankToWallet } = getAvailableRechargeMethod(externalAccountIBAN);

	const { isRTL } = useRTL();

	return (
		<Tile $isRTL={isRTL} {...rest} onClick={onClick}>
			<OwnerName>
				{formatMessage(nameWithGenderLabels[gender], {
					name: displayName,
				})}
			</OwnerName>
			<AccountBalance amount={account.balance} />
			<Spacer />
			<AccountNumber>{formatMessage("account.accountId", { id: account.id })}</AccountNumber>
			<RechargeMethodContainer $isRTL={isRTL}>
				{hasRechargeByCard && (
					<IconContainer $isRTL={isRTL}>
						<RechargeCardIcon fill={theme.mainColor()} />
					</IconContainer>
				)}
				{hasBankToWallet && (
					<IconContainer $isRTL={isRTL}>
						<RechargeBankToWalletIcon fill={theme.mainColor()} />
					</IconContainer>
				)}
			</RechargeMethodContainer>
			<RechargeButton $isRTL={isRTL}>
				{formatMessage("recharge.rechargeButton")}
				<Chevron $isRTL={isRTL} />
			</RechargeButton>
		</Tile>
	);
}

const ClickableTile = styled.div<{ $isRTL: boolean }>`
	appearance: none;
	outline: none;
	border: none;
	:hover {
		cursor: pointer;
	}
	:disabled {
		cursor: auto;
	}
	position: relative;
	display: flex;
	flex-direction: column;
	width: ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE}px;
	min-height: 188px;
	padding: ${props => (props.$isRTL ? `26px 31px 26px 22px` : `26px 22px 26px 31px`)};
	border-radius: 13px;
	${shadows.medium};
	background-color: #ffffff;
	color: #000000;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE}px;
	}
`;

const Tile = styled(ClickableTile)`
	:hover {
		cursor: auto;
	}
`;

export const SkeletonAccountTile = styled(ClickableTile)`
	${AnimatedSkeletonStyle};
`;

const ActiveMark = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 38px;
	left: ${props => (props.$isRTL ? `unset` : 0)};
	right: ${props => (props.$isRTL ? 0 : `unset`)};
	width: 6px;
	height: 112px;
	border-radius: 3px;
	background-color: ${theme.mainColor};
`;

const OwnerName = styled.span`
	font-size: 1.125rem;
	${theme.text};
	margin-bottom: 3px;
	text-align: start;
`;

const AccountBalance = styled(AmountText)`
	font-size: 1.25rem;
	${theme.boldText};
	margin-bottom: 33px;
	text-align: start;
`;

const StyledAccountType = styled.span`
	font-size: 0.9375rem;
	${theme.text};
	margin-bottom: 4px;
	text-align: start;
`;

const AccountNumber = styled.span`
	font-size: 0.8125rem;
	${theme.boldText};
	color: #b1b1b1;
	padding-bottom: 14px;
	word-break: break-all;
	text-align: start;
`;

const AccountStatus = styled(AccountStatusView)<{ $isRTL: boolean }>`
	position: absolute;
	right: ${props => (props.$isRTL ? `unset` : `15px`)};
	left: ${props => (props.$isRTL ? `15px` : `unset`)};

	bottom: 15px;
`;

const DetailButton = styled(TertiaryButton)<{ $isRTL: boolean }>`
	position: absolute;
	right: ${props => (props.$isRTL ? `unset` : `15px`)};
	left: ${props => (props.$isRTL ? `15px` : `unset`)};
	top: 15px;
`;

const RechargeMethodContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: row;
	position: absolute;
	right: ${props => (props.$isRTL ? `unset` : `15px`)};
	left: ${props => (props.$isRTL ? `15px` : `unset`)};
	top: 15px;
`;

const IconContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border-radius: 8px;
	background-color: ${() => theme.mainColorLightBackground()};
	&:not(:last-of-type) {
		margin-right: ${props => (props.$isRTL ? 0 : 10)}px;
		margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
	}
`;

const RechargeButton = styled(TertiaryButton)<{ $isRTL: boolean }>`
	position: absolute;
	display: flex;
	justify-content: center;
	right: ${props => (props.$isRTL ? `unset` : `15px`)};
	left: ${props => (props.$isRTL ? `15px` : `unset`)};
	bottom: 35px;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		bottom: 70px;
	}
`;

const Spacer = styled.div`
	display: flex;
	flex: 1;
`;

const Chevron = styled(SmallChevronIcon)<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? -4 : 4)}px;
	margin-right: ${props => (props.$isRTL ? 4 : -4)}px;
`;
