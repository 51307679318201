import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Amount, switchCurrency } from "../../../../../shared/core/amount/amount";
import { Currencies } from "../../../../../shared/core/currency/currency";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { useAccounts } from "../../../../../shared/domains/account/use-accounts";
import { useSelectableCurrencies } from "../../../../../shared/domains/account/use-selectable-currencies";
import { isDefined } from "../../../../../shared/utils/assert";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { AmountInput } from "../../../common/forms/amount-input";
import { Selector } from "../../../common/selector";
import { theme } from "../../../styles/theme";
import { ModalBackButton } from "./modal-back-button";

interface SelectAmountLimitProps {
	className?: string;
	acceptorId: string;
	backMethod: () => void;
	selectMethod: (amount: Amount) => void;
	loading?: boolean;
}

export const SelectAmountLimit = (props: SelectAmountLimitProps) => {
	const { acceptorId, backMethod, selectMethod } = props;
 
	const accounts = useAccounts();
	const selectableCurrencies = useSelectableCurrencies(accounts);
	const [currency, setCurrency] = useState(accounts[0]?.balance.currency);
	const [amount, setAmount] = useState<Amount | null>(null);
	const { formatMessage } = useIntl();
	useEffect(() => {
		let initialCurrency = currency;
		if (accounts.length > 0 && !isDefined(currency)) {
			initialCurrency = accounts[0]?.balance.currency;
			setCurrency(initialCurrency);
		}
		if (!isDefined(amount) && isDefined(initialCurrency)) {
			setAmount(new Amount(0, initialCurrency));
		}
	}, [accounts, currency, amount]);

	useEffect(() => {
		if (isDefined(amount) && currency !== amount.currency) {
			setAmount(switchCurrency(amount, currency));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency]);

	return (
		<Container className={props.className}>
			<TopLeft>
				<ModalBackButton onClick={backMethod} />
			</TopLeft>
			<Title>{formatMessage("transactionCode.selectAmount.title")}</Title>
			<AcceptorId>
				{formatMessage("transactionCode.selectAmount.acceptorReminder", { acceptorId: acceptorId })}
			</AcceptorId>
			<Row>
				<CurrencyLabel>{formatMessage("transactionCode.selectAmount.currencyLabel")}</CurrencyLabel>
				<Selector
					options={Array.from(selectableCurrencies).map(currency => ({
						value: currency,
						text: Currencies[currency].symbol,
						key: currency.toString(),
					}))}
					value={currency}
					onChange={c => setCurrency(c)}
					size="S"
				/>
			</Row>
			<Row>
				<AmountLabel>{formatMessage("transactionCode.selectAmount.amountLabel")}</AmountLabel>
			</Row>
			<Row>{amount && <AmountInput value={amount} onChange={setAmount} />}</Row>
			<Row>
				<AmountInformation>{formatMessage("transactionCode.selectAmount.amountInformationLabel")}</AmountInformation>
			</Row>
			<ButtonContainer>
				<NextButton
					size="S"
					disabled={!(amount && amount?.value > 0)}
					onClick={() => {
						isDefined(amount) && selectMethod(amount);
					}}
					showSpinner={props.loading}>
					<ButtonLabel>{formatMessage("transactionCode.selectAmount.next")}</ButtonLabel>
				</NextButton>
			</ButtonContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
`;

const TopLeft = styled.div`
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;
`;

const Row = styled.div`
	display: flex;
	align-self: stretch;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;

const Title = styled.span`
	${theme.boldText};
	font-size: 1.375rem;
	color: #000000;
	margin-top: 50px;
	margin-bottom: 12px;
`;

const AcceptorId = styled.span`
	${theme.mediumText};
	font-size: 0.8125rem;
	color: #b1b1b1;
	margin-bottom: 40px;
`;

const CurrencyLabel = styled.span`
	${theme.mediumText};
	font-size: 0.9375rem;
	margin-right: 10px;
	flex: 1;
`;

const AmountLabel = styled(CurrencyLabel)`
	margin-top: 10px;
`;

const AmountInformation = styled.span`
	${theme.mediumText};
	font-size: 0.9375rem;
	color: #aaaaaa;
	margin-top: 5px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 40px;
	margin-bottom: 40px;
`;
const NextButton = styled(PrimaryButton)``;

const ButtonLabel = styled.span<{ disabled?: boolean }>`
	color: ${props => (props.disabled ? "#b1b1b1" : "#ffffff")};
`;
