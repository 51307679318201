import { Card, CardFeatureType, OpposeCardReason } from "./card";

import { Signal } from "micro-signals";
import { NativeCardDetailsManager } from "../../../mobile/domain/cards/card-details-manager";
import { IUpdateOutstandingParams } from "../../../mobile/ui/screens/cards/components/outstandings/update-outstanding-screen";
import { CardDetailsManager } from "../../../web/domain/cards/card-details-manager";
import { logger } from "../../core/logging/logger";
import { Observable } from "../../utils/observable";
import { ObservableArray } from "../../utils/observable-array";
import { PaginationOptions } from "../../utils/pagination";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { CardStatus } from "./card-filter";
import { CardService, OverrideCardData } from "./card-service";
import { Outstanding } from "./outstanding";

export const CREATE_PINCODE = false;
export const UPDATE_PINCODE = true;

export const EXCLUDED_STATUSES = [CardStatus.CANCELLED, CardStatus.REMOVED, CardStatus.EXPIRED];

export class CardManager {
	public cards = new ObservableArray<Card>([]);
	public outstandings = new ObservableArray<Outstanding>([]);
	public publicKey = new Observable<Uint8Array | null>(null);
	public privateKey = new Observable<Uint8Array | null>(null);

	public readonly onCardStatusWillChange = new Signal<CardStatus | undefined>();

	public constructor(
		private cardService: CardService,
		private authenticationManager: AuthenticationManager,
		private cardDetailManager: CardDetailsManager | NativeCardDetailsManager
	) {
		this.authenticationManager.isConnected.onChange.add(isConnected => {
			if (!isConnected) {
				this.clear();
			}
		});
	}

	public async refresh(status?: CardStatus) {
		try {
			const cards = await this.cardService.fetchCards({} as PaginationOptions, status);
			this.cards.set(cards.items.filter(i => !EXCLUDED_STATUSES.includes(i.status)));
			this.outstandings.set([]);
		} catch (e) {
			logger.debug("CardManager", "Failed to refresh", e);
			throw e;
		}
	}

	public async oppose(cardId: string, reason: OpposeCardReason) {
		try {
			const opposedCard = await this.cardService.opposeCard(cardId, reason);
			this.cards.replace(opposedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to oppose card", e);
			throw e;
		}
	}

	public async block(cardId: string) {
		try {
			this.onCardStatusWillChange.dispatch(CardStatus.BLOCKED);
			const blockedCard = await this.cardService.blockCard(cardId);
			this.cards.replace(blockedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to block card", e);
			throw e;
		}
	}

	public async unblock(cardId: string) {
		try {
			this.onCardStatusWillChange.dispatch(CardStatus.ACTIVE);
			const unblockedCard = await this.cardService.unblockCard(cardId);
			this.cards.replace(unblockedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to unblock card", e);
			throw e;
		}
	}

	public async activate(cardId: string, activationCode?: string, scaSessionToken?: string) {
		try {
			const activatedCard = await this.cardService.activateCard(cardId, activationCode, scaSessionToken || "");
			this.cards.replace(activatedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to activate card", e);
			throw e;
		}
	}

	public async deactivate(cardId: string, scaSessionToken?: string) {
		try {
			const deactivatedCard = await this.cardService.deactivateCard(cardId, scaSessionToken);
			this.cards.replace(deactivatedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to deactivate card", e);
			throw e;
		}
	}

	public async enableFeature(cardId: string, feature: CardFeatureType, isEnabled: boolean) {
		try {
			const updatedCard = await this.cardService.enableCardFeature(cardId, feature, isEnabled);
			this.cards.replace(updatedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to enable feature", e);
			throw e;
		}
	}

	public async createCard(
		accountId: string,
		productId?: string,
		pincode?: string,
		scaToken?: string,
		isVirtual?: boolean
	) {
		try {
			const createdCard = await this.cardService.createCard(accountId, productId, pincode, scaToken || "", isVirtual);
			this.cards.set([...this.cards.get(), createdCard]);
		} catch (e) {
			logger.debug("CardManager", "Failed to create card", e);
			throw e;
		}
	}

	public async reissuePincode(cardId: string, newPincode: string, withChannel: boolean, scaSessionToken?: string) {
		try {
			const modifiedCard = await this.cardService.reissuePincode(
				cardId,
				newPincode,
				withChannel,
				scaSessionToken || ""
			);
			this.cards.replace(modifiedCard, card => card.id === cardId);
		} catch (e) {
			logger.debug("CardManager", "Failed to reissue Pin code for card", e);
			throw e;
		}
	}

	public async retrieveCard(cardId: string) {
		try {
			const updatedCard = await this.cardService.retrieveCard(cardId);
			this.cards.replace(updatedCard, card => card.id === cardId);
			return updatedCard;
		} catch (e) {
			logger.debug("CardManager", "Failed to retrieve card", e);
			throw e;
		}
	}

	public async getCardImage(cardId: string) {
		try {
			const cardImage = await this.cardService.getCardImage(cardId);
			return cardImage;
		} catch (e) {
			logger.debug("CardManager", "Failed to get card image", e);
			return "";
		}
	}

	public async getOutstandingsCard(cardId: string, url: string | undefined = undefined) {
		try {
			this.outstandings.set([]);
			const cardOutstandings = await this.cardService.getOutstandings(cardId, url);
			this.outstandings.set(cardOutstandings);
			return cardOutstandings;
		} catch (e) {
			logger.debug("CardManager", "Failed to get oustandings card", e);
			throw e;
		}
	}

	public async updateOutstanding(cardId: string, params: IUpdateOutstandingParams, scaToken?: string) {
		try {
			await this.cardService.updateOutstanding(cardId, params, scaToken || "");
		} catch (e) {
			logger.debug("CardManager", "Failed to enable feature", e);
			throw e;
		}
	}

	public async refabricateCard(cardId: string, replacementReason: string, scaToken?: string) {
		try {
			await this.cardService.refabricateCard(cardId, replacementReason, scaToken || "");
		} catch (e) {
			logger.debug("CardManager", "Failed to refabricate Card", e);
			throw e;
		}
	}

	public async getSensitiveCardDetails(cardId: string, scaToken?: string): Promise<OverrideCardData> {
		return this.cardDetailManager.getSensitiveCardDetails(cardId, scaToken);
	}

	private clear() {
		this.cards.set([]);
		this.outstandings.set([]);
	}
}
