import React from "react";
import styled from "styled-components";
import { DeleteButton, EditButton, MoreButton, OpenButton } from "./card-more-menu-actions";

type CardMoreMenuProps = {
    isOpen: boolean;
    isRTL?: boolean;
    alwaysOpened?: boolean;
    onToggle: () => void;
    onOpen?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
}

export default function CardMoreMenu(props: CardMoreMenuProps) {
    const { isRTL = true, alwaysOpened = false, isOpen, onToggle, onOpen, onEdit, onDelete } = props;
    return <MoreMenu $isRTL={isRTL}>
        {!alwaysOpened && (!!onDelete || !!onEdit || !!onOpen) && <MoreButton active={isOpen} onClick={onToggle} />}
        {!!onDelete && <DeleteButton show={alwaysOpened || isOpen} onClick={onDelete} />}
        {!!onEdit && <EditButton show={alwaysOpened || isOpen} onClick={onEdit} />}
        {!!onOpen && <OpenButton show={alwaysOpened || isOpen} onClick={onOpen} />}
    </MoreMenu>;
}

const MoreMenu = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	display: flex;
	flex-direction: ${props => (props.$isRTL ? `row` : `row-reverse`)};
	align-items: center;
	right: ${props => (props.$isRTL ? `unset` : `25px`)};
	left: ${props => (props.$isRTL ? `25px` : `unset`)};
	top: 0px;
	bottom: 0px;
	z-index: 1;
`;