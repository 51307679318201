import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ChevronIcon } from "../svg/chevron";
import { Direction } from "./direction";

interface ArrowButtonProps extends HTMLAttributes<HTMLDivElement> {
	onClick?: () => void;
	onPressStart?: () => void;
	onPressEnd?: (canceled?: boolean) => void;
	disabled?: boolean;
	direction: Direction;
}

export const ArrowButton = (props: ArrowButtonProps) => {
	const { direction, onClick, disabled, onPressStart, onPressEnd, ...otherProps } = props;

	return (
		<RoundButton
			role="button"
			onClick={disabled ? undefined : onClick}
			onMouseDown={disabled ? undefined : onPressStart}
			onMouseUp={disabled ? undefined : () => onPressEnd?.()}
			onMouseLeave={() => onPressEnd?.(true)}
			disabled={disabled}
			{...otherProps}>
			{direction === "left" ? <LeftArrowIcon /> : <RightArrowIcon />}
		</RoundButton>
	);
};

const RightArrowIcon = styled(ChevronIcon)`
	width: 20px;
	height: 20px;
	svg {
		fill: #000000;
	}
`;

const LeftArrowIcon = styled(RightArrowIcon)`
	transform: rotate(180deg);
`;

const RoundButton = styled.div<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	border-radius: 25px;
	background-color: #ececec;
	cursor: ${props => (props.disabled ? "default" : "pointer")};
	opacity: ${props => (props.disabled ? 0.3 : 1)};
	transition: background-color 0.2s linear;

	${props =>
		!props.disabled
			? css`
					&:hover,
					:active,
					:focus {
						background-color: #e2e2e2;
					}
			  `
			: ""}
`;
