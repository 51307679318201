import styled from "styled-components";
import { theme } from "../../styles/theme";

export const PageSectionTitle = styled.h2`
	font-size: 1.125rem;
	${theme.boldText};
	color: #000000;
`;

export const PageSectionDescription = styled.span`
	font-size: 0.9375rem;
	${theme.text};
	color: #b1b1b1;
`;
