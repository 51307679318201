import { Paginated, PaginationOptions } from "../../utils/pagination";

import { Acceptor } from "./acceptor";
import { AcceptorCategory } from "./acceptor-category";
import { ApiService } from "../../core/net/api-service";
import { logger } from "../../core/logging/logger";

export class AcceptorService {
	public constructor(private apiService: ApiService) {}

	public async searchAcceptors(
		latitude: number,
		longitude: number,
		supportDedicatedTransfers: boolean,
		categories?: AcceptorCategory[],
		pagination?: PaginationOptions,
		radius?: number,
	) {
		try {
			const response = await this.apiService.instance.get<Paginated<Acceptor>>(`/acceptors`, {
				params: {
					latitude,
					longitude,
					supportDedicatedTransfers,
					categories: categories ? categories.map(category => category.id) : undefined,
					...pagination,
					...radius && { radius }
				},
			});
			const result = response.data;
			return result;
		} catch (e) {
			logger.debug("AcceptorService", "Search acceptor failed", e);
			throw e;
		}
	}

	public async getAcceptorCategories() {
		try {
			const response = await this.apiService.instance.get<AcceptorCategory[]>(`/acceptors/categories`);
			return response.data;
		} catch (e) {
			logger.debug("AcceptorService", "Get acceptor categories failed", e);
			throw e;
		}
	}
}
