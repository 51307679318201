import { FormInput, FormInputWithHierarchy } from "../../core/data-forms/form-input-types";
import { InitialOnboarding, OnboardingFile } from "./onboarding";
import { featuresManager, onboardingManager } from "../../core/service/services";

import { FormFieldValueType } from "../../../mobile/core/data-forms/form-field";
import { UploadedImage } from "../../utils/images-converter";
import { UrlLink } from "../BaseUrl";
import { useAsyncEffect } from "../../utils/utils";
import { useObservable } from "../../utils/observable";
import { useState } from "react";

export const useOnboardingLinks = () => {
	const features = useObservable(featuresManager.features);

	const getLinks = (onboardingFile?: OnboardingFile) => onboardingFile?.links ?? [];

	const canUpdate = (onboardingFile?: OnboardingFile | null) => {
		let links = [] as UrlLink[];
		if (onboardingFile) {
			links = getLinks(onboardingFile);
		}
		return links.some(link => link.rel === "updateFile") && features.sdaCustomerOnboardingUpdate;
	};

	const canSubmit = (onboardingFile?: OnboardingFile | null) => {
		let links = [] as UrlLink[];
		if (onboardingFile) {
			links = getLinks(onboardingFile);
		}
		return links.some(link => link.rel === "submitFile") && features.sdaCustomerOnboardingSubmit;
	};

	return {
		getLinks,
		canUpdate,
		canSubmit,
	};
};

export const useOnboardingFile = () => {
	const onboardingFile = useObservable(onboardingManager.onboardingFile);
	const steps = useObservable(onboardingManager.steps);
	const [loading, setLoading] = useState<boolean>(true);
	const needSaveBeforeSubmit = useObservable(onboardingManager.updated);
	const isUpdating = useObservable(onboardingManager.updating);
	const isSubmitting = useObservable(onboardingManager.submitting);
	const error = useObservable(onboardingManager.error);
	const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
	const [erroredFields, setErroredFields] = useState<string[]>([]);
	const { canSubmit, canUpdate } = useOnboardingLinks();
	const needOnboardingFileRedirect = useObservable(onboardingManager.needOnboardingFileRedirect);
	const [clientDelegateErrorDetected, setClientDelegateErrorDetected] = useState<boolean>(false);

	useAsyncEffect(async () => {
		try {
			setLoading(true);
			await onboardingManager.initialize();
		} catch (e) {
			if (e === "CLIENT_DELEGATE_USER_NOT_ALLOWED") {
				setClientDelegateErrorDetected(true);
			} else {
				console.error(e);
			}
		} finally {
			setLoading(false);
		}
	}, []);

	const moveForward = async () => {
		// console.log("moveForward", currentStepIndex, steps);
		setErroredFields([]);
		if (currentStepIndex + 1 <= steps.length) {
			if (needSaveBeforeSubmit && canUpdate(onboardingFile)) {
				try {
					await onboardingManager.updateOnboardingFile(currentStepIndex);
					if (currentStepIndex + 1 < steps.length) {
						setCurrentStepIndex(currentStepIndex + 1);
					}
				} catch (e) {
					if (
						e.hasOwnProperty("message") &&
						(e.message === "Invalid fields" || e.message === "Missing required fields")
					) {
						setErroredFields(e.fields);
					} else {
						console.log("error updating onboarding file", e);
					}
				}
			} else {
				if (currentStepIndex + 1 < steps.length) {
					setCurrentStepIndex(currentStepIndex + 1);
				}
			}
		}
	};

	const moveBackward = () => {
		if (currentStepIndex > 0) {
			setCurrentStepIndex(currentStepIndex - 1);
		}
	};

	const submitOnboardingFile = async () => {
		if (!canSubmit(onboardingFile)) {
			return;
		}
		try {
			await onboardingManager.submitOnboardingFile();
		} catch (e) {
			console.log("error submitting onboarding file", e);
		}
	};

	return {
		onboardingFile,
		steps,
		loading,
		currentStepIndex,
		currentStep: steps[currentStepIndex],
		needSaveBeforeSubmit,
		isUpdating,
		isSubmitting,
		error,
		erroredFields,
		canSubmit: canSubmit(onboardingFile),
		canUpdate: canUpdate(onboardingFile),
		isLastStep: currentStepIndex === steps.length - 1,
		needOnboardingFileRedirect,
		clientDelegateErrorDetected,
		moveForward,
		moveBackward,
		submitOnboardingFile,
		initiate: async (data: InitialOnboarding) => {
			await onboardingManager.initiate(data);
		},
		fillResponse: (input: FormInput, value: FormFieldValueType) => {
			onboardingManager.fillResponse(input, value, currentStepIndex);
		},
		fillMultiImages: (input: FormInputWithHierarchy, values: UploadedImage[]) =>
			onboardingManager.fillMultiImagesResponse(input, values, currentStepIndex),
	};
};
