import moment, { Moment } from "moment";

import { Observable } from "../../../utils/observable";
import { TimeRange } from "./timerange";
import { TransactionDirection } from "../transaction/transaction-filter";

export interface TimeRangeSelection {
	timeRange: TimeRange;
	bounds: [Moment | null, Moment | null];
}

const NO_BOUNDS: [null, null] = [null, null];

export class TransactionSearchManager {
	public timeRangeSelection = new Observable<TimeRangeSelection>({
		timeRange: TimeRange.Quarter,
		bounds: this.getTimeBounds(TimeRange.Quarter),
	});
	public absoluteAmountSelection = new Observable<[number | null, number | null]>(NO_BOUNDS);
	public directionSelection = new Observable<TransactionDirection>(TransactionDirection.ALL);
	public amountRange = new Observable<[number | null, number | null]>(NO_BOUNDS);

	setTimeRange(timeRange: TimeRange, bounds?: [Moment | null, Moment | null]) {
		if (timeRange === TimeRange.Other) {
			this.timeRangeSelection.set({ timeRange: TimeRange.Other, bounds: bounds ? bounds : NO_BOUNDS });
		} else {
			this.timeRangeSelection.set({ timeRange, bounds: this.getTimeBounds(timeRange) });
		}
	}

	setAmountRange(low: number | null, high: number | null) {
		this.amountRange.set([low, high]);
	}

	setTransactionDirection(direction: TransactionDirection) {
		this.directionSelection.set(direction);
	}

	private getTimeBounds(timeRange: Exclude<TimeRange, TimeRange.Other>): [Moment | null, Moment | null] {
		const today = moment();
		const todayRef = moment();
		switch (timeRange) {
			case TimeRange.Quarter:
				return [todayRef.subtract(3, "months"), today];
			case TimeRange.Week:
				return [todayRef.subtract(7, "days"), today];
			case TimeRange.Month:
				return [todayRef.subtract(1, "months"), today];
		}
	}

	public clear() {
		this.timeRangeSelection.set({ timeRange: TimeRange.Quarter, bounds: NO_BOUNDS });
		this.absoluteAmountSelection.set(NO_BOUNDS);
		this.amountRange.set(NO_BOUNDS);
		this.directionSelection.set(TransactionDirection.ALL);
	}
}
