import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { useRTL } from "../../../domain/language/use-rtl";
import { theme } from "../../styles/theme";

export interface RadioButtonsInputProps<T> extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
	innerId: string;
	value: T;
	options: readonly T[];
	onChange: (v: T) => void;
	disabled?: boolean;
	className?: string;
	orientation?: "left" | "right";
	itemRenderer?: (item: T) => string;
	buttonColorRenderer?: (item: T) => string;
}

export type RadioButtonsType<K> = React.FC<RadioButtonsInputProps<K>>;

export function RadioButtonsInput<T>({
	innerId: id,
	onChange,
	options,
	value,
	className,
	orientation = "left",
	itemRenderer = v => `${v}`,
	buttonColorRenderer,
	disabled,
	...rest
}: RadioButtonsInputProps<T>): JSX.Element {
	const valueStr = itemRenderer(value);
	const selectedIndex = options.findIndex(it => itemRenderer(it) === valueStr);
	const { isRTL } = useRTL();

	return (
		<RadioContainer className={className} $isRTL={isRTL}>
			{options.map((v, index) => (
				<ButtonWrapper key={index} orientation={orientation} disabled={disabled} $isRTL={isRTL}>
					<CheckedInput
						type="radio"
						id={`${id}-${index}`}
						name={id}
						defaultChecked={selectedIndex === -1 ? undefined : selectedIndex === index}
						color={buttonColorRenderer?.(v)}
						disabled={disabled}
						value={itemRenderer(v)}
						onChange={() => onChange(v)}
						$isRTL={isRTL}
						{...rest}
					/>
					<StyledLabel htmlFor={`${id}-${index}`}>{itemRenderer(v)}</StyledLabel>
				</ButtonWrapper>
			))}
		</RadioContainer>
	);
}

const RadioContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: row;

	> *:not(:last-child) {
		margin-right: ${props => (props.$isRTL ? 0 : 10)}px;
		margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
	}
`;

const StyledLabel = styled.label`
	font-size: 0.875rem;
	${theme.boldText}
`;

const ButtonWrapper = styled.div<{ orientation: "left" | "right"; disabled?: boolean; $isRTL: boolean }>`
	position: relative;
	display: flex;
	flex-direction: ${props => (props.orientation === "left" ? "row" : "row-reverse")};
	justify-content: ${props => (props.orientation === "left" ? "flex-start" : "flex-end")};
	align-items: center;

	> * {
		cursor: ${props => (props.disabled ? null : "pointer")};
	}

	> *:not(:last-child) {
		${props => (props.orientation === "left" && !props.$isRTL ? "margin-right: 10px" : "margin-left: 10px")};
	}
`;

const CheckedInput = styled.input<{ color?: string; $isRTL: boolean }>`
	width: 25px;
	height: 25px;
	background-color: #fff;
	border-radius: 15px;
	display: inline-block;
	border: solid 1px ${props => props.color ?? theme.mainColor};
	position: relative;
	-webkit-appearance: none;

	:checked:after {
		background-color: ${props => props.color ?? theme.mainColor};
		border-radius: 25px;
		content: "";
		display: block;
		height: 11px;
		width: 11px;
		left: ${props => (props.$isRTL ? `unset` : `6px`)};
		right: ${props => (props.$isRTL ? `6px` : `unset`)};
		top: 6px;
		position: relative;
	}
`;
