import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { PincodeSubmission } from "./pincode";

export class PincodeService {
	public constructor(private apiService: ConnectedApiService) {}

	public async changePincode(currentPincode: PincodeSubmission, newPincode: PincodeSubmission) {
		try {
			const response = await this.apiService.instance.put<void>(`/client/pincode`, {
				currentPincode: currentPincode,
				newPincode: newPincode,
			});
			return response.data;
		} catch (e) {
			logger.debug("PincodeService", "Change pincode failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
