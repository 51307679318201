import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../../common/error-message";
import { UIConstants } from "../../../../styles/uiConstants";

interface FormWithButtonsProps {
	children: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	overrideActions?: React.ReactNode;
	disableSubmitButton?: boolean;
	submitWording?: string;
	errorMessage?: string | null;
	onSubmit?: () => void;
	onBackward?: () => void;
}
export const FormWithButtons: React.FC<FormWithButtonsProps> = ({
	children,
	disabled,
	overrideActions,
	errorMessage,
	loading,
	disableSubmitButton,
	submitWording,
	onSubmit,
	onBackward,
}) => {
	const { formatMessage } = useIntl();
	return (
		<FormContainer
			disabled={disabled}
			onSubmit={async e => {
				e?.preventDefault();
				onSubmit?.();
			}}>
			<div style={{ flex: 1 }}>{children}</div>
			{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
			{overrideActions}
			{!overrideActions && (
				<ButtonWrapper>
					{onBackward && (
						<NavigationButton size="S" type="button" onClick={onBackward}>
							{formatMessage("registerScreen.back")}
						</NavigationButton>
					)}
					{!!onSubmit && (
						<NavigationButton showSpinner={loading} size="S" type="submit" disabled={disableSubmitButton}>
							{submitWording || formatMessage("registerScreen.next")}
						</NavigationButton>
					)}
				</ButtonWrapper>
			)}
		</FormContainer>
	);
};

const FormContainer = styled.form<{ disabled?: boolean }>`
	display: flex;
	height: 100%;
	flex-direction: column;
	margin-bottom: 0;
	width: 100%;
	opacity: ${props => (props.disabled ? 0.5 : 1)};
	pointer-events: ${props => (props.disabled ? "none" : "auto")};
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 44px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin-bottom: 25px;
`;

const NavigationButton = styled(PrimaryButton)`
	margin-left: 10px;
	margin-right: 10px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		padding: 13px 30px;
	}
`;
