import { AccountManager } from "../../account/account-manager";
import { Amount } from "../../../core/amount/amount";
import { ConfirmationMode } from "../transaction-request";
import { DedicatedTransfer } from "./dedicated-transfer";
import { DedicatedTransferService } from "./dedicated-transfer-service";
import { ObservableArray } from "../../../utils/observable-array";
import { PincodeSubmission } from "../../pincode/pincode";
import { Transaction } from "../../accounting-transaction/transaction/transaction";
import { TransactionCode } from "../../transactions-codes/transaction-code";
import { TransactionGeolocationManager } from "../../../core/geolocalisation/transaction-geolocalisation-manager";
import { TransactionsManager } from "../../accounting-transaction/transaction/transactions-manager";
import { accountsAffectedByTransaction } from "../../accounting-transaction/transaction/accounts-affected-by-transaction";
import { runAfterInteractions } from "../../../core/interaction/interaction-manager";

export class DedicatedTransferManager {
	public transactionCodes = new ObservableArray<TransactionCode>([]);

	public constructor(
		private dedicatedTransferService: DedicatedTransferService,
		private geolocationManager: TransactionGeolocationManager,
		private accountManager: AccountManager,
		private transactionsManager: TransactionsManager
	) {}

	public async startTransfer(
		recipientId: string,
		acceptorId: string,
		amount: Amount,
		label: string | undefined = undefined
	) {
		try {
			const location = await this.geolocationManager.updatePosition();
			return await this.dedicatedTransferService.startTransfer(recipientId, acceptorId, amount, label, location);
		} catch (e) {
			console.log(e);
			throw e;
		}
	}

	public async confirmSendDedicatedTransfer(
		confirmationMode: ConfirmationMode,
		dedicatedTransfer: DedicatedTransfer,
		pincode?: PincodeSubmission
	) {
		const location = this.geolocationManager.getPosition();
		const result = await this.dedicatedTransferService.confirmSendDedicatedTransfer(
			confirmationMode,
			dedicatedTransfer,
			pincode,
			location
		);
		this.refreshAccountAndTransactions(result.metadata.transaction);
		return result;
	}

	private refreshAccountAndTransactions(transaction: Transaction) {
		runAfterInteractions(async () => {
			const accountIds = accountsAffectedByTransaction(transaction);
			await this.accountManager.refresh();
			await this.transactionsManager.refresh(accountIds);
		});
	}
}
