import { ApiService } from "../../core/net/api-service";
import { Configuration } from "./configuration";
import { logger } from "../../core/logging/logger";

export class ConfigurationService {
	public constructor(private apiService: ApiService) {}

	public async fetchConfiguration() {
		try {
			const response = await this.apiService.instance.get<Configuration>(`/configuration`);
			return response.data;
		} catch (e) {
			logger.debug("ConfigurationService", "Fetch configuration failed", e);
			throw e;
		}
	}
}
