export interface UrlLink {
	rel: string;
	href: string;
}

export interface BaseUrl {
	links?: UrlLink[];
}

export const getUrlFromLink = (links: UrlLink[] | undefined, relLink: string) => {
	if (!links) {
		return undefined;
	}
	const index = links.findIndex(link => link.rel === relLink);
	if (index > -1) {
		return links[index].href;
	} else {
		return undefined;
	}
};
