import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useRTL } from "../../../../domain/language/use-rtl";
import { SelectInput, SelectInputProps } from "../../../common/forms/select-input";
import { ChevronIcon } from "../../../common/svg/chevron";
import { theme } from "../../../styles/theme";

interface SettingsItemProps extends HTMLAttributes<HTMLDivElement> {
	title: string;
	subtitle: string;
	direction?: "row" | "column";
}
export const SettingsItem = (props: SettingsItemProps) => {
	const { title, subtitle, direction, children, ...rest } = props;
	return (
		<SettingsItemContainer direction={direction} {...rest}>
			<SettingsLabelsContainer>
				<SettingsTitle>{title}</SettingsTitle>
				<SettingsSubtitle>{subtitle}</SettingsSubtitle>
			</SettingsLabelsContainer>
			{children}
		</SettingsItemContainer>
	);
};

export const SettingsActionItem = (props: SettingsItemProps & { onClick: () => void }) => {
	const { onClick, ...rest } = props;
	const { isRTL } = useRTL();
	return (
		<SettingsItem {...rest} onClick={onClick}>
			<ChevronContainer>
				<ChevronIcon
					width="18"
					height="18"
					fill="black"
					style={{ transform: isRTL ? "rotate(180deg)" : "rotate(0deg)" }}
				/>
			</ChevronContainer>
		</SettingsItem>
	);
};

interface SettingsSelectItemProps extends Omit<SettingsItemProps, "direction"> {
	selectInputProps: SelectInputProps<string>;
}
export const SettingsSelectItem = (props: SettingsSelectItemProps) => {
	const { selectInputProps, ...rest } = props;
	return (
		<SettingsItem {...rest} direction="column">
			<ActionButtonContainer>
				<SelectInput {...selectInputProps} />
			</ActionButtonContainer>
		</SettingsItem>
	);
};

const SettingsItemContainer = styled.div<{ direction?: "row" | "column" }>`
	display: flex;
	flex-direction: ${props => (props.direction === "column" ? "column" : "row")};
	:hover {
		cursor: pointer;
	}
`;

const SettingsLabelsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const SettingsTitle = styled.div`
	${theme.mediumText};
	color: #000000;
	margin-bottom: 10px;
	font-size: 0.9375rem;
`;

const SettingsSubtitle = styled.span`
	font-size: 0.9375rem;
	color: #b1b1b1;
`;

const ActionButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 10px;
`;

const ChevronContainer = styled.div`
	margin: 0 10px;
	display: flex;
	flex-direction: row;
`;
