import { CardUpdateOutstandingStep, useOutstandings } from "../../../../../shared/domains/cards/use-outstandings";
import React, { useCallback, useState } from "react";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../shared/utils/phone-number";

import { AmountInput } from "../../../common/forms/amount-input";
import { CARD_UPDATE_OUTSTANDING_MODAL_ID } from "../../../../core/modal/modal-id";
import { Card } from "../../../../../shared/domains/cards/card";
import { DurationUnit } from "../../../../../shared/domains/cards/outstanding";
import { IUpdateOutstandingParams } from "../../../../../mobile/ui/screens/cards/components/outstandings/update-outstanding-screen";
import { MainColorSpinner } from "../../../common/spinner";
import { Modal } from "../../../common/modal/modal";
import { OtpConfirm } from "../../recipient/otp-confirm";
import { OutstandingCard } from "../../../../../mobile/ui/screens/cards/components/outstandings/outstanding-card";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ResultView } from "../../result/result-view";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { VerifyAuthenticationStatus } from "../../../../../shared/domains/strong-customer-authentication/strong-customer-authentication";
import { cardService } from "../../../../../shared/core/service/services";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { useClient } from "../../../../domain/authentication/use-client";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";

export interface ICardUpdateOutstandingModalProps {
	card: Card;
	outstanding: OutstandingCard;
	onSuccess: () => void;
}

export const CardUpdateOutstandingModal = (props: ICardUpdateOutstandingModalProps) => {
	const { card, outstanding, onSuccess } = props;
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { client } = useClient();

	const { loading, error, scaToken, updateOutstanding, resetStep, step } = useOutstandings(card);

	const [updateOutstandingParams, setUpdateOutstandingParams] = useState<IUpdateOutstandingParams>({
		limit: outstanding.type,
		amount: outstanding.maxValue,
	});

	const durationLabel = (unit: DurationUnit) => {
		const durationUnit = "cardOptions.outstandings.modal.inputLabelUnit";
		switch (unit) {
			case DurationUnit.Day:
				return durationUnit + ".day";
			case DurationUnit.Week:
				return durationUnit + ".week";
			case DurationUnit.Month:
				return durationUnit + ".month";
			case DurationUnit.Year:
				return durationUnit + ".year";
		}
	};

	const handleOTPValidation = async (otp, scaToken) => {
		if (scaToken) {
			try {
				const response = await cardService.scaAuthenticationVerifyToken(scaToken, otp);
				if (response.status === VerifyAuthenticationStatus.DONE) {
					await updateOutstanding(updateOutstandingParams, scaToken);
				}
			} catch (e) {
				setErrorMessage(e.response?.data?.error?.message || e.toString());
			}
		} else {
			throw new Error("Missing SCA Token");
		}
	};

	const handleSubmit = async () => {
		try {
			await updateOutstanding(updateOutstandingParams);
		} catch (e) {
			setErrorMessage(e.response.data.error.message);
		}
	};

	const { formatMessage } = useIntl();
	const closeModal = useCallback(() => Modal.dismiss(CARD_UPDATE_OUTSTANDING_MODAL_ID), []);

	return (
		<RoundedModalContainer closeButton={true} id={CARD_UPDATE_OUTSTANDING_MODAL_ID}>
			<StyledModalContainer>
				{step === CardUpdateOutstandingStep.UpdateLimit && (
					<>
						<div>
							<StyledModalTitle>{formatMessage("cardOptions.outstandings.modal.title")}</StyledModalTitle>
							{outstanding.rawPeriod && (
								<StyledInputLabel>
									{formatMessage("cardOptions.outstandings.modal.inputLabel")}{" "}
									{formatMessage(durationLabel(outstanding.rawPeriod?.unit))}
								</StyledInputLabel>
							)}
							<div style={{ marginTop: 12 }}>
								<AmountInput
									variant="big"
									id="amount"
									value={outstanding.maxAmount}
									onChange={i =>
										setUpdateOutstandingParams({
											...updateOutstandingParams,
											amount: i.value,
										})
									}
								/>
							</div>
							{/* <StyledMaximumLabel>{formatMessage('cardOptions.outstandings.modal.maximumLabel')}</StyledMaximumLabel> */}
							{loading && (
								<StyledModalInner>
									<StyledBlueSpinner key="spinner" />
								</StyledModalInner>
							)}
							{error.length > 0 && <StyledErrorLabel>{error}</StyledErrorLabel>}
						</div>
						<StyledButton size="S" onClick={() => handleSubmit()}>
							{formatMessage("cardOptions.outstandings.modal.submitButtonLabel")}
						</StyledButton>
					</>
				)}
				{step === CardUpdateOutstandingStep.SCACheck && (
					<OtpConfirm
						submitOtp={(otp: string) => handleOTPValidation(otp, scaToken)}
						errorMessage={errorMessage}
						phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
						loading={loading}
					/>
				)}
				{step === CardUpdateOutstandingStep.Error && (
					<StyledResultView
						type={"error"}
						title={formatMessage("resultScreen.updateOutstanding.failureTitle")}
						subtitle={formatMessage("resultScreen.updateOutstanding.failureSubtitle")}
						description={formatMessage("resultScreen.updateOutstanding.failureDescription")}
						error={error.response?.data?.error?.message || error.toString()}
						onConfirm={() => {
							closeModal();
						}}
					/>
				)}
				{step === CardUpdateOutstandingStep.Success && (
					<StyledResultView
						type={"success"}
						title={formatMessage("resultScreen.updateOutstanding.successTitle")}
						subtitle={formatMessage("resultScreen.updateOutstanding.defaultSuccessSubtitle")}
						onConfirm={() => {
							closeModal();
						}}
					/>
				)}
			</StyledModalContainer>
		</RoundedModalContainer>
	);
};

const StyledButton = styled(PrimaryButton)`
	margin-top: 32px;
	align-self: center;
`;

const StyledInputLabel = styled.p`
	${theme.bodyGreyRegular}
	font-size: 20px;
	text-align: left;
`;

const StyledErrorLabel = styled.p`
	font-size: 14px;
	color: red;
	margin-top: 12px;
`;

const StyledResultView = styled(ResultView)`
	margin-left: 20px;
	margin-right: 20px;
`;

const StyledMaximumLabel = styled.p`
	${theme.bodyGreyRegular}
	font-size: 14px;
	margin-top: 12px;
`;

const StyledModalTitle = styled.span`
	${theme.boldText}
	padding-bottom: 40px;
	font-size: 1.125rem;
	color: #000000;
`;

const StyledModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const StyledModalInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
`;

const StyledBlueSpinner = styled(MainColorSpinner)``;
