import React, { SVGProps } from "react";

export function WalletIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
			<g clipPath="url(#26vxymbxta)">
				<path d="M5 17.57V7.713c.011-.04.026-.078.034-.119.039-.216.06-.436.116-.647.251-.952.81-1.613 1.795-1.855.137-.034.275-.062.412-.093H17c.031.013.061.03.094.037a1.712 1.712 0 0 1 1.334 1.678c.001.244.004.488-.002.732-.002.103.02.147.136.177.858.217 1.301.992 1.299 1.688-.01 2.654-.009 5.308-.001 7.961A1.734 1.734 0 0 1 18.142 19c-3.808-.005-7.617-.002-11.425-.003a1.706 1.706 0 0 1-1.66-1.265c-.016-.055-.038-.108-.057-.163zM6.217 7.57H17.08c.036 0 .072-.003.107 0 .082.01.1-.031.1-.104-.004-.25.005-.5-.004-.75-.011-.332-.251-.563-.584-.574-.048-.002-.096 0-.143 0H7.954c-.166 0-.333.011-.498.03-.753.08-1.233.616-1.24 1.398zm11.64 5.847a1.3 1.3 0 0 0-1.295-1.276c-.699.006-1.29.597-1.274 1.294.018.73.575 1.278 1.292 1.271.739-.007 1.26-.573 1.277-1.289z" />
			</g>
			<defs>
				<clipPath id="26vxymbxta">
					<path transform="translate(5 5)" d="M0 0h14.861v14H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
