import { ReadableSignal, Signal } from "micro-signals";
import { useEffect, useState } from "react";

export interface ReadableObservable<T> {
	onChange: ReadableSignal<T>;
	get(): T;
}

abstract class BaseObservable<T> implements ReadableObservable<T> {
	protected _val: T;

	public constructor(val: T) {
		this._val = val;
	}

	abstract onChange: ReadableSignal<T>;

	public get(): T {
		return this._val;
	}
}

export class Observable<T> extends BaseObservable<T> {
	private _onChange = new Signal<T>();

	public get onChange(): ReadableSignal<T> {
		return this._onChange;
	}

	public set(val: T) {
		if (this._val !== val) {
			this._val = val;
			this._onChange.dispatch(val);
		}
	}
}

export function useObservable<T>(observable: Observable<T>) {
	const [value, setValue] = useState(observable.get());
	useEffect(() => {
		observable.onChange.add(setValue);
		if (observable.get() !== value) {
			setValue(observable.get());
		}
		return () => observable.onChange.remove(setValue);
	}, [observable, value]);
	return value;
}
