import React, { SVGProps } from "react";

export function BigBankToWalletIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" {...props}>
			<g fill={props.fill} fillRule="nonzero">
				<path d="M16.388 12.386h1.875v4.489h-1.875zM12.838 12.386h1.875v4.489h-1.875zM9.287 12.386h1.875v4.489H9.287zM5.737 12.386h1.875v4.489H5.737zM18.888 10.98v-.47H5.112v.47c0 .258.21.468.469.468H18.42c.258 0 .468-.21.468-.469zM19.531 17.813H4.469A.469.469 0 0 0 4 18.28v1.25c0 .26.21.469.47.469H19.53c.259 0 .469-.21.469-.469v-1.25a.469.469 0 0 0-.469-.468zM4.469 9.573H19.53a.469.469 0 0 0 .246-.868L12.246 4.07a.469.469 0 0 0-.492 0L4.223 8.705a.469.469 0 0 0 .246.868zm7.212-2.816h.638a.469.469 0 1 1 0 .938h-.638a.469.469 0 1 1 0-.938z" />
			</g>
		</svg>
	);
}
