import { Amount } from "../../../core/amount/amount";
import { logger } from "../../../core/logging/logger";
import { ConnectedApiService } from "../../../core/net/connected-api-service";
import { AdditionalDataValue, Spending } from "./spending";

export interface AddDetailedSpending {
	categoryId: string;
	subcategoryId: string;
	label: string;
	amount?: Amount;
	additionalData?: AdditionalDataValue[];
}

export interface UpdateDetailedSpending {
	categoryId?: string;
	subcategoryId?: string;
	label?: string;
	amount?: Amount;
	additionalData?: AdditionalDataValue[];
}

export class SpendingsService {
	public constructor(private apiService: ConnectedApiService) {}

	public async getSpendingDetails(transactionId: string, url: string | undefined): Promise<Spending[]> {
		try {
			const response = await this.apiService.instance.get<Spending[]>(
				url ?? `/transactions/${transactionId}/spendings`
			);
			return response.data;
		} catch (e) {
			logger.debug("SpendingsService", "Get spending details failed", JSON.stringify(e));
			throw e;
		}
	}

	public async addDetailedSpending(
		transactionId: string,
		detailedSpending: AddDetailedSpending,
		url: string | undefined
	): Promise<Spending> {
		try {
			const response = await this.apiService.instance.post<Spending>(
				url ?? `/transactions/${transactionId}/spendings`,
				detailedSpending
			);
			return response.data;
		} catch (e) {
			logger.debug("SpendingsService", "Add detailed spending failed", e);
			if (e?.response?.status === 403 && e?.response?.data?.error?.code === 71404) {
				throw e?.response?.data?.error?.message;
			} else {
				throw undefined;
			}
		}
	}

	public async updateSpendingDetails(
		transactionId: string,
		spendingId: string,
		detailedSpending: UpdateDetailedSpending,
		url: string | undefined
	): Promise<Spending> {
		try {
			const response = await this.apiService.instance.patch<Spending>(
				url ?? `/transactions/${transactionId}/spendings/${spendingId}`,
				detailedSpending
			);
			return response.data;
		} catch (e) {
			logger.debug("SpendingsService", "Updated detailed spending failed", e);
			throw e?.response?.data?.error?.message;
		}
	}

	public async deleteSpendingDetails(transactionId: string, spendingId: string, url: string | undefined) {
		try {
			await this.apiService.instance.delete(url ?? `/transactions/${transactionId}/spendings/${spendingId}`);
		} catch (e) {
			logger.debug("SpendingsService", "Delete detailed spending failed", e);
			throw e;
		}
	}
}
