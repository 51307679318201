import React, { SVGProps } from "react";

export function TransferIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
			<g clipPath="url(#7x1b55l5ja)">
				<path d="M4.478 15.461c.017.033.036.064.05.098.122.282.348.41.644.432.049.003.098 0 .147 0h11.903c-.048.052-.08.087-.114.12l-1.992 1.994c-.07.07-.138.141-.197.219a.685.685 0 0 0 .17.98c.297.192.652.144.909-.115.54-.544 1.085-1.086 1.627-1.628l1.733-1.733a.78.78 0 0 0 .21-.33.685.685 0 0 0-.14-.66c-.028-.034-.062-.063-.093-.095l-3.308-3.308-.063-.063c-.196-.195-.433-.24-.691-.17-.273.075-.427.274-.481.54a.643.643 0 0 0 .155.56c.038.045.081.085.123.127.685.686 1.37 1.371 2.057 2.056.034.035.072.065.135.12H5.24a.833.833 0 0 0-.358.06.677.677 0 0 0-.376.427c-.006.016-.02.03-.029.044v.325zM4.478 8.582c.01.01.024.018.026.03a.712.712 0 0 0 .194.323c1.132 1.13 2.264 2.259 3.392 3.394a.72.72 0 0 0 .823.146.685.685 0 0 0 .203-1.072c-.033-.037-.068-.07-.103-.105L6.946 9.23c-.034-.034-.066-.07-.113-.118h.194l11.81.001c.14 0 .269-.021.397-.083.324-.156.488-.628.255-.985-.135-.206-.332-.294-.567-.318-.048-.005-.098 0-.147 0H6.836l.087-.093L8.99 5.568c.028-.028.054-.057.084-.083.384-.343.242-1.002-.27-1.157-.016-.005-.03-.018-.045-.028h-.325c-.01.01-.017.024-.028.027a.705.705 0 0 0-.322.195L4.699 7.907a.924.924 0 0 0-.186.279c-.01.024-.024.047-.035.07v.326z" />
			</g>
			<defs>
				<clipPath id="7x1b55l5ja">
					<path transform="matrix(-1 0 0 1 19.6 4.3)" d="M0 0h15.122v15.121H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}
