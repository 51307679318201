import {
    ACCOUNTS_CACHE_STORAGE_KEY,
    BENEFICIARIES_CACHE_STORAGE_KEY,
    BILL_ISSUERS_CACHE_STORAGE_KEY,
    CLIENT_CACHE_STORAGE_KEY,
    TRANSACTIONS_CACHE_STORAGE_KEY,
} from "../../../web/core/storage/storage-keys";
import { Trust, TrustManager } from "../../../web/domain/trust/trust-manager";
import { BillCategory, BillIssuer } from "../../domains/bills/bill";

import _sodium from "libsodium-wrappers";
import { AccountDeleteService } from "../../../web/core/account-deletion/account-delete-service";
import { WebAuthExecutor } from "../../../web/core/auth/web-auth-executor";
import { LocalStorage } from "../../../web/core/cache/local-storage";
import { MemoryCache } from "../../../web/core/cache/memory-cache";
import { SessionCache } from "../../../web/core/cache/session-cache";
import { SessionStorage } from "../../../web/core/cache/session-storage";
import {
    WebTransactionGeolocalisationManager
} from "../../../web/core/geolocalisation/web-transaction-geolocalisation-manager";
import { WebAppStartManager } from "../../../web/core/lifecycle/app-start-manager";
import { WebNetworkInfoManager } from "../../../web/core/net/web-network-info-manager";
import { WebSecuredCookiesService } from "../../../web/core/net/web-secured-cookies-service";
import { WebTranslationsManager } from "../../../web/core/translations/web-translations-manager";
import { WebUUIDGenerator } from "../../../web/core/uuid/web-uuid-generator";
import { WebDeviceInfoService } from "../../../web/core/web-device-info-service";
import { WebAccountStatementsService } from "../../../web/domain/account-statements/web-account-statements-service";
import { WebOAuthService } from "../../../web/domain/authentication/web-oauth-service";
import { CardDetailsManager } from "../../../web/domain/cards/card-details-manager";
import { WebImagesConverter } from "../../../web/utils/web-images-converter";
import { AcceptorCategoriesManager } from "../../domains/acceptors/acceptor-categories-manager";
import { AcceptorManager } from "../../domains/acceptors/acceptor-manager";
import { AcceptorService } from "../../domains/acceptors/acceptor-service";
import { Account } from "../../domains/account/account";
import { AccountManager } from "../../domains/account/account-manager";
import { AccountService } from "../../domains/account/account-service";
import { TransactionSearchManager } from "../../domains/accounting-transaction/search/transaction-search-manager";
import { AttachmentsManager } from "../../domains/accounting-transaction/transaction/attachments-manager";
import { AttachmentsService } from "../../domains/accounting-transaction/transaction/attachments-service";
import { CategorizationsManager } from "../../domains/accounting-transaction/transaction/categorizations-manager";
import { CategorizationsService } from "../../domains/accounting-transaction/transaction/categorizations-service";
import { SpendingsManager } from "../../domains/accounting-transaction/transaction/spendings-manager";
import { SpendingsService } from "../../domains/accounting-transaction/transaction/spendings-service";
import { AccountingTransaction } from "../../domains/accounting-transaction/transaction/transaction";
import { TransactionManager } from "../../domains/accounting-transaction/transaction/transaction-manager";
import { TransactionService } from "../../domains/accounting-transaction/transaction/transaction-service";
import { TransactionsManager } from "../../domains/accounting-transaction/transaction/transactions-manager";
import { AuthenticationConfigurationService } from "../../domains/authentication/authentication-configuration-service";
import { AuthenticationManager } from "../../domains/authentication/authentication-manager";
import { AuthenticationService } from "../../domains/authentication/authentication-service";
import { AuthorizationHold } from "../../domains/authorization-hold/authorization-hold";
import { AuthorizationHoldManager } from "../../domains/authorization-hold/authorization-hold-manager";
import { AuthorizationHoldService } from "../../domains/authorization-hold/authorization-hold-service";
import { BicReferentialManager } from "../../domains/bic-referential/bic-referential-manager";
import { BicReferentialService } from "../../domains/bic-referential/bic-referential-service";
import { BillIssuerManager } from "../../domains/bills/bill-issuer-manager";
import { BillIssuerSelectionManager } from "../../domains/bills/bill-issuer-selection-manager";
import { BillManager } from "../../domains/bills/bill-manager";
import { BillService } from "../../domains/bills/bill-service";
import { CardFilterManager } from "../../domains/cards/card-filter-manager";
import { CardManager } from "../../domains/cards/card-manager";
import { CardService } from "../../domains/cards/card-service";
import { Client } from "../../domains/client/client";
import { ClientManager } from "../../domains/client/client-manager";
import { ClientService } from "../../domains/client/client-service";
import { ConfigurationManager } from "../../domains/configuration/configuration-manager";
import { ConfigurationService } from "../../domains/configuration/configuration-service";
import { CustomerActionService } from "../../domains/customer-actions/customer-actions-service";
import { RegisterManager } from "../../domains/enrollment/register-manager";
import { RegisterService } from "../../domains/enrollment/register-service";
import { FeaturesManager } from "../../domains/features/features-manager";
import { OnboardingManager } from "../../domains/onboarding/onboarding-manager";
import { OnboardingService } from "../../domains/onboarding/onboarding-service";
import { CheckPincodeService } from "../../domains/pincode/check-pincode-service";
import { PincodeKeyboardService } from "../../domains/pincode/pincode-keyboard-service";
import { PincodeService } from "../../domains/pincode/pincode-service";
import { ProductManager } from "../../domains/products/product-manager";
import { ProductService } from "../../domains/products/product-service";
import { BeneficiaryManager } from "../../domains/recipients/beneficiary-manager";
import { BeneficiaryService } from "../../domains/recipients/beneficiary-service";
import { ExternalAccountManager } from "../../domains/recipients/external-account-manager";
import { ExternalAccountService } from "../../domains/recipients/external-account-service";
import { Recipient } from "../../domains/recipients/recipient";
import { ServiceDomainManager } from "../../domains/service-domain/service-domain-manager";
import { ServiceDomainService } from "../../domains/service-domain/service-domain-service";
import {
    StrongAuthenticationManager
} from "../../domains/strong-customer-authentication/strong-customer-authentication-manager";
import {
    StrongAuthenticationService
} from "../../domains/strong-customer-authentication/strong-customer-authentication-service";
import { TransactionCodeManager } from "../../domains/transactions-codes/transaction-code-manager";
import { TransactionCodeService } from "../../domains/transactions-codes/transaction-code-service";
import { BankToWalletManager } from "../../domains/transactions/bank-to-wallet/bank-to-wallet-manager";
import { BankToWalletService } from "../../domains/transactions/bank-to-wallet/bank-to-wallet-service";
import { CashTransferManager } from "../../domains/transactions/cash-transfer/cash-transfer-manager";
import { CashTransferService } from "../../domains/transactions/cash-transfer/cash-transfer-service";
import { DedicatedTransferManager } from "../../domains/transactions/dedicated-transfer/dedicated-transfer-manager";
import { DedicatedTransferService } from "../../domains/transactions/dedicated-transfer/dedicated-transfer-service";
import { TransferManager } from "../../domains/transactions/transfer/transfer-manager";
import { TransferService } from "../../domains/transactions/transfer/transfer-service";
import { Paginated } from "../../utils/pagination";
import { LoginService } from "../auth/login-service";
import { DefaultCacheLoader } from "../cache/default-cache-loader";
import { Config } from "../config/config";
import { ContactService } from "../contact/contact-service";
import { AutocompleteService } from "../data-forms/autocomplete-service";
import { I18NManager } from "../i18n/i18n-manager";
import { I18NService } from "../i18n/i18n-service";
import { I18NStore } from "../i18n/i18n-store";
import { ApiService } from "../net/api-service";
import { ConnectedApiService } from "../net/connected-api-service";
import { ConnectedServiceDomainApiService } from "../net/connected-service-domain-api-service";
import { HttpService } from "../net/http-service";
import { OauthHttpService } from "../net/oauth-http-service";
import { ServiceDomainApiService } from "../net/service-domain-api-service";
import { UserAgentService } from "../net/user-agent-service";
import { UUIDManager } from "../uuid/uuid-manager";
import { WebAnalyticsService } from "./../../../web/core/analytics/web-analytics-service";
import { ErrorStore } from "../../error-store";

const sodium = _sodium;

const customUserAgentService: UserAgentService | null = null;
const webSecuredCookiesService = new WebSecuredCookiesService();

export const errorStore = new ErrorStore();
export const httpService = new HttpService(customUserAgentService, webSecuredCookiesService, undefined);
export const oAuthHttpService = new OauthHttpService(errorStore, customUserAgentService, webSecuredCookiesService, undefined);
export const uuidGenerator = new WebUUIDGenerator();
export const i18NStore = new I18NStore();
export const uuidManager = new UUIDManager(new LocalStorage(), uuidGenerator);
export const trustManager = new TrustManager(new LocalStorage<Trust>());

export const authenticationConfigurationService = new AuthenticationConfigurationService(
	null,
	customUserAgentService,
	i18NStore
);
export const oauthService = new WebOAuthService(oAuthHttpService, new LocalStorage(), trustManager);
export const authenticationService = new AuthenticationService(
	oAuthHttpService,
	authenticationConfigurationService,
	oauthService
);
export const networkInfoManager = new WebNetworkInfoManager();
export const authenticationManager = new AuthenticationManager(
	authenticationService,
	networkInfoManager,
	new SessionStorage(),
	webSecuredCookiesService
);
export const apiService = new ApiService(
	errorStore,
	authenticationManager,
	customUserAgentService,
	i18NStore,
	webSecuredCookiesService,
	undefined
);

export const featuresManager = new FeaturesManager(authenticationManager);
export const configurationService = new ConfigurationService(apiService);
export const configurationManager = new ConfigurationManager(
	configurationService,
	new DefaultCacheLoader(new SessionCache(Config.CONFIGURATION_CACHE_TTL, "configuration")),
	null,
	i18NStore
);

export const loginService = new LoginService(apiService);
export const authExecutor = new WebAuthExecutor(authenticationManager, configurationManager);
export const i18NService = new I18NService(apiService);
export const translationsManager = new WebTranslationsManager(
	configurationManager,
	new DefaultCacheLoader(new SessionCache(Config.TRANSLATIONS_CACHE_TTL, "translations")),
	i18NService
);
export const i18NManager = new I18NManager(i18NStore, translationsManager);
export const connectedApiService = new ConnectedApiService(
	errorStore,
	httpService,
	authenticationManager,
	customUserAgentService,
	webSecuredCookiesService,
	undefined
);

export const clientService = new ClientService(connectedApiService);
export const clientManager = new ClientManager(
	clientService,
	featuresManager,
	new DefaultCacheLoader<Client>(new MemoryCache(Config.CLIENT_CACHE_TTL, CLIENT_CACHE_STORAGE_KEY))
);

export const accountService = new AccountService(connectedApiService);
export const accountManager = new AccountManager(
	accountService,
	authenticationManager,
	clientManager,
	new DefaultCacheLoader<Paginated<Account>>(new MemoryCache(Config.ACCOUNTS_CACHE_TTL, ACCOUNTS_CACHE_STORAGE_KEY))
);

export const authorizationHoldService = new AuthorizationHoldService(connectedApiService, featuresManager);
export const authorizationHoldManager = new AuthorizationHoldManager(
	authorizationHoldService,
	authenticationManager,
	clientManager,
	new DefaultCacheLoader<Paginated<AuthorizationHold>>(
		new MemoryCache(Config.AUTHORIZATION_HOLDS_CACHE_TTL, ACCOUNTS_CACHE_STORAGE_KEY)
	)
);

export const transactionService = new TransactionService(connectedApiService);
export const transactionManager = new TransactionManager(transactionService);
export const transactionsManager = new TransactionsManager(
	transactionService,
	authenticationManager,
	clientManager,
	new DefaultCacheLoader<Paginated<AccountingTransaction>>(
		new MemoryCache(Config.TRANSACTIONS_CACHE_TTL, TRANSACTIONS_CACHE_STORAGE_KEY)
	)
);
export const beneficiaryService = new BeneficiaryService(connectedApiService);
export const beneficiaryManager = new BeneficiaryManager(
	beneficiaryService,
	authenticationManager,
	featuresManager,
	new DefaultCacheLoader<Paginated<Recipient>>(
		new MemoryCache(Config.RECIPIENTS_CACHE_TTL, BENEFICIARIES_CACHE_STORAGE_KEY)
	)
);
export const externalAccountService = new ExternalAccountService(connectedApiService);
export const externalAccountManager = new ExternalAccountManager(
	externalAccountService,
	authenticationManager,
	featuresManager,
	new DefaultCacheLoader<Paginated<Recipient>>(
		new MemoryCache(Config.RECIPIENTS_CACHE_TTL, BENEFICIARIES_CACHE_STORAGE_KEY)
	)
);
export const acceptorService = new AcceptorService(apiService);
export const acceptorManager = new AcceptorManager(acceptorService);
export const acceptorCategoriesManager = new AcceptorCategoriesManager(acceptorService);

export const transactionGeolocalisationManager = new WebTransactionGeolocalisationManager();
export const billService = new BillService(connectedApiService);
export const billManager = new BillManager(
	billService,
	transactionGeolocalisationManager,
	accountManager,
	transactionsManager
);
export const billIssuerManager = new BillIssuerManager(
	billService,
	authenticationManager,
	clientManager,
	new DefaultCacheLoader<(BillIssuer | BillCategory)[]>(
		new MemoryCache(Config.BILL_ISSUERS_CACHE_TTL, BILL_ISSUERS_CACHE_STORAGE_KEY)
	)
);
export const billIssuerSelectionManager = new BillIssuerSelectionManager(authenticationManager, clientManager);

export const pincodeKeyboardService = new PincodeKeyboardService(apiService);
export const pincodeService = new PincodeService(connectedApiService);
export const checkPincodeService = new CheckPincodeService(apiService);
export const accountStatementService = new WebAccountStatementsService(connectedApiService);
export const cashTransferService = new CashTransferService(connectedApiService);
export const cashTransferManager = new CashTransferManager(
	cashTransferService,
	transactionGeolocalisationManager,
	accountManager,
	transactionsManager
);

export const dedicatedTransferService = new DedicatedTransferService(connectedApiService);
export const dedicatedTransferManager = new DedicatedTransferManager(
	dedicatedTransferService,
	transactionGeolocalisationManager,
	accountManager,
	transactionsManager
);

export const registerService = new RegisterService(apiService);
export const registerManager = new RegisterManager(registerService, new LocalStorage(), new WebImagesConverter());

export const onboardingService = new OnboardingService(apiService);
export const onboardingManager = new OnboardingManager(onboardingService, featuresManager, new WebImagesConverter());

export const cardFilterManager = new CardFilterManager();
export const transactionSearchManager = new TransactionSearchManager();
export const productService = new ProductService(connectedApiService);
export const productManager = new ProductManager(productService, authenticationManager, clientManager);
export const autocompleteService = new AutocompleteService(apiService);
export const bankToWalletService = new BankToWalletService(connectedApiService);
export const bankToWalletManager = new BankToWalletManager(
	bankToWalletService,
	new LocalStorage(),
	transactionGeolocalisationManager,
	accountManager,
	transactionsManager
);
export const analyticsService = new WebAnalyticsService();
export const deviceInfoService = new WebDeviceInfoService();
export const contactService = new ContactService(configurationManager, i18NManager);
export const transactionCodeService = new TransactionCodeService(connectedApiService);
export const transactionCodeManager = new TransactionCodeManager(transactionCodeService);
export const accountDeleteService = new AccountDeleteService(clientManager);

export const bicReferentialService = new BicReferentialService(connectedApiService);
export const bicReferentialManager = new BicReferentialManager(bicReferentialService);

export const spendingsService = new SpendingsService(connectedApiService);
export const spendingsManager = new SpendingsManager(spendingsService);

export const attachmentsService = new AttachmentsService(connectedApiService);
export const attachmentsManager = new AttachmentsManager(attachmentsService);

export const categorizationsService = new CategorizationsService(connectedApiService);
export const categorizationsManager = new CategorizationsManager(categorizationsService);

export const strongAuthenticationService = new StrongAuthenticationService(connectedApiService);
export const strongAuthenticationManager = new StrongAuthenticationManager(
	strongAuthenticationService,
	featuresManager
);

export const customerActionService = new CustomerActionService(connectedApiService);

// SERVICE DOMAIN //

export const serviceDomainApiService = new ServiceDomainApiService(customUserAgentService, undefined);
export const serviceDomainService = new ServiceDomainService(serviceDomainApiService);
export const serviceDomainManager = new ServiceDomainManager(
	serviceDomainService,
	featuresManager,
	new SessionStorage(),
	webSecuredCookiesService
);

export const connectedServiceDomainApiService = new ConnectedServiceDomainApiService(
	httpService,
	customUserAgentService,
	authenticationManager,
	i18NStore,
	webSecuredCookiesService,
undefined
);

export const genericConnectedServiceDomainApiService = new ConnectedServiceDomainApiService(
	httpService,
	customUserAgentService,
	serviceDomainManager,
	i18NStore,
	webSecuredCookiesService,
undefined
);

// END SERVICE DOMAIN //

export const cardService = new CardService(connectedApiService, connectedServiceDomainApiService);
export const cardDetailsManager = new CardDetailsManager(cardService);
export const cardManager = new CardManager(cardService, authenticationManager, cardDetailsManager);

export const transferService = new TransferService(
	connectedApiService,
	connectedServiceDomainApiService,
	clientManager,
	checkPincodeService
);

export const transferManager = new TransferManager(
	transferService,
	transactionGeolocalisationManager,
	accountManager,
	transactionsManager,
	featuresManager
);

export const webAppStartManager = new WebAppStartManager(
	configurationManager,
	i18NManager,
	authenticationManager,
	clientManager,
	new SessionStorage(),
	serviceDomainManager
);
