import React, { useState } from "react";
import { AsYouType, parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";
import styled from "styled-components";
import { countries } from "../../../../../../shared/assets/countries";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../../../shared/core/service/services";
import { useObservable } from "../../../../../../shared/utils/observable";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { PhoneInput } from "../../../../common/forms/phone-input";
import { theme } from "../../../../styles/theme";
import { FormWithButtons } from "../../shared/components/form-with-buttons";

const PhoneNumberRegisterStep: React.FC<{
	onSubmit: (phoneNumber: PhoneNumber) => void;
	initialPhoneNumber?: PhoneNumber;
	loading?: boolean;
	errorMessage?: string | null;
}> = ({ onSubmit, loading, errorMessage, initialPhoneNumber }) => {
	const defaultCountry = useObservable(configurationManager.defaultCountry);
	const [countryCode, setCountryCode] = useState(initialPhoneNumber?.country ?? defaultCountry);
	const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber?.nationalNumber.toString() ?? "");
	const [error, setError] = useState<string | null>(null);
	const { formatMessage } = useIntl();

	const editPhoneNumber = (phoneNumber: string) => {
		const nationalNumber = "+" + countries[countryCode].phoneCode;
		const internationalPhoneNumber = phoneNumber.startsWith(nationalNumber)
			? phoneNumber
			: nationalNumber + phoneNumber;
		const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
		setPhoneNumber(formatted);
	};

	return (
		<FormWithButtons
			onSubmit={() => {
				try {
					setError(null);
					const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber, countryCode);
					if (!phoneNumberParsed) {
						throw formatMessage("common.invalidPhoneNumber");
					}
					onSubmit(phoneNumberParsed);
				} catch (e) {
					setError(e);
				}
			}}
			errorMessage={error || errorMessage}
			loading={loading}
		>
			<Container>
				<StepTitle>{formatMessage("registerScreen.firstStepTitle")}</StepTitle>
				<StepSubtitle>{formatMessage("registerScreen.firstStepSubtitle")}</StepSubtitle>
				<PhoneInput
					onChangeCountryCode={setCountryCode}
					onChangePhoneNumber={editPhoneNumber}
					initialCountryCode={initialPhoneNumber?.country ?? defaultCountry}
					initialPhone={initialPhoneNumber?.nationalNumber.toString() ?? ""}
				/>
			</Container>
		</FormWithButtons>
	);
};

export default PhoneNumberRegisterStep;

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const StepTitle = styled(MarkdownText)`
	${theme.boldText};
	font-size: 2.5rem;
	line-height: 2.875rem;
	align-self: flex-start;
	margin-bottom: 24px;
`;

const StepSubtitle = styled.span`
	${theme.text}
	color: #b1b1b1;
	font-size: 0.9375rem;
	margin-bottom: 52px;
`;
