import React, { SVGProps } from "react";

export function QuestionMarkIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 26 26" {...props}>
			<path
				fillRule="nonzero"
				d="M13 3C7.477 3 3 7.477 3 13s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C18.196 4.054 15.652 3 13 3zm1 15.5c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h1c.276 0 .5.224.5.5v1zm2.88-7.94c-.008 1.246-.815 2.346-2 2.73l-.88.31c-.048.02-.08.068-.08.12v.78c0 .276-.224.5-.5.5h-.76c-.276 0-.5-.224-.5-.5v-.78c.002-.81.522-1.526 1.29-1.78l.95-.31c.456-.158.761-.588.76-1.07V10c0-.304-.123-.595-.342-.806-.219-.212-.514-.325-.818-.314h-2c-.619 0-1.12.501-1.12 1.12v.5c0 .276-.224.5-.5.5h-.76c-.276 0-.5-.224-.5-.5V10c0-1.59 1.29-2.88 2.88-2.88h2c.764 0 1.496.303 2.036.844.54.54.844 1.272.844 2.036v.56z"
			/>
		</svg>
	);
}
