import { logger } from "../../core/logging/logger";
import { isDefined } from "../../utils/assert";
import { Observable } from "../../utils/observable";
import { ObservablePaginated } from "../../utils/observable-paginated";
import { createEmptyPaginatedData } from "../../utils/pagination";
import { UrlLink } from "../BaseUrl";
import { BicReferential } from "./bic-referential";
import { BicReferentialLinks } from "./bic-referential-links";
import { BicReferentialService } from "./bic-referential-service";

export const MIN_SEARCH_CHARACTERS = 3;

export class BicReferentialManager {
	public bics = new ObservablePaginated<BicReferential>(createEmptyPaginatedData<BicReferential>());

	public error = new Observable<Error | null>(null);
	public loading = new Observable(false);
	public loadingMore = new Observable(false);
	public loadingMoreError = new Observable<Error | null>(null);
	public canLoadMore = new Observable(false);

	private static search = "";
	private static timeoutId: NodeJS.Timeout | null = null;

	private nextPageUrl: string | undefined = undefined;

	public constructor(private bicReferentialService: BicReferentialService) {}

	public async reset(emptySet: boolean | undefined = false) {
		if (emptySet) {
			BicReferentialManager.search = "";
			this.bics.set(createEmptyPaginatedData<BicReferential>());
		}
		this.loading.set(false);
		this.loadingMore.set(false);
		this.canLoadMore.set(false);
		this.nextPageUrl = undefined;
	}

	public async scheduleLoad(search: string) {
		if (search.length < MIN_SEARCH_CHARACTERS) {
			return;
		}
		this.loading.set(true);
		BicReferentialManager.search = search.trim();
		if (BicReferentialManager.timeoutId) {
			clearTimeout(BicReferentialManager.timeoutId);
			BicReferentialManager.timeoutId = null;
		}
		BicReferentialManager.timeoutId = setTimeout(
			function(this: BicReferentialManager) {
				this.loadAfter();
			}.bind(this),
			700
		);
	}

	private loadAfter() {
		this.load(BicReferentialManager.search);
		BicReferentialManager.timeoutId = null;
	}

	private async load(search: string) {
		try {
			this.error.set(null);
			const bicsResult = await this.bicReferentialService.searchBic(search);
			if (!bicsResult) {
				throw Error("Failed to fetch bics");
			}
			const { links, ...bics } = bicsResult;
			this.nextPageUrl = this.getNextPageUrl(links);
			this.canLoadMore.set(isDefined(this.nextPageUrl));
			this.bics.set(bics);
			this.loading.set(false);
		} catch (e) {
			this.loading.set(false);
			if (!(e instanceof BicSearchCancelledError)) {
				this.error.set(e);
				logger.debug("BicReferentialManager", "Fetch bics failed: ", JSON.stringify(e));
				this.bics.set(createEmptyPaginatedData<BicReferential>());
				throw e;
			}
		}
	}

	public async loadMore(): Promise<void> {
		if (this.loading.get() || this.loadingMore.get()) {
			return;
		}
		try {
			this.loadingMore.set(true);
			this.loadingMoreError.set(null);
			const additional = await this.bicReferentialService.searchBic(BicReferentialManager.search, this.nextPageUrl);
			const { links, ...bics } = additional;
			this.nextPageUrl = this.getNextPageUrl(links);
			this.canLoadMore.set(isDefined(this.nextPageUrl));
			this.bics.add(bics);
			this.loadingMore.set(false);
		} catch (e) {
			this.loadingMoreError.set(e);
			logger.debug("BicReferentialManager", "Load more bics failed", e);
			this.loadingMore.set(false);
		}
	}

	private getNextPageUrl = (links: UrlLink[] | undefined) => {
		if (links) {
			const index = links.findIndex(link => link.rel === BicReferentialLinks.NextPage);
			if (index > -1) {
				return links[index].href;
			}
		}
		return undefined;
	};
}

export class BicSearchCancelledError extends Error {
	public constructor(message?: string) {
		super(message);
	}
}
