import React from "react";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account } from "../../../../../shared/domains/account/account";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import { useBeneficiaries } from "../../../../../shared/domains/recipients/use-beneficiaries";
import { useExternalAccounts } from "../../../../../shared/domains/recipients/use-external-accounts";
import { RECHARGE_MODAL_ID } from "../../../../core/modal/modal-id";
import { useClient } from "../../../../domain/authentication/use-client";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { RechargeMethod, RechargeState, useRechargeMachine } from "../../../../machine/recharge-machine";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { RechargeSummary } from "./recharge-summary";
import { SelectRechargeByBankAmount } from "./select-recharge-by-bank-amount";
import { SelectRechargeByCardAmount } from "./select-recharge-by-card-amount";
import { RechargeMethodSelection } from "./select-recharge-method";

interface RechargeModalProps {
	account?: Account;
	completePspTransactionFlow?: boolean;
}
const loadingStates = [
	PincodeState.FetchKeyboardAfterError,
	RechargeState.RequestingBankToWallet,
	RechargeState.Confirmation,
	PincodeState.PincodeConfirmation,
	RechargeState.FetchingCardPspWebviewUrl,
	RechargeState.PspWebviewRedirection,
];
export const RechargeModal = (props: RechargeModalProps) => {
	const { account, completePspTransactionFlow } = props;
	const { client } = useClient();

	const { state, context, selectRechargeMethod, selectAmount, submitPincode } = useRechargeMachine(
		completePspTransactionFlow ? RechargeState.PspTransactionComplete : RechargeState.SelectingRechargeMethod
	);
	const errorMessage = !isAccountBlocked(context.error) ? context.error : undefined;

	const { formatMessage } = useIntl();
	const { data } = useExternalAccounts();
	const externalAccount = data?.[0];
	const externalAccountIBAN = externalAccount?.iban;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const rechargeAccount = account!;

	const renderInnerStep: () => JSX.Element = () => {
		switch (state) {
			case RechargeState.SelectingRechargeMethod:
				return (
					<RechargeMethodSelection
						selectMethod={rechargeMethod => selectRechargeMethod(rechargeAccount, externalAccountIBAN, rechargeMethod)}
					/>
				);
			case RechargeState.RequestingBankToWalletError:
			case RechargeState.RequestingBankToWallet:
			case PincodeState.PincodeConfirmation:
			case RechargeState.ConfirmBankToWalletError:
			case RechargeState.SelectingBankToWalletAmount:
				return (
					<SelectRechargeByBankAmount
						account={rechargeAccount}
						externalAccountIBAN={externalAccountIBAN}
						submitAmount={selectAmount}
						errorMessage={errorMessage}
						loading={loadingStates.includes(state)}
					/>
				);
			case RechargeState.RequestingBankToWallet:
			case PincodeState.FetchKeyboardAfterError:
			case RechargeState.Confirmation:
			case PincodeState.PromptingKeyboard: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const keyboard = context.keyboard!;
				return (
					<PincodeKeyboard
						keyboard={keyboard}
						onSubmit={submitPincode}
						title={formatMessage("pincodeConfirmation.label")}
						errorMessage={errorMessage}
						loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
					/>
				);
			}
			case RechargeState.SelectingCardAmount:
			case RechargeState.SelectingCardAmountError:
			case RechargeState.FetchingCardPspWebviewUrl:
			case RechargeState.PspWebviewRedirection:
				return (
					<SelectRechargeByCardAmount
						account={rechargeAccount}
						owner={client}
						submitAmount={selectAmount}
						errorMessage={errorMessage}
						loading={loadingStates.includes(state)}
					/>
				);

			case RechargeState.PspTransactionComplete:
				return <></>;
			case RechargeState.Done:
				return context.rechargeMethod === RechargeMethod.BANK_TO_WALLET ? (
					<RechargeSummary
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						rechargeMethod={context.rechargeMethod!}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						accountId={context.account!.id}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						amount={context.amount!}
						onDone={() => Modal.dismiss(RECHARGE_MODAL_ID)}
					/>
				) : (
					<RechargeSummary
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						rechargeMethod={context.rechargeMethod!}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						accountId={context.completedPspTransaction!.accountId}
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						amount={context.completedPspTransaction!.amount}
						onDone={() => Modal.dismiss(RECHARGE_MODAL_ID)}
					/>
				);
		}
	};
	return (
		<RoundedModalContainer closeButton id={RECHARGE_MODAL_ID}>
			{renderInnerStep()}
		</RoundedModalContainer>
	);
};
