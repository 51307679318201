import React, { useCallback, useEffect } from "react";
import { clientManager, featuresManager } from "../../../../../../shared/core/service/services";

import { MUST_RETAKE_ONBOARDING_FILE_STATUSES } from "../../../../../../shared/domains/onboarding/onboarding";
import { Path } from "../../../../../core/routing/path";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { useHistory } from "react-router-dom";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { useObservable } from "../../../../../../shared/utils/observable";
import { useOnboardingFile } from "../../../../../../shared/domains/onboarding/use-onboarding";

const WarningIcon = require("../../../../../assets/images/svg/warning.svg");

export const OnboardingFileStatusCard: React.FC = () => {
	const features = useObservable(featuresManager.features);
	const client = useObservable(clientManager.client);
	const { onboardingFile } = useOnboardingFile();
	const { formatMessage } = useIntl();
	const history = useHistory();

	const handleEditOnboardingFile = useCallback(async () => {
		if (features.sdaCustomerOnboardingUpdate) {
			history.push(Path.OnboardingFile);
		}
	}, []);

	const  mustRetakeOnboarding = onboardingFile && MUST_RETAKE_ONBOARDING_FILE_STATUSES.includes(onboardingFile.status)

	return  mustRetakeOnboarding ? (
		<Container>
			<>
				<TitleContainer>
					<Title>{formatMessage("onboardingStatusCard.title")}</Title>
					<WarningIconImage src={WarningIcon} alt="" />
				</TitleContainer>
				{client && client.requiredActions.reason.length > 0 && (
					<Subtitle>{client.requiredActions.reason}</Subtitle>
				)}
				<UnderlinedButton disabled={!features.sdaCustomerOnboardingUpdate} onClick={handleEditOnboardingFile}>
					{formatMessage("onboardingStatusCard.button")}
				</UnderlinedButton>
			</>
		</Container>
	) : null;
};

const Container = styled.div`
	flex: 1;
	background-color: #ffecd1;
	border-radius: 13px;
	padding: 16px;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
`;

const Title = styled.div`
	${theme.bodyBlackBold}
	color: '#000000'};
	flex-grow: 1;
	flex-shrink: 1;
    padding-right: 8px;
`;

const Subtitle = styled.div`
	${theme.bodyGreyRegular}
	marginTop: 4px;
	font-size: 13px;
	color: ${theme.mainColor};
`;

const UnderlinedButton = styled.button<{ disabled?: boolean }>`
	${theme.bodyGreyRegular}
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	appearance: none;
	color: #000000;
	font-size: 13px;
	text-decoration: underline;
	margin-top: 16px;
	&:hover {
		text-decoration: none;
	}
	${props =>
		props.disabled &&
		`
        pointer-events: none;
        opacity: 0.5;
    `}
`;

const WarningIconImage = styled.img`
	width: 24px;
	height: 24px;
`;
