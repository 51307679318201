import { FormCollectionInput } from "../data-forms/form-input-types";
import { ApiService } from "../net/api-service";

export class LoginService {
	private static ERROR_CODES_INDICATING_PHONE_NUMBER_OK = [
		30234,
		62201
	];

	public constructor(private apiService: ApiService) {}

	public async checkPhoneNumber(phoneNumber: string): Promise<boolean> {
		try {
			await this.apiService.instance.get<FormCollectionInput>(`/enrollment/${phoneNumber}`);
			return false;
		} catch (e) {
			if (e?.response?.status === 400 && this.checkErrorCode(e?.response?.data?.error?.code)) {
				return true;
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	private checkErrorCode(code: any): boolean {
		return LoginService.ERROR_CODES_INDICATING_PHONE_NUMBER_OK.includes(code);
	}
}
