import React, { SVGProps } from "react";

export const AccountPlaceholder = (props: SVGProps<SVGSVGElement>) => (
	<svg {...props} width="180" height="107" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
		<defs>
			<filter x="-13.4%" y="-23%" width="126.7%" height="157.5%" filterUnits="objectBoundingBox" id="a">
				<feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
				<feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" in="shadowBlurOuter1" />
			</filter>
			<rect id="b" x="0" y="0" width="149.674" height="69.521" rx="10.052" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<g transform="translate(29.935 .337)">
				<rect fillOpacity=".04" fill="#000" width="149.674" height="69.521" rx="10.052" />
				<path
					d="M133.542 8.247h2.055c1.85 0 2.655.22 3.424.63a4.288 4.288 0 0 1 1.784 1.784c.411.77.63 1.574.63 3.424v2.07c0 1.85-.219 2.654-.63 3.423a4.288 4.288 0 0 1-1.784 1.784c-.769.411-1.574.63-3.424.63h-2.055c-1.85 0-2.655-.219-3.424-.63a4.288 4.288 0 0 1-1.784-1.784c-.411-.769-.63-1.574-.63-3.424v-2.069c0-1.85.219-2.655.63-3.424a4.288 4.288 0 0 1 1.784-1.784c.769-.41 1.574-.63 3.424-.63z"
					fill="#B1B1B1"
					opacity=".241"
				/>
				<ellipse fillOpacity=".179" fill="#000" cx="134.57" cy="15.12" rx="2.472" ry="2.474" />
				<g transform="translate(16.63 22.34)">
					<rect fillOpacity=".179" fill="#000" width="43.239" height="5.714" rx="2.857" />
					<rect fill="#F4F4F4" y="14.761" width="100.258" height="5.714" rx="2.857" />
				</g>
			</g>
			<g transform="translate(0 37.479)">
				<use fill="#000" filter="url(#a)" xlinkHref="#b" />
				<use fill="#FFF" xlinkHref="#b" />
			</g>
			<g transform="translate(127.43 45.421)">
				<path
					d="M5.838 0h2.055c1.85 0 2.656.219 3.425.63A4.288 4.288 0 0 1 13.1 2.414c.412.77.63 1.574.63 3.424v2.069c0 1.85-.218 2.655-.63 3.424a4.288 4.288 0 0 1-1.783 1.784c-.77.411-1.574.63-3.425.63H5.838c-1.85 0-2.655-.219-3.424-.63A4.288 4.288 0 0 1 .63 11.33C.22 10.562 0 9.757 0 7.907V5.838c0-1.85.219-2.655.63-3.424A4.288 4.288 0 0 1 2.414.63C3.184.22 3.988 0 5.838 0z"
					fill="#F4F4F4"
				/>
				<ellipse fill="#D5D5D5" cx="6.866" cy="6.873" rx="2.472" ry="2.474" />
			</g>
			<g transform="translate(16.63 63.668)">
				<rect fill="#D5D5D5" width="43.239" height="5.714" rx="2.857" />
				<rect fill="#F4F4F4" y="14.761" width="100.258" height="5.714" rx="2.857" />
			</g>
		</g>
	</svg>
);
