import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { theme } from "../../../../styles/theme";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
const SuccessLogo = require("../../../../../assets/images/svg/big-success.svg");

export const OnboardingInitialFormSuccess: React.FC<{
    onConnect?: () => void;
}> = (props) => {
    const { onConnect } = props;
	const { formatMessage } = useIntl();

	return (
        <Container>
            <SuccessLogoImage src={SuccessLogo} alt="" />
            <Title>{formatMessage("onboardingScreen.successIntroduction")}</Title>
            <Subtitle>{formatMessage("onboardingScreen.initiationSuccessTitle")}</Subtitle>
            <PrimaryButton size="S" onClick={() => onConnect?.()}>{formatMessage("onboardingScreen.initiationSuccessButton")}</PrimaryButton>
        </Container>
	);
};

const SuccessLogoImage = styled.img`
    align-self: center;
    width: 200px;
    height: 200px;
`;

const Container = styled.div`
	display: flex;
    text-align: center;
	flex-direction: column;
`;

const Title = styled(MarkdownText)`
	${theme.boldText};
	font-size: 2.5rem;
	line-height: 2.875rem;
	margin-bottom: 10px;
`;

const Subtitle = styled(MarkdownText)`
    color: ${theme.colors.gray[700]};
	line-height: 2.875rem;
	align-self: flex-start;
    margin-bottom: 24px;
`;
