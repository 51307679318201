import { Observable } from "../../utils/observable";
import { FeaturesManager } from "../features/features-manager";
import { StrongAuthenticationSearchQuery, StrongAuthenticationService } from "./strong-customer-authentication-service";
import { StrongAuthenticationRequest } from "./strong-customer-authentication";
import { PincodeSubmission } from "../pincode/pincode";

export class StrongAuthenticationManager {
	authentications = new Observable<StrongAuthenticationRequest[]>([]);
	hasNext = new Observable<boolean>(false);
	loading = new Observable<boolean>(false);

	constructor(
		private strongAuthenticationService: StrongAuthenticationService,
		private featuresManager: FeaturesManager,
	) {}

	async list (query?: StrongAuthenticationSearchQuery) {
		if (this.featuresManager.features.get().strongCustomerAuthentication) {
			this.loading.set(true);
			try {
				const response = await this.strongAuthenticationService.list(query);
        this.authentications.set(response.items)
        this.hasNext.set(response.total > this.authentications.get().length)
				return response.items;
			}
			catch (e) {
				console.log('error getting onboarding file', e)
			}
			finally {
				this.loading.set(false);
			}
		}
	}

	async get(id: number) {
		if (this.featuresManager.features.get().strongCustomerAuthentication) {
			this.loading.set(true);
			try {
				const response = await this.strongAuthenticationService.get(id);
				return response;
			}
			catch (e) {
				console.log('error getting onboarding file', e)
			}
			finally {
				this.loading.set(false);
			}
		}
	}

	async accept(id: number, pincode: PincodeSubmission) {
		if (this.featuresManager.features.get().strongCustomerAuthentication) {
			this.loading.set(true);
			try {
				const response = await this.strongAuthenticationService.accept(id, pincode);
				return response;
			}
			catch (e) {
				console.log('error getting onboarding file', e)
				throw e?.response?.data?.error?.message || e.toString();
			}
			finally {
				this.loading.set(false);
			}
		}
	}

	async refuse(id: number, pincode: PincodeSubmission) {
		if (this.featuresManager.features.get().strongCustomerAuthentication) {
			this.loading.set(true);
			try {
				const response = await this.strongAuthenticationService.refuse(id, pincode);
				return response;
			}
			catch (e) {
				console.log('error getting onboarding file', e)
				throw e?.response?.data?.error?.message || e.toString();
			}
			finally {
				this.loading.set(false);
			}
		}
	}
}