import moment from "moment";
import { Cache } from "../../../shared/core/cache/cache";
import { CacheEntry, isCacheValid } from "../../../shared/core/cache/cache-entry";
import { CacheStatus } from "../../../shared/core/cache/cache-status";

export class MemoryCache<T> implements Cache<T> {
	private cacheEntry: Map<string, CacheEntry<T>> = new Map();

	constructor(private ttl: number, private storageKey = "memory") {}
	async read(key: string): Promise<T | null> {
		const value = this.cacheEntry.get(this.getStorageKey(key));
		return value?.data ?? null;
	}
	store(data: T, creation: Date | undefined, key: string): void {
		const entryCreation = creation ? moment(creation) : moment();
		this.cacheEntry.set(this.getStorageKey(key), { data, creation: entryCreation, ttl: this.ttl });
	}
	clear(key: string): Promise<void> {
		this.cacheEntry.delete(this.getStorageKey(key));
		return Promise.resolve();
	}
	async isValid(key: string): Promise<boolean> {
		const entry = await this.cacheEntry.get(this.getStorageKey(key));
		if (entry !== null) {
			return isCacheValid(entry);
		}
		return false;
	}
	async readStatus(key: string): Promise<CacheStatus | null> {
		const cacheEntry = await this.cacheEntry.get(this.getStorageKey(key));
		return cacheEntry
			? {
					isValid: isCacheValid(cacheEntry),
					creation: moment(cacheEntry.creation).toDate(),
			  }
			: null;
	}

	private getStorageKey(key: string): string {
		if (key !== "default") {
			return `${this.storageKey}_${key}`;
		}
		return this.storageKey;
	}
}
