import React, { useCallback } from "react";
import { RecipientState, useRecipientMachine } from "../../../machine/recipient-machine";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../shared/utils/phone-number";

import { ADD_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { AddBeneficiaryDetail } from "./add-recipient-detail";
import { Modal } from "../../common/modal/modal";
import { OtpConfirm } from "./otp-confirm";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { SearchBank } from "./components/search-bank";
import { TransferTransaction } from "../transfer/components/transfer-modal";
import { useClient } from "../../../domain/authentication/use-client";
import { useIntl } from "../../../../shared/core/i18n/use-intl";

const notCancellableState = [
	RecipientState.RequestingRecipient,
	RecipientState.OTPConfirming,
	RecipientState.OTPRefreshing,
];

export interface AddBeneficiaryModalProps {
	initialName?: string;
	forExternalAccount?: boolean;
}

export const AddBeneficiaryModal = (props: AddBeneficiaryModalProps) => {
	const { initialName, forExternalAccount } = props;

	const {
		state,
		context,
		createPhoneRecipient,
		confirmOtp,
		createIbanRecipient,
		createAccountRecipient,
		searchBank,
		updateBankName,
		sendOtpAgain,
	} = useRecipientMachine();

	const { formatMessage } = useIntl();
	const { client } = useClient();
	const closeModal = useCallback(() => Modal.dismiss(ADD_RECIPIENT_MODAL_ID), []);
	const renderInnerStep = () => {
		const errorMessage = context.errorMessage;
		switch (state) {
			case RecipientState.RequestingRecipient:
			case RecipientState.CreatingRecipient:
				return (
					<AddBeneficiaryDetail
						createPhoneRecipient={createPhoneRecipient}
						createIbanRecipient={createIbanRecipient}
						createAccountRecipient={createAccountRecipient}
						searchBank={searchBank}
						loading={state === RecipientState.RequestingRecipient}
						errorMessage={errorMessage}
						initialName={initialName}
						forExternalAccount={forExternalAccount}
						bankName={context.bankName}
						bicCode={context.bicCode}
					/>
				);
			case RecipientState.SearchBank:
				return (
					<SearchBank
						title={formatMessage("addRecipient.searchBank.title")}
						onSelect={(bankName, bic) => updateBankName(bankName, bic)}
					/>
				);
			case RecipientState.OTPRefreshing:
			case RecipientState.OTPSubmission:
			case RecipientState.OTPConfirming:
				const additionalText = context.recipientToAdd?.bankName
					? formatMessage("addRecipient.otpAdditionalTextWithBank", {
							bankName: context.recipientToAdd?.bankName,
							name: context.recipientToAdd.name,
					  })
					: formatMessage("addRecipient.otpAdditionalText", { name: context.recipientToAdd?.name });
				return (
					<OtpConfirm
						submitOtp={confirmOtp}
						sendOtpAgain={sendOtpAgain}
						onOtpBlocked={closeModal}
						additionalText={additionalText}
						errorMessage={errorMessage}
						phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
						loading={state === RecipientState.OTPConfirming}
						refreshingOtp={state === RecipientState.OTPRefreshing}
					/>
				);
			case RecipientState.Done:
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const recipient = context.recipientToAdd!;
				return (
					<TransferTransaction showAvatar showTitle closeModal={closeModal} recipient={recipient}></TransferTransaction>
				);
		}
	};
	return (
		<RoundedModalContainer closeButton={!notCancellableState.includes(state)} id={ADD_RECIPIENT_MODAL_ID}>
			{renderInnerStep()}
		</RoundedModalContainer>
	);
};
