import filesize from "filesize";
import React, { useMemo } from "react";
import styled from "styled-components";
import { FormFileInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../shared/utils/assert";
import { FileUploader, UploadTypeAllowed } from "../forms/file-uploader";

export const FileField: React.FC<{
	input: FormFileInput;
	onChange: (picture: string | null) => void;
	disabled?: boolean;
}> = ({ input, onChange, disabled }) => {
	const { formatMessage } = useIntl();

	const isDictInputValue = input.value && typeof input.value === "object";

	const hasExistingFile = useMemo(() => {
		if (typeof input.value === "object") {
			return isDefined(input.value?.documentId);
		}
		return false;
	}, [input.value]);

	return (
		<InputContainer>
			<FileUploader
				initialFile={
					input.value && !isDictInputValue
						? {
								name: formatMessage("billFormScreen.docInputFilled"),
								type: "image/png",
								size: filesize((input.value as string).length),
						  }
						: undefined
				}
				hasExistingFile={hasExistingFile}
				typeAllowed={
					(input.acceptedTypes as UploadTypeAllowed[]) || ["application/pdf", "image/png", "image/jpg", "image/jpeg"]
				}
				fileUploaded={async file => {
					if (!file) {
						onChange(null);
						return;
					}
					const reader = new FileReader();

					reader.addEventListener(
						"load",
						() => {
							// convert image file to base64 string
							const base64Image = reader.result?.toString() ?? null;
							onChange(base64Image?.split("base64,")?.[1] ?? null);
						},
						false
					);

					if (file) {
						reader.readAsDataURL(file);
					}
				}}
				instructions={formatMessage("billFormScreen.docInputEmpty")}
				disabled={disabled}
				maxSize={input.maxSize}
				required={input.required}
				showDownloadIcon
			/>
		</InputContainer>
	);
};

const InputContainer = styled.div`
	position: relative;
	flex: 1;
`;
