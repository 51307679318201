import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { Intl } from "../core/i18n/use-intl";

export function formatPhoneNumber(phoneNumber: string) {
	const formattedPhoneNumber = parsePhoneNumberFromString(`+${phoneNumber}`);
	return formattedPhoneNumber ? formattedPhoneNumber.formatInternational() : phoneNumber;
}

export function validatePhoneNumber(intl: Intl, phone: string, country: string) {
	const phoneNumber = parsePhoneNumberFromString(phone, country as CountryCode);
	if (!phoneNumber) {
		throw intl.formatMessage("common.invalidPhoneNumber");
	}
	return phoneNumber.number as string;
}

export function anonymizePhoneNumber(str: string, leftover = 0) {
	return str.slice(0, str.length - leftover).replace(/[^\s]/g, "•") + str.slice(str.length - leftover);
}
