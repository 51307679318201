import { debounce } from "lodash";
import { useCallback } from "react";

export function useLoadMoreDebounce(wait?: number) {
	const loadMore = useCallback((fetch, loading, hasMore) => {
		if (!loading && hasMore) {
			fetch();
		}
	}, []);

	return useCallback(debounce(loadMore, wait ? wait : 200, { leading: true, trailing: false }), []);
}
