import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { Path } from "../../../core/routing/path";
import { useConnectFromRedirect } from "../../../domain/authentication/use-connect-from-redirect";
import { MainColorSpinner } from "../../common/spinner";
import { ResultView } from "../result/result-view";

export const OAuthLoadingScreen = () => {
	const parsed = useConnectFromRedirect();

	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	const { formatMessage } = useIntl();

	return (parsed.error ?
		redirectToLogin ?
			<Redirect to={{ pathname: Path.Root }} />
			:
			<div style={{ margin: "auto" }}>
				<AuthenticationError
					type={"error"}
					title={formatMessage("authScreen.authenticationFailure.title")}
					onConfirm={
						function (): void {
							setRedirectToLogin(true);
						}
					}
				/>
			</div>
		:
		<MainColorSpinner size="60px" style={{ margin: "auto" }} />
	);
};

const AuthenticationError = styled(ResultView)`
	
`;