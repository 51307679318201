import { ReadableSignal, Signal } from "micro-signals";
import { analyticsService, deviceInfoService, uuidManager } from "../../core/service/services";
import { NetworkErrorOnAuthentication } from "./../../../mobile/domain/authentication/authentication-error";
import { AuthenticationService, RegisteringParameters, TokenResult } from "./authentication-service";
import { NotAuthenticatedScopes, Scope } from "./scope";
import { AccessToken, Session, isAccessTokenExpired } from "./session";

import AsyncLock from "async-lock";
import { BasicStorage } from "../../core/cache/basic-storage";
import { logger } from "../../core/logging/logger";
import { NetworkInfoManager } from "../../core/net/network-info-manager";
import { SecuredCookiesService } from "../../core/net/secured-cookies-service";
import { isDefined } from "../../utils/assert";
import { Observable } from "../../utils/observable";
import { PincodeSubmission } from "../pincode/pincode";
import { SessionBuilder } from "./session-builder";

export const SESSION_KEYSTORE_KEY = "session";

export class AuthenticationManager {
	private sessionBuilder = new SessionBuilder(this.securedCookiesService);
	private codeValidationTokenResult: TokenResult | null = null;

	public session = new Observable<Session>(this.sessionBuilder.build());
	public hasBeenDisconnectedDueToInactivity = new Observable<boolean>(false);
	public isConnected = new Observable<boolean>(false);
	public isAuthenticated = new Observable<boolean>(false);

	private _lock = new AsyncLock();
	private _initialized = false;

	private _onSessionExpired = new Signal<void>();

	private _isFirstLaunch = false;

	private _notAuthenticatedScopes = NotAuthenticatedScopes;

	public constructor(
		private authenticationService: AuthenticationService,
		private networkInfoManager: NetworkInfoManager,
		private storage: BasicStorage<string>,
		private securedCookiesService?: SecuredCookiesService
	) {
		this.session.onChange.add(session => this.sessionChanged(session));
	}

	public async initialize(isFirstLaunch?: boolean): Promise<void> {
		if (!this._initialized) {
			if (isDefined(isFirstLaunch)) {
				this._isFirstLaunch = isFirstLaunch;
			}
			await this.restoreCurrentSession();
			this._initialized = true;
		}
	}

	public async connect(phoneNumber?: string, registeringParameters?: RegisteringParameters): Promise<void> {
		this.hasBeenDisconnectedDueToInactivity.set(false);
		const authorizeResult = await this.authenticationService.authorize(phoneNumber, registeringParameters);
		if (authorizeResult) {
			// Only mobile get AccessToken in one step.
			// Web must proceed with connectWithCodeGrant after redirection
			this.session.set(this.sessionBuilder.build(authorizeResult));
			await this.saveCurrentSession();
		}
	}
	public async connectWithCodeGrant(code: string, state: string): Promise<void> {
		this.hasBeenDisconnectedDueToInactivity.set(false);
		const tokenResult = await this.authenticationService.authorizeWithCodeGrant(code, state);
		this.session.set(this.sessionBuilder.build(tokenResult));
		await this.saveCurrentSession();
	}

	public async connectWithToken(tokenResult: TokenResult) {
		this.hasBeenDisconnectedDueToInactivity.set(false);
		this.session.set(this.sessionBuilder.build(tokenResult));
		await this.saveCurrentSession();
	}

	public async connectWithPhoneNumberAndPincode(
		pincode: PincodeSubmission,
		verifyOtp: (onOtpConfirm: (otp: string) => void) => void,
		registeringParameters?: RegisteringParameters
	) {
		const skipOtp = isDefined(registeringParameters);
		if (skipOtp) {
			const connectedTokenResult = await this.authenticationService.authorizeWithPincode(
				pincode,
				registeringParameters?.enrollment_id
			);
			this.session.set(this.sessionBuilder.build(connectedTokenResult));
			await this.saveCurrentSession();
		} else {
			const codeValidationTokenResult = await this.authenticationService.authorizeWithPincode(pincode);
			verifyOtp(async (otp: string) => {
				const authorizeResult = await this.authenticationService.validateAuthorizationWithPincode(
					codeValidationTokenResult.accessToken,
					otp
				);
				this.session.set(this.sessionBuilder.build(authorizeResult));
				await this.saveCurrentSession();
			});
		}
	}

	public async askWebAuthentOtp(pincode: PincodeSubmission, registeringParameters?: RegisteringParameters) {
		if (registeringParameters) {
			this.codeValidationTokenResult = await this.authenticationService.authorizeWithPincode(
				pincode,
				registeringParameters?.enrollment_id
			);
			this.session.set(this.sessionBuilder.build(this.codeValidationTokenResult));
			await this.saveCurrentSession();
		} else {
			this.codeValidationTokenResult = await this.authenticationService.authorizeWithPincode(pincode);
		}
	}

	public async validateWebAuthentOtp(otp: string | undefined): Promise<void> {
		if (!this.codeValidationTokenResult) {
			return Promise.reject("Token Error");
		} else if (!otp) {
			return Promise.reject("Otp Error");
		} else {
			try {
				const authorizeResult = await this.authenticationService.validateAuthorizationWithPincode(
					this.codeValidationTokenResult.accessToken,
					otp
				);
				this.session.set(this.sessionBuilder.build(authorizeResult));
				await this.saveCurrentSession();
				return Promise.resolve();
			} catch (error) {
				logger.debug("validateWebAuthentOtp error : ", error);
				return Promise.reject(error);
			}
		}
	}

	public async validateRegisterOtp(registeringParameters: RegisteringParameters) {
		return await this.authenticationService.validateRegisterOtp(registeringParameters);
	}

	public async getAccessToken(forceRefresh = false): Promise<AccessToken | null> {
		return await this._lock.acquire<AccessToken | null>("tokenLock", async () => {
			let token = this.session.get().accessToken;
			if (!token || forceRefresh || (token && isAccessTokenExpired(token))) {
				token = await this.renewAccessToken();
			}
			return token;
		});
	}

	public setIsAuthenticated(isAuthenticated: boolean): void {
		this.isAuthenticated.set(isAuthenticated);
	}

	public async logout(hasBeenDisconnectedDueToInactivity = false): Promise<void> {
		await analyticsService.event("user_disconnect_action", {
			install_id: await uuidManager.getUUID(),
			device_model: deviceInfoService.getModel(),
		});
		this.session.set(this.sessionBuilder.build());
		this.setIsAuthenticated(false);
		if (hasBeenDisconnectedDueToInactivity) {
			this.hasBeenDisconnectedDueToInactivity.set(true);
		}
	}

	public get onSessionExpired(): ReadableSignal<void> {
		return this._onSessionExpired;
	}

	private async renewAccessToken(): Promise<AccessToken | null> {
		try {
			const hasInternet = await this.networkInfoManager.hasInternet();
			if (!hasInternet) {
				return this.session.get().accessToken;
			}
			await this.renewSession();
		} catch (e) {
			logger.debug("AuthenticationManager", "Failed to renew token", e);
			await this.handleAccessTokenRenewError(e);
		}
		await this.saveCurrentSession();
		const newSession = this.session.get();
		return newSession ? newSession.accessToken : null;
	}

	private async handleAccessTokenRenewError(error: any): Promise<void> {
		if (!(error instanceof Error)) {
			error = new Error(error);
		}

		await analyticsService.recordError(error);
		await analyticsService.event("token_renew_failure", {
			install_id: await uuidManager.getUUID(),
			device_model: deviceInfoService.getModel(),
			error: JSON.stringify(error),
		});
		if (error instanceof NetworkErrorOnAuthentication) {
			await analyticsService.event("token_renew_failure_networl_error");
			logger.warn("Network error during access token refresh");
		} else {
			await analyticsService.event("token_renew_failure_expired_session");
			this.session.set(this.sessionBuilder.build());
			this._onSessionExpired.dispatch();
			this.setIsAuthenticated(false);
		}
	}

	private async saveCurrentSession(): Promise<void> {
		try {
			const session = this.session.get();
			await this.storage.store(JSON.stringify(session), SESSION_KEYSTORE_KEY);
			// if (session && session.accessToken && session.isConnected) {
			// 	this.setIsAuthenticated(true);
			// }
		} catch (e) {
			if (!this._isFirstLaunch) {
				logger.debug("AuthenticationManager", "Save current session failed", e);
				await analyticsService.recordError(e instanceof Error ? e : new Error(e));
				await analyticsService.event("session_save_failure", {
					install_id: await uuidManager.getUUID(),
					device_model: deviceInfoService.getModel(),
					error: JSON.stringify(e),
				});
			} else {
				logger.debug("AuthenticationManager", "saveCurrentSession error: Is First Launch");
				await analyticsService.recordError(e instanceof Error ? e : new Error(e));
				await analyticsService.event("session_save_first_launch", {
					install_id: await uuidManager.getUUID(),
					device_model: deviceInfoService.getModel(),
					error: JSON.stringify(e),
				});
			}
		}
	}

	public async restoreCurrentSession(): Promise<void> {
		try {
			const sessionString = await this.storage.read(SESSION_KEYSTORE_KEY);
			let session = sessionString ? JSON.parse(sessionString) : null;
			if (!session) {
				session = this.sessionBuilder.build();
			}
			this.session.set(session);
		} catch (e) {
			if (!this._isFirstLaunch) {
				logger.debug("AuthenticationManager", "Failed to restore current session", e);
				await analyticsService.recordError(e instanceof Error ? e : new Error(e));
				await analyticsService.event("session_restore_failure", {
					install_id: await uuidManager.getUUID(),
					device_model: deviceInfoService.getModel(),
					error: JSON.stringify(e),
				});
			} else {
				logger.debug("AuthenticationManager", "restoreCurrentSession error : Is First Launch");
				await analyticsService.recordError(e instanceof Error ? e : new Error(e));
				await analyticsService.event("session_restore_first_launch", {
					install_id: await uuidManager.getUUID(),
					device_model: deviceInfoService.getModel(),
					error: JSON.stringify(e),
				});
			}
			this.session.set(this.sessionBuilder.build());
		}
	}

	public async renewSession(): Promise<void> {
		const session = this.session.get();
		let result;
		try {
			if (session && session.isConnected) {
				result = await this.authenticationService.refresh(session.refreshToken);
			} else {
				result = await this.authenticationService.requestNotConnectedAccessToken(this._notAuthenticatedScopes);
			}
			this.session.set(this.sessionBuilder.build(result));
		} catch (e) {
			this.logout();
		}
	}

	private sessionChanged(session: Session | null) {
		this.isConnected.set(session !== null && session.isConnected);
	}

	public updateNotAuthenticatedScope(scope: Scope) {
		if (NotAuthenticatedScopes.includes(scope)) {
			let updatedScopes = this._notAuthenticatedScopes;
			if (updatedScopes.includes(scope)) {
				updatedScopes = updatedScopes.filter(function (value) {
					return value !== scope;
				});
			} else {
				updatedScopes.push(scope);
			}
			this._notAuthenticatedScopes = updatedScopes;
		}
	}

	public getNotAuthenticatedScope(scope: Scope): boolean {
		return this._notAuthenticatedScopes.includes(scope);
	}

	public resetNotAuthenticatedScope() {
		this._notAuthenticatedScopes = NotAuthenticatedScopes;
	}

	public getClientId() {
		const session = this.session.get();
		if (session && session.clientId) {
			return session.clientId;
		}
		return null;
	}
}
