import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Account } from "../../../../../shared/domains/account/account";
import { isAnNotEmptyArray } from "../../../../../shared/utils/utils";
import { useQueryParam } from "../../../../domain/authentication/use-query-param";

export const ACCOUNT_QUERY_PARAM = "account";
export const useSelectedAccount = (accounts: Account[]) => {
	const accountId = useQueryParam(ACCOUNT_QUERY_PARAM);
	const history = useHistory();
	const accountState = useState<Account | null>(accounts.find(account => account.id === accountId) ?? null);
	const [selectedAccount, setSelectedAccount] = accountState;

	if (selectedAccount === null && accounts && isAnNotEmptyArray(accounts)) {
		setSelectedAccount(accounts.find(account => account.id === accountId) ?? accounts[0]);
	}
	if ((accounts === null || !isAnNotEmptyArray(accounts)) && selectedAccount) {
		setSelectedAccount(null);
	}

	useEffect(() => {
		setSelectedAccount(accounts.find(account => account.id === accountId) ?? null);
	}, [accountId, accounts, setSelectedAccount]);

	useEffect(() => {
		selectedAccount &&
			history.push({
				search: `?${ACCOUNT_QUERY_PARAM}=${selectedAccount.id}`,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAccount]);

	return accountState;
};
