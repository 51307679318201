import React, { useCallback, useMemo, useState } from "react";
import { ADD_RECIPIENT_MODAL_ID, EDIT_RECIPIENT_MODAL_ID, TRANSFER_MODAL_ID } from "../../../core/modal/modal-id";

import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../shared/core/service/services";
import { Recipient } from "../../../../shared/domains/recipients/recipient";
import { useBeneficiaries } from "../../../../shared/domains/recipients/use-beneficiaries";
import { useLoadMoreDebounce } from "../../../../shared/utils/list";
import { useObservable } from "../../../../shared/utils/observable";
import { caseInsensitiveIncludes } from "../../../../shared/utils/string";
import { useRTL } from "../../../domain/language/use-rtl";
import { SearchBar } from "../../common/forms/search-bar";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { Selector } from "../../common/selector";
import { MainColorSpinner } from "../../common/spinner";
import { PlusIcon } from "../../common/svg/plus-icon";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { performTaskIfPossible } from "../pincode/assert-banking-task";
import { AddBeneficiaryModal } from "../recipient/add-recipient-modal";
import { EditBeneficiaryModal } from "../recipient/edit-recipient-modal";
import { EmptyBlock } from "../transfer/components/empty-block";
import { ExternalAccountsView } from "../transfer/components/external-accounts";
import { RecipientDetailModal } from "../transfer/components/recipient-detail";
import { RecipientRow } from "../transfer/components/recipient-row";

export enum TransferDestination {
	Beneficiaries,
	ExternalAccounts,
}

export function RecipientsScreen() {
	const features = useObservable(featuresManager.features);
	const recipients = useBeneficiaries();
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();
	const [search, setSearch] = useState<string | undefined>(undefined);
	const loadMoreDebounce = useLoadMoreDebounce();
	const [destination, setDestination] = useState(
		features.beneficiariesManagement || features.beneficiariesUsage
			? TransferDestination.Beneficiaries
			: features.externalAccountsManagement || features.externalAccountsUsage
			? TransferDestination.ExternalAccounts
			: undefined
	);

	const filteredRecipients = useMemo(
		() =>
			search
				? recipients.data.filter(
						r => caseInsensitiveIncludes(r.name, search) || caseInsensitiveIncludes(r.phone, search)
				  )
				: recipients.data,
		[recipients.data, search]
	);

	const loadMore = useCallback(
		() => loadMoreDebounce(recipients.loadMore, recipients.loading, recipients.canLoadMore),
		[loadMoreDebounce, recipients.canLoadMore, recipients.loadMore, recipients.loading]
	);

	const openAddBeneficiaryModal = (name?: string, forExternalAccount?: boolean) =>
		Modal.present(
			ADD_RECIPIENT_MODAL_ID,
			() => <AddBeneficiaryModal initialName={name} forExternalAccount={forExternalAccount} />,
			{
				canBeDismissed: false,
			}
		);

	const openEditBeneficiaryModal = (recipient: Recipient, forExternalAccount?: boolean) =>
		Modal.present(
			EDIT_RECIPIENT_MODAL_ID,
			() => <EditBeneficiaryModal recipient={recipient} forExternalAccount={forExternalAccount} />,
			{
				canBeDismissed: false,
			}
		);

	const openRecipientDetailModal = (recipient: Recipient) =>
		Modal.present(TRANSFER_MODAL_ID, () => <RecipientDetailModal recipient={recipient} />, {
			canBeDismissed: false,
		});

	const hasRecipients = useMemo(
		() => (destination === TransferDestination.Beneficiaries ? recipients.data.length > 0 : false),
		[destination, recipients.data.length]
	);

	const handleSetSearch = (value: string) => {
		setSearch(value);
	};

	const handleSetDestination = (value: TransferDestination) => {
		handleSetSearch("");
		setDestination(value);
	};

	return (
		<>
			<Title>{formatMessage("recipient.title")}</Title>
			<Subtitle>{formatMessage("recipient.subtitle")}</Subtitle>
			<RecipientHeader>
				<SelectorContainer>
					<Selector
						size={"L"}
						options={[
							{
								value: TransferDestination.Beneficiaries,
								text: formatMessage("recipient.selector.beneficiaries"),
								key: "recipients",
								disabled: !features.beneficiariesUsage,
							},
							{
								value: TransferDestination.ExternalAccounts,
								text: formatMessage("recipient.selector.externalAccounts"),
								key: "externalAccount",
								disabled: !features.externalAccountsUsage,
							},
						]}
						value={destination}
						onChange={handleSetDestination}
					/>
				</SelectorContainer>
				<Spacer />
				{(hasRecipients || destination === TransferDestination.ExternalAccounts) && (
					<SearchBar
						value={search}
						placeholder={formatMessage("recipient.searchInputPlaceholder")}
						initialValue={search || ""}
						onChange={handleSetSearch}
					/>
				)}
			</RecipientHeader>
			{destination === TransferDestination.Beneficiaries && (
				<>
					{hasRecipients && features.beneficiariesManagement && (
						<AddBeneficiaryContainer onClick={performTaskIfPossible(openAddBeneficiaryModal)}>
							<RoundContainer>
								<PlusIcon width={18} height={18} />
							</RoundContainer>
							<AddBeneficiaryLabel>{formatMessage("recipient.addRecipientButton")}</AddBeneficiaryLabel>
						</AddBeneficiaryContainer>
					)}
					<RecipientSection $isRTL={isRTL}>{formatMessage("recipient.section")}</RecipientSection>
					{!hasRecipients ? (
						recipients.loading ? (
							<StyledMainColorSpinner />
						) : (
							<EmptyBlock
								title={formatMessage("recipient.noRecipientsAvailable")}
								message={formatMessage("recipient.noRecipientsMessage")}
								button={formatMessage("recipient.addRecipientButton")}
								onClick={features.beneficiariesManagement ? performTaskIfPossible(openAddBeneficiaryModal) : undefined}
							/>
						)
					) : (
						<StyledInfiniteScroll
							loadMore={loadMore}
							hasMore={recipients.canLoadMore}
							loader={<StyledMainColorSpinner />}
						>
							{filteredRecipients.map(recipient => (
								<RecipientRow
									key={recipient.id}
									recipient={recipient}
									onEdit={performTaskIfPossible(() => openEditBeneficiaryModal(recipient))}
									onClick={performTaskIfPossible(() => openRecipientDetailModal(recipient))}
									isRTL={isRTL}
									readonly={!features.beneficiariesManagement}
								/>
							))}
						</StyledInfiniteScroll>
					)}{" "}
				</>
			)}
			{destination === TransferDestination.ExternalAccounts && <ExternalAccountsView search={search} />}
		</>
	);
}

const StyledInfiniteScroll = styled(InfiniteScroll)`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 10px;
`;

const StyledMainColorSpinner = styled(MainColorSpinner)`
	margin: 40px auto 0 auto;
`;

const RecipientSection = styled(PageSectionTitle)<{ $isRTL: boolean }>`
	font-size: 0.9375rem;
	${theme.mediumText};
	margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
	margin-right: ${props => (props.$isRTL ? 0 : 10)}px;
`;

const Spacer = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
`;
const RecipientHeader = styled(PageHeader)`
	align-self: stretch;
	display: flex;
	justify-content: center;
`;

const Title = styled.span`
	font-size: 1.5625rem;
	${theme.boldText};
	color: ${theme.mainColor};
	white-space: pre;
`;

const Subtitle = styled(Title)`
	margin-bottom: 40px;
	line-height: 32px;
	height: auto;
	flex-grow: 0;
	color: black;
	width: 330px;
	overflow-wrap: break-word;
	white-space: pre-line;
`;

const SelectorContainer = styled.div`
	margin-top: 8px;
	margin-bottom: 8px;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-bottom: 32px;
	}
`;

const AddBeneficiaryContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 18px;
	margin-top: 32px;
	margin-bottom: 32px;
	margin-left: 12px;
	margin-right: 12px;
	:hover {
		cursor: pointer;
	}
`;

const AddBeneficiaryLabel = styled.span`
	${theme.text}
	font-size: 0.9375rem;
	text-align: left;
	margin-horizontal: 16px;
`;

const RoundContainer = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	background-color: #ececec;
	align-items: center;
	justify-content: center;
`;
