export enum ProductType {
	Current = "CURRRENT",
	Savings = "SAVINGS",
	Commissions = "COMMISSIONS",
	Dedicated = "DEDICATED",
	MoneyPot = "MONEYPOT",
	DebitCard = "DEBIT_CARD",
	GuaranteeAccount = "GUARANTEE_ACCOUNT",
}

export interface ProductCardCreationProcess {
	termsOfServiceHtml?: string;
	creationEndText?: string;
	isPincodeSelectedByClient: boolean;
}

export enum ProductCardActivationMode {
	withCode = "WITH_CODE",
	withoutCode = "WITHOUT_CODE",
}

export interface Product {
	id: string;
	name: string;
	type: ProductType;
	currency: string;
	cardActivationProcess?: string;
	cardCreationProcess?: ProductCardCreationProcess;
	cardActivationCodeValidationRegex?: string;
	cardActivationMode?: ProductCardActivationMode;
}
