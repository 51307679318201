import React, { useState } from "react";
import { accountManager, cardService, productManager } from "../../../../../../shared/core/service/services";
import { CardCreationStep, useCardCreation } from "../../../../../../shared/domains/cards/use-card-creation";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../../shared/utils/phone-number";

import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { VerifyAuthenticationStatus } from "../../../../../../shared/domains/strong-customer-authentication/strong-customer-authentication";
import { isDefined } from "../../../../../../shared/utils/assert";
import { useAsyncFunction } from "../../../../../../shared/utils/utils";
import { CARD_CREATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { useClient } from "../../../../../domain/authentication/use-client";
import { Modal } from "../../../../common/modal/modal";
import { RoundedModalContainer } from "../../../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../../../common/spinner";
import { OtpConfirm } from "../../../recipient/otp-confirm";
import { ResultView } from "../../../result/result-view";
import { CreateCardCguStep } from "./create-card-cgu-step";
import { CreateCardSelectionStep } from "./create-card-selection-step";

export const CardCreationModal = ({ isVirtual }: { isVirtual?: boolean }) => (
	<RoundedModalContainer closeButton id={CARD_CREATION_MODAL_ID}>
		<CardCreationView isVirtual={isVirtual} />
	</RoundedModalContainer>
);

const CardCreationView = ({ isVirtual }: { isVirtual?: boolean }) => {
	const {
		step,
		selectedProduct,
		selectedAccount,
		debitCardProducts,
		currentAccounts,
		cgu,
		creationError,
		scaToken,
		startCreationFlow,
		setSelectedProduct,
		setSelectedAccount,
		confirmSelection,
		acceptCguWithoutPin,
		createCard,
	} = useCardCreation(isVirtual);

	const [scaIsLoading, setScaIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { client } = useClient();

	const { formatMessage } = useIntl();

	const { loading } = useAsyncFunction(async () => {
		await Promise.all([accountManager.load(), productManager.loadDebitCardProducts()]);
		await startCreationFlow();
	}, []);

	const successSubtitle = () =>
		selectedProduct?.cardCreationProcess?.creationEndText ||
		formatMessage("resultScreen.createCard.defaultSuccessSubtitle");

	const handleOTPValidation = async (otp, scaToken) => {
		if (scaToken) {
			try {
				setScaIsLoading(true);
				const response = await cardService.scaAuthenticationVerifyToken(scaToken, otp);
				if (response.status === VerifyAuthenticationStatus.DONE) {
					createCard(selectedAccount, selectedProduct, null, scaToken);
				}
			} catch (e) {
				setErrorMessage(e);
			} finally {
				setScaIsLoading(false);
			}
		} else {
			throw new Error("Missing SCA Token");
		}
	};

	const innerStepComponent = () => {
		switch (step) {
			case CardCreationStep.SelectAccountOrCard:
				return (
					<CreateCardSelectionStep
						isVirtual={isVirtual}
						currentAccounts={currentAccounts}
						selectedAccount={isDefined(selectedAccount) ? selectedAccount : undefined}
						selectAccount={setSelectedAccount}
						debitCardProducts={debitCardProducts}
						selectedProduct={isDefined(selectedProduct) ? selectedProduct : undefined}
						selectProduct={setSelectedProduct}
						confirmSelection={confirmSelection}
					/>
				);

			case CardCreationStep.PresentCgu:
				return <CreateCardCguStep cgu={cgu} acceptCgu={acceptCguWithoutPin} />;

			case CardCreationStep.SCACheck:
				return (
					<OtpConfirm
						submitOtp={(otp: string) => handleOTPValidation(otp, scaToken)}
						errorMessage={errorMessage}
						phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
						loading={scaIsLoading}
					/>
				);

			case CardCreationStep.Error:
				return (
					<StyledResultView
						type={"error"}
						title={formatMessage("resultScreen.createCard.failureTitle")}
						subtitle={formatMessage("resultScreen.createCard.failureSubtitle")}
						description={formatMessage("resultScreen.createCard.failureDescription")}
						error={creationError}
						onConfirm={() => {
							Modal.dismiss(CARD_CREATION_MODAL_ID);
						}}
					/>
				);

			case CardCreationStep.Success:
				return (
					<StyledResultView
						type={"success"}
						title={formatMessage("resultScreen.createCard.successTitle")}
						subtitle={successSubtitle()}
						onConfirm={() => {
							Modal.dismiss(CARD_CREATION_MODAL_ID);
						}}
					/>
				);
		}
	};

	return <ModalContainer>{loading ? <StyledSpinner /> : innerStepComponent()}</ModalContainer>;
};

const ModalContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledSpinner = styled(MainColorSpinner)`
	margin: auto;
`;

const StyledResultView = styled(ResultView)`
	margin-left: 20px;
	margin-right: 20px;
`;
