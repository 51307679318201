import { logger } from "../../core/logging/logger";
import { productManager } from "../../core/service/services";
import { useAsyncEffect } from "../../utils/utils";
import { useObservable } from "../../utils/observable";
import { useState } from "react";

export const useProducts = () => {
	const products = useObservable(productManager.debitCardProducts);
	const [loading, setLoading] = useState(true);

	useAsyncEffect(async () => {
		try {
			setLoading(true);
			await productManager.loadDebitCardProducts();
		} catch (e) {
			logger.debug("error fetching products", e);
		} finally {
			setLoading(false);
		}
	}, []);

	return { products, loading };
};
