import { useCallback, useState } from "react";
import { cardManager, cardService } from "../../core/service/services";

import { Card } from "./card";

export enum CardRefabricationStep {
	Motive = "MOTIVE",
	Confirm = "CONFIRM",
	Success = "SUCCESS",
	Error = "ERROR",
	SCACheck = "SCA_CHECK",
}

export const useCardRefabrication = () => {
	const [step, setStep] = useState(CardRefabricationStep.Motive);
	const [scaToken, setScaToken] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const [card, setCard] = useState<Card | undefined>(undefined);

	const [textError, setTextError] = useState<string | undefined>(undefined);

	const resetStep = () => {
		setStep(CardRefabricationStep.Motive);
	};

	const resetStepToConfirm = () => {
		setStep(CardRefabricationStep.Confirm);
	};

	const refabricateCardStartFlow = useCallback(
		async (cardToUse: Card, replacementReason: string, scaSessionToken: string) => {
			try {
				setLoading(true);
				await cardManager.refabricateCard(cardToUse.id, replacementReason, scaSessionToken);
				setStep(CardRefabricationStep.Success);
				setLoading(false);
			} catch (e) {
				const result = e as any;
				//SCA use case, must send and verify the given token
				if (result.response?.data?.error === "Unauthorized" && result.response?.data?.new_token) {
					setScaToken(result.response.data.new_token);
					setStep(CardRefabricationStep.SCACheck);
					cardService.scaAuthenticationSendToken(result.response.data.new_token);
				} else {
					setTextError(result.response?.data?.error?.message || result.toString());
					setStep(CardRefabricationStep.Error);
				}
				setLoading(false);
			}
		},
		[setStep, setTextError]
	);

	const refabricateCard = useCallback(
		async (card: Card, replacementReason: string, scaSessionToken?: string) => {
			await refabricateCardStartFlow(card, replacementReason, scaSessionToken || "");
		},
		[refabricateCardStartFlow]
	);

	return {
		step,
		loading,
		card,
		setCard,
		refabricateCard,
		error: textError,
		scaToken,
		resetStep,
		resetStepToConfirm,
	};
};
