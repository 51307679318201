import React from "react";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { attachmentsManager } from "../../../../../shared/core/service/services";
import { Attachment } from "../../../../../shared/domains/accounting-transaction/transaction/attachment";
import { AttachmentsLinks } from "../../../../../shared/domains/accounting-transaction/transaction/attachments-links";
import { AttachmentContent } from "../../../../../shared/domains/accounting-transaction/transaction/attachments-service";
import { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useAttachmentLinks } from "../../../../../shared/domains/accounting-transaction/use-attachment-links";
import { getUrlFromLink } from "../../../../../shared/domains/BaseUrl";
import { useAsyncEffect } from "../../../../../shared/utils/utils";
import styled from "styled-components";
import fileSize from "filesize";
import { theme } from "../../../styles/theme";

type AttachmentPreviewProps = {
	attachment: Attachment;
	transaction?: AccountingTransaction;
};

export default function AttachmentPreview(props: AttachmentPreviewProps) {
	const { attachment, transaction } = props;
	const [content, setContent] = React.useState<AttachmentContent>();
	const { canGetContent } = useAttachmentLinks();
	const { formatMessage } = useIntl();

	useAsyncEffect(async () => {
		if (transaction && canGetContent(attachment)) {
			try {
				const url = getUrlFromLink(attachment.links, AttachmentsLinks.GetContent);
				const content = await attachmentsManager.getContent(transaction.id, attachment.id, url);
				if (content) {
					setContent(content);
				}
			} catch (e) {
				console.log(e);
			}
		}
	}, [attachment]);

	const contentType = content?.contentType;
	const fileType = contentType?.split("/")[0];
	const extention = contentType?.split("/")[1].split(";")[0] || "";
	const base64Img = content?.base64Img || "";

	return (
		<AttachmentContentStyled>
			{formatMessage("registerScreen.pictureInputFilled")} {fileType} {extention ? `(${extention})` : ""}
			{!!content && <AttachmentContentDescription>{fileSize(base64Img.length)}</AttachmentContentDescription>}
		</AttachmentContentStyled>
	);
}

const AttachmentContentStyled = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const AttachmentContentDescription = styled.div`
	color: ${theme.colors.gray[500]};
	font-transform: uppercase;
	font-size: 12px;
`;