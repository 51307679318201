import React from "react";
import styled from "styled-components";
import { useRTL } from "../../../domain/language/use-rtl";
import { theme } from "../../styles/theme";
import { SelectInput, SelectInputProps } from "./select-input";

interface PrefixedSelectInputProps<T> extends SelectInputProps<T> {
	prefixElement?: React.ReactNode;
}
export type PrefixedSelectInputType<K> = React.FC<PrefixedSelectInputProps<K>>;

export function PrefixedSelectInput<K>(props: PrefixedSelectInputProps<K>) {
	const { prefixElement, className, ...rest } = props;
	const { isRTL } = useRTL();

	return (
		<Row className={className} dir={"ltr"}>
			<PrefixContainer $isRTL={isRTL}>{prefixElement}</PrefixContainer>
			<SelectInput<K> style={selectInputStyle} {...rest}></SelectInput>
		</Row>
	);
}

// We use React CSSProperties because Generic Type Component is not working well with StyledComponent
const selectInputStyle: React.CSSProperties = {
	width: "100%",
	borderBottomLeftRadius: 0,
	borderBottomRightRadius: 10,
	borderTopLeftRadius: 0,
	borderTopRightRadius: 10,
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const PrefixContainer = styled.div<{ $isRTL: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	background-color: #ffffff;
	width: 80px;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		bottom: 0;
		right: ${props => (props.$isRTL ? "auto" : 0)};
		left: ${props => (props.$isRTL ? 0 : "auto")};
		width: 1px;
		height: calc(100% - 10px);
		background-color: ${theme.colors.gray[500]};
		transform: translateY(-50%);
	}
`;
