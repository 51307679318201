import { useCallback, useRef } from "react";
import ReactTooltip from "react-tooltip";

export function useTooltip<T extends Element>() {
	const elementRef = useRef<T>(null);

	const tooltipHideTimeout = useRef<NodeJS.Timeout | null>(null);
	const showTooltip = useCallback(() => {
		if (tooltipHideTimeout.current) {
			clearTimeout(tooltipHideTimeout.current);
		}
		if (elementRef.current) {
			ReactTooltip.show(elementRef.current);
			tooltipHideTimeout.current = setTimeout(() => {
				if (elementRef.current) {
					ReactTooltip.hide(elementRef.current);
				}
			}, 3000);
		}
	}, []);
	return { elementRef, showTooltip };
}
