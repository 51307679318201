import { Paginated, PaginationOptions } from "../../utils/pagination";
import { Card, CardFeatureType, CardStatus, OpposeCardReason } from "./card";
import { Outstanding, OutstandingDTO, OutstandingResponse, amount, isPayment } from "./outstanding";

import { Buffer } from "buffer";
import { IUpdateOutstandingParams } from "../../../mobile/ui/screens/cards/components/outstandings/update-outstanding-screen";
import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";
import { isDefined } from "../../utils/assert";
import { VerifyAuthenticationSMSResponse } from "../strong-customer-authentication/strong-customer-authentication";

interface CardDTO extends Omit<Card, "id"> {
	id: number;
}

interface RefabricatedCard {
	id: number;
	pendingManagmentOperation: {
		operationType: string;
		featureId: string;
	};
}

export interface EncryptedCardDetailsResponse {
	encryptedPan?: string;
	encryptedCvv?: string;
	expirationMonth?: number;
	expirationYear?: number;
	content?: string;
}

export interface DecryptedCardDetailsResponse {
	pan: string;
	cvv: string;
	expirationMonth: number;
	expirationYear: number;
}

export interface OverrideCardData {
	panDisplay?: string;
	expirationDate?: string;
	cvv?: string;
	base64Image?: string;
}

export class CardService {
	public constructor(
		private apiService: ConnectedApiService,
		private serviceDomainService: ConnectedServiceDomainApiService // private sodium: any
	) {}

	public async fetchCards(pagination?: PaginationOptions, status?: CardStatus): Promise<Paginated<Card>> {
		try {
			const response = await this.apiService.instance.get<Paginated<CardDTO>>("/cards", {
				params: {
					...pagination,
					status: status,
				},
			});
			return { ...response.data, items: response.data.items.map(dto => this.transformDtoToCard(dto)) };
		} catch (e) {
			logger.debug("CardService", "Fetch cards failed", e);
			throw e;
		}
	}

	public async opposeCard(id: string, reason: OpposeCardReason): Promise<Card> {
		try {
			const response = await this.apiService.instance.post<CardDTO>(`/cards/${id}/_actions/oppose`, {
				reason: reason,
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Oppose card failed", e);
			throw e;
		}
	}

	public async blockCard(id: string): Promise<Card> {
		try {
			const response = await this.apiService.instance.post<CardDTO>(`/cards/${id}/_actions/block`);
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Block card failed", e);
			throw e;
		}
	}

	public async unblockCard(id: string): Promise<Card> {
		try {
			const response = await this.apiService.instance.post<CardDTO>(`/cards/${id}/_actions/unblock`);
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Unblock card failed", e);
			throw e;
		}
	}

	public async activateCard(id: string, activationCode?: string, scaSessionToken?: string): Promise<Card> {
		try {
			const body =
				isDefined(activationCode) && activationCode.length > 0
					? {
							code: activationCode,
					  }
					: undefined;
			const response = await this.apiService.instance.post<CardDTO>(`/cards/${id}/_actions/activate`, body, {
				headers: scaSessionToken
					? {
							"SCAP-Token": scaSessionToken,
					  }
					: {},
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Activate card failed", e);
			throw e;
		}
	}

	public async deactivateCard(id: string, scaSessionToken?: string): Promise<Card> {
		try {
			const response = await this.apiService.instance.delete<CardDTO>(`/cards/${id}/_actions/deactivate`, {
				headers: scaSessionToken
					? {
							"SCAP-Token": scaSessionToken,
					  }
					: {},
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Deactivate card failed", e);
			throw e;
		}
	}

	public async enableCardFeature(id: string, feature: CardFeatureType, isEnabled: boolean): Promise<Card> {
		try {
			const response = await this.apiService.instance.patch<CardDTO>(`/cards/${id}/features`, {
				id: feature,
				enabled: isEnabled,
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Enable card feature failed", e);
			throw e;
		}
	}

	public async createCard(
		accountId: string,
		productId?: string,
		pincode?: string,
		scaToken?: string,
		isVirtual?: boolean
	) {
		try {
			const response = await this.apiService.instance.post<CardDTO>(
				`/accounts/${accountId}/${isVirtual ? "cards-virtual" : "cards"}`,
				{
					productId: productId,
					pincode: pincode,
				},
				{
					headers: scaToken
						? {
								"SCAP-Token": scaToken,
						  }
						: {},
				}
			);
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Enable card feature failed", e);
			throw e;
		}
	}

	public async reissuePincode(
		id: string,
		pincode: string,
		withChannel: boolean,
		scaSessionToken?: string
	): Promise<Card> {
		try {
			const body = withChannel
				? {
						newPin: pincode,
						channnel: "APP",
				  }
				: { newPin: pincode };

			const response = await this.apiService.instance.post<CardDTO>(`/cards/${id}/_actions/pinCodeReissuing`, body, {
				headers: scaSessionToken
					? {
							"SCAP-Token": scaSessionToken,
					  }
					: {},
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Reissue Pin code for card failed", e);
			throw e;
		}
	}

	public async retrieveCard(id: string) {
		try {
			const response = await this.apiService.instance.get<CardDTO>(`/cards/${id}`);
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Retrieve card failed", e);
			throw e;
		}
	}

	public async getCardImage(id: string) {
		try {
			const response = await this.apiService.instance.get(`/cards/${id}/image`, { responseType: "arraybuffer" });
			const base64 = Buffer.from(response.data, "binary").toString("base64");
			return base64;
		} catch (e) {
			logger.debug("CardService", "Get card image failed", e);
			throw e;
		}
	}

	public async getOutstandings(id: string, url: string | undefined) {
		try {
			const response = await this.apiService.instance.get<OutstandingResponse>(url ?? `/cards/${id}/outstandings`);
			if (response.data.outstandings && response.data.outstandings.length > 0) {
				const formatted = response.data.outstandings.map(outstandingDTO =>
					this.transformDtoToOutstanding(outstandingDTO)
				);
				return formatted;
			} else {
				return [];
			}
		} catch (e) {
			logger.debug("CardService", "Get outstandings failed", JSON.stringify(e));
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async updateOutstanding(card_id: string, params: IUpdateOutstandingParams, scaToken?: string): Promise<Card> {
		try {
			const response = await this.apiService.instance.patch<CardDTO>(`/cards/${card_id}/limits`, params, {
				headers: scaToken
					? {
							"SCAP-Token": scaToken,
					  }
					: {},
			});
			return this.transformDtoToCard(response.data);
		} catch (e) {
			logger.debug("CardService", "Enable card feature failed", e);
			throw e;
		}
	}

	public async refabricateCard(
		card_id: string,
		replacement_reason: string,
		scaToken?: string
	): Promise<RefabricatedCard> {
		try {
			const response = await this.apiService.instance.patch<RefabricatedCard>(
				`/cards/${card_id}/refabricate`,
				{ replacement_reason: replacement_reason },
				{
					headers: scaToken
						? {
								"SCAP-Token": scaToken,
						  }
						: {},
				}
			);
			return response.data;
		} catch (e) {
			logger.debug("CardService", "refabricateCard failed", e);
			throw e || e.toString();
		}
	}

	private transformDtoToCard(card: CardDTO): Card {
		return { ...card, id: card.id.toString() };
	}

	private transformDtoToOutstanding(outstanding: OutstandingDTO): Outstanding {
		const maxValue = outstanding.limit.value;
		const usedValue = outstanding.current.value;
		const availableValue = maxValue - usedValue >= 0 ? maxValue - usedValue : 0;

		return {
			id: outstanding.id,
			title: outstanding.label ?? "",
			maxValue,
			usedValue,
			availableValue,
			maxAmount: amount(maxValue, outstanding.limit.currency),
			usedAmount: amount(usedValue, outstanding.current.currency),
			availableAmount: amount(availableValue, outstanding.current.currency),
			isPayment: isPayment(outstanding.type),
			period: outstanding.period,
			specificPeriod: outstanding.specificPeriod,
			type: outstanding.type,
		};
	}

	public async scaAuthenticationSendToken(token: string, method = "sms") {
		try {
			const url = `/authentications/sessions/${token}/methods/${method}/send`;
			const response = await this.serviceDomainService.instance.post(url);
			return response.data;
		} catch (e) {
			logger.debug("ServiceDomainService", "SCA send token failed", e);
			throw e;
		}
	}

	public async scaAuthenticationVerifyToken(
		token: string,
		otpCode: string,
		method = "sms"
	): Promise<VerifyAuthenticationSMSResponse> {
		try {
			const url = `/authentications/sessions/${token}/methods/${method}/verify`;
			const response = await this.serviceDomainService.instance.post(url, {
				code: otpCode,
			});
			return response.data;
		} catch (e) {
			logger.debug("ServiceDomainService", "SCA verify token failed", e);
			throw e.response.data.message;
		}
	}

	public async getSensitiveCardDetails(
		cardId: string,
		publicKey: string,
		scaToken?: string
	): Promise<EncryptedCardDetailsResponse> {
		try {
			const response = await this.apiService.instance.post(
				`/cards/${cardId}/details`,
				{
					publicKey,
				},
				{
					headers: scaToken
						? {
								"SCAP-Token": scaToken,
						  }
						: {},
				}
			);
			return response.data;
		} catch (e) {
			// if (e.response.data.error.code === 500) {
			// 	return {
			// 		encryptedPan: this.sodium.to_hex(
			// 			this.sodium.crypto_box_seal("0812081208120812", this.sodium.from_hex(publicKey))
			// 		),
			// 		encryptedCvv: this.sodium.to_hex(this.sodium.crypto_box_seal("123", this.sodium.from_hex(publicKey))),
			// 		expirationMonth: 9,
			// 		expirationYear: 2023,
			// 	};
			// } else {
			logger.debug("CardService", "Get sensitive card details failed", e);
			throw e;
			// }
		}
	}
}
