import React from "react";
import styled from "styled-components";
import { Config } from "../../../../../shared/core/config/config";
import { theme } from "../../../styles/theme";

export function VersionBox() {
    return (
        <Version>
            <VersionContent>{Config.APP_VERSION}</VersionContent>
        </Version>
    );
}

const Version = styled.div`
    align-self: flex-start;
`

const VersionContent = styled.span`
    ${theme.text};
    color: #cacaca;
`
