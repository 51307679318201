import { createIntl, FormatNumberOptions } from "react-intl";
import { Client } from "../../domains/client/client";
import { Observable } from "../../utils/observable";
import { Amount } from "../amount/amount";
import { Config } from "../config/config";
import { Currencies, Currency } from "../currency/currency";
import { logger } from "../logging/logger";
import { TranslationsUpdateError } from "./i18n-error";
import { I18NStore } from "./i18n-store";
import { findBestAvailableLanguage, isRTLLayout } from "./locale";
import { LanguageTag, LanguageTranslations } from "./translations";
import { TranslationsManager } from "./translations-manager";

const fallbackLanguage = Config.FALLBACK_LANGUAGE as LanguageTag;

const fallbackTranslationsEnabled = Config.FALLBACK_TRANSLATIONS_ENABLED;

export class I18NManager {
	public intl = new Observable(I18NManager.createIntl(this.localeTag.get(), {}));

	public isRTL = new Observable(false);

	public initialized = new Observable(false);
	private localTranslations = this.translationsManager.localTranslations;

	public constructor(private i18NStore: I18NStore, private translationsManager: TranslationsManager) {}

	public async initialize(client: Client | null) {
		const configuredLanguages = this.translationsManager.getConfiguredLanguages();
		logger.debug("I18NManager", "available languages:", configuredLanguages);
		this.i18NStore.localeTag.set(this.getClientOrDeviceOrFallbackLanguage(client, configuredLanguages));
		try {
			await this.setLocale(this.i18NStore.localeTag.get());
			this.initialized.set(true);
		} catch (e) {
			if (e instanceof TranslationsUpdateError) {
				this.initialized.set(true);
			}
			throw e;
		}
	}

	public get localeTag() {
		return this.i18NStore.localeTag;
	}

	public getClientOrDeviceOrFallbackLanguage(client: Client | null, configuredLanguages: string[]): LanguageTag {
		if (client?.lang) {
			logger.debug("I18NManager", "client already has a defined language: ", client.lang);
			return client.lang as LanguageTag;
		}
		const userLocale = I18NManager.getBestAvailableLanguage(configuredLanguages);
		if (userLocale) {
			logger.debug("I18NManager", "selecting best available locale from device ", userLocale);
			return userLocale;
		}

		logger.debug("I18NManager", "falling back to", fallbackLanguage);
		return fallbackLanguage;
	}

	public async setLocale(locale: LanguageTag) {
		this.i18NStore.localeTag.set(locale);
		await this.updateTranslations(this.i18NStore.localeTag.get());
		this.isRTL.set(isRTLLayout(locale));
	}

	private async updateTranslations(localeTag: LanguageTag) {
		let translations;
		let error;
		try {
			const remoteTranslations = await this.translationsManager.loadTranslations(localeTag);

			const localTranslation = this.localTranslations[localeTag];
			const fallbackTranslations = fallbackTranslationsEnabled ? this.localTranslations[fallbackLanguage] : undefined;

			translations = {
				...(fallbackTranslations ? fallbackTranslations() : {}),
				...(localTranslation ? localTranslation() : {}),
				...remoteTranslations[localeTag],
			};
		} catch (e) {
			if (this.localTranslations[localeTag]) {
				translations = {
					...this.localTranslations[localeTag](),
					...this.localTranslations[fallbackLanguage]()
				};
			} else {
				logger.debug("I18NManager", `No local translation for user language, falling back to ${fallbackLanguage}`);
				translations = this.localTranslations[fallbackLanguage]();
			}
		}
		this.intl.set(I18NManager.createIntl(this.i18NStore.localeTag.get(), translations));
		if (error) {
			throw new TranslationsUpdateError("Translations update failed");
		}
	}

	public formatAmount = (amount: Amount, absolute?: boolean, forceSign?: boolean) => {
		const currency: Currency = Currencies[amount.currency];
		let amountValue: number = amount.value / Math.pow(10, currency ? currency.decimal_number : 2);
		amountValue = absolute ? Math.abs(amountValue) : amountValue;

		const additionalFormatConfig: FormatNumberOptions = {
			currencyDisplay: "symbol",
		};

		const locale = this.intl.get().locale;
		if (locale == "ar-MR") {
			additionalFormatConfig.currencyDisplay = "code";
		}

		const result =
			(forceSign && Math.sign(amount.value) > 0 ? "+" : "") +
			this.intl.get().formatNumber(amountValue, {
				...additionalFormatConfig,
				currency: amount.currency as string,
				style: "currency",
				minimumFractionDigits: currency ? currency.decimal_number : 2,
				numberingSystem: "latn",
			});

		const handleRTLLayout = (value: string) => {
			const regex = /([+\-])([^0-9]+)([0-9,\.]+)/;
			const match = value.match(regex);
			let updatedValue = value;
			if (match) {
				updatedValue = match[2] + match[1] + match[3];
			}

			return updatedValue;
		};

		return isRTLLayout(locale) ? handleRTLLayout(result) : result;
	};

	public formatMessage = (id: string, values?: Record<string, string | number | boolean | null | undefined | Date>) =>
		this.initialized.get() ? this.intl.get().formatMessage({ id }, values) : "";

	public formatRelativeDate = (date: string) => {
		const intl = this.intl.get();
		if (intl.formatDate(new Date()) === intl.formatDate(date)) {
			return this.formatMessage("common.today");
		} else if (intl.formatDate(Date.now() - 1000 * 60 * 60 * 24) === intl.formatDate(date)) {
			return this.formatMessage("common.yesterday");
		}
		return intl.formatDate(date, { month: "long", day: "2-digit", year: "numeric" });
	};

	private static createIntl(localeTag: LanguageTag, translations: LanguageTranslations) {
		return createIntl({ locale: localeTag.replace("_", "-"), messages: translations });
	}

	private static getBestAvailableLanguage(languageTags: string[]): LanguageTag | null {
		const userLocale = findBestAvailableLanguage(languageTags.map(l => l.replace("_", "-")));
		if (userLocale) {
			const localeTag = userLocale.languageTag.replace("-", "_");
			return localeTag as LanguageTag;
		}
		return null;
	}
}
