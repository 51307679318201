import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { cashTransferService } from "../../../../shared/core/service/services";
import {
	CashTransfer,
	CashTransferType,
	defaultTransferTitles,
} from "../../../../shared/domains/transactions/cash-transfer/cash-transfer";
import { usePaginatedFetch } from "../../../../shared/utils/pagination";
import { CASH_TRANSFER_MODAL_ID } from "../../../core/modal/modal-id";
import { DefaultButton } from "../../common/buttons/default-button";
import { ErrorMessage } from "../../common/error-message";
import { Modal } from "../../common/modal/modal";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { MainColorSpinner } from "../../common/spinner";
import { theme } from "../../styles/theme";
import { performTaskIfPossible } from "../pincode/assert-banking-task";
import { AccountTransactionCard } from "./components/account-transaction-card";
import { CashTransferModal } from "./components/cash-transfer-modal";

export const CashTransferScreen = () => {
	const { formatMessage } = useIntl();
	const [selectedType, setSelectedType] = useState(CashTransferType.Received);

	const {
		data: cashTransfers,
		loading,
		error,
		redo,
		redoLoading,
		hasMore,
		fetchMore,
		moreError,
	} = usePaginatedFetch(pagination => cashTransferService.fetchCashTransfer(pagination));

	const groupedCashTransfers = useMemo(
		() =>
			cashTransfers.reduce<{ [key in CashTransferType]: CashTransfer[] }>(
				(groups, cashTransfer) => {
					groups[cashTransfer.type].push(cashTransfer);
					return groups;
				},
				{
					[CashTransferType.Received]: [],
					[CashTransferType.Sent]: [],
				}
			),
		[cashTransfers]
	);

	return (
		<>
			<TransactionsSection>
				<PageSectionTitle>{formatMessage("cashTransferScreen.title")}</PageSectionTitle>
				<Description>{formatMessage("cashTransferScreen.subtitle")}</Description>
				<SelectorWrapper>
					<SelectorButton
						isSelected={selectedType === CashTransferType.Received}
						onClick={() => setSelectedType(CashTransferType.Received)}>
						{formatMessage("cashTransferScreen.receivedSelector")}
					</SelectorButton>
					<SelectorButton
						isSelected={selectedType === CashTransferType.Sent}
						onClick={() => setSelectedType(CashTransferType.Sent)}>
						{formatMessage("cashTransferScreen.sentSelector")}
					</SelectorButton>
				</SelectorWrapper>
				{loading || redoLoading ? (
					<StyledBlueSpinner key="spinner" />
				) : groupedCashTransfers[selectedType].length === 0 ? (
					<NoTransactionAvailable>{formatMessage(emptyListLabels[selectedType])}</NoTransactionAvailable>
				) : (
					<StyledInfiniteScroll
						loadMore={fetchMore}
						hasMore={cashTransfers.length > 0 && hasMore}
						loader={<StyledBlueSpinner key="cash-transfer-loader" />}>
						{groupedCashTransfers[selectedType].map(cashTransfer => (
							<CashTransfertCard
								key={cashTransfer.id}
								transaction={
									cashTransfer.label
										? cashTransfer
										: { ...cashTransfer, label: formatMessage(defaultTransferTitles[cashTransfer.type]) }
								}
								received={selectedType === CashTransferType.Received}
								onClick={performTaskIfPossible(() =>
									Modal.present(
										CASH_TRANSFER_MODAL_ID,
										() => <CashTransferModal cashTransfer={cashTransfer} onComplete={redo} />,
										{
											canBeDismissed: false,
										}
									)
								)}
							/>
						))}
						{(error || moreError) && <StyledErrorMessage>{error || moreError}</StyledErrorMessage>}
					</StyledInfiniteScroll>
				)}
			</TransactionsSection>
		</>
	);
};

const emptyListLabels = {
	[CashTransferType.Received]: "cashTransferScreen.emptyReceivedList",
	[CashTransferType.Sent]: "cashTransferScreen.emptySentList",
};

const TransactionsSection = styled.section`
	align-self: stretch;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Description = styled.div`
	${theme.text};
	font-size: 0.9375rem;
	color: #b1b1b1;
	margin-bottom: 40px;
`;

const NoAccountAvailable = styled.span`
	${theme.text};
	margin-top: 20px;
	margin-bottom: 40px;
`;

const NoTransactionAvailable = styled(NoAccountAvailable)``;

const StyledInfiniteScroll = styled(InfiniteScroll)`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const StyledBlueSpinner = styled(MainColorSpinner)`
	margin: 40px auto 0 auto;
`;

const CashTransfertCard = styled(AccountTransactionCard)`
	cursor: ${props => (props.onClick ? "pointer" : null)};
	align-self: stretch;
	&:not(:last-of-type) {
		margin-bottom: 25px;
	}
`;

const SelectorWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
	align-self: stretch;
`;

const SelectorButton = styled(DefaultButton)<{ isSelected: boolean }>`
	font-size: 1rem;
	position: relative;
	${theme.boldText};

	:first-child {
		margin-right: 20px;
	}

	:after {
		position: absolute;
		bottom: -4px;
		left: 0;
		right: 0;
		display: ${props => (props.isSelected ? null : "none")};
		content: "";
		margin-top: 2px;
		height: 2px;
		width: 100%;
		background-color: ${theme.mainColor};
	}
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin-top: 10px;
`;
