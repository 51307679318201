import React, { SVGProps } from "react";

export function EyeIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 22 22" {...props}>
			<path
				fillRule="nonzero"
				d="M11 5.5c-3.846 0-7.13 2.392-8.462 5.77C3.87 14.645 7.154 17.037 11 17.037s7.13-2.392 8.462-5.769C18.13 7.892 14.846 5.5 11 5.5zm0 9.615c-2.123 0-3.846-1.723-3.846-3.846S8.877 7.423 11 7.423s3.846 1.723 3.846 3.846-1.723 3.846-3.846 3.846zm0-6.153c-1.277 0-2.308 1.03-2.308 2.307S9.723 13.577 11 13.577s2.308-1.03 2.308-2.308c0-1.277-1.031-2.307-2.308-2.307z"
				transform="translate(-279 -44) translate(279 44)"
			/>
		</svg>
	);
}
