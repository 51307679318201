import { featuresManager } from "../../core/service/services";
import { isDefined } from "../../utils/assert";
import { AccountIBAN } from "../account/account";

export const getAvailableRechargeMethod = (externalAccountIBAN: AccountIBAN) => {
	const features = featuresManager.features.get();
	const hasBankToWallet = features.bankToWallet && isDefined(externalAccountIBAN);
	const hasRechargeByCard = features.walletRechargeByCard;

	return { hasRechargeByCard, hasBankToWallet };
};
