import { ObservableArray } from "../../utils/observable-array";
import { AcceptorCategory } from "./acceptor-category";
import { AcceptorService } from "./acceptor-service";

export class AcceptorCategoriesManager {
	public acceptorCategories = new ObservableArray<AcceptorCategory>([]);

	public constructor(private acceptorService: AcceptorService) {}

	public async refresh() {
		const categories = await this.acceptorService.getAcceptorCategories();
		this.acceptorCategories.set(categories);
	}
}
