import { Observable } from "./observable";

export class ObservableArray<T> extends Observable<T[]> {
	public replace(value: T, comparator: (item: T) => boolean): void {
		const array = [...this.get()];
		const index = array.findIndex(item => comparator(item));
		if (index !== -1) {
			array[index] = value;
		}
		this.set(array);
	}
}
