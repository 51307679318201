import { PhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { PrimaryButton, WhitePrimaryButton } from "../../../../common/buttons/primary-button";

import parseMax from "libphonenumber-js/max";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../../../shared/core/service/services";
import { InitialOnboarding } from "../../../../../../shared/domains/onboarding/onboarding";
import { useObservable } from "../../../../../../shared/utils/observable";
import { ErrorMessage } from "../../../../common/error-message";
import FormLabel from "../../../../common/forms/form-label";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { PhoneInput } from "../../../../common/forms/phone-input";
import { TextInput } from "../../../../common/forms/text-input";
import { theme } from "../../../../styles/theme";
import { RegisterPrivacyPolicy } from "../../register/components/register-privacy-policy";
import { RegisterTermOfServiceWidget } from "../../register/components/register-term-of-service";

const OnboardingInitialForm: React.FC<{
	value: InitialOnboarding;
	onChange?: (data: InitialOnboarding) => void;
	onPhoneNumberChange?: (phoneNumber: PhoneNumber) => void;
	onCancel: () => void;
	onSubmit: (data: InitialOnboarding) => void;
	initialPhoneNumber?: PhoneNumber;
	errorMessage?: string | null;
	loading?: boolean;
}> = ({ value, loading, errorMessage, initialPhoneNumber, onCancel, onChange, onSubmit }) => {
	const configuration = useObservable(configurationManager.configuration);
	const defaultCountry = useObservable(configurationManager.defaultCountry);
	const [countryCode, setCountryCode] = useState(initialPhoneNumber?.country ?? defaultCountry);
	const [number, setPhoneNumber] = useState<string>((value.phoneNumber?.number as string) || "");
	const [error, setError] = useState<string | null>(null);
	const [isTermAccepted, setIsTermAccepted] = useState<boolean>(false);
	const [isPrivacyAccepted, setIsPrivacyAccepted] = useState<boolean>(false);
	const { formatMessage } = useIntl();

	const [needDistributorSelectionDuringOnboarding, setNeedDistributorSelectionDuringOnboarding] = useState(false);

	useEffect(() => {
		configurationManager.reload().then(function () {
			setNeedDistributorSelectionDuringOnboarding(configurationManager.needDistributorSelectionDuringOnboarding());
		});
	}, []);

	useEffect(() => {
		const phoneNumber = parsePhoneNumberFromString(number, countryCode);
		onChange?.({
			...value,
			phoneNumber: phoneNumber,
		});
	}, [countryCode, number]);

	const handleChange = (name: string, val: string) => {
		onChange?.({
			...value,
			[name]: val,
		});
	};

	const isFormValid = () => {
		if (!value.firstName || !value.lastName || !value.email || !value.phoneNumber) {
			return false;
		}
		if (configuration.privacyPolicyUrl && !isPrivacyAccepted) {
			return false;
		}
		return configuration.termsAndConditionsUrl ? isTermAccepted : true;
	};

	const checkIsTaxedNumber = () => {
		const ACCEPTED_TYPES = ["MOBILE", "FIXED_LINE", "FIXED_LINE_OR_MOBILE"];
		const phone = parsePhoneNumberFromString(number, countryCode);
		const type = parseMax(phone?.number)?.getType();
		return {
			accepted: ACCEPTED_TYPES.includes(type),
			type: type,
		};
	};

	const handleSubmit = () => {
		const checkTaxedNumber = checkIsTaxedNumber();
		if (!checkTaxedNumber.accepted) {
			setError(formatMessage(`onboardingScreen.taxedNumberError.${checkTaxedNumber.type}`));
		} else {
			onSubmit(value);
		}
	};

	const handleTermsChange = () => {
		onChange?.({
			...value,
			termsAcceptance: !isTermAccepted,
		});
		setIsTermAccepted(!isTermAccepted);
	};

	const handlePrivacyChange = () => {
		onChange?.({
			...value,
			privacyPolicyAcceptance: !isPrivacyAccepted,
		});
		setIsPrivacyAccepted(!isPrivacyAccepted);
	};

	return (
		<>
			<Container>
				<StepTitle>{formatMessage("onboardingScreen.initialStepTitle")}</StepTitle>
				<FormFieldWrapper>
					<FormLabel label="onboardingScreen.formLabels.firstName" />
					<TextInput
						disabled={loading}
						value={value.firstName}
						onChange={text => handleChange("firstName", text.target.value)}
					/>
				</FormFieldWrapper>
				<FormFieldWrapper>
					<FormLabel label="onboardingScreen.formLabels.lastName" />
					<TextInput
						disabled={loading}
						value={value.lastName}
						onChange={text => handleChange("lastName", text.target.value)}
					/>
				</FormFieldWrapper>
				<FormLabel label="onboardingScreen.formLabels.phoneNumber" />
				<PhoneInput
					maxWidth="0"
					disabled={loading}
					onChangeCountryCode={setCountryCode}
					onChangePhoneNumber={setPhoneNumber}
					initialCountryCode={initialPhoneNumber?.country ?? defaultCountry}
					initialPhone={initialPhoneNumber?.nationalNumber.toString() ?? ""}
				/>
				<FormFieldWrapper>
					<FormLabel label="onboardingScreen.formLabels.email" />
					<TextInput
						disabled={loading}
						type="email"
						value={value.email}
						onChange={text => handleChange("email", text.target.value)}
					/>
				</FormFieldWrapper>
				{needDistributorSelectionDuringOnboarding && (
					<FormFieldWrapper>
						<FormLabel label="onboardingScreen.formLabels.selectDistributorDuringOnboarding" />
						<TextInput
							disabled={loading}
							type="text"
							errorMessage={formatMessage("onboardingScreen.formLabels.selectDistributorDuringOnboardingHelperText")}
							value={value.distributorConfigurationCode}
							onChange={text => handleChange("distributorConfigurationCode", text.target.value)}
						/>
					</FormFieldWrapper>
				)}

				{configuration.termsAndConditionsUrl && (
					<StyledRegisterTermOfServiceWidgetWrapper>
						<RegisterTermOfServiceWidget
							showTitle={false}
							isTermAccepted={isTermAccepted}
							onTermChange={handleTermsChange}
							url={configuration.termsOfService}
							hasEula={!!configuration.termsOfService}
						/>
					</StyledRegisterTermOfServiceWidgetWrapper>
				)}
				{configuration.privacyPolicyUrl && (
					<StyledRegisterTermOfServiceWidgetWrapper>
						<RegisterPrivacyPolicy
							showTitle={false}
							isTermAccepted={isPrivacyAccepted}
							onTermChange={handlePrivacyChange}
							url={configuration.privacyPolicyUrl}
							hasEula={!!configuration.privacyPolicyUrl}
						/>
					</StyledRegisterTermOfServiceWidgetWrapper>
				)}
				{!!(error || errorMessage) && <ErrorMessage>{error || errorMessage}</ErrorMessage>}
				<ButtonContainer>
					<WhitePrimaryButton onClick={() => onCancel?.()} size="S">
						{formatMessage("onboardingScreen.cancelButton")}
					</WhitePrimaryButton>
					<PrimaryButton
						disabled={!isFormValid() || loading}
						onClick={() => {
							try {
								handleSubmit();
							} catch (e) {
								setError(e);
							}
						}}
						size="S">
						{formatMessage("onboardingScreen.getStartedButton")}
					</PrimaryButton>
				</ButtonContainer>
			</Container>
		</>
	);
};

export default OnboardingInitialForm;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 450px;
`;

const StepTitle = styled(MarkdownText)`
	${theme.boldText};
	font-size: 2.5rem;
	line-height: 2.875rem;
	align-self: flex-start;
	margin-bottom: 24px;
`;

const FormFieldWrapper = styled.div`
	margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	width: 100%;
`;

const StyledRegisterTermOfServiceWidgetWrapper = styled.div`
	background-color: white;
	border-radius: 12px;
	padding: 20px 20px 0 20px;
	margin-bottom: 20px;
`;
