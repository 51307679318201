import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cardManager, featuresManager } from "../../../../shared/core/service/services";
import { useCardOperation, useCards } from "../../../../shared/domains/cards/use-cards";
import { PadlockLocked, PadlockUnlock } from "../../common/svg/padlock";
import { shadows, theme } from "../../styles/theme";
import { CardTile, SkeletonCardTile } from "./components/card-tile";

import styled from "styled-components";
import { useCardLinks } from "../../../../mobile/ui/screens/cards/components/use-card-links";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger";
import { Card, CardStatus } from "../../../../shared/domains/cards/card";
import { OverrideCardData } from "../../../../shared/domains/cards/card-service";
import { useProducts } from "../../../../shared/domains/products/use-products";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { CARD_CREATION_MODAL_ID, CARD_SENSITIVE_DETAILS_MODAL_ID } from "../../../core/modal/modal-id";
import { useRTL } from "../../../domain/language/use-rtl";
import { UnstyledButton } from "../../common/buttons/default-button";
import { SectionCarousel } from "../../common/carousel/section-carousel";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { AddCircleIcon } from "../../common/svg/add-circle-icon";
import { EyeIcon } from "../../common/svg/eye-icon";
import { UIConstants } from "../../styles/uiConstants";
import { AddCardItem } from "./components/card-creation/add-card-item";
import { CardCreationModal } from "./components/card-creation/create-card-view";
import { CardSensitiveDetails } from "./components/card-sensitive-detail";
import { AdditionalInfosSection } from "./components/infos/additional-infos";
import { ActivationButton } from "./components/options/activation-button";
import { CardFeaturesSwitches } from "./components/options/card-features-switches";
import { DeactivateButton } from "./components/options/deactivate-button";
import { OppositionButton } from "./components/options/opposition-button";
import { OrderNewCardButton } from "./components/options/order-new-card";
import { OutstandingsButton } from "./components/options/outstandings-button";
import { RefabricateButton } from "./components/options/refabricate-button";
import { UpdateCardPinCodeButton } from "./components/pincode/update-card-pincode-button";
import { useSelectedCard } from "./utils/use-selected-card";

export function AllCardsScreen() {
	const { cards, loading: cardsLoading } = useCards();
	const { loading: productsLoading, products } = useProducts();
	const [focusedCardSensitiveData, setFocusedCardSensitiveData] = useState<OverrideCardData | null>();
	const features = useObservable(featuresManager.features);

	const screenLoading = cardsLoading || productsLoading;

	const [focusedCard, setFocusedCard] = useSelectedCard(cards);

	const { loading, oppose, deactivate, block, unblock, enableFeature } = useCardOperation(focusedCard?.id ?? null);

	const { formatMessage } = useIntl();
	const [pendingOperation, setPendingOperation] = useState(false);

	const { canRefabricateCard } = useCardLinks();

	const optionsSectionIsVisible = useMemo(() => {
		switch (focusedCard?.status) {
			case CardStatus.Active:
			case CardStatus.Blocked:
				return true;
			default:
				return false;
		}
	}, [focusedCard]);

	const actionsSectionIsVisible = useMemo(() => {
		switch (focusedCard?.status) {
			case CardStatus.Pending:
			case CardStatus.Active:
			case CardStatus.Blocked:
				return true;
			default:
				return false;
		}
	}, [focusedCard]);

	const canActiveOrDeactivateCard = useMemo(() => {
		switch (focusedCard?.status) {
			case CardStatus.Active:
			case CardStatus.Blocked:
				return true;
			default:
				return false;
		}
	}, [focusedCard]);

	useEffect(() => {
		setFocusedCardSensitiveData(null);
		setPendingOperation(isDefined(focusedCard?.pendingManagementOperation));
	}, [focusedCard]);

	const refreshCard = useCallback(async () => {
		if (isDefined(focusedCard)) {
			try {
				await cardManager.retrieveCard(focusedCard.id);
			} catch (e) {
				const error = e as any;
				logger.debug("Error refreshCard : ", error.response.data.error.message);
			}
		}
	}, [focusedCard]);

	const { isRTL } = useRTL();

	const canActivateCard = useMemo(() => {
		const cardProduct = products.find(p => p.id === focusedCard?.productId);
		return isDefined(cardProduct?.cardActivationMode);
	}, [focusedCard, products]);

	const excludedStatuses = [CardStatus.Removed, CardStatus.Cancelled, CardStatus.Expired];
	const isFocusedCardBlocked = useMemo(() => focusedCard?.status === CardStatus.Blocked, [focusedCard]);

	const handleLockOrUnlockFocusedCard = useCallback(async () => {
		if (!focusedCard) {
			return;
		} else if (isFocusedCardBlocked) {
			await unblock();
		} else {
			await block();
		}
	}, [focusedCard]);

	const startVirtualCardCreationFlow = () => {
		Modal.present(CARD_CREATION_MODAL_ID, () => <CardCreationModal isVirtual />, {
			canBeDismissed: false,
		});
	};

	const handleShowCardDetails = () => {
		if (focusedCardSensitiveData?.panDisplay) {
			setFocusedCardSensitiveData(null);
		} else {
			Modal.present(
				CARD_SENSITIVE_DETAILS_MODAL_ID,
				() => <CardSensitiveDetails card={focusedCard} onCompleted={res => setFocusedCardSensitiveData(res)} />,
				{
					canBeDismissed: false,
				}
			);
		}
	};

	const canShowDetails = (cardId: string) => !features.cardSensitiveDataView || cardId === focusedCard?.id;
	/**
	 * SCOPE PRESENT && isSelected && showDetails
	 * SCOPE ABSENT : yes
	 */

	const handleChangeFocused = (card: Card) => {
		setFocusedCardSensitiveData({} as OverrideCardData);
		setFocusedCard(card);
	};

	return (
		<>
			<CardsSection>
				<StyledPageHeader>
					<CardsSectionTitle $isRTL={isRTL}>{formatMessage("cardsList.title")}</CardsSectionTitle>
					{features.virtualCardCreation && (
						<AddVirtualCardButton onClick={startVirtualCardCreationFlow}>
							<AddCircleIcon width={30} height={30} fill={theme.mainColor()} />
						</AddVirtualCardButton>
					)}
				</StyledPageHeader>
				{!screenLoading && cards.length === 0 ? (
					<AddCardItem />
				) : (
					<SectionCarousel
						items={cards.filter(c => !excludedStatuses.includes(c.status))}
						loading={screenLoading}
						getItemWidth={isMobile =>
							isMobile ? UIConstants.CARD_TILE_WIDTH_MOBILE : UIConstants.CARD_TILE_WIDTH_NOT_MOBILE
						}
						renderItem={(card, index) => (
							<CardTile
								key={card.id + index}
								onClick={() => handleChangeFocused(card)}
								isActive={card.id === focusedCard?.id ?? index === 0}
								card={card}
								sensitiveData={focusedCardSensitiveData}
								showDetails={canShowDetails(card.id)}
								loading={card.id === focusedCard?.id && loading}
							/>
						)}
						renderSkeleton={index => <SkeletonCardTile key={index} />}
					/>
				)}
			</CardsSection>
			{focusedCard && (
				<SectionsContainer>
					<LeftSection $isRTL={isRTL}>
						{actionsSectionIsVisible && (
							<>
								<CardButtonsContainer>
									{focusedCard.virtual ? (
										<StyledDeactivateButton card={focusedCard} loading={loading} deactivate={deactivate} />
									) : (
										<StyledOppositionButton card={focusedCard} loading={loading} oppose={oppose} />
									)}
									{canActivateCard && <StyledActivationButton card={focusedCard} disabled={pendingOperation} />}
									<StyledUpdateCardPinCodeButton card={focusedCard} disabled={pendingOperation} />
									{features.cards && <StyledOutstandingsButton card={focusedCard} />}
								</CardButtonsContainer>
							</>
						)}
						{optionsSectionIsVisible && focusedCard.features && focusedCard.features.length > 0 ? (
							<>
								<OptionsTitle>{formatMessage("cardOptions.title")}</OptionsTitle>
								<OptionContainer $isRTL={isRTL}>
									<StyledCardFeaturesSwitches
										card={focusedCard}
										loading={loading}
										enableFeature={enableFeature}
										disabled={pendingOperation}
									/>
								</OptionContainer>
							</>
						) : null}
					</LeftSection>
					<RightSection>
						{canRefabricateCard(focusedCard) && focusedCard.status !== CardStatus.Opposed && (
							<CardButtonsContainer isSmall>
								<StyledRefabricateButton card={focusedCard} />
							</CardButtonsContainer>
						)}
						{focusedCard.status === CardStatus.Opposed && (
							<CardButtonsContainer>
								<StyledOrderNewCard card={focusedCard} />
							</CardButtonsContainer>
						)}
						{(canActiveOrDeactivateCard || focusedCard.pendingManagementOperation) && (
							<CardContainer>
								<CardButtonsContainer>
									<ActionsContainer>
										{features.cardSensitiveDataView && (
											<ActionButton onClick={() => handleShowCardDetails()}>
												<ActionIconContainer
													style={{
														backgroundColor: focusedCardSensitiveData?.panDisplay ? theme.colors.gray[700] : "",
													}}>
													{focusedCardSensitiveData?.panDisplay ? (
														<StyledShowDetails fill="white" />
													) : (
														<StyledShowDetails />
													)}
												</ActionIconContainer>
												<ActionLabel>
													{formatMessage(
														focusedCardSensitiveData?.panDisplay
															? "cardOptions.switch.hideDetails"
															: "cardOptions.switch.showDetails"
													)}
												</ActionLabel>
											</ActionButton>
										)}
										<ActionButton
											disabled={!!focusedCard.pendingManagementOperation}
											onClick={handleLockOrUnlockFocusedCard}>
											<ActionIconContainer>
												{isFocusedCardBlocked ? <StyledPadlockUnlock /> : <StyledPadlockLocked />}
											</ActionIconContainer>
											<ActionLabel>
												{formatMessage(
													isFocusedCardBlocked ? "cardOptions.switch.unlockCard" : "cardOptions.switch.lockCard"
												)}
											</ActionLabel>
										</ActionButton>
									</ActionsContainer>
									<AdditionalInfosSection card={focusedCard} onClick={refreshCard} />
								</CardButtonsContainer>
							</CardContainer>
						)}
					</RightSection>
				</SectionsContainer>
			)}
		</>
	);
}

const CardContainer = styled.div``;

const CardsSection = styled.section`
	align-self: stretch;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const SectionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: -20px;

	@media (max-width: 1030px) {
		flex-direction: column;
	}
`;

const LeftSection = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: column;
	width: 50%;
	margin-right: ${props => (props.$isRTL ? 0 : 40)}px;
	margin-left: ${props => (props.$isRTL ? 40 : 0)}px;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		width: 100%;
		min-width: ${UIConstants.CARD_TILE_WIDTH_MOBILE}px;
	}
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-right: ${props => (props.$isRTL ? 0 : 20)}px;
		margin-left: ${props => (props.$isRTL ? 20 : 0)}px;
	}
`;

const StyledPageHeader = styled(PageHeader)`
	margin-bottom: 10px;
`;

const RightSection = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		margin-top: 20px;
		width: 100%;
		min-width: ${UIConstants.CARD_TILE_WIDTH_MOBILE}px;
	}
`;

const OptionsTitle = styled.p`
	${theme.mediumText};
	color: ${theme.colors.gray[500]};
`;

const CardsSectionTitle = styled(PageSectionTitle)<{ $isRTL: boolean }>`
	margin-right: ${props => (props.$isRTL ? 0 : 10)}px;
	margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
`;

const OptionContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: column;
	margin-right: ${props => (props.$isRTL ? 0 : 20)}px;
	margin-left: ${props => (props.$isRTL ? 20 : 0)}px;
	width: 100%;
	border-radius: 13px;
	padding: 6px 16px;
	background-color: white;

	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		margin-right: 0px;
		margin-left: 0px;
		width: 100%;
	}
	${shadows.medium};
`;

const ActionsContainer = styled.div`
	display: flex;
	padding: 15px 32px;
	flex-direction: row;
	justify-content: center;
`;

const ActionButton = styled.button`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	appearance: none;
	border: none;
	background-color: transparent;
	max-width: 30%;
`;

const ActionIconContainer = styled.div`
	display: flex;
	width: 49px;
	height: 49px;
	background-color: ${theme.colors.gray[100]};
	align-items: center;
	justify-content: center;
	border-radius: 24px;
`;

const ActionLabel = styled.p`
	${theme.mediumText}
	font-size: 12px;
	text-align: center;
	margin-top: 8px;
`;

const StyledCardFeaturesSwitches = styled(CardFeaturesSwitches)`
	width: 100%;
	margin-bottom: 20px;
`;

const StyledActivationButton = styled(ActivationButton)`
	padding: 13px;
	width: 100%;
`;

const StyledOppositionButton = styled(OppositionButton)`
	padding: 13px;
	width: 100%;
`;

const StyledDeactivateButton = styled(DeactivateButton)`
	padding: 13px;
	width: 100%;
`;

const StyledUpdateCardPinCodeButton = styled(UpdateCardPinCodeButton)`
	padding: 13px;
	width: 100%;
`;

const StyledOutstandingsButton = styled(OutstandingsButton)`
	padding: 13px;
	width: 100%;
`;

const CardButtonsContainer = styled.div<{ isSmall?: boolean }>`
	width: 100%;
	justify-content: center;
	border-radius: 13px;
	padding: ${props => (props.isSmall ? "4px" : "16px")};
	margin-bottom: 20px;
	background-color: white;
	${shadows.medium};
`;

const StyledPadlockUnlock = styled(PadlockUnlock)`
	height: 20px;
	width: 20px;
`;

const StyledPadlockLocked = styled(PadlockLocked)`
	height: 20px;
	width: 20px;
`;

const StyledShowDetails = styled(EyeIcon)`
	height: 20px;
	width: 20px;
`;

const StyledRefabricateButton = styled(RefabricateButton)`
	padding: 13px;
	width: 100%;
`;

const StyledOrderNewCard = styled(OrderNewCardButton)`
	padding: 13px;
	width: 100%;
`;

const AddVirtualCardButton = styled(UnstyledButton)``;
