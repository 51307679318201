import { Amount } from "../../core/amount/amount";
import { Account } from "../account/account";
import { Recipient } from "../recipients/recipient";

export type AccountOrRecipient = Account & Recipient;

export enum ServiceLevel {
	XCT = "XCT",
	SEPA = "SEPA",
	BACI = "BACI",
	KNGA = "KNGA",
	ON_US = "ON_US",
}

export enum PaymentType {
	PHONE = "phone",
	IBAN = "IBAN",
	OTHER = "other",
}

export enum PaymentIdentificationMode {
	IBAN = "IBAN",
	PHONE = "PHONE",
	ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
	IDENTITY_ID = "IDENTITY_ID",
}

export enum PaymentAcceptedAddress {
	STRUCTURED = "STRUCTURED",
	UNSTRUCTURED = "UNSTRUCTURED",
	BOTH = "BOTH",
	NO_ADDRESS = "NO_ADDRESS",
}

type StructuredAddress = {
	streetName?: string;
	buildingNumber?: string;
	postCode?: string;
	townName?: string;
};

export type PaymentAddress = {
	structuredAddress?: StructuredAddress;
	country: string;
	addressLines?: string;
};

export interface PaymentNetwork {
	name: string;
	serviceLevel: ServiceLevel;
	activated: boolean;
	identificationMode: PaymentIdentificationMode[];
	acceptedAddresses: PaymentAcceptedAddress;
}

type CustomerInstructionInformation = {
	paymentInstrument: string;
	paymentService: string;
	batchBooking: boolean;
	customerInstructionReference: string;
	requestedExecutionDate?: string;
	customerInstructionTypeInformation: {
		categoryPurpose: "NULL";
		serviceLevel?: ServiceLevel;
	};
};

type CustomerInstructionOrderingParties = {
	initiatingParty: {
		name?: string;
		// postalAddress: {
		// 	country: number;
		// };
		id: {
			value?: string;
			type?: PaymentType;
		};
	};
	debtor: {
		name?: string;
		// postalAddress: {
		// 	country: number;
		// };
		accountId: {
			value?: string;
			type?: PaymentType;
			bankId?: {
				value?: string;
				type?: PaymentType;
			};
		};
	};
};

type PaymentTransaction = {
	paymentTransactionDedicatedInformations?: {
		remittanceInformation: {
			value: string;
			type: "UNSTRUCTURED";
		};
	};
	paymentTransactionAmountInformation: {
		instructedAmount?: Amount;
	};
	paymentTransactionParties: {
		creditor: {
			name?: string;
			postalAddress?: PaymentAddress;
			accountId?: {
				value?: string;
				type?: PaymentType;
			};
		};
	};
};
export interface CustomerInstruction {
	customerInstructionInformation: CustomerInstructionInformation;
	customerInstructionOrderingParties: CustomerInstructionOrderingParties;
	paymentTransaction: PaymentTransaction;
}

export interface CustomerInstructionResult {
	id: number;
	status: {
		value: string;
		label: string;
	};
	customerInstructionInformation?: CustomerInstructionInformation;
	calculatedNbPaymentTransactions?: number;
	calculatedAmount?: Amount;
	customerInstructionOrderingParties?: CustomerInstructionOrderingParties;
	paymentTransaction?: PaymentTransaction;
	strongAuthenticationReference?: string;
}

export function getBlankCustomerInstruction(): CustomerInstruction {
	return {
		customerInstructionInformation: {
			paymentInstrument: "CreditTransfer",
			paymentService: "PAYMENTS",
			batchBooking: true,
			customerInstructionReference: "CINSTR202302011556",
			requestedExecutionDate: undefined,
			customerInstructionTypeInformation: {
				categoryPurpose: "NULL",
				serviceLevel: undefined,
			},
		},
		customerInstructionOrderingParties: {
			initiatingParty: {
				name: undefined,
				// postalAddress: {
				// 	country: 250,
				// },
				id: {
					value: undefined,
					type: undefined,
				},
			},
			debtor: {
				name: undefined,
				// postalAddress: {
				// 	country: 250,
				// },
				accountId: {
					value: undefined,
					type: undefined,
					bankId: {
						value: undefined,
						type: undefined,
					},
				},
			},
		},
		paymentTransaction: {
			paymentTransactionAmountInformation: {
				instructedAmount: undefined,
			},
			paymentTransactionParties: {
				creditor: {
					name: undefined,
					// postalAddress: {
					// 	country: "",
					// },
					accountId: {
						value: undefined,
						type: undefined,
					},
				},
			},
		},
	};
}
