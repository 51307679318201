import { AmountRangeFilter, TimeRangeFilterButton, TransactionDirectionFilter } from "./filter-button";

import { Account } from "../../../../../shared/domains/account/account";
import React from "react";
import { UIConstants } from "../../../styles/uiConstants";
import styled from "styled-components";

interface FilterBarProps {
	account: Account | null;
	isRTL: boolean;
}

export const FilterBar = (props: FilterBarProps) => {
	const { account, isRTL } = props;

	return (
		<FilterBarContainer $isRTL={isRTL}>
			<StyledTransactionDirectionFilter $isRTL={isRTL} />
			<StyledTimeRangeFilterButton $isRTL={isRTL} />
			<StyledAmountRangeFilter account={account} />
		</FilterBarContainer>
	);
};

const StyledTransactionDirectionFilter = styled(TransactionDirectionFilter)<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
	margin-right: ${props => (props.$isRTL ? 0 : 10)}px;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-right: 0px;
		margin-left: 0px;
		margin-bottom: 10px;
	}
`;
const StyledTimeRangeFilterButton = styled(TimeRangeFilterButton)<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? 10 : 0)}px;
	margin-right: ${props => (props.$isRTL ? 0 : 10)}px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-right: 0px;
		margin-left: 0px;
		margin-bottom: 10px;
	}
`;
const StyledAmountRangeFilter = styled(AmountRangeFilter)``;

const FilterBarContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: row;
	margin-left: ${props => (props.$isRTL ? 0 : 10)}px;
	margin-right: ${props => (props.$isRTL ? 10 : 0)}px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		flex-direction: column;
		margin-left: 0px;
		margin-right: 0px;
	}
	z-index: 5;
`;
