import styled, { keyframes } from "styled-components";

import _ from "lodash";
import React from "react";
import { colorWithOpacity } from "../../../../shared/utils/colors";
import { useLockBodyScroll } from "./useLockBodyScroll";

let modalContainer: ModalContainer | null = null;

export interface ModalOptions {
	backdropOpacity?: number;
	backdropColor?: string;
	canBeDismissed?: boolean;
}

export interface ModalProps extends ModalOptions {
	visible?: boolean;
	onShouldDismiss: () => void;
	onHideComplete: () => void;
}

export class Modal extends React.PureComponent<ModalProps> {
	private backdropOpacity = this.props.backdropOpacity ?? 0.7;
	private backdropColor = this.props.backdropColor ?? "#000000";

	static present(id: string, content: () => JSX.Element, options: ModalOptions) {
		const container = modalContainer;
		if (container === null) throw new Error("ModalContainer must be mounted to present a modal");
		container.present(id, content, options);
	}

	static dismiss(id: string) {
		const container = modalContainer;
		if (container === null) throw new Error("ModalContainer must be mounted to dismiss a modal");
		container.dismiss(id);
	}

	static dismissAll() {
		const container = modalContainer;
		if (container === null) throw new Error("ModalContainer must be mounted to dismiss a modal");
		Object.keys(container.state.modals).forEach(id => container.dismiss(id));
	}

	render() {
		const { canBeDismissed, onShouldDismiss, onHideComplete } = this.props;
		return (
			<ModalContent
				backdropOpacity={this.backdropOpacity}
				backdropColor={this.backdropColor}
				onClick={() => canBeDismissed && onShouldDismiss()}
				isVisible={!!this.props.visible}
				onTransitionEnd={() => onHideComplete()}>
				{this.props.children}
			</ModalContent>
		);
	}
}

const ModalContent: React.FC<{
	backdropOpacity: number;
	backdropColor: string;
	onClick?: () => void;
	isVisible: boolean;
	onTransitionEnd?: () => void;
}> = ({ backdropOpacity, backdropColor, onClick, isVisible, onTransitionEnd, children }) => {
	useLockBodyScroll();
	return (
		<ModalBackdrop
			id="backdrop"
			opacity={backdropOpacity}
			color={backdropColor}
			onClick={onClick}
			isVisible={isVisible}
			onTransitionEnd={onTransitionEnd}>
			<ContentContainer onTransitionEnd={e => e.stopPropagation()}>{isVisible && children}</ContentContainer>
		</ModalBackdrop>
	);
};

const fadeIn = keyframes`
	from {
		opacity: 0;
	} to {
		opacity: 1;
	}
`;

// firefox bug: sometimes firing a onTransitionEnd event while opacity is set to 1 when mount.
// We just want the transition append when opacity go from 1 to 0 that's why "${props => (!props.isVisible ? "transition: opacity 0.5s" : null)};"
const ModalBackdrop = styled.div<{ isVisible: boolean; opacity: number; color: string }>`
	position: fixed;
	pointer-events: auto;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 2000000000;
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-y: auto;
	background-color: ${props => colorWithOpacity(props.color, props.opacity)};
	opacity: ${props => (props.isVisible ? 1 : 0)};
	${props => (!props.isVisible ? "transition: opacity 0.5s" : null)};
	animation: ${fadeIn} 0.2s;
`;

const ContentContainer = styled.div`
	position: relative;
	pointer-events: all;
`;

interface ModalContainerState {
	modals: { [id: string]: { content: () => JSX.Element; options: ModalOptions; visible: boolean } };
}

export class ModalContainer extends React.PureComponent<unknown, ModalContainerState> {
	constructor(props: unknown) {
		super(props);
		this.state = { modals: {} };
	}

	present(id: string, content: () => JSX.Element, options: ModalOptions) {
		this.setState(state => ({ modals: { ...state.modals, [id]: { content, options, visible: true } } }));
	}

	dismiss(id: string) {
		const modal = this.state.modals[id];
		if (modal) {
			this.setState({ modals: { ...this.state.modals, [id]: { ...modal, visible: false } } });
		}
	}

	unmount(id: string) {
		this.setState(state => ({ modals: _.omit(state.modals, id) }));
	}

	componentDidMount() {
		modalContainer = this;
	}

	componentWillUnmount() {
		modalContainer = null;
	}

	render(): JSX.Element {
		return (
			<ModalHolder>
				{Object.entries(this.state.modals).map(([id, modal]) => (
					<Modal
						key={id}
						{...modal.options}
						visible={modal.visible}
						onShouldDismiss={() => this.dismiss(id)}
						onHideComplete={() => this.unmount(id)}>
						{modal.content()}
					</Modal>
				))}
			</ModalHolder>
		);
	}
}

const ModalHolder = styled.div`
	pointer-events: none;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
`;
