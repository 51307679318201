import React, { useState } from "react";
import styled from "styled-components";
import { externalAccountManager } from "../../../../../shared/core/service/services";
import { Recipient, RecipientStateEnum } from "../../../../../shared/domains/recipients/recipient";
import { anonimyzeIban } from "../../../../../shared/utils/iban";
import { REMOVE_RECIPIENT_MODAL_ID } from "../../../../core/modal/modal-id";
import { Modal } from "../../../common/modal/modal";
import { BigBankToWalletIcon } from "../../../common/svg/big-bank-to-wallet-icon";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { RemoveRecipientModal } from "../../recipient/remove-recipient-modal";
import CardMoreMenu from "../../../common/card-more-menu";
import { PendingColoredTag } from "../../account-transactions/components/pending-colored-tag";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";

export interface RecipientCardProps {
	externalAccount: Recipient;
	isRTL: boolean;
	readonly?: boolean;
	disabled?: boolean;
	onClick?: () => void;
	onEdit?: () => void;
}

export const RecipientCard = (props: RecipientCardProps) => {
	const { formatMessage } = useIntl();
	const { disabled, externalAccount, isRTL, readonly, onClick, onEdit } = props;
	const [openMenu, setOpenMenu] = useState(false);
	const toggleMenu = () => setOpenMenu(bool => !bool);

	return (
		<Card onClick={onClick} disabled={disabled}>
			<CardContainer>
				<TopEndImage $isRTL={isRTL}>
					<BigBankToWalletIcon fill={theme.mainColor()} />
				</TopEndImage>
				<NameAndBankContainer>
					<Name $isRTL={isRTL}>{externalAccount.name}</Name>
					{externalAccount.bankName && <Bank $isRTL={isRTL}>{externalAccount.bankName}</Bank>}
				</NameAndBankContainer>
				<BottomRow>
					<AccountNumber>
						{externalAccount.accountReference ?? anonimyzeIban(externalAccount.iban ?? " ")}
						{externalAccount.state === RecipientStateEnum.PENDING && <StyledPendingColoredTagWrapper>
							<PendingColoredTag label={formatMessage('recipient.externalAccounts.pendingExternalAccountMessage')} />
						</StyledPendingColoredTagWrapper>}
					</AccountNumber>
					{(!readonly && !disabled) && <CardMoreMenu
						isRTL={isRTL}
						isOpen={openMenu}
						onToggle={toggleMenu}
						onEdit={() => onEdit?.()}
						onDelete={() => {
							Modal.present(
								REMOVE_RECIPIENT_MODAL_ID,
								() => (
									<RemoveRecipientModal
										recipient={externalAccount}
										onConfirm={() => externalAccountManager.deleteRecipient(externalAccount.id)}
									/>
								),
								{
									canBeDismissed: true,
								}
							);
						}}
					/>}
				</BottomRow>
			</CardContainer>
		</Card>
	);
};

const Card = styled.button`
	position: relative;
	height: 190px;
	width: ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE}px;
	border-radius: 13px;
	border: none;
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
	background-color: white;
	overflow: hidden;
	transition: transform ease-out 0.2s;
	will-change: transform;
	@media (max-width: ${UIConstants.PHONE_BREAKPOINT}px) {
		width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE}px;
	}
	:hover {
		cursor: pointer;
	}
`;

const NameAndBankContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 2px;
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 24px;
	height: 100%;
	width: 100%;
	justify-content: space-between;
`;

const TopEndImage = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 16px;
	right: ${props => (props.$isRTL ? `unset` : `24px`)};
	left: ${props => (props.$isRTL ? `24px` : `unset`)};
	width: 35px;
	height: 35px;
	background-color: ${theme.mainColorLightBackground};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 13px;
`;

const Name = styled.span<{ $isRTL: boolean }>`
	${theme.boldText}
	font-size: 1.125rem;
	text-align: ${props => (props.$isRTL ? `right` : `left`)};
	white-space: nowrap;
	overflow: hidden;
	overflow-wrap: break-word;
	text-overflow: ellipsis;
	width: ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE - 100}px;
	@media (max-width: ${UIConstants.PHONE_BREAKPOINT}px) {
		width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE - 90}px;
	}
`;

const Bank = styled.span<{ $isRTL: boolean }>`
	${theme.text}
	font-size: 0.875rem;
	line-height: 1.5rem;
	color: #b1b1b1;
	text-align: ${props => (props.$isRTL ? `right` : `left`)};
	white-space: nowrap;
	overflow: hidden;
	overflow-wrap: break-word;
	text-overflow: ellipsis;
	width: ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE - 60}px;
	@media (max-width: ${UIConstants.PHONE_BREAKPOINT}px) {
		width: ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE - 60}px;
	}
`;

const BottomRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const AccountNumber = styled.span`
	${theme.mediumText}
	font-size: 0.875rem;
	line-height: 1.5rem;
	text-align: left;
	color: #b1b1b1;
`;

const StyledPendingColoredTagWrapper = styled.div`
	margin-top: 4px;
`