import { Currencies, CurrencyIdentifier } from "../currency/currency";

export class Amount {
	public value: number;

	public constructor(value: number | null, public currency: CurrencyIdentifier) {
		const currencyData = Currencies[currency];
		this.value = Math.round((value || 0) * Math.pow(10, currencyData ? currencyData.decimal_number : 2));
	}

	// This class is deserialized directly from JSON, please don't put functions in there
	// it's a class rather than an interface to force initialization via constructor
}

export function addAmounts(...amounts: Amount[]) {
	const currency = amounts[0].currency;
	const currencyData = Currencies[currency];
	return new Amount(
		amounts.reduce((total, amount) => {
			if (currency != amount.currency) {
				throw new Error("trying to add amounts with different currencies");
			}
			return total + amount.value / Math.pow(10, currencyData.decimal_number);
		}, 0),
		currency
	);
}

export function diffAmount(firstAmount: Amount, secondAmount: Amount) {
	const diffVal =
		(Math.abs(firstAmount.value) - Math.abs(secondAmount.value)) /
		Math.pow(10, Currencies[firstAmount.currency].decimal_number);
	return new Amount(diffVal, firstAmount.currency);
}

export function amountValueIsLower(firstAmount: Amount, secondAmount: Amount) {
	return Math.abs(firstAmount.value) < Math.abs(secondAmount.value);
}

export function absoluteAmount(amount: Amount) {
	const absoluteVal = Math.abs(amount.value) / Math.pow(10, Currencies[amount.currency]?.decimal_number);
	return new Amount(absoluteVal, amount.currency);
}

export function getAmountValueString(amount: Amount) {
	return (amount.value / Math.pow(10, Currencies[amount.currency]?.decimal_number)).toString();
}

export function amountValueForCurrency(value: number, currency: string) {
	return value / Math.pow(10, Currencies[currency].decimal_number);
}

function convertValue(value: number, oldDecimals: number, newDecimals: number) {
	const decimalValue = value / Math.pow(10, oldDecimals);
	const integerPart = Math.trunc(decimalValue);
	const decimalPart = Math.trunc((decimalValue - integerPart) * Math.pow(10, newDecimals) + 0.00001);
	return integerPart + decimalPart / Math.pow(10, newDecimals);
}

export function switchCurrency(amount: Amount, currency: CurrencyIdentifier) {
	const convertedValue = convertValue(
		amount.value,
		Currencies[amount.currency].decimal_number,
		Currencies[currency].decimal_number
	);
	return new Amount(convertedValue, currency);
}

export const sanitizeAmountValue = (str: string | null, decimals: number) => {
	let safeValue = "";
	if (str && !isNaN(parseFloat(str))) {
		const sanitizedString = str.replace(/[^0-9,\.]/, "").replace(/^[,\.]/, "");
		const index = sanitizedString.split("").findIndex(c => c === "." || c === ",");
		if (index > 0) {
			const lengthMax = Math.min(sanitizedString.length, decimals);
			const decimalPart = sanitizedString.substring(index + 1, index + 1 + lengthMax);
			safeValue = sanitizedString.substring(0, index + 1);
			if (decimals > 0) {
				safeValue += decimalPart.replace(/[\.,]/, "");
			} else {
				safeValue = safeValue.replace(/[,\.]/, "");
			}
		} else {
			safeValue = sanitizedString;
		}
	}
	if (parseFloat(safeValue) === 0) {
		return "0";
	}
	return safeValue;
};
