import React from "react";
import styled from "styled-components";
import { ResponsiveContainer } from "../../../../common/responsive-container";
import { StaticAssets } from "../../../../styles/custom-assets";
import { shadows } from "../../../../styles/theme";
import { UIConstants } from "../../../../styles/uiConstants";

const EnrollmentLayout: React.FC<{
	children: React.ReactNode;
	centered?: boolean;
}> = ({ children, centered }) => (
	<Page>
		<LeftPanel>
			<LeftImage src={StaticAssets.registerImage} />
		</LeftPanel>
		<RightPanel isCentered={centered}>{children}</RightPanel>
	</Page>
);

export default EnrollmentLayout;

const Page = styled(ResponsiveContainer)`
	position: relative;
	display: flex;
	flex-direction: row;
	padding-bottom: 30px;
	padding: 0;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		flex-direction: column;
		align-items: stretch;

		> *:not(:first-child) {
			margin-top: 0;
		}
	}
`;

const LeftPanel = styled.div`
	position: sticky;
	top: 0;
	justify-content: center;
	flex-direction: column;
	margin: 0;
	width: 40%;
	max-width: 600px;
	height: 100vh;
	padding: 40px;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		display: none;
	}
`;

const LeftImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 21px;
	${shadows.medium};
`;

const RightPanel = styled.div<{ isCentered?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 60%;
	padding: 40px;
	align-items: flex-start;
	justify-content: center;
	align-items: ${props => (props.isCentered ? "center" : "flex-start")};
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		padding: 20px;
		width: 100%;
	}
`;

const Spacer = styled.div`
	flex-grow: 1;
`;
