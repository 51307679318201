import React, { SVGProps } from "react";

export function TransactionSentIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 40 40" {...props}>
			<path
				fill="#F4F4F4"
				stroke="#979797"
				strokeOpacity=".096"
				d="M15.383 0h9.234c5.349 0 7.289.557 9.244 1.603 1.956 1.046 3.49 2.58 4.536 4.536C39.443 8.094 40 10.034 40 15.383v9.234c0 5.349-.557 7.289-1.603 9.244-1.046 1.956-2.58 3.49-4.536 4.536C31.906 39.443 29.966 40 24.617 40h-9.234c-5.349 0-7.289-.557-9.244-1.603-1.956-1.046-3.49-2.58-4.536-4.536C.557 31.906 0 29.966 0 24.617v-9.234c0-5.349.557-7.289 1.603-9.244 1.046-1.956 2.58-3.49 4.536-4.536C8.094.557 10.034 0 15.383 0z"
			/>
			<path
				fill="#161616"
				d="M13 6c.036 0 .07.003.105.008l.027.004c.025.005.05.01.073.018l.036.012c.021.007.042.016.063.026l.031.015.06.036c.039.026.076.056.11.09l-.08-.068.011.008.07.06 4.285 4.286c.279.279.279.731 0 1.01-.28.279-.731.279-1.01 0l-3.067-3.067v9.705c0 .361-.268.66-.617.708l-.097.006c-.394 0-.714-.32-.714-.714l-.001-9.704-3.066 3.066c-.25.251-.642.276-.921.075l-.089-.075c-.279-.279-.279-.731 0-1.01l4.286-4.286.069-.06.01-.008.03-.022c.02-.013.04-.025.061-.036l.031-.015c.02-.01.042-.019.063-.026l.036-.012c.024-.007.048-.013.073-.018l.028-.004C12.929 6.003 12.964 6 13 6z"
				transform="translate(-235 -1061) translate(235 1061) translate(7 7)"
			/>
		</svg>
	);
}
