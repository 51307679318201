import React from "react";
import styled from "styled-components";
import { useRTL } from "../../../../domain/language/use-rtl";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { EmptyRowsPlaceholder } from "../../../common/svg/empty-rows-placeholder";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface EmptyBlockProps {
	illustration?: JSX.Element;
	title: string;
	message: string;
	button?: string;
	onClick?: () => void;
}

export const EmptyBlock = (props: EmptyBlockProps) => {
	const { illustration, title, message, button, onClick } = props;
	const { isRTL } = useRTL();

	return (
		<GreyCard>
			<IllustrationContainer>{illustration ?? <EmptyRowsPlaceholder />}</IllustrationContainer>
			<ContentContainer $isRTL={isRTL}>
				<Title>{title}</Title>
				<Description>{message}</Description>
			</ContentContainer>
			{onClick && (
				<>
					<Spacer />
					<AddBeneficiaryButton size="S" onClick={onClick} $isRTL={isRTL}>
						{button}
					</AddBeneficiaryButton>
				</>
			)}
		</GreyCard>
	);
};

const GreyCard = styled.div`
	display: flex;
	align-self: stretch;
	flex-direction: row;
	background-color: #f4f4f4;
	border-radius: 18px;
	padding: 40px;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		flex-direction: column;
	}
`;

const ContentContainer = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: ${props => (props.$isRTL ? 0 : 26)}px;
	margin-right: ${props => (props.$isRTL ? 26 : 0)}px;

	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		margin-right: 0px;
		margin-left: 0px;
		margin-top: 26px;
		margin-bottom: 26px;
	}
`;

const IllustrationContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		align-items: center;
	}
`;

const Title = styled.div`
	font-size: 0.9375rem;
	margin-bottom: 8px;
	${theme.boldText};
	color: #000000;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		text-align: center;
	}
`;

const Description = styled.div`
	font-size: 0.9375rem;
	${theme.text};
	color: #b1b1b1;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
		text-align: center;
	}
`;

const AddBeneficiaryButton = styled(PrimaryButton)<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? 0 : 20)}px;
	margin-right: ${props => (props.$isRTL ? 20 : 0)}px;
	flex-shrink: 0;
	height: 46px;
	align-self: center;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-top: 20px;
		margin-left: 0px;
		margin-right: 0px;
	}
`;

const Spacer = styled.div`
	flex-grow: 1;
`;
