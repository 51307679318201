import React, { useEffect } from "react";
import styled from "styled-components";
import { configurationManager } from "../../../../shared/core/service/services";
import { Keyboard } from "../../../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../../../shared/domains/pincode/pincode";
import { usePincode } from "../../../../shared/domains/pincode/use-pincode";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { repeat } from "../../../../shared/utils/utils";
import { useRTL } from "../../../domain/language/use-rtl";
import { DefaultButton } from "../buttons/default-button";
import { ErrorMessage } from "../error-message";
import { MainColorSpinner } from "../spinner";
import { ResetIcon } from "../svg/reset-icon";

interface PincodeKeyboardProps {
	keyboard: Keyboard | undefined;
	onSubmit: (submission: PincodeSubmission) => void;
	title?: string;
	className?: string;
	errorMessage?: string;
	loadingKeyboard?: boolean;
	keyColor?: string;
}

const GRID_COLUMNS = 4;
export const GRID_ROWS = 4;
const KEYBOARD_PADDING = 45;
export const KEYBOARD_SPACING = 10;
const TOUCH_SIZE = 45;

export const PincodeKeyboard = (props: PincodeKeyboardProps) => {
	const { keyboard, onSubmit, className, title, errorMessage, loadingKeyboard, keyColor } = props;
	const configuration = useObservable(configurationManager.configuration);
	const pincodeMaxLength = configuration.pincode.maxLength;
	const { pincode, enterDigit, resetCode } = usePincode<number>(pincodeMaxLength);
	const { isRTL } = useRTL();

	useEffect(() => {
		if (pincode.length >= pincodeMaxLength) {
			resetCode();
			if (onSubmit && keyboard) {
				onSubmit({ id: keyboard.id, value: pincode });
			}
		}
	}, [keyboard, onSubmit, pincode, resetCode, pincodeMaxLength]);

	return (
		<KeyboardContainer className={className}>
			<Header>
				<Title>{title}</Title>
				{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
				<PincodeRowIndicator>
					{repeat(pincodeMaxLength, index => (
						<Circle key={`indicator-${index}`} filled={index < pincode.length} />
					))}
					<ResetButton onClick={resetCode} $isRTL={isRTL}>
						<ResetIcon />
					</ResetButton>
				</PincodeRowIndicator>
			</Header>
			{loadingKeyboard || !isDefined(keyboard) ? (
				<SpinnerContainer>
					<MainColorSpinner />
				</SpinnerContainer>
			) : (
				repeat(GRID_ROWS, rowIndex => (
					<KeyboardRow key={rowIndex}>
						{repeat(GRID_COLUMNS, cellIndex => (
							<KeyboardTouch
								key={`${rowIndex}-${cellIndex}`}
								onClick={() => enterDigit(rowIndex * GRID_ROWS + cellIndex)}
								backgroundColor={keyColor}>
								<KeyboardImage
									src={`data:image/png;base64,${keyboard.images[rowIndex * 4 + cellIndex]}`}></KeyboardImage>
							</KeyboardTouch>
						))}
					</KeyboardRow>
				))
			)}
		</KeyboardContainer>
	);
};

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${GRID_ROWS * (TOUCH_SIZE + KEYBOARD_SPACING) - KEYBOARD_SPACING}px;
	width: ${GRID_COLUMNS * (TOUCH_SIZE + KEYBOARD_SPACING) - KEYBOARD_SPACING + 2 * KEYBOARD_PADDING}px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 30px;
	width: 300px;
	text-align: center;
	position: relative;
`;
const Title = styled.span`
	color: #aaaaaa;
`;

const KeyboardContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-left: ${KEYBOARD_PADDING}px;
	padding-right: ${KEYBOARD_PADDING}px;
	padding-bottom: ${KEYBOARD_PADDING}px;
`;

const KeyboardRow = styled.div`
	display: flex;
	flex-direction: row;
	&:not(:last-of-type) {
		margin-bottom: ${KEYBOARD_SPACING}px;
	}
`;

const KeyboardTouch = styled(DefaultButton)<{ backgroundColor?: string }>`
	background-color: ${props => props.backgroundColor ?? "transparent"};
	padding: 0px;
	width: ${TOUCH_SIZE}px;
	height: ${TOUCH_SIZE}px;
	border-radius: ${TOUCH_SIZE / 2}px;
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
	margin-left: ${KEYBOARD_SPACING / 2}px;
	margin-right: ${KEYBOARD_SPACING / 2}px;
`;

const KeyboardImage = styled.img`
	width: ${TOUCH_SIZE};
	height: ${TOUCH_SIZE};
`;

const PincodeRowIndicator = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 25px;
	justify-content: center;
`;

const Circle = styled.div<{ filled: boolean }>`
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background-color: ${props => (props.filled ? "#000000" : "#eeeafb")};
	margin-left: 5px;
	margin-right: 5px;
`;

const ResetButton = styled(DefaultButton)<{ $isRTL: boolean }>`
	padding: 0px;
	position: absolute;
	height: 19px;
	width: 24px;
	right: ${props => (props.$isRTL ? `unset` : `30px`)};
	left: ${props => (props.$isRTL ? `30px` : `unset`)};
	svg {
		width: 19px;
		height: 24px;
		object-fit: contain;
		fill: #000000;
	}
	transform: ${props => (props.$isRTL ? `rotate(180deg)` : `rotate(0deg)`)};
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 0px 10px 0px;
	text-align: center;
`;
