import { cardManager, cardService } from "../../core/service/services";
import { useCallback, useState } from "react";

import { Card } from "./card";
import { UPDATE_PINCODE } from "./card-manager";

export enum CardUpdatePincodeStep {
	DefinePincode = "CARD_UPDATE_PINCODE_DEFINE",
	Success = "CARD_UPDATE_PINCODE_SUCCESS",
	Error = "CARD_UPDATE_PINCODE_ERROR",
	SCACheck = "SCA_CHECK" //custom VQ step
}

export const useCardUpdatePincode = () => {
	const [step, setStep] = useState(CardUpdatePincodeStep.DefinePincode);
	const [scaToken, setScaToken] = useState<string>('');

	const [card, setCard] = useState<Card | undefined>(undefined);

	const [textError, setTextError] = useState<string | undefined>(undefined);

	const resetStep = () => {
		setStep(CardUpdatePincodeStep.DefinePincode)
	}

	const reissuePincodeOnCard = useCallback(
		async (cardToUse: Card, newPincode: string, scaSessionToken: string) => {
			try {
				const pincodeReissuing = await cardManager.reissuePincode(cardToUse.id, newPincode, UPDATE_PINCODE, scaSessionToken);
				setStep(CardUpdatePincodeStep.Success);
			} catch (e) {
				const result = e as any;
				//SCA use case, must send and verify the given token
				if(result.response?.data?.error === 'Unauthorized' && result.response?.data?.new_token) {
					setScaToken(result.response.data.new_token)
					setStep(CardUpdatePincodeStep.SCACheck)
					cardService.scaAuthenticationSendToken(result.response.data.new_token)
				} else {
					setTextError(result.response?.data?.error?.message || result.toString());
					setStep(CardUpdatePincodeStep.Error);
				}
			}
		},
		[setStep, setTextError]
	);

	const validatePincodeOnCard = useCallback(
		async (card: Card, pincode: string, scaSessionToken?: string) => {
			await reissuePincodeOnCard(card, pincode, scaSessionToken || '');
		},
		[reissuePincodeOnCard]
	);

	return {
		step,
		card,
		setCard,
		validatePincode: validatePincodeOnCard,
		textError,
		scaToken,
		resetStep,
	};
};
