import React, { HTMLAttributes } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../styles/theme";

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
	size?: string;
	color?: string;
}

export function Spinner(props: SpinnerProps) {
	const { size = "30px", color = "currentColor", ...rest } = props;
	return (
		<div {...rest}>
			<Loader size={size} color={color} />
		</div>
	);
}

export const MainColorSpinner = (props: Omit<SpinnerProps, "color">) => <Spinner color={theme.mainColor()} {...props} />;

const clip = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  50% {transform: rotate(180deg) scale(0.8)}
  100% {transform: rotate(360deg) scale(1)}
`;

const Loader = styled.div<{ size: string; color: string }>`
	background: transparent;
	width: ${({ size }) => size};
	height: ${({ size }) => size};
	border-radius: 100%;
	border: 2px solid;
	border-color: ${({ color }) => color};
	border-bottom-color: transparent;
	animation: ${clip} 0.75s 0s infinite linear;
	animation-fill-mode: both;
`;
