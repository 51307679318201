export const PINCODE_MUST_BE_CHANGED_ERROR_CODE = 30242;
export const ACCOUNT_BLOCKED_ERROR_AFTER_THREE_PINCODE_ATTEMPTS_ERROR_CODE = 30229;
export const ACCOUNT_BLOCKED_ERROR_CODE = 20029;
export const RECIPIENT_NOT_FOUND_BY_INVALID_PHONE_ERROR_CODE = 62329;

export enum ErrorType {
	PincodeMustBeChanged = "PINCODE_MUST_BE_CHANGE",
	AccountBlockedAfterThreePincodeAttempts = "ACCOUNT_BLOCKED_AFTER_THREE_PINCODE_ATTEMPTS",
	DefaultAccountBlocked = "DEFAULT_ACCOUNT_BLOCKED",
	RecipientNotFoundByInvalidPhoneError = "RECIPIENT_NOT_FOUND_BY_INVALID_PHONE_ERROR",
}

export class PincodeMustBeChangedError extends Error {
	public type = ErrorType.PincodeMustBeChanged;

	public constructor(message?: string) {
		super(message);
	}
}

export class AccountBlockedAfterThreePincodeAttemptsError extends Error {
	public type = ErrorType.AccountBlockedAfterThreePincodeAttempts;

	public constructor(message?: string) {
		super(message);
	}
}
export class DefaultAccountBlockedError extends Error {
	public type = ErrorType.DefaultAccountBlocked;

	public constructor(message?: string) {
		super(message);
	}
}
export class RecipientNotFoundByInvalidPhoneRespondError extends Error {
	public type = ErrorType.RecipientNotFoundByInvalidPhoneError;

	public constructor(message?: string) {
		super(message);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AccountBlockedErrorFromErrorResponse(error: any) {
	const code = error?.response?.data?.error?.code;
	const message = error?.response?.data?.error?.message || error.toString();

	if (code === ACCOUNT_BLOCKED_ERROR_CODE) {
		return new DefaultAccountBlockedError(message);
	} else if (code === ACCOUNT_BLOCKED_ERROR_AFTER_THREE_PINCODE_ATTEMPTS_ERROR_CODE) {
		return new AccountBlockedAfterThreePincodeAttemptsError(message);
	} else if (code === PINCODE_MUST_BE_CHANGED_ERROR_CODE) {
		return new PincodeMustBeChangedError(message);
	} else if (code === RECIPIENT_NOT_FOUND_BY_INVALID_PHONE_ERROR_CODE) {
		return new RecipientNotFoundByInvalidPhoneRespondError(message);
	}
	return message;
}

export function isAccountBlockedErrorResponse(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any
): boolean {
	return [ACCOUNT_BLOCKED_ERROR_CODE, ACCOUNT_BLOCKED_ERROR_AFTER_THREE_PINCODE_ATTEMPTS_ERROR_CODE].includes(
		error?.response?.data?.error?.code
	);
}

export function isAccountBlocked(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any
): error is AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError {
	return (
		error?.type === ErrorType.AccountBlockedAfterThreePincodeAttempts || error?.type === ErrorType.DefaultAccountBlocked
	);
}

export function isRecipientNotFoundByInvalidPhoneErrorResponse(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any
): error is RecipientNotFoundByInvalidPhoneRespondError {
	return error?.type === ErrorType.RecipientNotFoundByInvalidPhoneError;
}

export function isRecipientNotFoundByInvalidPhoneError(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any
): boolean {
	return error?.response?.data?.error?.code === RECIPIENT_NOT_FOUND_BY_INVALID_PHONE_ERROR_CODE;
}
