import { logger } from "../../core/logging/logger";
import { isDefined } from "../../utils/assert";
import { ObservableArray } from "../../utils/observable-array";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { ClientManager } from "../client/client-manager";
import { Product, ProductCardActivationMode, ProductType } from "./product";
import { ProductService } from "./product-service";

export class ProductManager {
	public debitCardProducts = new ObservableArray<Product>([]);

	public constructor(
		private productService: ProductService,
		private authenticationManager: AuthenticationManager,
		private clientManager: ClientManager
	) {
		this.authenticationManager.isConnected.onChange.add(isConnected => {
			if (!isConnected) {
				this.clear();
			}

			this.clientManager.onLanguageChange.add(async () => {
				await this.clear();
			});
		});
	}

	// TPMW-76 : Fix while the backend does not fill the cardActivationMode
	// In this case we must be able to activate the card with a code
	// which is considered the default mode
	private addActivationMode(products: Product[]): Product[] {
		const updatedProducts = products.map(product =>
			!isDefined(product.cardActivationMode)
				? {
						...product,
						cardActivationMode: ProductCardActivationMode.withCode,
				  }
				: product
		);

		return updatedProducts;
	}

	public async loadDebitCardProducts() {
		try {
			const products = await this.productService.fetchProducts(ProductType.DebitCard);
			this.debitCardProducts.set(this.addActivationMode(products.items));
		} catch (e) {
			logger.debug("ProductManager", "Failed to load products of type DebitCard", e);
			throw e;
		}
	}

	private clear() {
		this.debitCardProducts.set([]);
	}
}
