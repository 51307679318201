import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { DefaultButton, DefaultExternalLink, DefaultLink } from "./default-button";

export enum ButtonHorizontalPadding {
	XS = "0px",
	S = "46px",
	M = "76px",
	L = "102px",
}

const getCommonStyle = (paddingType: "XS" | "S" | "M" | "L") => css`
	padding: 13px ${ButtonHorizontalPadding[paddingType]};
	border-radius: 25px;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
	background-color: #000000;
	font-size: 0.9375rem;
	${theme.boldText};
	line-height: normal;
	text-align: center;
	color: #ffffff;

	:focus,
	:active,
	:hover {
		background-color: #3d3d3d;
	}
	:disabled {
		background-color: #b1b1b1;
		color: #ffffff;
	}
`;

export const PrimaryButton = styled(DefaultButton)<{ size: "XS" | "S" | "M" | "L" }>`
	${props => getCommonStyle(props.size)};
`;

export const WhitePrimaryButton = styled(DefaultButton)<{ size: "XS" | "S" | "M" | "L" }>`
	${props => getCommonStyle(props.size)};
	background-color: #ffffff;
	color: #000000;
	:focus,
	:active,
	:hover {
		background-color: #f9f9f9;
	}
`;

export const PrimaryLink = styled(DefaultLink)<{ size: "XS" | "S" | "M" | "L" }>`
	${props => getCommonStyle(props.size)};
`;
export const PrimaryExternalLink = styled(DefaultExternalLink)<{ size: "XS" | "S" | "M" | "L" }>`
	${props => getCommonStyle(props.size)};
`;
