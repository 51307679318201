import filesize from "filesize";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../shared/utils/assert";
import { useRTL } from "../../../domain/language/use-rtl";
import { shadows, theme } from "../../styles/theme";
import { BadgeCount } from "../data-forms/components/badge-count";
import { ErrorMessage } from "../error-message";
import { DownloadIcon } from "../svg/download-icon";

const MAX_SIZE_ALLOWED = 7340032; // 7MB

export type UploadTypeAllowed = "application/pdf" | "image/jpg" | "image/jpeg" | "image/png";
const allUploadTypeAllowed = ["application/pdf", "image/jpg", "image/jpeg", "image/png"];
const uploadTypeExtension: Map<UploadTypeAllowed, string> = new Map([
	["application/pdf", "PDF"],
	["image/jpg", "JPG"],
	["image/jpeg", "JPEG"],
	["image/png", "PNG"],
]);

const MAX_SIZE_FILENAME = 15;

interface FileProps {
	name: string;
	size: string;
	type: string;
}

interface FileUploaderProps {
	initialFile?: FileProps;
	hasExistingFile?: boolean;
	fileUploaded: (fileUploaded: File | null) => void;
	onPreviewClicked?: () => void;
	onImageDeleted?: () => void;
	typeAllowed?: UploadTypeAllowed[];
	maxSize?: number;
	className?: string;
	instructions?: string;
	disabled?: boolean;
	required?: boolean;
	showDownloadIcon?: boolean;
	badgeIndex?: number;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
	hasExistingFile,
	initialFile,
	fileUploaded,
	onPreviewClicked,
	onImageDeleted,
	typeAllowed,
	className,
	instructions,
	disabled,
	required,
	maxSize,
	showDownloadIcon,
	badgeIndex,
}) => {
	const { formatMessage } = useIntl();
	const maxSizeAllowed = maxSize ?? MAX_SIZE_ALLOWED;
	const [fileAccepted, setFileAccepted] = useState(!!initialFile);
	const [fileProperties, setFileProperties] = useState<FileProps>(
		initialFile ?? {
			name: "",
			size: "",
			type: "",
		}
	);
	const [error, setError] = useState("");
	const updateFile = (file: File | null) => {
		setFileAccepted(!!file);
		fileUploaded(file);
	};

	const formatAllowedString = typeAllowed ? typeAllowed.join(",") : Array.from(uploadTypeExtension.keys()).join(", ");

	const onFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		if (fileList && fileList.length > 0) {
			const fileName = truncateFilename(fileList[0].name, MAX_SIZE_FILENAME);
			const fileType = fileList[0].type;
			const biggerThanAllowed = fileList[0].size > maxSizeAllowed;
			const wrongFormat = typeAllowed
				? !typeAllowed.find(type => type === fileType)
				: !allUploadTypeAllowed.includes(fileType);

			if (biggerThanAllowed) {
				setError(formatMessage("formError.invalidFileSize", { size: filesize(maxSizeAllowed) }));
				return;
			} else if (wrongFormat) {
				setError(formatMessage("formError.invalidFileFormat", { formats: formatAllowedString }));
			} else {
				setError("");
				const fileSizeLabel = filesize(fileList[0].size);
				updateFile(fileList[0]);
				setFileProperties({ name: fileName, size: fileSizeLabel, type: fileType });
			}
		}
	};

	function truncateFilename(originalFilename: string, len: number): string {
		const extension = originalFilename
			.substring(originalFilename.lastIndexOf(".") + 1, originalFilename.length)
			.toLowerCase();
		let filename = originalFilename.replace("." + extension, "");
		if (filename.length <= len) {
			return originalFilename;
		} else {
			filename =
				filename.substr(0, Math.floor(len / 2)) +
				"..." +
				filename.substr(filename.length - Math.floor(len / 2), filename.length);
			return filename + "." + extension;
		}
	}

	const uploadWording = instructions ?? "";
	const { isRTL } = useRTL();

	return (
		<FileUploadContainer className={className}>
			<DropArea $isRTL={isRTL}>
				{fileAccepted || hasExistingFile ? (
					<>
						<GreenCheck $isRTL={isRTL} src={require("../../../assets/images/bills/green-check.png")} />
						<FileLabel $isRTL={isRTL}>
							<span>{fileProperties.name}</span>
							{!hasExistingFile && <FileSizeStyle>({fileProperties.size})</FileSizeStyle>}
						</FileLabel>
						{!!onPreviewClicked && !hasExistingFile ? (
							<PreviewIcon
								$isRTL={isRTL}
								onClick={() => onPreviewClicked()}
								src={require("../../../assets/images/bills/preview.png")}
							/>
						) : null}
						<RemoveFileIcon
							$isRTL={isRTL}
							src={require("../../../assets/images/bills/bin.png")}
							onClick={() => {
								updateFile(null);
								onImageDeleted?.();
							}}
						/>
					</>
				) : (
					<>
						{isDefined(badgeIndex) && <BadgeCount num={`${badgeIndex}`} margin={10} />}
						<Placeholder>{uploadWording}</Placeholder>
						{showDownloadIcon ? (
							<DonwloadFileIcon $isRTL={isRTL} />
						) : (
							<FileIcon $isRTL={isRTL} src={require("../../../assets/images/bills/download.png")} />
						)}
					</>
				)}
				{!fileAccepted && !hasExistingFile && (
					<input
						type="file"
						accept={formatAllowedString}
						required={required}
						onChange={onFileAdded}
						disabled={disabled}
						autoFocus
					/>
				)}
			</DropArea>
			{error ? <StyledErrorMessage>{error}</StyledErrorMessage> : null}
		</FileUploadContainer>
	);
};

const StyledErrorMessage = styled(ErrorMessage)`
	margin-top: 10px;
`;

const FileSizeStyle = styled.span`
	color: #828282;
	padding-left: 5px;
`;

const FileUploadContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	${theme.mediumText};
`;

const DropArea = styled.div<{ dragging?: boolean; $isRTL: boolean }>`
	position: relative;
	padding: ${props => (props.$isRTL ? `15px 17px 14px 66px` : `15px 66px 14px 17px`)};
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1;
	background-color: #ffffff;
	border-radius: 10px;
	${shadows.medium};
	border: ${props => (props.dragging ? "1px dashed #2b2b2b" : "1px solid #ffffff")};

	input[type="file"],
	input[type="file"]::-webkit-file-upload-button {
		appearance: none;
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		width: 100%;
	}

	input[type="file"]:not(:disabled) {
		cursor: pointer;
	}
`;

const FileLabel = styled.div<{ $isRTL: boolean }>`
	min-height: 20px;
	display: flex;
	flex-direction: column;
	margin-left: ${props => (props.$isRTL ? 0 : 35)}px;
	margin-right: ${props => (props.$isRTL ? 35 : 0)}px;
`;

const SideIcon = styled.img`
	position: absolute;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	width: 25px;
	height: 25px;
	border-radius: 7px;
`;

const FileIcon = styled(SideIcon)<{ $isRTL: boolean }>`
	right: ${props => (props.$isRTL ? `unset` : `10px`)};
	left: ${props => (props.$isRTL ? `10px` : `unset`)};
`;

const DonwloadFileIcon = styled(DownloadIcon)<{ $isRTL: boolean }>`
	position: absolute;
	cursor: pointer;
	top: 50%;
	transform: translateY(-50%);
	right: ${props => (props.$isRTL ? `unset` : `10px`)};
	left: ${props => (props.$isRTL ? `10px` : `unset`)};
	width: 25px;
	height: 25px;
	border-radius: 7px;
`;

const RemoveFileIcon = styled(SideIcon)<{ $isRTL: boolean }>`
	width: 20px;
	height: 20px;
	right: ${props => (props.$isRTL ? `unset` : `10px`)};
	left: ${props => (props.$isRTL ? `10px` : `unset`)};
	z-index: 2;
`;

const GreenCheck = styled(SideIcon)<{ $isRTL: boolean }>`
	width: 35px;
	height: 35px;
	right: ${props => (props.$isRTL ? `10px` : `unset`)};
	left: ${props => (props.$isRTL ? `unset` : `10px`)};
	z-index: 2;
	padding: 10px;
	border-radius: 35px;
	background-color: #2dd794;
`;

const Placeholder = styled.span`
	color: #b1b1b1;
	font-weight: bold;
`;

const PreviewIcon = styled(SideIcon)<{ $isRTL: boolean }>`
	right: ${props => (props.$isRTL ? `unset` : `40px`)};
	left: ${props => (props.$isRTL ? `40px` : `unset`)};
`;
