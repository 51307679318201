import React, { InputHTMLAttributes, forwardRef } from "react";
import styled, { css } from "styled-components";

import { theme } from "../../styles/theme";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	variant?: "default" | "big";
	hasError?: boolean;
	errorMessage?: string | null;
	bottomOffset?: number;
	disabled?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props: TextInputProps, ref) => {
	const { variant, hasError, errorMessage, className, disabled, bottomOffset, ...rest } = props;

	return (
		<>
			<Container className={className} disabled={disabled}>
				<Input variant={variant} ref={ref} hasError={hasError} {...rest} />
			</Container>
			{errorMessage && hasError && <StyledErrorMessage hasError={hasError}>{errorMessage}</StyledErrorMessage>}
		</>
	);
});

const Container = styled.div<{ disabled?: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	padding: 15px 19px 14px 17px;
	border-radius: 10px;
	background-color: #ffffff;
	${({ disabled }) => ({
		opacity: disabled ? 0.5 : 1,
	})}
`;

export const Input = styled.input<{ variant; hasError?: boolean }>`
	max-width: 100%;
	flex: 1 0 auto;
	outline: none;
	border: none;
	${theme.boldText};
	font-size: 0.8125rem;
	color: #000000;
	::placeholder {
		color: #b1b1b1;
	}
	${({ hasError }) =>
		hasError &&
		css`
			color: #ff4242;
		`}
	${({ variant }) =>
		variant === "big" &&
		css`
			font-size: 1.5rem;
			line-height: 1.75rem;
			height: 2rem;
		`}
`;

const StyledErrorMessage = styled.p<{ hasError?: boolean }>`
	${theme.mediumText}
	font-size: 0.825rem;
	margin-top: 6px;
	color: ${props => (props.hasError ? theme.colors.error[500] : theme.colors.gray[700])};
`;
