import React, { ButtonHTMLAttributes, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { useRTL } from "../../../domain/language/use-rtl";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { DefaultButton } from "../buttons/default-button";
import { AccountIcon } from "../svg/account-icon";
import { CardIcon } from "../svg/card-icon";
import { LoanIcon } from "../svg/loan-icon";
import { QuestionIcon } from "../svg/question-icon";
import { ServiceIcon } from "../svg/service-icon";
import { TransferIcon } from "../svg/transfer-icon";
import { WalletIcon } from "../svg/wallet-icon";
import { sideBarColors } from "./component/colors";
import { NavType } from "./nav-type";

interface NavButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
	linkType: NavType;
	withBackground?: boolean;
}

const NavLink = {
	account: { icon: <WalletIcon />, labelKey: "navigationMenu.accountLink" },
	transfers: { icon: <TransferIcon />, labelKey: "navigationMenu.transferLink" },
	services: { icon: <ServiceIcon />, labelKey: "navigationMenu.payBillLink" },
	cards: { icon: <CardIcon />, labelKey: "navigationMenu.cardsLink" },
	settings: { icon: <AccountIcon />, labelKey: "navigationMenu.settingsLink" },
	contact: { icon: <QuestionIcon />, labelKey: "navigationMenu.contactLink" },
	loan: { icon: <LoanIcon />, labelKey: "navigationMenu.loansLink" },
};

export function NavButton(props: NavButtonProps) {
	const { linkType, isActive, withBackground = true, onClick, ...rest } = props;
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();

	const [toolTipVisible, setToolTipVisible] = useState(false);

	return (
		<ButtonBackground active={isActive} withoutBackground={!withBackground}>
			<Button
				onMouseEnter={() => setToolTipVisible(true)}
				onMouseLeave={() => setToolTipVisible(false)}
				role="menuitem"
				active={isActive}
				onClick={e => {
					setToolTipVisible(false);
					onClick?.(e);
				}}
				$isRTL={isRTL}
				{...rest}
			>
				{NavLink[linkType].icon}
				<Label active={isActive} $isRTL={isRTL}>
					{formatMessage(NavLink[linkType].labelKey)}
				</Label>
			</Button>
			<Tooltip $isVisible={toolTipVisible} $isRTL={isRTL}>
				{formatMessage(NavLink[linkType].labelKey)}
			</Tooltip>
		</ButtonBackground>
	);
}

interface SubNavButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
	name: string;
}

export function SubNavButton(props: SubNavButtonProps) {
	const { isActive, name, onClick, ...rest } = props;
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();

	return (
		<SubButtonBackground active={isActive}>
			<Button role="menuitem" active={isActive} onClick={onClick} $isRTL={isRTL} {...rest}>
				<SubItemLabel active={isActive} $isRTL={isRTL}>
					{formatMessage(name)}
				</SubItemLabel>
			</Button>
		</SubButtonBackground>
	);
}

const ButtonBackground = styled.div<{ active?: boolean; withoutBackground?: boolean }>`
	display: flex;
	flex-direction: row;
	background-color: ${({ active, withoutBackground }) =>
		active && !withoutBackground ? sideBarColors.selectedBackground : sideBarColors.background};
	margin: 0 16px;
	padding: 12px;
	border-radius: 13px;
	flex: 1;

	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		background-color: ${({ active }) => (active ? sideBarColors.selectedBackground : sideBarColors.background)};
		margin: 0 8px;
		padding: 12px 4px;
		justify-content: center;
		align-items: stretch;
		flex-direction: column;
	}
`;

const SubButtonBackground = styled(ButtonBackground)`
	margin: 0 16px 0 48px;
	justify-content: flex-start;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		margin: 0 16px;
	}
`;

const Button = styled(DefaultButton)<{ active?: boolean; $isRTL: boolean }>`
	position: relative;
	align-items: flex-start;
	justify-content: flex-start;

	svg {
		width: 24px;
		height: 24px;
		fill: ${({ active }) => (active ? sideBarColors.activeItem : sideBarColors.inactiveItem)};
	}
	:disabled {
		opacity: 0.3;
	}

	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		justify-content: center;
	}
`;

const BaseLabel = styled.span<{ active?: boolean; $isRTL: boolean }>`
	font-size: 0.9375rem;
	${theme.text};
	color: ${({ active }) => (active ? sideBarColors.activeItem : sideBarColors.inactiveItem)};
	margin-left: ${props => (props.$isRTL ? 0 : 12)}px;
	margin-right: ${props => (props.$isRTL ? 12 : 0)}px;

	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 170px;
`;

const Label = styled(BaseLabel)`
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		display: none;
	}
`;

const SubItemLabel = styled(BaseLabel)`
	margin-left: ${props => (props.$isRTL ? 0 : 4)}px;
	margin-right: ${props => (props.$isRTL ? 4 : 0)}px;
	max-width: 100%;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		font-size: 0.875rem;
	}
`;

const Tooltip = styled.span<{ $isVisible: boolean; $isRTL: boolean }>`
	${theme.text}
	text-align: ${props => (props.$isRTL ? `right` : `left`)};
	color: ${sideBarColors.toolTipText};
	font-size: 0.825rem;
	height: 23px;
	min-width: fill-content;
	width: intrinsic; /* Safari/WebKit utilisent un nom non-standard */
	width: -moz-max-content; /* Firefox/Gecko */
	width: -webkit-max-content; /* Chrome */

	padding: 3px 8px;
	border-radius: 8px;
	background-color: ${sideBarColors.toolTipBackground};
	position: absolute;
	margin-right: ${props => (props.$isRTL ? 60 : 0)};
	margin-left: ${props => (props.$isRTL ? 0 : 60)};
	opacity: ${props => (props.$isVisible ? 1 : 0)};
	z-index: 10;
	transition: opacity 0.5s;
	@media (min-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		display: none;
	}
`;
