import { BasicStorage } from "../../../shared/core/cache/basic-storage";

export class LocalStorage<T> implements BasicStorage<T> {
	async read(key: string): Promise<T | null> {
		const data = localStorage.getItem(key);
		return data ? JSON.parse(data) : null;
	}
	async store(data: T, key: string): Promise<void> {
		localStorage.setItem(key, JSON.stringify(data));
	}
	async clear(key: string): Promise<void> {
		localStorage.removeItem(key);
	}
}
