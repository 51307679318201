import { Config } from "../../../shared/core/config/config";

const toStaticImageUri = (filename: string) => `/static/${filename}`;
const toStaticImagesUri = (filenames: string[]) => filenames.map(toStaticImageUri);

export const StaticAssets = {
	carouselImages: toStaticImagesUri(Config.APP_CAROUSEL_IMAGES),
	registerImage: toStaticImageUri(Config.APP_REGISTER_IMAGE),
	logo: toStaticImageUri(Config.APP_LOGO_IMAGE),
};
