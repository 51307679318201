import { Amount } from "../../core/amount/amount";
import { TransactionGeolocationManager } from "../../core/geolocalisation/transaction-geolocalisation-manager";
import { runAfterInteractions } from "../../core/interaction/interaction-manager";
import { AccountManager } from "../account/account-manager";
import { accountsAffectedByTransaction } from "../accounting-transaction/transaction/accounts-affected-by-transaction";
import { Transaction } from "../accounting-transaction/transaction/transaction";
import { TransactionsManager } from "../accounting-transaction/transaction/transactions-manager";
import { PincodeSubmission } from "../pincode/pincode";
import { ConfirmationMode } from "../transactions/transaction-request";
import { BillService } from "./bill-service";

export class BillManager {
	public constructor(
		private billService: BillService,
		private geolocationManager: TransactionGeolocationManager,
		private accountManager: AccountManager,
		private transactionsManager: TransactionsManager
	) {}

	public async initPayBill(issuerId: string) {
		const location = await this.geolocationManager.updatePosition();
		return this.billService.initPayBill(issuerId, location);
	}

	public async payBill(billSession: string, inputValues: { [key: string]: string | Amount }) {
		return this.billService.payBill(billSession, inputValues);
	}

	public async validateBillPayment(
		billSession: string,
		confirmationMode: ConfirmationMode,
		pincode?: PincodeSubmission
	) {
		const result = await this.billService.validateBillPayment(billSession, confirmationMode, pincode);
		await this.refreshAccountAndTransactions(result.metadata.transaction);
		return result;
	}

	private refreshAccountAndTransactions(transaction: Transaction) {
		runAfterInteractions(async () => {
			const accountIds = accountsAffectedByTransaction(transaction);
			await this.accountManager.refresh();
			await this.transactionsManager.refresh(accountIds);
		});
	}
}
