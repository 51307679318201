import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Spinner } from "../spinner";

export interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	showSpinner?: boolean;
	spinnerColor?: string;
	spinnerSize?: string;
}

export const DefaultButton = forwardRef<HTMLButtonElement, DefaultButtonProps>((props: DefaultButtonProps, ref) => {
	const { showSpinner = false, spinnerColor = "inherit", spinnerSize = "20px", children, ...rest } = props;
	return (
		<Button ref={ref} noPointerEvents={showSpinner} {...rest}>
			{showSpinner && <StyledSpinner size={spinnerSize} color={spinnerColor} />}
			<ButtonContent hide={showSpinner}>{children}</ButtonContent>
		</Button>
	);
});

export const UnstyledButton = forwardRef<HTMLButtonElement, DefaultButtonProps>((props: DefaultButtonProps, ref) => {
	const { children, ...rest } = props;
	return (
		<NoAppearenceButton ref={ref} {...rest}>
			{children}
		</NoAppearenceButton>
	);
});

const commonStyle = css`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: transparent;

	appearance: none;
	outline: none;
	border: none;

	:hover {
		cursor: pointer;
	}

	:disabled {
		cursor: auto;
	}
`;

export const DefaultLink = styled(Link)`
	${commonStyle};
`;

export const DefaultExternalLink = styled.a`
	${commonStyle};
`;

const Button = styled.button<{ noPointerEvents: boolean }>`
	${({ noPointerEvents: noPointersEvents }) =>
		noPointersEvents &&
		css`
			pointer-events: none;
		`};
	${commonStyle}
`;

const StyledSpinner = styled(Spinner)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ButtonContent = styled.div<{ hide: boolean }>`
	opacity: ${props => (props.hide ? 0 : 1)};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const NoAppearenceButton = styled.button`
	appereance: none;
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
`;
