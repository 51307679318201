import React from "react";
import styled from "styled-components";
import { CardStatus } from "../../../../../shared/domains/cards/card";
import { PadlockLocked, PadlockUnlock } from "../../../common/svg/padlock";
import { theme } from "../../../styles/theme";

interface CardViewConfiguration {
	statusText?: string;
	statusColor?: string;
	cornerIcon?: JSX.Element;
}

export const getCardConfigurations: (cardStatus?: CardStatus) => CardViewConfiguration = cardStatus => {
	switch (cardStatus) {
		case CardStatus.New:
			return { statusText: "card.status.new", statusColor: "#1c1dff" };
		case CardStatus.Pending:
			return { statusText: "card.status.pending", statusColor: "#1c1dff" };
		case CardStatus.Active:
			return {
				statusText: "card.status.active",
				statusColor: theme.colors.success[500],
				cornerIcon: <StyledPadlockUnlock />,
			};
		case CardStatus.Blocked:
			return {
				statusText: "card.status.blocked",
				statusColor: "#ff6b21",
				cornerIcon: <StyledPadlockLocked />,
			};
		case CardStatus.Opposed:
			return { statusText: "card.status.opposed", statusColor: "#e61c1c" };
		case CardStatus.Expired:
			return { statusText: "card.status.expired", statusColor: "#aaaaaa" };
	}
	return {} as CardViewConfiguration;
};

const StyledPadlockUnlock = styled(PadlockUnlock)`
	height: 16px;
	width: 16px;
	fill: white;
`;

const StyledPadlockLocked = styled(PadlockLocked)`
	height: 16px;
	width: 16px;
	fill: white;
`;
