import { useCallback, useMemo, useState } from "react";
import { beneficiaryManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";
import { Recipient } from "./recipient";

export interface Beneficiaries {
	data: Recipient[];
	loading: boolean;
	error: Error | null;
	canLoadMore: boolean;
	loadMore: () => void;
	loadingMore: boolean;
	loadingMoreError: Error | null;
	refresh: () => void;
	refreshing: boolean;
}

export function useBeneficiaries(): Beneficiaries {
	const [refreshing, setRefreshing] = useState(false);
	const rawData = useObservable(beneficiaryManager.recipients);
	const loading = useObservable(beneficiaryManager.loading);
	const error = useObservable(beneficiaryManager.error);
	const loadingMore = useObservable(beneficiaryManager.loadingMore);
	const loadingMoreError = useObservable(beneficiaryManager.loadingMoreError);

	useAsyncEffect(async () => {
		await beneficiaryManager.load();
	}, []);

	const refresh = useCallback(async () => {
		setRefreshing(true);
		await beneficiaryManager.load(true);
		setRefreshing(false);
	}, []);

	const canLoadMore = useMemo(() => rawData.offset + rawData.limit < rawData.total, [
		rawData.offset,
		rawData.limit,
		rawData.total,
	]);

	const data = useMemo(() => rawData.items, [rawData.items]);

	const loadMore = useCallback(async () => {
		await beneficiaryManager.loadMore();
	}, []);

	return {
		data,
		loading,
		error,
		canLoadMore,
		loadMore,
		loadingMore,
		loadingMoreError,
		refresh,
		refreshing,
	};
}
