export const sideBarColors = {
	background: "white",
	inactiveItem: "#8f8f94",
	activeItem: "black",
	selectedBackground: "#f4f4f4",
	userCircle: "#e2e2e2",
	disconnectCircle: "#ededed",
	bottomBackground: "white",
	bottomSeparator: "#f4f4f4",
	verticalLine: "#f4f4f4",
	name: "black",
	profile: "#8f8f94",
	toolTipBackground: "black",
	toolTipText: "white",
};
