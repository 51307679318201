import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { MIN_SEARCH_CHARACTERS } from "../../../../../shared/domains/bic-referential/bic-referential-manager";
import { useBicReferential } from "../../../../../shared/domains/bic-referential/use-bic-referential";
import { useLoadMoreDebounce } from "../../../../../shared/utils/list";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { SearchBar } from "../../../common/forms/search-bar";
import { InfiniteScroll } from "../../../common/infinite-scroll";
import { MainColorSpinner } from "../../../common/spinner";
import { theme } from "../../../styles/theme";

interface SearchBankProps {
	onSelect: (name: string, bic: string) => void;
	title?: string;
	className?: string;
}

const PADDING = 10;

export const SearchBank = (props: SearchBankProps) => {
	const { className, title, onSelect } = props;
	const { formatMessage } = useIntl();
	const [bankSearch, setBankSearch] = useState("");

	const bicReferential = useBicReferential();
	const loadMoreDebounce = useLoadMoreDebounce();
	const loadMore = useCallback(
		() => loadMoreDebounce(bicReferential.loadMore, bicReferential.loadingMore, bicReferential.canLoadMore),
		[bicReferential.canLoadMore, bicReferential.loadMore, bicReferential.loadingMore, loadMoreDebounce]
	);

	const [error, setError] = useState<Error | null>(null);

	const updateBankSearch = useCallback(
		(text: string) => {
			setError(null);
			setBankSearch(text);
			if (bankSearch.length != text.length) {
				bicReferential.reset(text.length < MIN_SEARCH_CHARACTERS);
			}
			if (text.length >= MIN_SEARCH_CHARACTERS) {
				bicReferential.scheduleLoad(text);
			}
		},
		[bankSearch.length, bicReferential]
	);

	useEffect(() => {
		setError(bicReferential.error);
	}, [bicReferential.error]);

	const retry = useCallback(() => {
		setError(null);
		bicReferential.scheduleLoad(bankSearch);
	}, [bankSearch, bicReferential]);

	return (
		<Container className={className}>
			<Header>
				<Title>{title}</Title>
			</Header>
			<SearchBarContainer>
				<SearchBar
					placeholder={formatMessage("addRecipient.searchBank.bankPlaceholder", { num: MIN_SEARCH_CHARACTERS })}
					initialValue={bankSearch}
					onChange={text => updateBankSearch(text)}
					withIcon={false}
					widthSize={350}
				/>
			</SearchBarContainer>
			{bankSearch.length < MIN_SEARCH_CHARACTERS ? (
				<EmptyContent>
					<Message>{formatMessage("addRecipient.searchBank.firstSearchMessage")}</Message>
				</EmptyContent>
			) : (
				<>
					{bicReferential.loading ? (
						<SpinnerContainer>
							<MainColorSpinner size="30px" />
						</SpinnerContainer>
					) : null}
					{error ? (
						<>
							<ErrorContainer>
								<ErrorMessage style={{ textAlign: "center", paddingTop: 30 }}>
									{formatMessage("addRecipient.searchBank.errorMessage")}
								</ErrorMessage>
								<ErrorButton size="M" onClick={retry}>
									{formatMessage("addRecipient.searchBank.retryButton")}
								</ErrorButton>
							</ErrorContainer>
						</>
					) : !bicReferential.loading && bicReferential.count == 0 ? (
						<EmptyContent>
							<Message>{formatMessage("addRecipient.searchBank.noBankMessage")}</Message>
						</EmptyContent>
					) : (
						<InfiniteScroll
							fetchMore={loadMore}
							hasMore={bicReferential.canLoadMore}
							loading={bicReferential.loadingMore}
						>
							<StyledInsideInfiniteScroll>
								{bicReferential.data.map((bank, index) => (
									<Bank
										key={index}
										onClick={() => onSelect(bank.institutionName, bank.ibanBic)}
									>{`${bank.institutionName} (${bank.ibanBic})`}</Bank>
								))}
							</StyledInsideInfiniteScroll>
						</InfiniteScroll>
					)}
				</>
			)}
		</Container>
	);
};

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 30px;
	width: 300px;
	text-align: center;
	position: relative;
`;
const Title = styled.span`
	font-size: 1.125rem;
	${theme.boldText};
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-left: ${PADDING}px;
	padding-right: ${PADDING}px;
	padding-bottom: ${PADDING}px;
`;

const SearchBarContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-right: 20px;
`;

const StyledInsideInfiniteScroll = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	max-height: 400px;
	overflow-y: scroll;
`;

const Bank = styled.span`
	${theme.boldText};
	font-size: 0.938rem;
	text-align: left;
	margin: 10px 0;
`;

const EmptyContent = styled.div`
	flex-direction: column;
	flex-grow: 1;
	flex: 1;
	padding: 0 20px;
	justify-content: center;
	align-items: center;
	padding: 120px 0;
	min-height: 300px;
`;

const Message = styled.span`
	${theme.text};
	font-size: 0.85rem;
	color: #b1b1b1;
	text-align: center;
`;

const ErrorContainer = styled.div`
	flex-direction: column;
	display: flex;
	flex: 1;
	flex-grow: 1;
	min-height: 300px;
	justify-content: center;
	padding: 30px;
`;

const ErrorButton = styled(PrimaryButton)`
	margin: 40px;
`;
