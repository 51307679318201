import { Transaction } from "../accounting-transaction/transaction/transaction";

export enum TransactionCallType {
	PreAuth = "PREAUTH",
	Transaction = "TRANSACTION",
}

export enum ConfirmationMode {
	Confirm = "CONFIRM",
	PinCode = "PINCODE",
}

export interface TransactionRequest {
	metadata: {
		mode: TransactionCallType;
		confirmationMode: ConfirmationMode;
		transaction: Transaction;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
}
