import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useRTL } from "../../../../domain/language/use-rtl";
import { ChevronIcon } from "../../../common/svg/chevron";
import { shadows, theme } from "../../../styles/theme";

interface OptionCardProps extends HTMLAttributes<HTMLDivElement> {
	title: string;
	subtitle: string;
	onClick: () => void;
	children: JSX.Element;
}

export function OptionCard(props: OptionCardProps) {
	const { title, subtitle, onClick, children } = props;
	const { isRTL } = useRTL();

	return (
		<Card $isRTL={isRTL} onClick={onClick}>
			<IconContainer>{children}</IconContainer>
			<MainContainer>
				<Title>{title}</Title>
				<Subtitle>{subtitle}</Subtitle>
			</MainContainer>
			<ChevronContainer>
				<ChevronIcon
					width="18"
					height="18"
					fill="black"
					style={{ transform: isRTL ? "rotate(180deg)" : "rotate(0deg)" }}
				/>
			</ChevronContainer>
		</Card>
	);
}

const Card = styled.div<{ $isRTL: boolean }>`
	display: flex;
	align-items: center;
	padding: 25px 16px;
	border-radius: 13px;
	${shadows.medium};
	background-color: #ffffff;
	box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
	margin: 10px 8px;
	:hover {
		cursor: pointer;
	}
`;

const IconContainer = styled.div`
	display: flex;
	width: 28px;
	height: 28px;
	justify-content: center;
	align-items: center;
	object-fit: contain;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 16px;
`;

const Title = styled.span`
	${theme.mediumText}
	color: black;
	font-size: 0.9375rem;
	line-height: 1.4375rem;
	margin-bottom: 4px;
`;

const Subtitle = styled.span`
	${theme.text}
	font-size: 0.8125rem;
	color: #b1b1b1;
`;

const ChevronContainer = styled.div`
	padding: 8px;
`;
