import React, { useState } from "react";
import styled from "styled-components";
import { BillCategory } from "../../../../../shared/domains/bills/bill";
import { shadows, theme } from "../../../styles/theme";
import { billCategoryIcon } from "./bill-category-icons";
import { CategoryOpener } from "./category-opener";

export const BillCategoryView: React.FC<{
	category: BillCategory;
	color: string;
	isTopLevel?: boolean;
}> = ({ category, color, isTopLevel, children }) => {
	const [isOpened, setIsOpened] = useState(false);
	return (
		<Category>
			<CategoryContainer
				onClick={() => setIsOpened(!isOpened)}
				style={isTopLevel ? { backgroundColor: color, borderRadius: 20 } : undefined}>
				<CategoryIcon>
					<Logo src={billCategoryIcon[category.id] || billCategoryIcon.default} />
				</CategoryIcon>
				<CategoryName style={{ color: isTopLevel ? "#fff" : "#000" }}>{category.name}</CategoryName>
				<CategoryOpener isOpened={isOpened} />
			</CategoryContainer>
			{isOpened && children}
		</Category>
	);
};

const Category = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 20px;
	margin: 10px 0;
	${shadows.medium};
	background-color: #ffffff;
	align-items: stretch;
`;

const CategoryContainer = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const CategoryIcon = styled.div`
	display: flex;
	width: 32px;
	height: 32px;
	border-radius: 9px;
	background-color: rgba(0, 0, 0, 0.2);
	margin: 16px;
	align-items: center;
	justify-content: center;
`;

const CategoryName = styled.span`
	${theme.mediumText}
	font-size: 1rem;
	margin: 0 16px;
	flex: 1;
`;

const Logo = styled.img`
	width: 20px;
	height: 20px;
	object-fit: contain;
`;
