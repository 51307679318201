import { logger } from "../../../core/logging/logger";
import { ObservableArray } from "../../../utils/observable-array";
import { BaseSpendingCategory, SpendingAdditionalData } from "./categorization";
import { CategorizationsService } from "./categorizations-service";

export class CategorizationsManager {
	public categories = new ObservableArray<BaseSpendingCategory>([]);
	public additionalData = new ObservableArray<SpendingAdditionalData>([]);

	public constructor(private categorizationsService: CategorizationsService) {}

	public async load(forceReload = false) {
		try {
			if (this.categories.get().length == 0 || forceReload) {
				const categories = await this.categorizationsService.getCategories();
				this.categories.set(categories);
			}
			if (this.additionalData.get().length == 0 || forceReload) {
				const additionalData = await this.categorizationsService.getAdditionalData();
				this.additionalData.set(additionalData);
			}
		} catch (e) {
			logger.debug("CategorizationsManager", "Failed to load", e);
			throw e;
		}
	}
}
