import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { DefaultExternalLink } from "../../../../common/buttons/default-button";
import { SwitchContainer } from "../../../../common/buttons/switch-button";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { theme } from "../../../../styles/theme";
import { FormWithButtons } from "../../shared/components/form-with-buttons";

export const RegisterTermOfServiceWidget: React.FC<{
	url: string;
	hasEula?: boolean;
	isTermAccepted: boolean;
	showTitle?: boolean;
	onTermChange: (isTermAccepted: boolean) => void;
}> = ({ showTitle = true, url, hasEula, isTermAccepted, onTermChange }) => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			{showTitle && <Title>{formatMessage(hasEula ? "registerScreen.eulaTitle" : "registerScreen.noEulaTitle")}</Title>}
			<LinkWrapper>
				<MainDescriptionText>
					{formatMessage(hasEula ? "registerScreen.eulaFirstDescription" : "registerScreen.noEulaFirstDescription")}
				</MainDescriptionText>
				<DescriptionText>
					{formatMessage(hasEula ? "registerScreen.eulaSecondDescription" : "registerScreen.noEulaSecondDescription")}
					{hasEula && (
						<TermOfUseLink target="_blank" href={url}>
							{formatMessage("registerScreen.eulaLink")}
						</TermOfUseLink>
					)}
				</DescriptionText>
			</LinkWrapper>
			{hasEula && (
				<StyledSwitchButton
					getSwitchLabel={() => formatMessage("registerScreen.eulaAcceptLabel")}
					value={isTermAccepted}
					onSwitch={onTermChange}
				/>
			)}
		</Container>
	);
};

export const RegisterTermOfServiceStep: React.FC<{
	url: string;
	onSubmit: () => void;
	onBackward: () => void;
	errorMessage?: string | null;
	loading?: boolean;
	hasEula?: boolean;
}> = ({ onSubmit, onBackward, errorMessage, loading, url, hasEula }) => {
	const { formatMessage } = useIntl();
	const [isTermAccepted, setIsTermAccepted] = useState(false);

	return (
		<FormWithButtons
			onSubmit={onSubmit}
			errorMessage={errorMessage}
			onBackward={onBackward}
			loading={loading}
			disableSubmitButton={hasEula ? !isTermAccepted : false}
			submitWording={formatMessage("registerScreen.createMyAccountButton")}
		>
			<RegisterTermOfServiceWidget
				hasEula={hasEula}
				url={url}
				isTermAccepted={isTermAccepted}
				onTermChange={setIsTermAccepted}
			/>
		</FormWithButtons>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 500px;
`;

const Title = styled(MarkdownText)`
	${theme.boldText};
	font-size: 1.875rem;
	margin-bottom: 20px;
`;

const LinkWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const StyledSwitchButton = styled(SwitchContainer)`
	margin-bottom: 30px;
	max-width: 400px;
`;

const DescriptionText = styled.div`
	font-size: 0.8rem;
	margin-bottom: 20px;
	color: ${theme.colors.gray[700]};
`;

const MainDescriptionText = styled(DescriptionText)`
	font-weight: bold;
	color: black;
`;

const TermOfUseLink = styled(DefaultExternalLink)`
	color: ${theme.mainColor};
	text-decoration: underline;
	display: inline;
	margin-left: 5px;
	text-transform: lowercase;
`;
