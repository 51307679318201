import { useCallback, useMemo, useState } from "react";
import { accountManager, cardManager, cardService, productManager } from "../../core/service/services";
import { Account, AccountType } from "../account/account";
import { Product, ProductCardCreationProcess } from "../products/product";

import { isDefined } from "../../utils/assert";
import { useObservable } from "../../utils/observable";

export enum CardCreationStep {
	SelectAccountOrCard = "SELECT_ACCOUNT_OR_CARD",
	PresentCgu = "PRESENT_CGU",
	Success = "CARD_CREATION_SUCCESS",
	Error = "CARD_CREATION_ERROR",
	SCACheck = "SCA_CHECK", //custom VQ step
}

export interface CardCreationSettings {
	creationStep: CardCreationStep;
	account?: Account;
	cardCreationProcess: ProductCardCreationProcess;
}

export const useCardCreation = (isVirtual?: boolean) => {
	const [step, setStep] = useState<CardCreationStep | undefined>(undefined);
	const [scaToken, setScaToken] = useState<string>("");

	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
	const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

	const [creationError, setCreationError] = useState<string | undefined>(undefined);

	const accounts = useObservable(accountManager.accounts);
	const currentAccounts = useMemo(() => accounts.filter(account => account.type == AccountType.Current), [accounts]);

	const debitCardProducts = useObservable(productManager.debitCardProducts);

	const cgu = useMemo(() => selectedProduct?.cardCreationProcess?.termsOfServiceHtml || "", [selectedProduct]);

	const resetStep = () => {
		setStep(CardCreationStep.SelectAccountOrCard);
	};

	const createCard = useCallback(
		async (account, product, pincode?, scaToken?: string) => {
			try {
				if (!isDefined(account)) {
					throw new Error("missing account to create card");
				}
				if (!isDefined(product) && !isVirtual) {
					throw new Error("missing product to create card");
				}
				setCreationError(undefined);

				await cardManager.createCard(account.id, product.id, pincode, scaToken, isVirtual);
				setStep(CardCreationStep.Success);
			} catch (e) {
				//SCA use case, must send and verify the given token
				if (e.response?.data?.error === "Unauthorized" && e.response?.data?.new_token) {
					setScaToken(e.response.data.new_token);
					setStep(CardCreationStep.SCACheck);
					cardService.scaAuthenticationSendToken(e.response.data.new_token);
				} else {
					setCreationError(e.response?.data?.error?.message || e.toString());
					setStep(CardCreationStep.Error);
				}
			}
		},
		[setStep, setCreationError]
	);

	const startCreationFlow = useCallback(async () => {
		const availableAccounts = accountManager.accounts.get().filter(account => account.type == AccountType.Current);
		const availableDebitCards = productManager.debitCardProducts.get();

		if (availableAccounts.length === 0) {
			setCreationError("Can't create card. No current account available.");
			setStep(CardCreationStep.Error);
			return;
		}

		if (availableDebitCards.length === 0) {
			setCreationError("Can't create card. No debit card product available.");
			setStep(CardCreationStep.Error);
			return;
		}

		if (availableAccounts.length > 1 || availableDebitCards.length > 1) {
			setStep(CardCreationStep.SelectAccountOrCard);
		} else {
			setSelectedAccount(availableAccounts[0]);
			setSelectedProduct(availableDebitCards[0]);

			const nextStep = getStepFromCardCreationProcess(availableDebitCards[0].cardCreationProcess);
			if (isDefined(nextStep)) {
				setStep(nextStep);
			} else {
				await createCard(availableAccounts[0], availableDebitCards[0]);
			}
		}
	}, [createCard]);

	const confirmSelection = useCallback(async () => {
		if (!isDefined(selectedAccount)) {
			throw new Error("missing account to create card");
		}
		if (!isDefined(selectedProduct)) {
			throw new Error("missing product to create card");
		}
		// to show CGU again if dismissed without validation
		setStep(CardCreationStep.SelectAccountOrCard);

		const nextStep = getStepFromCardCreationProcess(selectedProduct.cardCreationProcess);
		if (isDefined(nextStep)) {
			setStep(nextStep);
		} else {
			await createCard(selectedAccount, selectedProduct);
		}
	}, [selectedAccount, selectedProduct, createCard, setStep]);

	const rejectCgu = useCallback(() => {
		setStep(CardCreationStep.SelectAccountOrCard);
	}, [setStep]);

	const acceptCguWithoutPin = useCallback(async () => {
		await createCard(selectedAccount, selectedProduct);
	}, [selectedAccount, selectedProduct, createCard]);

	const getStepFromCardCreationProcess = (process?: ProductCardCreationProcess) => {
		if (process?.termsOfServiceHtml) {
			return CardCreationStep.PresentCgu;
		}
		return undefined;
	};

	return {
		startCreationFlow,
		step,
		selectedProduct,
		setSelectedProduct,
		selectedAccount,
		setSelectedAccount,
		debitCardProducts,
		currentAccounts,
		confirmSelection,
		cgu,
		rejectCgu,
		acceptCguWithoutPin,
		creationError,
		scaToken,
		resetStep,
		createCard,
	};
};
