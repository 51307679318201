import { Observable } from "../../utils/observable";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { ClientManager } from "../client/client-manager";
import { BillCategory } from "./bill";

export class BillIssuerSelectionManager {
	public search = new Observable<string | undefined>(undefined);
	public hierarchy = new Observable<BillCategory[]>([]);

	public constructor(private authenticationManager: AuthenticationManager, private clientManager: ClientManager) {
		this.authenticationManager.isConnected.onChange.add(isConnected => {
			if (!isConnected) {
				this.clear();
			}
			this.clientManager.onLanguageChange.add(() => {
				this.clear();
			});
		});
	}

	public clear() {
		this.search.set(undefined);
		this.hierarchy.set([]);
	}
}
