/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import { assign, Machine, send } from "xstate";
import { cashTransferManager, pincodeKeyboardService } from "../../shared/core/service/services";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError,
} from "../../shared/domains/pincode/pincode-error";
import { CashTransfer, CashTransferType } from "../../shared/domains/transactions/cash-transfer/cash-transfer";
import { CashTransferCodeNotFound } from "../../shared/domains/transactions/cash-transfer/retreive-cash-transfer-error";
import { ConfirmationMode, TransactionRequest } from "../../shared/domains/transactions/transaction-request";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

export const useRetrieveCashTransferMachine = () => {
	const [machine, sendEvent] = useMachine(retrieveCashTransferMachine);
	const { value: state, context } = machine as {
		value: CashTransferState | PincodeState;
		context: RetrieveCashTransferMachineContext;
	};

	const start = (cashTransfer: CashTransfer) => {
		sendEvent(cashTransfer.type === CashTransferType.Sent ? "CANCEL" : "RETRIEVE", { cashTransfer });
	};

	const submitCashCode = (cashCode: string) => {
		sendEvent("SUBMIT_CASH_CODE", { cashCode });
	};

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};

	return { state, context, error: context.error, start, submitPincode, submitCashCode };
};

interface RetrieveCashTransferMachineContext {
	cashTransfer?: CashTransfer;
	cashCode?: string;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	transactionRequest?: TransactionRequest;
	transactionResult?: TransactionRequest;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError | CashTransferCodeNotFound;
}

export enum CashTransferState {
	WaitingToRetrieveOrCancel = "WaitingToRetrieveOrCancel",
	EnteringCashCode = "EnteringCashCode",
	RequestingOrCancellingTransfer = "RequestingOrCancellingTransfer",
	Confirmation = "Confirmation",
	ConfirmTransferError = "ConfirmTransferError",
	Done = "Done",
}
type CashTransferEvent =
	| { type: "CANCEL"; cashTransfer: CashTransfer }
	| { type: "RETRIEVE"; cashTransfer: CashTransfer }
	| { type: "SUBMIT_CASH_CODE"; cashCode: string }
	| { type: "CASH_CODE_ERROR" }
	| { type: "RETRIEVE_CASH_ERROR" }
	| { type: "CONFIRM" }
	| { type: "TRANSFER_DONE" }
	| { type: "TRANSFER_CONFIRMATION_PINCODE_ERROR" }
	| { type: "TRANSFER_CONFIRMATION_ERROR" }
	| KeyboardEvent;

export const retrieveCashTransferMachine = Machine<RetrieveCashTransferMachineContext, CashTransferEvent>({
	id: "cash-transfer",
	initial: CashTransferState.WaitingToRetrieveOrCancel,
	states: {
		[CashTransferState.WaitingToRetrieveOrCancel]: {
			on: {
				CANCEL: {
					target: CashTransferState.RequestingOrCancellingTransfer,
					actions: assign({
						cashTransfer: (_, event) => event.cashTransfer,
					}),
				},
				RETRIEVE: {
					target: CashTransferState.EnteringCashCode,
					actions: assign({
						cashTransfer: (_, event) => event.cashTransfer,
					}),
				},
			},
		},
		[CashTransferState.EnteringCashCode]: {
			on: {
				SUBMIT_CASH_CODE: {
					target: CashTransferState.RequestingOrCancellingTransfer,
					actions: assign({
						cashCode: (_, event) => event.cashCode,
					}),
				},
			},
		},
		[CashTransferState.RequestingOrCancellingTransfer]: {
			invoke: {
				id: "requestOrCancelTransfert",
				src: context => cashTransferManager.startRetrieveCashTransfer(context.cashCode || context.cashTransfer!.code),
				onDone: {
					actions: [
						assign({
							transactionRequest: (_, event) => event.data,
							error: _ => undefined,
						}),
						send(context =>
							context.transactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
								? { type: "PINCODE_CONFIRM" }
								: { type: "CONFIRM" }
						),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send(ctx => (ctx.cashCode ? { type: "CASH_CODE_ERROR" } : { type: "RETRIEVE_CASH_ERROR" })),
					],
				},
			},
			on: {
				PINCODE_CONFIRM: PincodeState.PincodeConfirmation,
				CONFIRM: CashTransferState.Confirmation,
				CASH_CODE_ERROR: CashTransferState.EnteringCashCode,
				RETRIEVE_CASH_ERROR: CashTransferState.WaitingToRetrieveOrCancel,
			},
		},
		[PincodeState.PincodeConfirmation]: {
			invoke: {
				id: "fetchKeyboard",
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PincodeState.PromptingKeyboard]: {
			on: {
				SUBMIT_PINCODE: {
					target: CashTransferState.Confirmation,
					actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
				},
			},
		},
		[CashTransferState.Confirmation]: {
			invoke: {
				id: "confirm-transfer",
				src: context =>
					cashTransferManager.confirmRetrieveCashTransfer(
						context.transactionRequest!.metadata.confirmationMode,
						context.cashCode || context.cashTransfer!.code,
						context.pincodeSubmission
					),
				onDone: {
					actions: [
						assign({
							transactionResult: (_, event) => event.data,
							error: _ => undefined,
						}),
						send({ type: "TRANSFER_DONE" }),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send(ctx =>
							ctx.transactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
								? { type: "TRANSFER_CONFIRMATION_PINCODE_ERROR" }
								: { type: "TRANSFER_CONFIRMATION_ERROR" }
						),
					],
				},
			},
			on: {
				TRANSFER_DONE: CashTransferState.Done,
				TRANSFER_CONFIRMATION_PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
				TRANSFER_CONFIRMATION_ERROR: CashTransferState.ConfirmTransferError,
			},
		},
		[PincodeState.FetchKeyboardAfterError]: {
			invoke: {
				id: "fetch-keyboard",
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[CashTransferState.ConfirmTransferError]: {
			exit: assign({ error: _ => undefined }),
			on: {
				CANCEL: {
					target: CashTransferState.RequestingOrCancellingTransfer,
					actions: assign({
						cashTransfer: (_, event) => event.cashTransfer,
					}),
				},
				RETRIEVE: {
					target: CashTransferState.EnteringCashCode,
					actions: assign({
						cashTransfer: (_, event) => event.cashTransfer,
					}),
				},
				SUBMIT_CASH_CODE: {
					target: CashTransferState.RequestingOrCancellingTransfer,
					actions: assign({
						cashCode: (_, event) => event.cashCode,
					}),
				},
			},
		},
		[CashTransferState.Done]: {
			type: "final",
		},
	},
});
