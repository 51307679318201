import React, { useEffect } from "react";
import { webAppStartManager } from "../../../shared/core/service/services";
import { useObservable } from "../../../shared/utils/observable";
import { Modal } from "../../ui/common/modal/modal";
import { PincodeChangeModal } from "../../ui/screen/pincode/pincode-change-modal";
import { PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../modal/modal-id";

export const usePromptPincodeChangeAfterAuthentication = () => {
	const shouldPromptPincodeChange = useObservable(webAppStartManager.shouldPromptPincodeChange);
	useEffect(() => {
		if (shouldPromptPincodeChange) {
			Modal.present(PINCODE_MUST_BE_CHANGED_MODAL_ID, () => <PincodeChangeModal type="after-authentication" />, {
				canBeDismissed: false,
			});
			webAppStartManager.hasPromptPincodeChange();
		}
	}, [shouldPromptPincodeChange]);
};
