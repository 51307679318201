/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import { useMemo } from "react";
import { assign, Machine, send } from "xstate";
import { Amount } from "../../shared/core/amount/amount";
import { bankToWalletManager, pincodeKeyboardService } from "../../shared/core/service/services";
import { Account, AccountIBAN } from "../../shared/domains/account/account";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError,
} from "../../shared/domains/pincode/pincode-error";
import { PendingRechargeTransaction } from "../../shared/domains/transactions/bank-to-wallet/bank-to-wallet-manager";
import { PspTransactionRequest } from "../../shared/domains/transactions/bank-to-wallet/psp-transaction-request";
import { RtpTransactionRequest } from "../../shared/domains/transactions/bank-to-wallet/rpt-transaction-request";
import { ConfirmationMode } from "../../shared/domains/transactions/transaction-request";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

export enum RechargeMethod {
	BANK_TO_WALLET = "BANK_TO_WALLET",
	WITH_CARD = "WITH_CARD",
}
export const useRechargeMachine = (
	initialState:
		| RechargeState.SelectingRechargeMethod
		| RechargeState.PspTransactionComplete = RechargeState.PspTransactionComplete
) => {
	const rechargeMachine = useMemo(() => registerMachineBuilder(initialState), [initialState]);
	const [state, sendEvent] = useMachine(rechargeMachine);

	const selectRechargeMethod = (account: Account, externalAccountIBAN: AccountIBAN, method: RechargeMethod) => {
		if (method === RechargeMethod.BANK_TO_WALLET) {
			sendEvent("RECHARGE_BANK_TO_WALLET", { account, externalAccountIBAN });
		} else {
			sendEvent("RECHARGE_WITH_CARD", { account });
		}
	};

	const selectAmount = (amount: Amount) => {
		sendEvent("SELECT_AMOUNT", { amount });
	};

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};
	return {
		state: state.value as RechargeState | PincodeState,
		context: state.context,
		selectRechargeMethod,
		selectAmount,
		submitPincode,
	};
};

interface RechargeMachineContext {
	rechargeMethod?: RechargeMethod;
	account?: Account;
	externalAccountIBAN?: string;
	amount?: Amount;
	rtpTransactionRequest?: RtpTransactionRequest;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	transactionResult?: RtpTransactionRequest;
	rechargeByCardResult?: PspTransactionRequest;
	completedPspTransaction?: PendingRechargeTransaction;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError;
}

export enum RechargeState {
	SelectingRechargeMethod = "SelectingRechargeMethod",
	SelectingBankToWalletAmount = "SelectingBankToWalletAmount",
	RequestingBankToWallet = "RequestingBankToWallet",
	RequestingBankToWalletError = "RequestingBankToWalletError",
	Confirmation = "Confirmation",
	ConfirmBankToWalletError = "ConfirmBankToWalletError",
	SelectingCardAmount = "SelectingCardAmount",
	SelectingCardAmountError = "SelectingCardAmountError",
	FetchingCardPspWebviewUrl = "FetchingRechargeByCardWebview",
	PspWebviewRedirection = "PspWebviewRedirection",
	PspTransactionComplete = "PspTransactionComplete",
	Done = "Done",
}
type RechargeEvent =
	| {
			type: "RECHARGE_BANK_TO_WALLET";
			rechargeMethod: RechargeMethod;
			account: Account;
			externalAccountIBAN: AccountIBAN;
	  }
	| {
			type: "RECHARGE_WITH_CARD";
			rechargeMethod: RechargeMethod;
			account: Account;
	  }
	| {
			type: "SELECT_AMOUNT";
			amount: Amount;
	  }
	| { type: "CONFIRM" }
	| { type: "RECHARGE_DONE" }
	| { type: "RECHARGE_CONFIRMATION_PINCODE_ERROR" }
	| { type: "RECHARGE_CONFIRMATION_ERROR" }
	| { type: "REDIRECT_TO_CARD_PSP" }
	| KeyboardEvent;

enum RechargeInvokeName {
	StartRecharge = "StartRecharge",
	FetchKeyboard = "FetchKeyboard",
	ConfirmRecharge = "ConfirmRecharge",
	FetchCardPspWebviewUrl = "FetchCardPspWebviewUrl",
	RedirectToPspWebview = "RedirectToPspWebview",
	CompletePspTransaction = "CompletePspTransaction",
}

const registerMachineBuilder = (initialState: RechargeState) =>
	Machine<RechargeMachineContext, RechargeEvent>({
		id: "recharge",
		initial: initialState,
		states: {
			[RechargeState.SelectingRechargeMethod]: {
				on: {
					RECHARGE_BANK_TO_WALLET: {
						target: RechargeState.SelectingBankToWalletAmount,
						actions: [
							assign({
								rechargeMethod: _ => RechargeMethod.BANK_TO_WALLET,
								account: (_, event) => event.account,
								externalAccountIBAN: (_, event) => event.externalAccountIBAN,
							}),
						],
					},
					RECHARGE_WITH_CARD: {
						target: RechargeState.SelectingCardAmount,
						actions: [
							assign({
								rechargeMethod: _ => RechargeMethod.WITH_CARD,
								account: (_, event) => event.account,
							}),
						],
					},
				},
			},
			[RechargeState.SelectingBankToWalletAmount]: {
				on: {
					SELECT_AMOUNT: {
						target: RechargeState.RequestingBankToWallet,
						actions: [
							assign({
								amount: (_, event) => event.amount,
							}),
						],
					},
				},
			},
			[RechargeState.RequestingBankToWallet]: {
				invoke: {
					id: RechargeInvokeName.StartRecharge,
					src: context =>
						bankToWalletManager.startBankToWallet(context.amount!, context.account!.id, context.externalAccountIBAN),
					onDone: {
						actions: [
							assign({
								rtpTransactionRequest: (_, event) => event.data,
							}),
							send(context =>
								context.rtpTransactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
									? { type: "PINCODE_CONFIRM" }
									: { type: "CONFIRM" }
							),
						],
					},
					onError: {
						target: RechargeState.RequestingBankToWalletError,
						actions: [
							assign({
								error: (_, event) => event.data,
							}),
						],
					},
				},
				on: {
					PINCODE_CONFIRM: PincodeState.PincodeConfirmation,
					CONFIRM: RechargeState.Confirmation,
				},
			},
			[RechargeState.RequestingBankToWalletError]: {
				exit: assign<RechargeMachineContext, RechargeEvent>({ error: undefined }),
				on: {
					SELECT_AMOUNT: {
						target: RechargeState.RequestingBankToWallet,
						actions: [
							assign({
								amount: (_, event) => event.amount,
							}),
						],
					},
				},
			},
			[PincodeState.PincodeConfirmation]: {
				invoke: {
					id: RechargeInvokeName.FetchKeyboard,
					src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
					onDone: {
						actions: [
							assign({
								keyboard: (_, event) => event.data,
							}),
							send({ type: "PROMPT_KEYBOARD" }),
						],
					},
				},
				on: {
					PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
				},
			},
			[PincodeState.PromptingKeyboard]: {
				on: {
					SUBMIT_PINCODE: {
						target: RechargeState.Confirmation,
						actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
					},
				},
			},
			[RechargeState.Confirmation]: {
				invoke: {
					id: RechargeInvokeName.ConfirmRecharge,
					src: context =>
						bankToWalletManager.confirmBankToWallet(
							context.rtpTransactionRequest!.metadata.confirmationMode,
							context.amount!,
							context.account!.id,
							context.externalAccountIBAN,
							context.pincodeSubmission
						),
					onDone: {
						actions: [
							assign<RechargeMachineContext, { type: string; data: RtpTransactionRequest | undefined }>({
								transactionResult: (_, event) => event.data,
								error: undefined,
							}),
							send({ type: "RECHARGE_DONE" }),
						],
					},
					onError: {
						actions: [
							assign({
								error: (_, event) => event.data,
							}),
							send(ctx =>
								ctx.rtpTransactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
									? { type: "RECHARGE_CONFIRMATION_PINCODE_ERROR" }
									: { type: "RECHARGE_CONFIRMATION_ERROR" }
							),
						],
					},
				},
				on: {
					RECHARGE_DONE: RechargeState.Done,
					RECHARGE_CONFIRMATION_PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
					RECHARGE_CONFIRMATION_ERROR: RechargeState.ConfirmBankToWalletError,
				},
			},
			[PincodeState.FetchKeyboardAfterError]: {
				invoke: {
					id: RechargeInvokeName.FetchKeyboard,
					src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
					onDone: {
						actions: [
							assign({
								keyboard: (_, event) => event.data,
							}),
							send({ type: "PROMPT_KEYBOARD" }),
						],
					},
				},
				on: {
					PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
				},
			},
			[RechargeState.ConfirmBankToWalletError]: {
				exit: assign<RechargeMachineContext, RechargeEvent>({ error: undefined }),
				on: {
					SELECT_AMOUNT: {
						target: RechargeState.RequestingBankToWallet,
						actions: [
							assign({
								amount: (_, event) => event.amount,
							}),
						],
					},
				},
			},
			[RechargeState.SelectingCardAmount]: {
				on: {
					SELECT_AMOUNT: {
						target: RechargeState.FetchingCardPspWebviewUrl,
						actions: [
							assign({
								amount: (_, event) => event.amount,
							}),
						],
					},
				},
			},
			[RechargeState.FetchingCardPspWebviewUrl]: {
				invoke: {
					id: RechargeInvokeName.FetchCardPspWebviewUrl,
					src: context => bankToWalletManager.confirmRechargeByCard(context.amount!, context.account!.id),
					onDone: {
						actions: [
							assign({
								rechargeByCardResult: (_, event) => event.data,
							}),
							send({ type: "REDIRECT_TO_CARD_PSP" }),
						],
					},
					onError: {
						target: RechargeState.SelectingCardAmountError,
						actions: [
							assign({
								error: (_, event) => event.data,
							}),
						],
					},
				},
				on: {
					REDIRECT_TO_CARD_PSP: RechargeState.PspWebviewRedirection,
				},
			},
			[RechargeState.SelectingCardAmountError]: {
				exit: assign<RechargeMachineContext, RechargeEvent>({ error: undefined }),
				on: {
					SELECT_AMOUNT: {
						target: RechargeState.FetchingCardPspWebviewUrl,
						actions: [
							assign({
								amount: (_, event) => event.amount,
							}),
						],
					},
				},
			},
			[RechargeState.PspWebviewRedirection]: {
				invoke: {
					id: RechargeInvokeName.RedirectToPspWebview,
					src: async context => location.assign(context.rechargeByCardResult!.data.webviewUrl),
				},
			},
			[RechargeState.PspTransactionComplete]: {
				invoke: {
					id: RechargeInvokeName.CompletePspTransaction,
					src: () => bankToWalletManager.completePspTransaction(),
					onDone: {
						actions: [
							assign({
								rechargeMethod: _ => RechargeMethod.WITH_CARD,
								completedPspTransaction: (_, event) => event.data,
							}),
							send({ type: "RECHARGE_DONE" }),
						],
					},
				},
				on: {
					RECHARGE_DONE: RechargeState.Done,
				},
			},

			[RechargeState.Done]: {
				type: "final",
			},
		},
	});
