import React, { HTMLAttributes, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import { buildLinkMailTo, buildLinkPhoneTo } from "../../../../shared/utils/link-builder";
import { useObservable } from "../../../../shared/utils/observable";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { ContactEmailIcon } from "../svg/contact-email-icon";
import { ContactPhoneIcon } from "../svg/contact-phone-icon";
import { QuestionIcon } from "../svg/question-icon";
import { ContactLink } from "./contact-link";

export interface BankContactProps extends HTMLAttributes<HTMLDivElement> {
	withBigButtons?: boolean;
}

export const BankContact = (props: BankContactProps) => {
	const { withBigButtons: bigSection } = props;
	const { formatMessage } = useIntl();
	const configuration = useObservable(configurationManager.configuration);
	const { contactEmail, contactPhone } = configuration;
	const infoUrl = configurationManager.getBestInfoUrlLocale();

	const [phoneTooltipVisible, setPhoneTooltipVisible] = useState(false);
	const [mailTooltipVisible, setMailTooltipVisible] = useState(false);
	const [webTooltipVisible, setWebTooltipVisible] = useState(false);

	return (
		<>
			{bigSection ? (
				<>
					{isDefined(contactEmail) && <StyledContactLink type="email" href={buildLinkMailTo(contactEmail)} />}
					{isDefined(contactPhone) && <StyledContactLink type="phone" href={buildLinkPhoneTo(contactPhone)} />}
					{isDefined(infoUrl) && <StyledContactLink href={infoUrl} type="website" />}
				</>
			) : (
				<>
					<HelpQuestion>{formatMessage("bankContactScreen.question")}</HelpQuestion>
					<HelpButtonsContainer>
						{isDefined(contactPhone) && (
							<HelpContainer>
								<HelpTip $isVisible={phoneTooltipVisible}>{formatMessage("bankContactScreen.phoneContact")}</HelpTip>
								<HelpButton
									onMouseEnter={() => setPhoneTooltipVisible(true)}
									onMouseLeave={() => setPhoneTooltipVisible(false)}
									type="phone"
									href={buildLinkPhoneTo(contactPhone)}>
									<ContactPhoneIcon color={"black"} width={20} height={20} />
								</HelpButton>
							</HelpContainer>
						)}
						{isDefined(contactEmail) && (
							<HelpContainer>
								<HelpTip $isVisible={mailTooltipVisible}>{formatMessage("bankContactScreen.emailContact")}</HelpTip>
								<HelpButton
									onMouseEnter={() => setMailTooltipVisible(true)}
									onMouseLeave={() => setMailTooltipVisible(false)}
									type="email"
									href={buildLinkMailTo(contactEmail)}>
									<ContactEmailIcon color={"black"} width={20} height={20} />
								</HelpButton>
							</HelpContainer>
						)}
						{isDefined(infoUrl) && (
							<HelpContainer>
								<HelpTip $isVisible={webTooltipVisible}>{formatMessage("bankContactScreen.urlContact")}</HelpTip>
								<HelpButton
									onMouseEnter={() => setWebTooltipVisible(true)}
									onMouseLeave={() => setWebTooltipVisible(false)}
									type="website"
									href={infoUrl}>
									<QuestionIcon color={"black"} width={20} height={20} />
								</HelpButton>
							</HelpContainer>
						)}
					</HelpButtonsContainer>
				</>
			)}
		</>
	);
};

const ContactSection = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 304px;
	border-radius: 21px;
	${shadows.medium};
	background-color: #ffffff;
	padding-top: 40px;
	padding-bottom: 40px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-top: 20px;
		width: unset;
	}
`;

const StyledContactLink = styled(ContactLink)`
	&:not(:last-of-type) {
		margin-bottom: 64px;
		@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
			margin-bottom: 34px;
		}
	}
`;

const HelpQuestion = styled.span`
	${theme.mediumText}
	font-size: 0.8125rem;
	color: #b1b1b1;
`;

const HelpContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`;

const HelpTip = styled.span<{ $isVisible: boolean }>`
	${theme.text}
	color: white;
	font-size: 0.825rem;
	text-align: center;
	height: 23px;
	width: auto;
	padding: 3px 8px;
	border-radius: 8px;
	background-color: #1a1c1e;
	display: inline-block;
	position: absolute;
	opacity: ${props => (props.$isVisible ? 1 : 0)};
	margin-top: -32px;
	z-index: 10;
	transition: opacity 0.3s ease-in;
`;

const HelpButton = styled.a`
	background-color: #e2e2e2;
	width: 40px;
	height: 40px;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const HelpButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
`;
