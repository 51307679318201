import React, { SVGProps } from "react";

export function IconKeypad(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
			<path
				d="M13 20.313a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.875zm0-6.5a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.875zm0-6.5a2.437 2.437 0 1 0 0 4.874 2.437 2.437 0 0 0 0-4.874zm0-6.5a2.437 2.437 0 1 0 0 4.874 2.437 2.437 0 0 0 0-4.875zm6.5 13a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.875zm0-6.5a2.437 2.437 0 1 0 0 4.874 2.437 2.437 0 0 0 0-4.874zm0-6.5a2.437 2.437 0 1 0 0 4.874 2.437 2.437 0 0 0 0-4.875zm-13 13a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.875zm0-6.5a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.876zm0-6.5a2.438 2.438 0 1 0 0 4.875 2.438 2.438 0 0 0 0-4.875z"
				fill={props.color ?? "#81C241"}
			/>
		</svg>
	);
}
