export interface Currency {
	name: string;
	iso_numeric: string;
	iso_alpha: string;
	decimal_number: number;
	symbol: string;
}

export type CurrencyIdentifier = keyof typeof Currencies;

export const Currencies: { [id: string]: Currency } = {
	ALL: {
		name: "Lek",
		iso_numeric: "008",
		iso_alpha: "ALL",
		decimal_number: 2,
		symbol: "ALL",
	},
	DZD: {
		name: "Algerian Dinar",
		iso_numeric: "012",
		iso_alpha: "DZD",
		decimal_number: 2,
		symbol: "DZD",
	},
	ARS: {
		name: "Argentine Peso",
		iso_numeric: "032",
		iso_alpha: "ARS",
		decimal_number: 2,
		symbol: "ARS",
	},
	AUD: {
		name: "Australian Dollar",
		iso_numeric: "036",
		iso_alpha: "AUD",
		decimal_number: 2,
		symbol: "AUD",
	},
	BSD: {
		name: "Bahamian Dollar",
		iso_numeric: "044",
		iso_alpha: "BSD",
		decimal_number: 2,
		symbol: "BSD",
	},
	BHD: {
		name: "Bahraini Dinar",
		iso_numeric: "048",
		iso_alpha: "BHD",
		decimal_number: 3,
		symbol: "BHD",
	},
	BDT: {
		name: "Taka",
		iso_numeric: "050",
		iso_alpha: "BDT",
		decimal_number: 2,
		symbol: "Tk",
	},
	AMD: {
		name: "Armenian Dram",
		iso_numeric: "051",
		iso_alpha: "AMD",
		decimal_number: 2,
		symbol: "AMD",
	},
	BBD: {
		name: "Barbados Dollar",
		iso_numeric: "052",
		iso_alpha: "BBD",
		decimal_number: 2,
		symbol: "BBD",
	},
	BMD: {
		name: "Bermudian Dollar",
		iso_numeric: "060",
		iso_alpha: "BMD",
		decimal_number: 2,
		symbol: "BMD",
	},
	BTN: {
		name: "Ngultrum",
		iso_numeric: "064",
		iso_alpha: "BTN",
		decimal_number: 2,
		symbol: "BTN",
	},
	BOB: {
		name: "Boliviano",
		iso_numeric: "068",
		iso_alpha: "BOB",
		decimal_number: 2,
		symbol: "BOB",
	},
	BWP: {
		name: "Pula",
		iso_numeric: "072",
		iso_alpha: "BWP",
		decimal_number: 2,
		symbol: "BWP",
	},
	BZD: {
		name: "Belize Dollar",
		iso_numeric: "084",
		iso_alpha: "BZD",
		decimal_number: 2,
		symbol: "BZD",
	},
	SBD: {
		name: "Solomon Islands Dollar",
		iso_numeric: "090",
		iso_alpha: "SBD",
		decimal_number: 2,
		symbol: "SBD",
	},
	BND: {
		name: "Brunei Dollar",
		iso_numeric: "096",
		iso_alpha: "BND",
		decimal_number: 2,
		symbol: "BND",
	},
	MMK: {
		name: "Kyat",
		iso_numeric: "104",
		iso_alpha: "MMK",
		decimal_number: 2,
		symbol: "MMK",
	},
	BIF: {
		name: "Burundi Franc",
		iso_numeric: "108",
		iso_alpha: "BIF",
		decimal_number: 0,
		symbol: "BIF",
	},
	KHR: {
		name: "Riel",
		iso_numeric: "116",
		iso_alpha: "KHR",
		decimal_number: 2,
		symbol: "KHR",
	},
	CAD: {
		name: "Canadian Dollar",
		iso_numeric: "124",
		iso_alpha: "CAD",
		decimal_number: 2,
		symbol: "CAD",
	},
	CVE: {
		name: "Cabo Verde Escudo",
		iso_numeric: "132",
		iso_alpha: "CVE",
		decimal_number: 2,
		symbol: "CVE",
	},
	KYD: {
		name: "Cayman Islands Dollar",
		iso_numeric: "136",
		iso_alpha: "KYD",
		decimal_number: 2,
		symbol: "KYD",
	},
	LKR: {
		name: "Sri Lanka Rupee",
		iso_numeric: "144",
		iso_alpha: "LKR",
		decimal_number: 2,
		symbol: "LKR",
	},
	CLP: {
		name: "Chilean Peso",
		iso_numeric: "152",
		iso_alpha: "CLP",
		decimal_number: 0,
		symbol: "CLP",
	},
	CNY: {
		name: "Yuan Renminbi",
		iso_numeric: "156",
		iso_alpha: "CNY",
		decimal_number: 2,
		symbol: "CNY",
	},
	COP: {
		name: "Colombian Peso",
		iso_numeric: "170",
		iso_alpha: "COP",
		decimal_number: 2,
		symbol: "COP",
	},
	KMF: {
		name: "Comorian Franc",
		iso_numeric: "174",
		iso_alpha: "KMF",
		decimal_number: 0,
		symbol: "KMF",
	},
	CRC: {
		name: "Colon CostaRica",
		iso_numeric: "188",
		iso_alpha: "CRC",
		decimal_number: 0,
		symbol: "CRC",
	},
	HRK: {
		name: "Croatian Kuna",
		iso_numeric: "191",
		iso_alpha: "HRK",
		decimal_number: 2,
		symbol: "HRK",
	},
	CUP: {
		name: "Cuban Peso",
		iso_numeric: "192",
		iso_alpha: "CUP",
		decimal_number: 2,
		symbol: "CUP",
	},
	CZK: {
		name: "Czech Koruna",
		iso_numeric: "203",
		iso_alpha: "CZK",
		decimal_number: 2,
		symbol: "CZK",
	},
	DKK: {
		name: "Danish Krone",
		iso_numeric: "208",
		iso_alpha: "DKK",
		decimal_number: 2,
		symbol: "DKK",
	},
	DOP: {
		name: "Dominican Peso",
		iso_numeric: "214",
		iso_alpha: "DOP",
		decimal_number: 2,
		symbol: "DOP",
	},
	SVC: {
		name: "El Salvador Colon",
		iso_numeric: "222",
		iso_alpha: "SVC",
		decimal_number: 2,
		symbol: "SVC",
	},
	ETB: {
		name: "Ethiopian Birr",
		iso_numeric: "230",
		iso_alpha: "ETB",
		decimal_number: 2,
		symbol: "ETB",
	},
	ERN: {
		name: "Nakfa",
		iso_numeric: "232",
		iso_alpha: "ERN",
		decimal_number: 2,
		symbol: "ERN",
	},
	FKP: {
		name: "Falkland Islands Pound",
		iso_numeric: "238",
		iso_alpha: "FKP",
		decimal_number: 2,
		symbol: "FKP",
	},
	FJD: {
		name: "Fiji Dollar",
		iso_numeric: "242",
		iso_alpha: "FJD",
		decimal_number: 2,
		symbol: "FJD",
	},
	DJF: {
		name: "Djibouti Franc",
		iso_numeric: "262",
		iso_alpha: "DJF",
		decimal_number: 0,
		symbol: "DJF",
	},
	GMD: {
		name: "Dalasi",
		iso_numeric: "270",
		iso_alpha: "GMD",
		decimal_number: 2,
		symbol: "GMD",
	},
	GIP: {
		name: "Gibraltar Pound",
		iso_numeric: "292",
		iso_alpha: "GIP",
		decimal_number: 2,
		symbol: "GIP",
	},
	GTQ: {
		name: "Quetzal",
		iso_numeric: "320",
		iso_alpha: "GTQ",
		decimal_number: 2,
		symbol: "GTQ",
	},
	GNF: {
		name: "Guinea Franc",
		iso_numeric: "324",
		iso_alpha: "GNF",
		decimal_number: 0,
		symbol: "GNF",
	},
	GYD: {
		name: "Guyana Dollar",
		iso_numeric: "328",
		iso_alpha: "GYD",
		decimal_number: 2,
		symbol: "GYD",
	},
	HTG: {
		name: "Gourde",
		iso_numeric: "332",
		iso_alpha: "HTG",
		decimal_number: 0,
		symbol: "HTG",
	},
	HNL: {
		name: "Lempira",
		iso_numeric: "340",
		iso_alpha: "HNL",
		decimal_number: 2,
		symbol: "HNL",
	},
	HKD: {
		name: "Hong Kong Dollar",
		iso_numeric: "344",
		iso_alpha: "HKD",
		decimal_number: 2,
		symbol: "HKD",
	},
	HUF: {
		name: "Forint",
		iso_numeric: "348",
		iso_alpha: "HUF",
		decimal_number: 2,
		symbol: "HUF",
	},
	ISK: {
		name: "Iceland Krona",
		iso_numeric: "352",
		iso_alpha: "ISK",
		decimal_number: 0,
		symbol: "ISK",
	},
	INR: {
		name: "Indian Rupee",
		iso_numeric: "356",
		iso_alpha: "INR",
		decimal_number: 0,
		symbol: "Rs ",
	},
	IDR: {
		name: "Indonesian rupiah",
		iso_numeric: "360",
		iso_alpha: "IDR",
		decimal_number: 0,
		symbol: "Rp",
	},
	IRR: {
		name: "Iranian Rial",
		iso_numeric: "364",
		iso_alpha: "IRR",
		decimal_number: 2,
		symbol: "IRR",
	},
	IQD: {
		name: "Iraqi Dinar",
		iso_numeric: "368",
		iso_alpha: "IQD",
		decimal_number: 3,
		symbol: "IQD",
	},
	ILS: {
		name: "New Israeli Sheqel",
		iso_numeric: "376",
		iso_alpha: "ILS",
		decimal_number: 2,
		symbol: "ILS",
	},
	JMD: {
		name: "Jamaican Dollar",
		iso_numeric: "388",
		iso_alpha: "JMD",
		decimal_number: 2,
		symbol: "JMD",
	},
	JPY: {
		name: "Yen",
		iso_numeric: "392",
		iso_alpha: "JPY",
		decimal_number: 0,
		symbol: "JPY",
	},
	KZT: {
		name: "Tenge",
		iso_numeric: "398",
		iso_alpha: "KZT",
		decimal_number: 2,
		symbol: "KZT",
	},
	JOD: {
		name: "Jordanian Dinar",
		iso_numeric: "400",
		iso_alpha: "JOD",
		decimal_number: 3,
		symbol: "JOD",
	},
	KES: {
		name: "Shilling Kenyan",
		iso_numeric: "404",
		iso_alpha: "KES",
		decimal_number: 2,
		symbol: "Ksh",
	},
	KPW: {
		name: "North Korean Won",
		iso_numeric: "408",
		iso_alpha: "KPW",
		decimal_number: 2,
		symbol: "KPW",
	},
	KRW: {
		name: "Won",
		iso_numeric: "410",
		iso_alpha: "KRW",
		decimal_number: 0,
		symbol: "KRW",
	},
	KWD: {
		name: "Kuwaiti Dinar",
		iso_numeric: "414",
		iso_alpha: "KWD",
		decimal_number: 3,
		symbol: "KWD",
	},
	KGS: {
		name: "Kyrgyzstani som",
		iso_numeric: "417",
		iso_alpha: "KGS",
		decimal_number: 2,
		symbol: "KGS",
	},
	LAK: {
		name: "Kip",
		iso_numeric: "418",
		iso_alpha: "LAK",
		decimal_number: 2,
		symbol: "LAK",
	},
	LBP: {
		name: "Lebanese Pound",
		iso_numeric: "422",
		iso_alpha: "LBP",
		decimal_number: 2,
		symbol: "LBP",
	},
	LSL: {
		name: "Loti",
		iso_numeric: "426",
		iso_alpha: "LSL",
		decimal_number: 2,
		symbol: "LSL",
	},
	LRD: {
		name: "Liberian Dollar",
		iso_numeric: "430",
		iso_alpha: "LRD",
		decimal_number: 2,
		symbol: "LRD",
	},
	LYD: {
		name: "Libyan Dinar",
		iso_numeric: "434",
		iso_alpha: "LYD",
		decimal_number: 3,
		symbol: "LYD",
	},
	MOP: {
		name: "Pataca",
		iso_numeric: "446",
		iso_alpha: "MOP",
		decimal_number: 2,
		symbol: "MOP",
	},
	MWK: {
		name: "Kwacha",
		iso_numeric: "454",
		iso_alpha: "MWK",
		decimal_number: 2,
		symbol: "MWK",
	},
	MYR: {
		name: "Malaysian Ringgit",
		iso_numeric: "458",
		iso_alpha: "MYR",
		decimal_number: 2,
		symbol: "MYR",
	},
	MVR: {
		name: "Rufiyaa",
		iso_numeric: "462",
		iso_alpha: "MVR",
		decimal_number: 2,
		symbol: "MVR",
	},
	MRO: {
		name: "Ouguiya",
		iso_numeric: "478",
		iso_alpha: "MRO",
		decimal_number: 2,
		symbol: "MRO",
	},
	MRU: {
		name: "Ouguiya",
		iso_numeric: "929",
		iso_alpha: "MRU",
		decimal_number: 2,
		symbol: "MRU",
	},
	MUR: {
		name: "Mauritius Rupee",
		iso_numeric: "480",
		iso_alpha: "MUR",
		decimal_number: 2,
		symbol: "MUR",
	},
	MXN: {
		name: "Mexican Peso",
		iso_numeric: "484",
		iso_alpha: "MXN",
		decimal_number: 2,
		symbol: "MXN",
	},
	MNT: {
		name: "Tugrik",
		iso_numeric: "496",
		iso_alpha: "MNT",
		decimal_number: 2,
		symbol: "MNT",
	},
	MDL: {
		name: "Moldovan Leu",
		iso_numeric: "498",
		iso_alpha: "MDL",
		decimal_number: 2,
		symbol: "MDL",
	},
	MAD: {
		name: "Moroccan Dirham",
		iso_numeric: "504",
		iso_alpha: "MAD",
		decimal_number: 2,
		symbol: "MAD",
	},
	OMR: {
		name: "Rial Omani",
		iso_numeric: "512",
		iso_alpha: "OMR",
		decimal_number: 3,
		symbol: "OMR",
	},
	NAD: {
		name: "Dollar Namibia",
		iso_numeric: "516",
		iso_alpha: "NAD",
		decimal_number: 2,
		symbol: "N$",
	},
	NPR: {
		name: "Nepalese Rupee",
		iso_numeric: "524",
		iso_alpha: "NPR",
		decimal_number: 2,
		symbol: "NPR",
	},
	ANG: {
		name: "Netherlands Antillean Guilder",
		iso_numeric: "532",
		iso_alpha: "ANG",
		decimal_number: 2,
		symbol: "ANG",
	},
	AWG: {
		name: "Aruban Florin",
		iso_numeric: "533",
		iso_alpha: "AWG",
		decimal_number: 2,
		symbol: "AWG",
	},
	VUV: {
		name: "Vatu",
		iso_numeric: "548",
		iso_alpha: "VUV",
		decimal_number: 0,
		symbol: "VUV",
	},
	NZD: {
		name: "New Zealand Dollar",
		iso_numeric: "554",
		iso_alpha: "NZD",
		decimal_number: 2,
		symbol: "NZD",
	},
	NIO: {
		name: "Nicaraguan c\u00f3rdoba",
		iso_numeric: "558",
		iso_alpha: "NIO",
		decimal_number: 2,
		symbol: "C$",
	},
	NGN: {
		name: "Nigeria Naira",
		iso_numeric: "566",
		iso_alpha: "NGN",
		decimal_number: 2,
		symbol: "NGN",
	},
	NOK: {
		name: "Norwegian Krone",
		iso_numeric: "578",
		iso_alpha: "NOK",
		decimal_number: 2,
		symbol: "NOK",
	},
	PKR: {
		name: "Pakistani Rupee",
		iso_numeric: "586",
		iso_alpha: "PKR",
		decimal_number: 0,
		symbol: "Rs ",
	},
	PAB: {
		name: "Balboa",
		iso_numeric: "590",
		iso_alpha: "PAB",
		decimal_number: 2,
		symbol: "PAB",
	},
	PGK: {
		name: "Kina",
		iso_numeric: "598",
		iso_alpha: "PGK",
		decimal_number: 2,
		symbol: "PGK",
	},
	PYG: {
		name: "Guarani",
		iso_numeric: "600",
		iso_alpha: "PYG",
		decimal_number: 0,
		symbol: "PYG",
	},
	PEN: {
		name: "Nuevo Sol",
		iso_numeric: "604",
		iso_alpha: "PEN",
		decimal_number: 2,
		symbol: "PEN",
	},
	PHP: {
		name: "Philippine Peso",
		iso_numeric: "608",
		iso_alpha: "PHP",
		decimal_number: 2,
		symbol: "PHP",
	},
	QAR: {
		name: "Qatari Rial",
		iso_numeric: "634",
		iso_alpha: "QAR",
		decimal_number: 2,
		symbol: "QAR",
	},
	RUB: {
		name: "Russian Ruble",
		iso_numeric: "643",
		iso_alpha: "RUB",
		decimal_number: 2,
		symbol: "RUB",
	},
	RWF: {
		name: "Franc Rwandais",
		iso_numeric: "646",
		iso_alpha: "RWF",
		decimal_number: 2,
		symbol: "RWF",
	},
	SHP: {
		name: "Saint Helena Pound",
		iso_numeric: "654",
		iso_alpha: "SHP",
		decimal_number: 2,
		symbol: "SHP",
	},
	STD: {
		name: "Dobra",
		iso_numeric: "678",
		iso_alpha: "STD",
		decimal_number: 2,
		symbol: "STD",
	},
	SAR: {
		name: "Saudi Riyal",
		iso_numeric: "682",
		iso_alpha: "SAR",
		decimal_number: 2,
		symbol: "SAR",
	},
	SCR: {
		name: "Seychelles Rupee",
		iso_numeric: "690",
		iso_alpha: "SCR",
		decimal_number: 2,
		symbol: "SCR",
	},
	SLL: {
		name: "Leone",
		iso_numeric: "694",
		iso_alpha: "SLL",
		decimal_number: 2,
		symbol: "SLL",
	},
	SGD: {
		name: "Singapore Dollar",
		iso_numeric: "702",
		iso_alpha: "SGD",
		decimal_number: 2,
		symbol: "S$",
	},
	VND: {
		name: "Vietnam dong",
		iso_numeric: "704",
		iso_alpha: "VND",
		decimal_number: 0,
		symbol: "VND",
	},
	SOS: {
		name: "Somali Shilling",
		iso_numeric: "706",
		iso_alpha: "SOS",
		decimal_number: 2,
		symbol: "SOS",
	},
	ZAR: {
		name: "South Africa Rand",
		iso_numeric: "710",
		iso_alpha: "ZAR",
		decimal_number: 2,
		symbol: "R",
	},
	SSP: {
		name: "South Sudanese Pound",
		iso_numeric: "728",
		iso_alpha: "SSP",
		decimal_number: 2,
		symbol: "SSP",
	},
	SZL: {
		name: "Lilangeni",
		iso_numeric: "748",
		iso_alpha: "SZL",
		decimal_number: 2,
		symbol: "SZL",
	},
	SEK: {
		name: "Swedish Krona",
		iso_numeric: "752",
		iso_alpha: "SEK",
		decimal_number: 2,
		symbol: "SEK",
	},
	CHF: {
		name: "Francs Suisse",
		iso_numeric: "756",
		iso_alpha: "CHF",
		decimal_number: 2,
		symbol: "Chf",
	},
	SYP: {
		name: "Syrian Pound",
		iso_numeric: "760",
		iso_alpha: "SYP",
		decimal_number: 2,
		symbol: "SYP",
	},
	THB: {
		name: "Baht",
		iso_numeric: "764",
		iso_alpha: "THB",
		decimal_number: 2,
		symbol: "THB",
	},
	TOP: {
		name: "Pa\u2019anga",
		iso_numeric: "776",
		iso_alpha: "TOP",
		decimal_number: 2,
		symbol: "TOP",
	},
	TTD: {
		name: "Trinidad and Tobago Dollar",
		iso_numeric: "780",
		iso_alpha: "TTD",
		decimal_number: 2,
		symbol: "TTD",
	},
	AED: {
		name: "UAE Dirham",
		iso_numeric: "784",
		iso_alpha: "AED",
		decimal_number: 2,
		symbol: "AED",
	},
	TND: {
		name: "Tunisian Dinar",
		iso_numeric: "788",
		iso_alpha: "TND",
		decimal_number: 3,
		symbol: "TND",
	},
	UGX: {
		name: "Uganda Shilling",
		iso_numeric: "800",
		iso_alpha: "UGX",
		decimal_number: 0,
		symbol: "UGX",
	},
	MKD: {
		name: "Denar",
		iso_numeric: "807",
		iso_alpha: "MKD",
		decimal_number: 2,
		symbol: "MKD",
	},
	EGP: {
		name: "Egyptian Pound",
		iso_numeric: "818",
		iso_alpha: "EGP",
		decimal_number: 2,
		symbol: "£",
	},
	GBP: {
		name: "Pound sterling",
		iso_numeric: "826",
		iso_alpha: "GBP",
		decimal_number: 2,
		symbol: "£",
	},
	TZS: {
		name: "Tanzanian Shilling",
		iso_numeric: "834",
		iso_alpha: "TZS",
		decimal_number: 2,
		symbol: "TZS",
	},
	USD: {
		name: "Dollar",
		iso_numeric: "840",
		iso_alpha: "USD",
		decimal_number: 2,
		symbol: "$",
	},
	UYU: {
		name: "Peso Uruguayo",
		iso_numeric: "858",
		iso_alpha: "UYU",
		decimal_number: 2,
		symbol: "UYU",
	},
	UZS: {
		name: "Uzbekistan Sum",
		iso_numeric: "860",
		iso_alpha: "UZS",
		decimal_number: 2,
		symbol: "UZS",
	},
	WST: {
		name: "Tala",
		iso_numeric: "882",
		iso_alpha: "WST",
		decimal_number: 2,
		symbol: "WST",
	},
	YER: {
		name: "Yemeni Rial",
		iso_numeric: "886",
		iso_alpha: "YER",
		decimal_number: 2,
		symbol: "YER",
	},
	ZMK: {
		name: "Kwacha",
		iso_numeric: "894",
		iso_alpha: "ZMK",
		decimal_number: 0,
		symbol: "ZMK",
	},
	TWD: {
		name: "New Taiwan Dollar",
		iso_numeric: "901",
		iso_alpha: "TWD",
		decimal_number: 2,
		symbol: "TWD",
	},
	CUC: {
		name: "Peso Convertible",
		iso_numeric: "931",
		iso_alpha: "CUC",
		decimal_number: 2,
		symbol: "CUC",
	},
	ZWL: {
		name: "Zimbabwe Dollar",
		iso_numeric: "932",
		iso_alpha: "ZWL",
		decimal_number: 2,
		symbol: "ZWL",
	},
	TMT: {
		name: "Turkmenistan New Manat",
		iso_numeric: "934",
		iso_alpha: "TMT",
		decimal_number: 2,
		symbol: "TMT",
	},
	GHS: {
		name: "Ghana Cedi",
		iso_numeric: "936",
		iso_alpha: "GHS",
		decimal_number: 2,
		symbol: "GHS",
	},
	VEF: {
		name: "Bolivar",
		iso_numeric: "937",
		iso_alpha: "VEF",
		decimal_number: 2,
		symbol: "VEF",
	},
	SDG: {
		name: "Sudanese Pound",
		iso_numeric: "938",
		iso_alpha: "SDG",
		decimal_number: 2,
		symbol: "SDG",
	},
	UYI: {
		name: "Uruguay Peso en Unidades Indexadas (URUIURUI)",
		iso_numeric: "940",
		iso_alpha: "UYI",
		decimal_number: 0,
		symbol: "UYI",
	},
	RSD: {
		name: "Serbian Dinar",
		iso_numeric: "941",
		iso_alpha: "RSD",
		decimal_number: 2,
		symbol: "RSD",
	},
	MZN: {
		name: "Mozambique Metical",
		iso_numeric: "943",
		iso_alpha: "MZN",
		decimal_number: 2,
		symbol: "MZN",
	},
	AZN: {
		name: "Azerbaijanian Manat",
		iso_numeric: "944",
		iso_alpha: "AZN",
		decimal_number: 2,
		symbol: "AZN",
	},
	RON: {
		name: "New Romanian Leu",
		iso_numeric: "946",
		iso_alpha: "RON",
		decimal_number: 2,
		symbol: "RON",
	},
	CHE: {
		name: "WIR Euro",
		iso_numeric: "947",
		iso_alpha: "CHE",
		decimal_number: 2,
		symbol: "CHE",
	},
	CHW: {
		name: "WIR Franc",
		iso_numeric: "948",
		iso_alpha: "CHW",
		decimal_number: 2,
		symbol: "CHW",
	},
	TRY: {
		name: "Turkish Lira",
		iso_numeric: "949",
		iso_alpha: "TRY",
		decimal_number: 2,
		symbol: "TL",
	},
	XAF: {
		name: "CFA Franc BEAC",
		iso_numeric: "950",
		iso_alpha: "XAF",
		decimal_number: 0,
		symbol: "XAF",
	},
	XCD: {
		name: "East Caribbean Dollar",
		iso_numeric: "951",
		iso_alpha: "XCD",
		decimal_number: 2,
		symbol: "XCD",
	},
	XOF: {
		name: "Franc CFA",
		iso_numeric: "952",
		iso_alpha: "XOF",
		decimal_number: 0,
		symbol: "FCFA",
	},
	XPF: {
		name: "CFP Franc",
		iso_numeric: "953",
		iso_alpha: "XPF",
		decimal_number: 0,
		symbol: "XPF",
	},
	XBA: {
		name: "Bond Markets Unit European Composite Unit (EURCO)",
		iso_numeric: "955",
		iso_alpha: "XBA",
		decimal_number: 0,
		symbol: "XBA",
	},
	XBB: {
		name: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
		iso_numeric: "956",
		iso_alpha: "XBB",
		decimal_number: 0,
		symbol: "XBB",
	},
	XBC: {
		name: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
		iso_numeric: "957",
		iso_alpha: "XBC",
		decimal_number: 0,
		symbol: "XBC",
	},
	XBD: {
		name: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
		iso_numeric: "958",
		iso_alpha: "XBD",
		decimal_number: 0,
		symbol: "XBD",
	},
	XAU: {
		name: "Gold",
		iso_numeric: "959",
		iso_alpha: "XAU",
		decimal_number: 0,
		symbol: "XAU",
	},
	XDR: {
		name: "SDR (Special Drawing Right)",
		iso_numeric: "960",
		iso_alpha: "XDR",
		decimal_number: 0,
		symbol: "XDR",
	},
	XAG: {
		name: "Silver",
		iso_numeric: "961",
		iso_alpha: "XAG",
		decimal_number: 0,
		symbol: "XAG",
	},
	XPT: {
		name: "Platinum",
		iso_numeric: "962",
		iso_alpha: "XPT",
		decimal_number: 0,
		symbol: "XPT",
	},
	XTS: {
		name: "Codes specifically reserved for testing purposes",
		iso_numeric: "963",
		iso_alpha: "XTS",
		decimal_number: 0,
		symbol: "XTS",
	},
	XPD: {
		name: "Palladium",
		iso_numeric: "964",
		iso_alpha: "XPD",
		decimal_number: 0,
		symbol: "XPD",
	},
	XUA: {
		name: "ADB Unit of Account",
		iso_numeric: "965",
		iso_alpha: "XUA",
		decimal_number: 0,
		symbol: "XUA",
	},
	ZMW: {
		name: "Zambian Kwacha",
		iso_numeric: "967",
		iso_alpha: "ZMW",
		decimal_number: 2,
		symbol: "ZMW",
	},
	SRD: {
		name: "Surinam Dollar",
		iso_numeric: "968",
		iso_alpha: "SRD",
		decimal_number: 2,
		symbol: "SRD",
	},
	MGA: {
		name: "Malagasy Ariary",
		iso_numeric: "969",
		iso_alpha: "MGA",
		decimal_number: 2,
		symbol: "MGA",
	},
	COU: {
		name: "Unidad de Valor Real",
		iso_numeric: "970",
		iso_alpha: "COU",
		decimal_number: 2,
		symbol: "COU",
	},
	AFN: {
		name: "Afghani",
		iso_numeric: "971",
		iso_alpha: "AFN",
		decimal_number: 2,
		symbol: "AFN",
	},
	TJS: {
		name: "Somoni",
		iso_numeric: "972",
		iso_alpha: "TJS",
		decimal_number: 2,
		symbol: "TJS",
	},
	AOA: {
		name: "Kwanza",
		iso_numeric: "973",
		iso_alpha: "AOA",
		decimal_number: 2,
		symbol: "AOA",
	},
	BYR: {
		name: "Belarussian Ruble",
		iso_numeric: "974",
		iso_alpha: "BYR",
		decimal_number: 0,
		symbol: "BYR",
	},
	BGN: {
		name: "Bulgarian Lev",
		iso_numeric: "975",
		iso_alpha: "BGN",
		decimal_number: 2,
		symbol: "BGN",
	},
	CDF: {
		name: "Franc Congolais",
		iso_numeric: "976",
		iso_alpha: "CDF",
		decimal_number: 0,
		symbol: "CDF",
	},
	BAM: {
		name: "Convertible Mark",
		iso_numeric: "977",
		iso_alpha: "BAM",
		decimal_number: 2,
		symbol: "BAM",
	},
	EUR: {
		name: "Euro",
		iso_numeric: "978",
		iso_alpha: "EUR",
		decimal_number: 2,
		symbol: "\u20ac",
	},
	MXV: {
		name: "Mexican Unidad de Inversion (UDI)",
		iso_numeric: "979",
		iso_alpha: "MXV",
		decimal_number: 2,
		symbol: "MXV",
	},
	UAH: {
		name: "Hryvnia",
		iso_numeric: "980",
		iso_alpha: "UAH",
		decimal_number: 2,
		symbol: "UAH",
	},
	GEL: {
		name: "Lari",
		iso_numeric: "981",
		iso_alpha: "GEL",
		decimal_number: 2,
		symbol: "GEL",
	},
	BOV: {
		name: "Mvdol",
		iso_numeric: "984",
		iso_alpha: "BOV",
		decimal_number: 2,
		symbol: "BOV",
	},
	PLN: {
		name: "Zloty",
		iso_numeric: "985",
		iso_alpha: "PLN",
		decimal_number: 2,
		symbol: "PLN",
	},
	BRL: {
		name: "Brazilian Real",
		iso_numeric: "986",
		iso_alpha: "BRL",
		decimal_number: 2,
		symbol: "BRL",
	},
	CLF: {
		name: "Unidad de Fomento",
		iso_numeric: "990",
		iso_alpha: "CLF",
		decimal_number: 4,
		symbol: "CLF",
	},
	XSU: {
		name: "Sucre",
		iso_numeric: "994",
		iso_alpha: "XSU",
		decimal_number: 0,
		symbol: "XSU",
	},
	USN: {
		name: "US Dollar (Next day)",
		iso_numeric: "997",
		iso_alpha: "USN",
		decimal_number: 2,
		symbol: "USN",
	},
	TAG: {
		name: "TAG$",
		iso_numeric: "999",
		iso_alpha: "TAG",
		decimal_number: 2,
		symbol: "T$",
	},
};
