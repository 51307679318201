import { CacheLoader } from "../../core/cache/cache-loader";
import { CacheStatus } from "../../core/cache/cache-status";
import { logger } from "../../core/logging/logger";
import { Observable } from "../../utils/observable";
import { ObservablePaginated } from "../../utils/observable-paginated";
import { createEmptyPaginatedData, Paginated } from "../../utils/pagination";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { FeaturesManager } from "../features/features-manager";
import { BeneficiaryService } from "./beneficiary-service";
import { Recipient } from "./recipient";

export class BeneficiaryManager {
	public recipients = new ObservablePaginated<Recipient>(createEmptyPaginatedData<Recipient>());
	public cacheStatus = new Observable<CacheStatus | null>(null);

	public loading = new Observable<boolean>(false);
	public error = new Observable<Error | null>(null);

	public loadingMore = new Observable<boolean>(false);
	public loadingMoreError = new Observable<Error | null>(null);

	public constructor(
		private beneficiaryService: BeneficiaryService,
		private authenticationManager: AuthenticationManager,
		private featuresManager: FeaturesManager,
		private cacheLoader: CacheLoader<Paginated<Recipient>>
	) {
		this.authenticationManager.isConnected.onChange.add(async isConnected => {
			if (!isConnected) {
				await this.clear();
			}
		});
	}

	public async load(forceRefresh?: boolean): Promise<void> {
		if (
			this.loading.get() ||
			!(
				this.featuresManager.features.get().beneficiariesManagement ||
				this.featuresManager.features.get().beneficiariesUsage
			)
		) {
			return;
		}
		try {
			this.loading.set(true);
			this.error.set(null);

			const recipients = await this.cacheLoader.load(
				() => this.beneficiaryService.fetchRecipients(undefined),
				forceRefresh
			);
			if (!recipients) {
				throw Error("Failed to fetch recipients");
			}
			await this.updateCacheStatus();
			this.recipients.set(recipients);
		} catch (e) {
			logger.debug("BeneficiaryManager", "Load beneficiary failed", e);
			this.error.set(e);
			this.recipients.set(createEmptyPaginatedData<Recipient>());
		} finally {
			this.loading.set(false);
		}
	}

	public async loadMore(): Promise<void> {
		if (this.loading.get() || this.loadingMore.get()) {
			return;
		}
		try {
			this.loadingMore.set(true);
			this.loadingMoreError.set(null);

			const additional = await this.beneficiaryService.fetchRecipients({
				offset: this.recipients.get().offset + this.recipients.get().limit,
				limit: this.recipients.get().limit,
				sort: this.recipients.get().sort,
			});
			this.recipients.add(additional);
			this.cacheLoader.store(this.recipients.get(), this.cacheStatus.get()?.creation);
		} catch (e) {
			logger.debug("BeneficiaryManager", "Load more recipient failed", e);
			this.loadingMoreError.set(e);
		} finally {
			this.loadingMore.set(false);
		}
	}

	public async requestNewRecipient(name: string, identity: RecipientData) {
		return await this.beneficiaryService.requestNewRecipient(name, identity);
	}

	public async confirmNewRecipient(name: string, identity: RecipientData, otp: string) {
		const result = await this.beneficiaryService.confirmNewRecipient(name, identity, otp);
		await this.load(true);
		return result;
	}

	public async deleteRecipient(id: string) {
		await this.beneficiaryService.deleteRecipient(id);
		await this.load(true);
	}

	public async updateRecipient(id: string, name: string) {
		await this.beneficiaryService.updateRecipient(id, name);
		const recipients = this.recipients.get();
		const index = recipients.items.findIndex(recipient => recipient.id === id);
		const recipient = recipients.items[index];
		if (index >= 0) {
			recipients.items[index] = { ...recipient, name };
			this.recipients.set(recipients);
		}
		await this.load(true);
	}

	private async updateCacheStatus() {
		const cacheStatus = await this.cacheLoader.readStatus();
		this.cacheStatus.set(cacheStatus);
	}

	private async clear() {
		await this.cacheLoader.clear();
		this.recipients.set(createEmptyPaginatedData<Recipient>());
		this.cacheStatus.set(null);
	}
}
