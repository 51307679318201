import React, { useEffect } from "react";
import { FormDateInput, FormDateTimeInput } from "../../../../shared/core/data-forms/form-input-types";
import { DateInput, DateMode } from "../forms/date-input";

interface DateFieldProps {
	input: FormDateInput | FormDateTimeInput;
	onChange?: (value: string) => void;
	disabled?: boolean;
	mode?: DateMode;
}

export const DateField: React.FC<DateFieldProps> = ({ input, onChange, disabled, mode }) => {
	const [value, setValue] = React.useState<string | undefined>(input.value);
	const handleOnChange = (val: string) => {
		setValue(val);
		onChange?.(val);
	};

	useEffect(() => {
		if (input.value !== value) {
			setValue(input.value);
		}
	}, [input.value]);

	return (
		<>
			<DateInput
				onChange={e => handleOnChange?.(e.target.value)}
				value={value ?? ""}
				disabled={disabled}
				required={input.required}
				mode={mode}
			/>
		</>
	);
};
