import React, { SVGProps } from "react";

export function DocumentIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 26 26" {...props}>
			<path
				fillRule="nonzero"
				d="M7.571 3.875h7.551c.34 0 .67.12.929.34l4.565 3.861c.323.273.509.675.509 1.098v12.03c0 1.717-.02 1.838-1.78 1.838H7.571c-1.76 0-1.78-.121-1.78-1.837V5.712c0-1.716.02-1.837 1.78-1.837zM8.667 12.5c-.53 0-.959.43-.959.958 0 .53.43.959.959.959h6.708c.53 0 .958-.43.958-.959 0-.529-.429-.958-.958-.958H8.667zm0 3.833c-.53 0-.959.43-.959.959 0 .529.43.958.959.958h2.875c.529 0 .958-.43.958-.958 0-.53-.43-.959-.958-.959H8.667z"
			/>
		</svg>
	);
}
