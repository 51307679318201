import { useEffect, useLayoutEffect, useState } from "react";

export const useMountAnimation = (visible: boolean, mountAnimation: () => void, unmountAnimation?: () => void) => {
	const [shouldRender, setShouldRender] = useState(visible);

	useEffect(() => {
		if (shouldRender) {
			mountAnimation && mountAnimation();
		}
		// Does not support mount animation change between renders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRender]);
	useLayoutEffect(() => {
		if (visible) {
			setShouldRender(true);
		} else {
			unmountAnimation && unmountAnimation();
		}
		// Does not support unmount animation change between renders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);
	const onTransitionEnd = () => {
		if (!visible) {
			setShouldRender(false);
		}
	};
	return { shouldRender, onTransitionEnd };
};
