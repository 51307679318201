import { AsYouType, CountryCode } from "libphonenumber-js";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Amount, switchCurrency } from "../../../../shared/core/amount/amount";
import { PrefixedSelectInput, PrefixedSelectInputType } from "../forms/prefixed-select-input";
import { PrefixContainer, PrefixedTextInput } from "../forms/prefixed-text-input";

import { Country, countries, countriesArray } from "../../../../shared/assets/countries";
import { Currencies } from "../../../../shared/core/currency/currency";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../shared/core/service/services";
import { Acceptor } from "../../../../shared/domains/acceptors/acceptor";
import { Account } from "../../../../shared/domains/account/account";
import { useAccounts } from "../../../../shared/domains/account/use-accounts";
import { useSelectableCurrencies } from "../../../../shared/domains/account/use-selectable-currencies";
import { Recipient, TransferRecipient } from "../../../../shared/domains/recipients/recipient";
import { TransferMode } from "../../../../shared/domains/transactions/cash-transfer/transfer-mode";
import {
	AccountOrRecipient,
	PaymentAcceptedAddress,
	PaymentAddress,
	PaymentIdentificationMode,
	PaymentNetwork,
} from "../../../../shared/domains/transactions/customer-instruction";
import { useCustomerInstruction } from "../../../../shared/domains/transactions/use-customer-instuction";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { AccountButton, SourceAndDestinationEnum } from "../../screen/transfer/components/source-and-destination";
import { theme } from "../../styles/theme";
import { PrimaryButton } from "../buttons/primary-button";
import { AmountInput } from "../forms/amount-input";
import { SelectInput } from "../forms/select-input";
import { TextInput } from "../forms/text-input";
import { Selector } from "../selector";
import { ChevronIcon } from "../svg/chevron";
import { TransferModeSelection } from "./transfer-mode-selection";

interface AmountSelectionProps {
	children?: React.ReactNode;
	className?: string;
	recipient: Recipient;
	acceptor?: Acceptor;
	loading?: boolean;
	isP2PSimpleTransfer?: boolean;
	isOpenLoop?: boolean;
	sourceAccount?: Account | null;
	destinationAccountOrRecipient?: AccountOrRecipient | null;
	editingSourceAndDestination?: SourceAndDestinationEnum | null;
	onOpenSourceAccountSelection?: () => void;
	onClearDestinationAccountOrRecipientSelection?: () => void;
	onOpenDestinationAccountOrRecipientSelection?: (
		needCreditorPhoneNumber?: boolean,
		needCreditorAccountNumber?: boolean,
		needCreditorAddress?: boolean
	) => void;
	submitAmount: (
		recipient: TransferRecipient | AccountOrRecipient,
		amount: Amount,
		sourceAccounts: Account[],
		sourceAccount?: Account | null,
		paymentNetwork?: PaymentNetwork | null,
		transferMode?: TransferMode,
		label?: string,
		creditorAddress?: PaymentAddress
	) => void;
}

export const AmountSelection = (props: AmountSelectionProps) => {
	const accounts = useAccounts();
	const selectableCurrencies = useSelectableCurrencies(accounts);
	const configuration = useObservable(configurationManager.configuration);
	const defaultCountry = useObservable(configurationManager.defaultCountry);
	const { paymentNetworks, getPaymentNetworks } = useCustomerInstruction();
	const [selectedPaymentNetwork, setSelectedPaymentNetwork] = useState<PaymentNetwork | null>(null);
	const [selectedCountry, setSelectedCountry] = useState<Country>(countriesArray[0]);
	const isOpenLoopTransfer = props.isOpenLoop ?? false;

	const [currency, setCurrency] = useState<string | number | null>(null);
	const [transferMode, setTransferMode] = useState<TransferMode>(TransferMode.AccountTransfer);
	const [amount, setAmount] = useState<Amount | null>(null);
	const { formatMessage } = useIntl();
	const [description, setDescription] = useState("");
	const [recipientData, setRecipientData] = useState<Recipient>({ ...props.recipient });
	const [creditorAddress, setCreditorAddress] = useState<PaymentAddress>({
		structuredAddress: {
			buildingNumber: "",
			streetName: "",
			postCode: "",
			townName: "",
		},
		country: "",
	});
	const [countryCode, setCountryCode] = useState(defaultCountry);
	const [dataError, setDataError] = useState<undefined | string>(undefined);
	const hasError = isDefined(dataError);
	const needCreditorPhoneNumber =
		isOpenLoopTransfer &&
		selectedPaymentNetwork?.identificationMode.includes(PaymentIdentificationMode.PHONE) &&
		selectedPaymentNetwork?.identificationMode.length === 1;
	const needCreditorAccountNumber =
		isOpenLoopTransfer &&
		selectedPaymentNetwork?.identificationMode.includes(PaymentIdentificationMode.ACCOUNT_NUMBER) &&
		selectedPaymentNetwork?.identificationMode.length === 1;
	const needCreditorAddress = isOpenLoopTransfer
		? selectedPaymentNetwork?.acceptedAddresses !== PaymentAcceptedAddress.NO_ADDRESS
		: false;

	useAsyncEffect(async () => {
		if (!isOpenLoopTransfer) {
			return;
		}
		getPaymentNetworks();
	}, []);

	useEffect(() => {
		if (!selectedPaymentNetwork && paymentNetworks.length > 0) {
			setSelectedPaymentNetwork(paymentNetworks[0]);
		}
	}, [paymentNetworks]);

	useEffect(() => {
		if (isOpenLoopTransfer && selectedPaymentNetwork) {
			props.onClearDestinationAccountOrRecipientSelection?.();
		}
	}, [selectedPaymentNetwork]);

	useEffect(() => {
		if (!isOpenLoopTransfer) {
			let initialCurrency = currency;
			if (accounts.length > 0 && !isDefined(currency)) {
				initialCurrency = accounts[0]?.balance.currency;
				setCurrency(initialCurrency);
			}
			if (!isDefined(amount) && isDefined(initialCurrency)) {
				setAmount(new Amount(0, initialCurrency));
			}
		}
	}, [accounts, currency, amount]);

	useEffect(() => {
		if (props.sourceAccount) {
			const currency = props.sourceAccount.balance.currency;
			setCurrency(currency);
			setAmount(new Amount(0, currency));
		}
	}, [props.sourceAccount]);

	useEffect(() => {
		if (!isOpenLoopTransfer && isDefined(amount) && currency && currency !== amount.currency) {
			setAmount(switchCurrency(amount, currency));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency]);

	useEffect(() => {
		if (configuration.countries) {
			const country = countriesArray.find(c => c["alpha-2"] === configuration.countries?.[0]);
			if (country) {
				handleSetSelectedCountry(country);
			}
		}
	}, [configuration.countries]);

	const handleSubmit = () => {
		if (isDefined(amount) && canSubmit) {
			props.submitAmount(
				isOpenLoopTransfer
					? (props.destinationAccountOrRecipient as AccountOrRecipient)
					: {
							...recipientData,
							phone: countries[countryCode].phoneCode + recipientData.phone?.split(" ").join(""),
					  },
				amount,
				accounts,
				isOpenLoopTransfer ? props.sourceAccount : undefined,
				selectedPaymentNetwork,
				transferMode ?? undefined,
				description,
				needCreditorAddress ? creditorAddress : undefined
			);
		}
	};

	const editPhone = (phoneNumber: string) => {
		const nationalNumber = "+" + countries[countryCode].phoneCode;
		const internationalPhoneNumber = phoneNumber.startsWith(nationalNumber)
			? phoneNumber
			: nationalNumber + phoneNumber;
		const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
		setRecipientData({ ...recipientData, phone: formatted });
	};

	const canSubmit = useMemo(() => {
		const isValueSet = !!amount && amount.value > 0;
		if (isOpenLoopTransfer) {
			return (
				isValueSet &&
				!!props.sourceAccount &&
				!!props.destinationAccountOrRecipient &&
				!!selectedPaymentNetwork &&
				(needCreditorAddress ? !!creditorAddress.country : true)
			);
		}
		return isValueSet;
	}, [props.sourceAccount, props.destinationAccountOrRecipient, amount, creditorAddress, needCreditorAddress]);

	const handleSetSelectedCountry = (country: Country) => {
		setSelectedCountry(country);
		setCreditorAddress({
			...creditorAddress,
			...(country && { country: country["iso-numeric"] }),
		});
	};

	return (
		<Container className={props.className}>
			<LeftColumn>
				{props.isP2PSimpleTransfer ? (
					<>
						<InputLabel>{formatMessage("amountSelection.simpleTransferNameLabel")}</InputLabel>
						<TextInput
							value={props.recipient?.name || recipientData.name}
							onChange={event => setRecipientData({ ...recipientData, name: event.target.value })}
							placeholder={formatMessage("amountSelection.simpleTransferNamePlaceholder")}
						/>
						<InputLabel>{formatMessage("amountSelection.simpleTransferPhoneLabel")}</InputLabel>
						<CountrySelectInput
							onChange={selectedCountryCode => setCountryCode(selectedCountryCode)}
							innerId="country-select-field"
							options={configuration.countries}
							itemRenderer={code => countries[code].englishName}
							value={countryCode}
							prefixElement={countries[countryCode] && <FlagIcon src={countries[countryCode].flag} />}
						/>
						<PhoneInput
							placeholder={formatMessage("amountSelection.simpleTransferPhonePlaceholder")}
							prefixElement={<PhoneCode>+{countries[countryCode].phoneCode}</PhoneCode>}
							type="tel"
							value={props.recipient?.phone || recipientData.phone}
							onChange={event => {
								if (hasError) {
									setDataError(undefined);
								}
								editPhone(event.target.value);
							}}
							hasError={hasError}
							errorMessage={formatMessage("common.invalidPhoneNumber")}
						/>
					</>
				) : !!isOpenLoopTransfer ? (
					<>
						{paymentNetworks.length > 1 ? (
							<>
								<InputLabel>{formatMessage("amountSelection.network")}*</InputLabel>
								<SelectInput
									style={{ width: "100%" }}
									innerId="payment-network"
									required
									options={paymentNetworks}
									value={selectedPaymentNetwork}
									onChange={value => setSelectedPaymentNetwork(value)}
									itemRenderer={v => v?.name ?? ""}
									hasError={hasError}
								/>
							</>
						) : null}
						<InputLabel>{formatMessage("amountSelection.since")}*</InputLabel>
						{props.sourceAccount ? (
							<AccountButton
								account={props.sourceAccount as AccountOrRecipient}
								onClick={props.onOpenSourceAccountSelection}
							/>
						) : (
							<SourceAndDestinationButton
								active={props.editingSourceAndDestination === SourceAndDestinationEnum.Source}
								onClick={props.onOpenSourceAccountSelection}>
								{formatMessage("amountSelection.choose_source_account")}
								<ChevronIcon width={18} height={18} style={{}} />
							</SourceAndDestinationButton>
						)}
					</>
				) : null}
				{!!isOpenLoopTransfer ? null : (
					<>
						<InputLabel>{formatMessage("amountSelection.currencyLabel")}</InputLabel>
						<Selector
							options={Array.from(selectableCurrencies).map(currency => ({
								value: currency,
								text: Currencies[currency].symbol,
								key: currency.toString(),
							}))}
							value={currency}
							onChange={c => setCurrency(c)}
							size="S"
						/>
					</>
				)}
				<InputLabel>{formatMessage("amountSelection.amountLabel")}*</InputLabel>
				<AmountInput disabled={!currency} variant="big" value={amount} onChange={setAmount} />
				<InputLabel>{formatMessage("amountSelection.descriptionLabel")}</InputLabel>
				<TextInput value={description} onChange={event => setDescription(event.target.value)} />
				{!!isOpenLoopTransfer ? (
					<>
						<InputLabel>{formatMessage("amountSelection.to")}*</InputLabel>
						{props.destinationAccountOrRecipient ? (
							<AccountButton
								account={props.destinationAccountOrRecipient}
								onClick={() =>
									props.onOpenDestinationAccountOrRecipientSelection?.(
										needCreditorPhoneNumber,
										needCreditorAccountNumber,
										needCreditorAddress
									)
								}
							/>
						) : (
							<SourceAndDestinationButton
								disabled={!props.sourceAccount}
								active={props.editingSourceAndDestination === SourceAndDestinationEnum.Destination}
								onClick={() =>
									props.onOpenDestinationAccountOrRecipientSelection?.(
										needCreditorPhoneNumber,
										needCreditorAccountNumber,
										needCreditorAddress
									)
								}>
								{formatMessage("amountSelection.choose_destination_account")}
								<ChevronIcon width={18} height={18} />
							</SourceAndDestinationButton>
						)}
					</>
				) : null}
				{!props.acceptor && !props.isP2PSimpleTransfer && !isOpenLoopTransfer && (
					<TransferModeSelection recipient={props.recipient} transferTypeSelected={setTransferMode} />
				)}
				{needCreditorAddress && (
					<>
						<InputLabel>{formatMessage("amountSelection.creditorAddress.addressLabel")}</InputLabel>
						<StyledAddressLine>
							<AddressTextInputSmall
								value={creditorAddress.structuredAddress?.buildingNumber}
								onChange={event =>
									setCreditorAddress({
										...creditorAddress,
										structuredAddress: {
											...creditorAddress.structuredAddress,
											buildingNumber: event.target.value,
										},
									})
								}
								placeholder={formatMessage("amountSelection.creditorAddress.buildingNumber")}
							/>
							<AddressTextInput
								value={creditorAddress.structuredAddress?.streetName}
								onChange={event =>
									setCreditorAddress({
										...creditorAddress,
										structuredAddress: {
											...creditorAddress.structuredAddress,
											streetName: event.target.value,
										},
									})
								}
								placeholder={formatMessage("amountSelection.creditorAddress.streetName")}
							/>
						</StyledAddressLine>
						<StyledAddressLine>
							<AddressTextInput
								value={creditorAddress.structuredAddress?.postCode}
								onChange={event =>
									setCreditorAddress({
										...creditorAddress,
										structuredAddress: {
											...creditorAddress.structuredAddress,
											postCode: event.target.value,
										},
									})
								}
								placeholder={formatMessage("amountSelection.creditorAddress.postCode")}
							/>
						</StyledAddressLine>
						<StyledAddressLine>
							<AddressTextInput
								value={creditorAddress.structuredAddress?.townName}
								onChange={event =>
									setCreditorAddress({
										...creditorAddress,
										structuredAddress: {
											...creditorAddress.structuredAddress,
											townName: event.target.value,
										},
									})
								}
								placeholder={formatMessage("amountSelection.creditorAddress.townName")}
							/>
						</StyledAddressLine>
						<StyledAddressLine>
							<SelectInput
								options={countriesArray}
								value={selectedCountry}
								itemRenderer={v => (v ? v.name : "-")}
								innerId="creditor-country"
								onChange={country => handleSetSelectedCountry(country)}
							/>
						</StyledAddressLine>
					</>
				)}
				<ButtonContainer>
					<NextButton size="S" disabled={!canSubmit} onClick={handleSubmit} showSpinner={props.loading}>
						<ButtonLabel>{formatMessage("amountSelection.sendButton")}</ButtonLabel>
					</NextButton>
				</ButtonContainer>
			</LeftColumn>
			{!!props.children ? <RightColumn>{props.children}</RightColumn> : null}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	gap: 30px;
`;

const LeftColumn = styled.div`
	position: sticky;
	top: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const InputLabel = styled.span`
	${theme.mediumText};
	font-size: 15px;
	margin-top: 30px;
	margin-bottom: 5px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;
const NextButton = styled(PrimaryButton)``;

const ButtonLabel = styled.span<{ disabled?: boolean }>`
	color: ${props => (props.disabled ? "#b1b1b1" : "#ffffff")};
`;

const inputStyle = css`
	width: 100%;
	margin-bottom: 25px;
`;

const CountrySelectInput = styled<PrefixedSelectInputType<CountryCode>>(PrefixedSelectInput)`
	${inputStyle};
`;

const FlagIcon = styled.img`
	width: 14px;
	height: 14px;
	border-radius: 7px;
`;

const PhoneInput = styled(PrefixedTextInput)`
	${PrefixContainer} {
		width: 80px;
	}
`;

const PhoneCode = styled.span`
	font-size: 0.875rem;
`;

const SourceAndDestinationButton = styled("button")<{ disabled?: boolean; active: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	appearance: none;
	border: none;
	height: 48px;
	box-shadow: none;
	background-color: white;
	text-align: left;
	padding: 15px;
	border-radius: 10px;
	cursor: pointer;
	${theme.bodyBlackRegular};
	${props =>
		props.active &&
		css`
			color: ${theme.mainColor()};
			svg {
				fill: ${theme.mainColor()};
			}
		`}
	${props =>
		props.disabled &&
		css`
			opacity: 0.5;
			:hover {
				cursor: not-allowed;
			}
		`}
`;

const StyledAddressLine = styled.div`
	display: flex;
	flex-direction: row;
	gap: 15px;
	margin-bottom: 15px;
`;

const AddressTextInput = styled(TextInput)`
	flex: 1;
`;

const AddressTextInputSmall = styled(AddressTextInput)`
	max-width: 100px;
`;
