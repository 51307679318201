import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { isDefined } from "../../../../../shared/utils/assert";
import { useRTL } from "../../../../domain/language/use-rtl";
import { theme } from "../../../styles/theme";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { PenIcon } from "../../../common/svg/pen-icon";

interface InformationSectionProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	value?: string;
	isPhone?: boolean;
	children?: React.ReactNode;
	onEdit?: () => void;
}
export const InformationSection = (props: InformationSectionProps) => {
	const { children, label, value, isPhone, onEdit, ...rest } = props;
	const { isRTL } = useRTL();

	if (!isDefined(value)) {
		return null;
	}
	return (
		<InformationContainer {...rest}>
			<StyledValueContainer>
				<InformationLabel>{label}</InformationLabel>
				{onEdit && (
					<RoundedSquareButton size={28} onClick={onEdit}>
						<PenIcon width={18} fill="#000000" />
					</RoundedSquareButton>
				)}
			</StyledValueContainer>
			{!!children ? (
				children
			) : (
				<InformationValue $isRTL={isRTL} $isPhone={isPhone}>
					{value}
				</InformationValue>
			)}
		</InformationContainer>
	);
};
const InformationContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const InformationLabel = styled.div`
	${theme.mediumText};
	color: #000000;
	font-size: 0.9375rem;
`;

const InformationValue = styled.span<{ $isRTL: boolean; $isPhone?: boolean }>`
	font-size: 0.9375rem;
	text-align: ${props => (props.$isRTL ? `right` : `left`)};
	color: #b1b1b1;
	direction: ${props => (!props.$isPhone && props.$isRTL ? `rtl` : `ltr`)};
`;

const StyledValueContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-bottom: 10px;
`;
