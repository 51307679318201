import { logger } from '../logging/logger';
import { ApiService } from '../net/api-service';
import { AutocompleteValues } from './autocomplete-values';

export class AutocompleteService {
	public constructor(private apiService: ApiService) { }

	public async fieldAutocomplete(inputId: string, userInput: string) {
		try {
			const response = await this.apiService.instance.get<AutocompleteValues>(`/autocomplete/${inputId}`, {
				params: { userInput: userInput },
			});
			return response.data;
		} catch (e) {
			logger.debug("AutocompleteService", "Autocomplete values search failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
