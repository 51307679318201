import React, { SVGProps } from "react";

export function PadlockUnlock(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" {...props}>
			<path
				fillRule="nonzero"
				d="M12.75 7.887c-.162-.162-.358-.243-.59-.243H5.794V4.876c0-.612.217-1.133.65-1.566.432-.433.954-.649 1.565-.649.612 0 1.134.216 1.566.649.433.433.649.955.649 1.566 0 .15.055.28.164.39.11.109.24.164.39.164h.553c.15 0 .28-.055.39-.165.11-.11.164-.24.164-.389 0-1.067-.38-1.98-1.138-2.738C9.988 1.379 9.076 1 8.008 1c-1.067 0-1.98.38-2.738 1.138-.759.758-1.138 1.67-1.138 2.738v2.768h-.277c-.23 0-.426.081-.588.243-.161.161-.242.357-.242.588v4.983c0 .231.08.427.242.589.162.161.358.242.588.242h8.306c.23 0 .427-.08.588-.242.161-.162.242-.358.242-.589V8.475c0-.23-.08-.427-.242-.588z"
			/>
		</svg>
	);
}

export function PadlockLocked(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" {...props}>
			<path
				fillRule="nonzero"
				d="M12.166 7.644c.23 0 .427.081.589.243.161.161.242.357.242.588v4.983c0 .231-.081.427-.242.589-.162.161-.358.242-.589.242H3.857c-.23 0-.427-.08-.589-.242-.161-.162-.242-.358-.242-.589V8.475c0-.23.08-.427.242-.588.162-.162.358-.243.589-.243h.277V4.876c0-1.067.38-1.98 1.138-2.738C6.031 1.379 6.944 1 8.012 1h-.003.003c1.064 0 1.977.38 2.736 1.138.759.758 1.138 1.67 1.138 2.738v2.768zm-1.941-2.768c0-.612-.217-1.133-.65-1.566-.432-.432-.954-.648-1.564-.649-.612 0-1.133.217-1.566.649-.433.433-.65.954-.65 1.566v2.768h4.43V4.876z"
			/>
		</svg>
	);
}
