import React, { HTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import { Config } from "../../../shared/core/config/config";
import { useIntl } from "../../../shared/core/i18n/use-intl";
import { useRTL } from "../../domain/language/use-rtl";
import { theme } from "../styles/theme";
import { UIConstants } from "../styles/uiConstants";
import { DefaultExternalLink } from "./buttons/default-button";

export interface ExternalSitesProps extends HTMLAttributes<HTMLDivElement> {
	centerWhenSmall?: boolean;
}

export const ExternalSites = (props: ExternalSitesProps) => {
	const { centerWhenSmall } = props;
	const { formatMessage } = useIntl();

	interface ExternalLink {
		url: string | undefined;
		question: string;
		linkLabel: string;
	}

	const externalLinks = useMemo(() => {
		const links: ExternalLink[] = [];
		if (Config.COMPANY_PORTAL_URL) {
			links.push({
				url: Config.COMPANY_PORTAL_URL,
				question: formatMessage("authScreen.goToCompanyPortalQuestion"),
				linkLabel: formatMessage("authScreen.goToCompanyPortalLink"),
			});
		}
		if (Config.PARTNERS_PORTAL_URL) {
			links.push({
				url: Config.PARTNERS_PORTAL_URL,
				question: formatMessage("authScreen.goToPartnersPortalQuestion"),
				linkLabel: formatMessage("authScreen.goToPartnersPortalLink"),
			});
		}
		if (Config.AGENT_PORTAL_URL) {
			links.push({
				url: Config.AGENT_PORTAL_URL,
				question: formatMessage("authScreen.goToAgentPortalQuestion"),
				linkLabel: formatMessage("authScreen.goToAgentPortalLink"),
			});
		}
		return links;
	}, [formatMessage]);

	const { isRTL } = useRTL();

	return (
		<ExternalSiteContainer centerWhenSmall={centerWhenSmall}>
			{externalLinks.map((externalLink, index) => (
				<div key={index}>
					<GoToExternalSiteQuestion $isRTL={isRTL} centerWhenSmall={centerWhenSmall}>
						{externalLink.question}
					</GoToExternalSiteQuestion>
					<GoToExternalSiteLink href={externalLink.url} centerWhenSmall={centerWhenSmall}>
						{externalLink.linkLabel}
					</GoToExternalSiteLink>
				</div>
			))}
		</ExternalSiteContainer>
	);
};

const ExternalSiteContainer = styled.div<{ centerWhenSmall?: boolean }>`
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;
	width: 100%;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		align-items: ${props => (props.centerWhenSmall ? `center` : `flex-start`)};
		margin: 0;
	}
`;

const GoToExternalSiteQuestion = styled.span<{ $isRTL: boolean; centerWhenSmall?: boolean }>`
	${theme.text};
	text-align: ${props => (props.$isRTL ? `left` : `right`)};
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		text-align: ${props => (props.centerWhenSmall ? `center` : props.$isRTL ? `left` : `right`)};
	}
`;

const GoToExternalSiteLink = styled(DefaultExternalLink)<{ centerWhenSmall?: boolean }>`
	${theme.boldText};
	color: ${theme.mainColor};
	justify-content: flex-end;

	margin-bottom: 20px;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		justify-content: ${props => (props.centerWhenSmall ? `center` : `flex-start`)};
	}
`;
