import { DefaultCacheLoader } from "../../core/cache/default-cache-loader";
import { Paginated } from "../../utils/pagination";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { ClientManager } from "../client/client-manager";
import { AuthorizationHold } from "./authorization-hold";
import { AuthorizationHoldLoader } from "./authorization-hold-loader";
import { AuthorizationHoldService } from "./authorization-hold-service";

export class AuthorizationHoldManager {
	public defaultAuthorizationHoldLoader: AuthorizationHoldLoader;
	public authorizationHoldLoaders = new Map<string, AuthorizationHoldLoader>();

	public constructor(
		private authorizationHoldService: AuthorizationHoldService,
		private authenticationManager: AuthenticationManager,
		private clientManager: ClientManager,
		private cacheLoader: DefaultCacheLoader<Paginated<AuthorizationHold>>
	) {
		this.defaultAuthorizationHoldLoader = new AuthorizationHoldLoader(
			null,
			this.authorizationHoldService,
			this.cacheLoader
		);
		this.authenticationManager.isConnected.onChange.add(async isConnected => {
			if (!isConnected) {
				await this.clear();
			}
		});
		this.clientManager.onLanguageChange.add(async () => {
			await this.clear();
		});
	}

	public getDefaultLoader(): AuthorizationHoldLoader {
		return this.defaultAuthorizationHoldLoader;
	}

	public getTransactionsLoader(accountId: string): AuthorizationHoldLoader {
		let cache = this.authorizationHoldLoaders.get(accountId);
		if (!cache) {
			cache = new AuthorizationHoldLoader(accountId, this.authorizationHoldService, this.cacheLoader);
			this.authorizationHoldLoaders.set(accountId, cache);
		}
		return cache;
	}

	public async clear() {
		for (const accountId of this.authorizationHoldLoaders.keys()) {
			await this.authorizationHoldLoaders.get(accountId)?.clear();
		}
	}
}
