import { BasicStorage } from "../../core/cache/basic-storage";
import { SecuredCookiesService } from "../../core/net/secured-cookies-service";
import { Observable } from "../../utils/observable";
import { TokenResultDto } from "../authentication/oauth-service";
import { AccessToken, Session } from "../authentication/session";
import { SessionBuilder } from "../authentication/session-builder";
import { FeaturesManager } from "../features/features-manager";
import { ServiceDomainService } from "./service-domain-service";

export class ServiceDomainManager {
	private sessionBuilder = new SessionBuilder(this.securedCookiesService);
	public session = new Observable<Session>(this.sessionBuilder.build());

	public constructor(
		private serviceDomainService: ServiceDomainService,
		private featuresManager: FeaturesManager,
		private storage: BasicStorage<string>,
		private securedCookiesService?: SecuredCookiesService
	) {}

	public isServiceDomainTokenValid = new Observable<boolean>(false);
	public serviceDomainToken = new Observable<TokenResultDto | null>(null);

	public async getToken() {
		try {
			const authorizeResult = await this.serviceDomainService.getToken();
			this.serviceDomainToken.set(authorizeResult);
			this.isServiceDomainTokenValid.set(true);
			// const tokenResult = AuthenticationService.tokenResultFromDto(authorizeResult);
			// this.session.set(this.sessionBuilder.build(tokenResult));
			// this.featuresManager.addServiceDomainFeatures(this.session.get());
			// const session = this.session.get();
			// await this.storage.store(JSON.stringify(session), SESSION_KEYSTORE_KEY);
			return authorizeResult;
		} catch (e) {
			throw e;
		}
	}

	public getAccessToken(): AccessToken | null {
		const token = this.serviceDomainToken.get() ?? null;
		if (!token) {
			return null;
		}
		return {
			type: token.token_type,
			value: token.access_token,
			expirationDate: new Date(token.expires_in),
		};
	}
}
