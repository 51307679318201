import { AnalyticsService } from "../../../shared/core/analytics/analytics-service";

export class WebAnalyticsService implements AnalyticsService {
	public recordError(e: Error) {
		
	}

	public tagScreen(screenName: string | null) {
		
	}

	public event(eventName: string, params?: { [key: string]: string | number | boolean }) {
		
	}
}
