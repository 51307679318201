import { useCallback, useMemo } from "react";

import { Acceptor } from "./acceptor";
import { acceptorManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";

export interface Acceptors {
	data: Acceptor[];
	load: (latitude: number, longitude: number, supportDedicatedTransfers: boolean, limit?: number, radius?: number) => void;
	loading: boolean;
	error: Error | null;
	canLoadMore: boolean;
	loadMore: () => void;
	loadingMore: boolean;
	loadingMoreError: Error | null;
}

export function useAcceptors(): Acceptors {
	const data = useObservable(acceptorManager.acceptors);
	const loading = useObservable(acceptorManager.loading);
	const error = useObservable(acceptorManager.error);
	const loadingMore = useObservable(acceptorManager.loadingMore);
	const loadingMoreError = useObservable(acceptorManager.loadingMoreError);

	const canLoadMore = useMemo(() => data.offset + data.limit < data.total, [data.offset, data.limit, data.total]);

	const load = useCallback(async (latitude: number, longitude: number, supportDedicatedTransfers: boolean, limit?: number, radius?: number) => {
		await acceptorManager.load(latitude, longitude, supportDedicatedTransfers, limit, radius);
	}, []);

	const loadMore = useCallback(async () => {
		await acceptorManager.loadMore();
	}, []);

	return {
		data: data.items,
		load,
		loading,
		error,
		canLoadMore,
		loadMore,
		loadingMore,
		loadingMoreError,
	};
}
