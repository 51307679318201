import { OtpInput, OtpInputRef } from "../../common/forms/otp-input";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { ErrorMessage } from "../../common/error-message";
import { MainColorSpinner } from "../../common/spinner";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { UIConstants } from "../../styles/uiConstants";
import { configurationManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { useObservable } from "../../../../shared/utils/observable";

interface OtpConfirmProps {
	submitOtp: (otp: string) => void;
	sendOtpAgain?: () => void;
	onOtpBlocked?: () => void;
	additionalText?: string;
	loading?: boolean;
	phoneNumber?: string;
	refreshingOtp?: boolean;
	errorMessage?: string;
	explanationColor?: string;
}

export const OtpConfirm = (props: OtpConfirmProps) => {
	const {
		submitOtp,
		sendOtpAgain,
		onOtpBlocked,
		additionalText,
		loading,
		phoneNumber,
		refreshingOtp,
		errorMessage,
		explanationColor,
	} = props;
	const [otp, setOTP] = useState("");
	const { formatMessage } = useIntl();
	const configuration = useObservable(configurationManager.configuration);
	const otpInputRef = useRef<OtpInputRef>(null);
	const hasOtpBeenReset = useRef(false);
	const resetOtp = useCallback(() => {
		otpInputRef.current?.reset();
		hasOtpBeenReset.current = true;
	}, []);
	const OTP_MAX_TRIES = 5;
	const [otpTriesLeft, setOtpTriesLeft] = useState<number>(OTP_MAX_TRIES);

	useEffect(() => {
		if (isDefined(errorMessage) && !hasOtpBeenReset.current) {
			resetOtp();
		} else if (otp.length >= configuration.otp.maxLength) {
			hasOtpBeenReset.current = false;
			if(onOtpBlocked) {
				setOtpTriesLeft(otpTriesLeft - 1)
			}
			submitOtp(otp);
		}
	}, [otp]);

	return (
		<Container>
			{isDefined(additionalText) && <AdditionalText>{additionalText}</AdditionalText>}
			<Explanation textColor={explanationColor}>{formatMessage("otp.explanation")}</Explanation>
			<PhoneNumber dir={"ltr"}>{phoneNumber ?? "** ** ** ** **"}</PhoneNumber>
			{otpTriesLeft > 0 ? (
				<>
					{isDefined(errorMessage) && <StyledErrorMessage>{errorMessage}{onOtpBlocked ? `\n${otpTriesLeft} tentative(s) restante(s)`: null}</StyledErrorMessage>}
					<StyledOtpInput
						ref={otpInputRef}
						length={configuration.otp.maxLength}
						onOtpChange={otp => setOTP(otp)}></StyledOtpInput>
				</>
			) : (
				<StyledErrorMessage>{formatMessage("otp.otpBlockedErrorMessage")}</StyledErrorMessage>
			)}
			<Footer>
				{loading ? (
					<MainColorSpinner />
				) : sendOtpAgain ? (
					<SendOtpAgainButton
						size="S"
						onClick={() => {
							if(otpTriesLeft <= 0 && onOtpBlocked) {
								onOtpBlocked();
							} else {
								resetOtp();
								setOtpTriesLeft(OTP_MAX_TRIES);
								sendOtpAgain();
							}
						}}
						showSpinner={refreshingOtp}>
						{formatMessage(otpTriesLeft <= 0 && onOtpBlocked ? "otp.backToRecipients" : "otp.sendBackCodeButton")}
					</SendOtpAgainButton>
				) : null}
			</Footer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 20px;
`;

const SendOtpAgainButton = styled(PrimaryButton)``;
const Explanation = styled.div<{ textColor?: string }>`
	color: ${props => props.textColor ?? "black"};
	margin-left: 50px;
	margin-right: 50px;
	text-align: center;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

const AdditionalText = styled(Explanation)`
	margin-bottom: 20px;
`;

const PhoneNumber = styled(Explanation)`
	${theme.boldText}
	color: black;
`;

const StyledOtpInput = styled(OtpInput)`
	justify-content: center;
	margin-top: 40px;
`;

const Footer = styled.div`
	display: flex;
	margin-top: 30px;
	margin-bottom: 20px;
	height: 46px;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 0px;
	text-align: center;
`;
