import { BasicStorage } from "../cache/basic-storage";
import { logger } from "../logging/logger";
import { UUIDGenerator } from "./uuid-generator";

const UUID_KEYSTORE_KEY = "device_uuid";
export class UUIDManager {
	private UUID: string | null = null;
	constructor(private cache: BasicStorage<string>, private uuidGenerator: UUIDGenerator) {}

	public async getUUID() {
		try {
			if (this.UUID !== null) {
				return this.UUID;
			} else {
				this.UUID = await this.restore();
				if (this.UUID === null || this.UUID === undefined) {
					this.UUID = await this.uuidGenerator.getRandomUUID();
					await this.save();
				}
				return this.UUID;
			}
		} catch (e) {
			logger.debug("UUID Manager", "Failed to load device uuid, generate a new UUID", e);
			this.UUID = await this.uuidGenerator.getRandomUUID();
			await this.save();
			return this.UUID;
		}
	}

	public async setUuid(id: string) {
		const oldId = this.UUID;
		try {
			this.UUID = id;
			await this.save();
			logger.debug("UUID Manager", "Saved custom ID: ", this.UUID);
		} catch (e) {
			this.UUID = oldId;
		}
	}

	private async save(): Promise<void> {
		try {
			if (this.UUID) {
				this.cache.store(this.UUID, UUID_KEYSTORE_KEY);
			}
		} catch (e) {
			logger.debug("UUID Manager", "Save UUID failed", e);
		}
	}

	private async restore(): Promise<string | null> {
		try {
			return this.cache.read(UUID_KEYSTORE_KEY);
		} catch (e) {
			logger.debug("UUID Manager", "Failed to restore device uuid", e);
			return Promise.resolve(null);
		}
	}
}
