import { useMachine } from "@xstate/react";
import { assign, Machine, send } from "xstate";
import { beneficiaryManager, externalAccountManager } from "../../shared/core/service/services";
import { Recipient } from "../../shared/domains/recipients/recipient";
import { RecipientData } from "../../shared/domains/recipients/beneficiary-service";

const REFRESHED_OTP_DELAY = 3000; // 3s
export const useRecipientMachine = () => {
	const [state, sendEvent] = useMachine(recipientMachine);

	const createIbanRecipient = (name: string, iban: string, externalAccount: boolean) => {
		sendEvent("CREATE_IBAN_RECIPIENT", { name, iban, externalAccount });
	};
	const createPhoneRecipient = (name: string, phone: string) => {
		sendEvent("CREATE_PHONE_RECIPIENT", { name, phone });
	};
	const createAccountRecipient = (name: string, account: string, bic: string) => {
		sendEvent("CREATE_ACCOUNT_RECIPIENT", { name, account, bic });
	};
	const searchBank = () => {
		sendEvent("SEARCH_BANK");
	};
	const updateBankName = (bankName: string, bic: string) => {
		sendEvent("UPDATE_BANK_NAME", { bankName, bic });
	};
	const confirmOtp = (otp: string) => {
		sendEvent("OTP_CONFIRM", { otp });
	};
	const sendOtpAgain = () => {
		sendEvent("SEND_OTP_AGAIN");
	};

	return {
		state: state.value as RecipientState,
		context: state.context,
		createIbanRecipient,
		createPhoneRecipient,
		createAccountRecipient,
		searchBank,
		updateBankName,
		confirmOtp,
		sendOtpAgain,
	};
};

interface RecipientMachineContext {
	name: string;
	recipientData: RecipientData;
	externalAccount?: boolean;
	recipientToAdd?: Recipient;
	otp?: string;
	errorMessage?: string | undefined;
	bicCode?: string;
	bankName?: string;
}

export enum RecipientState {
	CreatingRecipient = "CreatingRecipient",
	RequestingRecipient = "RequestingRecipient",
	SearchBank = "SearchBank",
	OTPSubmission = "OTPSubmission",
	OTPConfirming = "OTPConfirming",
	OTPRefreshing = "OTPRefreshing",
	Done = "Done",
}
type RecipientEvent =
	| { type: "CREATE_PHONE_RECIPIENT"; name: string; phone: string }
	| { type: "CREATE_IBAN_RECIPIENT"; name: string; iban: string; externalAccount: boolean }
	| { type: "CREATE_ACCOUNT_RECIPIENT"; name: string; account: string; bic: string; }
	| { type: "SEARCH_BANK" }
	| { type: "UPDATE_BANK_NAME"; bankName: string; bic: string }
	| { type: "REQUEST_NEW_RECIPIENT" }
	| { type: "OTP_CONFIRM"; otp: string }
	| { type: "SEND_OTP_AGAIN" }
	| { type: "OTP_REFRESHED" };

export const recipientMachine = Machine<RecipientMachineContext, RecipientEvent>({
	id: "Recipient",
	initial: RecipientState.CreatingRecipient,
	states: {
		[RecipientState.CreatingRecipient]: {
			on: {
				CREATE_PHONE_RECIPIENT: {
					target: RecipientState.RequestingRecipient,
					actions: [
						assign({
							name: (_, event) => event.name,
							recipientData: (_, event) => ({ phone: event.phone }),
						}),
						send({ type: "REQUEST_NEW_RECIPIENT" }),
					],
				},
				CREATE_IBAN_RECIPIENT: {
					target: RecipientState.RequestingRecipient,
					actions: [
						assign({
							name: (_, event) => event.name,
							recipientData: (_, event) => ({ iban: event.iban }),
							externalAccount: (_, event) => event.externalAccount,
						}),
						send({ type: "REQUEST_NEW_RECIPIENT" }),
					],
				},
				CREATE_ACCOUNT_RECIPIENT: {
					target: RecipientState.RequestingRecipient,
					actions: [
						assign({
							name: (_, event) => event.name,
							recipientData: (_, event) => ({ accountReference: event.account, bic: event.bic })
						}),
						send({ type: "REQUEST_NEW_RECIPIENT" }),
					],
				},
				SEARCH_BANK: {
					target: RecipientState.SearchBank,
				},
			},
		},
		[RecipientState.RequestingRecipient]: {
			invoke: {
				id: "requestRecipient",
				src: context => {
					if (context.externalAccount) {
						return externalAccountManager.requestNewRecipient(context.name, context.recipientData)
					} else {
						return beneficiaryManager.requestNewRecipient(context.name, context.recipientData)
					}
				},
				onDone: {
					target: RecipientState.OTPSubmission,
					actions: context => [
						assign<RecipientMachineContext, { type: string; data: Recipient }>({
							recipientToAdd: (_, event) => event.data,
							externalAccount: context.externalAccount,
							errorMessage: undefined,
						}),
					],
				},
				onError: {
					target: RecipientState.CreatingRecipient,
					actions: assign({
						errorMessage: (_, event) => event.data,
					}),
				},
			},
		},
		[RecipientState.SearchBank]: {
			on: {
				UPDATE_BANK_NAME: {
					target: RecipientState.CreatingRecipient,
					actions: [
						assign({
							bankName: (_, event) => event.bankName,
							bicCode: (_, event) => event.bic,
						}),
					],
				},
			},
		},
		[RecipientState.OTPRefreshing]: {
			invoke: {
				id: "requestRecipient",
				src: context =>
					beneficiaryManager.requestNewRecipient(context.name, context.recipientData),
				onDone: {
					actions: [
						assign<RecipientMachineContext, { type: string; data: Recipient }>({
							recipientToAdd: (_, event) => event.data,
							errorMessage: undefined,
						}),
						send("OTP_REFRESHED", { delay: REFRESHED_OTP_DELAY }),
					],
				},
			},
			on: {
				OTP_REFRESHED: RecipientState.OTPSubmission,
			},
		},
		[RecipientState.OTPSubmission]: {
			on: {
				OTP_CONFIRM: {
					target: RecipientState.OTPConfirming,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					actions: assign<RecipientMachineContext, any>({
						otp: (_, event) => event.otp,
						errorMessage: undefined,
					}),
				},
				SEND_OTP_AGAIN: {
					target: RecipientState.OTPRefreshing,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					actions: assign<RecipientMachineContext, any>({
						errorMessage: undefined,
					}),
				},
			},
		},
		[RecipientState.OTPConfirming]: {
			invoke: {
				id: "confirmNewRecipient",
				src: context => {
					if (context.externalAccount) {
						return externalAccountManager.confirmNewRecipient(
							context.name,
							context.recipientData,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							context.otp!
						)
					} else {
						return beneficiaryManager.confirmNewRecipient(
							context.name,
							context.recipientData,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							context.otp!
						)
					}
				},
				onDone: {
					target: RecipientState.Done,
					actions: [
						assign<RecipientMachineContext, { type: string; data: Recipient }>({
							recipientToAdd: (_, event) => event.data,
							errorMessage: undefined,
						}),
					],
				},
				onError: {
					target: RecipientState.OTPSubmission,
					actions: assign({
						errorMessage: (_, event) => event.data,
					}),
				},
			},
		},
		[RecipientState.Done]: { type: "final" },
	},
});
