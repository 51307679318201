import { Config } from "../../../shared/core/config/config";
import { I18NStore } from "../../../shared/core/i18n/i18n-store";
import { UserAgentService } from "../../../shared/core/net/user-agent-service";
import { ApplicationScopes } from "../../../shared/domains/authentication/scope";
import { UUIDManager } from "../../core/uuid/uuid-manager";
import { RegisteringParameters } from "./authentication-service";
import { getAuthorizeRedirectUrl } from "./authorize-redirect-url";
import { AuthConfiguration } from "./oauth-service";
export class AuthenticationConfigurationService {
	private configuration: AuthConfiguration = {
		serviceConfiguration: {
			authorizationEndpoint: `${Config.API_URL}/oauth2/authorize`,
			tokenEndpoint: `${Config.API_URL}/oauth2/token`,
		},
		clientId: Config.API_CLIENT_ID,
		clientSecret: Config.API_CLIENT_SECRET,
		redirectUrl: getAuthorizeRedirectUrl(),
		scopes: ApplicationScopes,
	};

	constructor(
		private uuidManager: UUIDManager | null,
		private customUserAgentService: UserAgentService | null,
		private i18NStore: I18NStore
	) {}

	async getConfiguration(
		phoneNumber?: string,
		registeringParameters?: RegisteringParameters
	): Promise<AuthConfiguration> {
		const deviceUUID = await this.uuidManager?.getUUID();
		const optionalDeviceUUID: { device_id: string } | Record<string, unknown> = deviceUUID ? { device_id: deviceUUID } : {};
		const userAgent = this.customUserAgentService ? await this.customUserAgentService.getUserAgent() : null;
		const localeTag = await this.i18NStore.localeTag.get();
		const customHeaders = userAgent
			? {
					token: {
						"User-Agent": userAgent,
					},
			  }
			: undefined;
		if (registeringParameters) {
			return {
				...this.configuration,
				additionalParameters: {
					...registeringParameters,
					...optionalDeviceUUID,
					signUp: "1",
					phone: phoneNumber ?? "",
					lang: localeTag,
				},
				// only used by Android
				customHeaders,
			};
		} else {
			return {
				...this.configuration,
				additionalParameters: {
					...optionalDeviceUUID,
					signUp: phoneNumber ? "1" : "0",
					phone: phoneNumber ?? "",
					lang: localeTag
				},
				// only used by Android
				customHeaders,
			};
		}
	}
}
