import React, { SVGProps } from "react";

export function HomeIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 26 26" {...props}>
			<path
				fillRule="nonzero"
				d="M15.2 17.484v2.993c0 .47.38.85.85.85h5.1c.47 0 .85-.38.85-.85v-7.514c0-.36-.143-.706-.398-.961L14.46 6.076c-.254-.255-.6-.398-.96-.398s-.706.143-.96.398l-7.142 5.926c-.255.255-.398.6-.398.961v7.514c0 .47.38.85.85.85h5.1c.225 0 .442-.09.601-.249.16-.16.249-.375.249-.6v-2.994c0-.939.761-1.7 1.7-1.7s1.7.761 1.7 1.7z"
			/>
		</svg>
	);
}
