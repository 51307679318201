import { useState } from "react";
import { categorizationsManager } from "../../../core/service/services";
import { logger } from "../../../core/logging/logger";
import { useObservable } from "../../../utils/observable";
import { useAsyncEffect } from "../../../utils/utils";

export const useCategorizations = (loadAtStartup = true) => {
	const categories = useObservable(categorizationsManager.categories);
	const additionalData = useObservable(categorizationsManager.additionalData);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");

	const load = async (forceRefresh = false) => {
		try {
			setError("");
			setLoading(true);
			await categorizationsManager.load(forceRefresh);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			setError(e);
			logger.debug("UseCategorization: error fetching catgorization", e);
		}
	};

	useAsyncEffect(async () => {
		if (loadAtStartup) {
			await load();
		}
	}, []);

	return { categories, additionalData, load, loading, error };
};
