import {MultiSelectInput, MultiSelectInputType} from "../forms/multi-select-input";
import React, {useEffect, useState} from "react";

import {DisableValueText} from "./disable-value-text";
import {FormMultiSelectInput} from "../../../../shared/core/data-forms/form-input-types";
import styled from "styled-components";
import {isAnNotEmptyArray} from "../../../../shared/utils/utils";

export const MultiSelectField: React.FC<{
	input: FormMultiSelectInput;
	onChange: (value: string[]) => void;
	disabled?: boolean;
}> = ({ input, onChange, disabled }) => {

	const getInitialSelectedValues = () => {
		if (!input.value || !isAnNotEmptyArray(input.value)) {
			return [];
		}

		return input.value
			.map(i => input.values.find(e => e.id === i))
			.filter(item => item !== undefined);
	};

	const [selected, setSelected] = useState(getInitialSelectedValues());

	return input.disabled || disabled ? (
		<DisableValueText>{getSelectedOptionsLabels(input)}</DisableValueText>
	) : (
		<InputContainer>
			<MultiSelect
				innerId={input.id}
				options={input.values}
				selectedValues={selected}
				disabled={input.disabled}
				required={input.required}
				onChange={selectedItems => {
					setSelected(selectedItems);
					if (onChange) {
						onChange(selectedItems.length !== 0 ? selectedItems.map(v => v.id.trim()) : []);
					}
				}}
				itemRenderer={v => v?.label ?? ""}
			/>
		</InputContainer>
	);
};

const MultiSelect = styled<MultiSelectInputType<{ id: string; label: string }>>(MultiSelectInput)`
	width: 100%;

	.multi-select {
		background-color: transparent;
		> div {
			height: 48px;
			border-radius: 10px;
			border: 0;
		}
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;
	padding-bottom: 10px;
`;

function getSelectedOptionsLabels(input: FormMultiSelectInput) {
	let selectedLabels = "";

	if (input.value) {
		input.value.forEach(id => {
			const value = input.values?.find(e => e.id === id);
			if (value) {
				selectedLabels = selectedLabels.concat(" " + value.label);
			}
		});
	}

	return selectedLabels;
}
