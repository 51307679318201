import { CountryCode } from "libphonenumber-js";

export enum AuthenticationMode {
	LoginPassword = "login_password",
	PhonePincode = "phone_pincode",
}
export interface Configuration {
	enrollment: boolean;
	pincode: { maxLength: number; minLength: number };
	otp: { maxLength: number; minLength: number };
	p2pCodeLength: { maxLength: number; minLength: number }; // Web only
	langs: string[];
	countries: CountryCode[];
	colorScheme: ColorConfiguration; // Web only ?
	authentication_mode: AuthenticationMode;
	visaPaymentAvailable: boolean;
	isCameraCaptureAuthorized: boolean;
	isLocalFileUploadAuthorized: boolean;
	logo?: string; // Web only
	favicon?: string; // Web only
	registerImage?: string; // Web only
	carouselImages?: string[]; // Web only
	contactEmail?: string;
	contactPhone?: string;
	infoUrl?: { [key: string]: string }; // Web only
	userAppMinimumVersion: string; // Mobile only ?
	trustDuration?: number; // Web only
	simplePayment?: boolean;
	selectDistributorDuringOnboarding?: boolean;
	termsOfService?: string;
	privacyPolicyUrl?: string;
	termsAndConditionsUrl?: string;
}

export interface ColorConfiguration {
	menuColors: string[];
	recipientColors: string[];
	billIssuerColors: string[];
	primaryColor: string;
}
