import { CREDIT_SCOPES, SERVICE_DOMAIN_SCOPES } from "./service-domain-scopes";

import { Config } from "../../core/config/config";
import { logger } from "../../core/logging/logger";
import { ServiceDomainApiService } from "../../core/net/service-domain-api-service";
import { TokenResultDto } from "../authentication/oauth-service";

export class ServiceDomainService {
	public constructor(private serviceDomainApiService: ServiceDomainApiService) {}

	public async getToken() {
		try {
			const response = await this.serviceDomainApiService.instance.post<TokenResultDto>(`/oauth2/token`, {
				grant_type: "client_credentials",
				client_id: Config.SERVICE_DOMAIN_API_ID,
				client_secret: Config.SERVICE_DOMAIN_API_SECRET,
				scope: [...CREDIT_SCOPES, ...SERVICE_DOMAIN_SCOPES].join(" "),
			});
			return response.data;
		} catch (e) {
			logger.debug("ServiceDomainService", "Fetch token failed", e);
			throw e;
		}
	}
}
