import { useCallback } from "react";
import { bicReferentialManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { BicReferential } from "./bic-referential";

export interface Bics {
	data: BicReferential[];
	count: number;
	scheduleLoad: (search: string) => void;
	loading: boolean;
	error: Error | null;
	canLoadMore: boolean;
	loadMore: () => void;
	loadingMore: boolean;
	loadingMoreError: Error | null;
	reset: (emptySet?: boolean) => void;
}

export function useBicReferential(): Bics {
	const data = useObservable(bicReferentialManager.bics);
	const loading = useObservable(bicReferentialManager.loading);
	const error = useObservable(bicReferentialManager.error);
	const loadingMore = useObservable(bicReferentialManager.loadingMore);
	const loadingMoreError = useObservable(bicReferentialManager.loadingMoreError);
	const canLoadMore = useObservable(bicReferentialManager.canLoadMore);

	const scheduleLoad = useCallback(async (search: string) => {
		await bicReferentialManager.scheduleLoad(search);
	}, []);

	const loadMore = useCallback(async () => {
		await bicReferentialManager.loadMore();
	}, []);

	const reset = (emptySet?: boolean) => bicReferentialManager.reset(emptySet);

	return {
		data: data.items,
		count: data.count,
		scheduleLoad,
		loading,
		error,
		canLoadMore,
		loadMore,
		loadingMore,
		loadingMoreError,
		reset,
	};
}
