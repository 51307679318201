import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import { AUTHORIZATION_HOLD_DETAILS_MODAL_ID } from "../../../../core/modal/modal-id";
import { AmountText } from "../../../common/amount-text";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { theme } from "../../../styles/theme";
import { useAuthorizationHoldLabel } from "../hooks/use-authorization-hold-label";
import { PendingColoredTag } from "./pending-colored-tag";

interface AuthorizationHoldDetailsProps {
	authorizationHold: AuthorizationHold;
}

export const AuthorizationHoldDetailsModal = (props: AuthorizationHoldDetailsProps) => (
	<RoundedModalContainer closeButton id={AUTHORIZATION_HOLD_DETAILS_MODAL_ID}>
		<AuthorizationHoldDetails authorizationHold={props.authorizationHold} />
	</RoundedModalContainer>
);

const AuthorizationHoldDetails: React.FC<AuthorizationHoldDetailsProps> = ({ authorizationHold }) => {
	const { formatMessage, formatDate } = useIntl();
	const { labelForType } = useAuthorizationHoldLabel();

	if (!authorizationHold.amount) {
		return null;
	}

	return (
		<Container>
			<Header>
				<Title>{labelForType(authorizationHold.type)}</Title>
			</Header>
			<DateAndTagContainer>
				<DateLabel>
					{formatDate(authorizationHold.date, {
						month: "long",
						day: "2-digit",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</DateLabel>
				<PendingColoredTag label={formatMessage("authorizationHoldsDetailsModal.pending")} />
			</DateAndTagContainer>
			{!!authorizationHold.label && <Description style={{ marginTop: 10 }}>{authorizationHold.label}</Description>}
			{!!authorizationHold.id && (
				<Description style={{ marginTop: 10 }}>
					{formatMessage("authorizationHoldsDetailsModal.reference", { id: authorizationHold.id })}
				</Description>
			)}
			<DetailsTitle>{formatMessage("authorizationHoldsDetailsModal.detailsTitle")}</DetailsTitle>
			<Items>
				<TransactionEntryView
					label={formatMessage("authorizationHoldsDetailsModal.transferLabel")}
					amount={authorizationHold.amount}
					hasLink
				/>
				<TransactionEntryView
					label={formatMessage("authorizationHoldsDetailsModal.amountLabel")}
					amount={authorizationHold.amount}
					bold
				/>
			</Items>
		</Container>
	);
};

interface TransactionEntryViewProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	amount: Amount | undefined;
	green?: boolean;
	hasLink?: boolean;
	bold?: boolean;
}

export const TransactionEntryView: React.FC<TransactionEntryViewProps> = props => {
	const { label, amount, green, hasLink, bold, ...viewProps } = props;
	if (!amount) {
		return null;
	}
	return (
		<Item {...viewProps}>
			<Bullet green={green} />
			<ItemLabel bold={bold}>{label}</ItemLabel>
			<ItemValue green={green} bold={bold}>
				<AmountText amount={amount} />
			</ItemValue>
			{hasLink && <Link />}
		</Item>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

const Title = styled.h2`
	${theme.boldText};
	font-size: 1.375rem;
	margin: 0;
	flex-shrink: 1;
	flex-grow: 1;
`;

const DetailsTitle = styled.h3`
	${theme.boldText};
	font-size: 1.125rem;
	margin: 20px 0;
`;

const DateAndTagContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px 0 8px;
`;

const DateLabel = styled.span`
	${theme.text};
	font-size: 0.9375rem;
	margin: 0 8px 0 0;
`;

const Description = styled.span`
	${theme.text};
	color: #b1b1b1;
	font-size: 0.9375rem;
	margin: 10px 0;
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
`;

const ItemLabel = styled.span<{ bold?: boolean; green?: boolean }>`
	${props => (props.bold ? theme.boldText : theme.text)};
	color: ${props => (props.green ? "#2ed794" : "#000000")};
	flex-shrink: 1;
	flex-grow: 1;
`;

const ItemValue = styled.span<{ bold?: boolean; green?: boolean }>`
	${props => (props.bold ? theme.boldText : theme.text)};
	color: ${props => (props.green ? "#2ed794" : "#000000")};
	margin-left: 20px;
`;

const Bullet = styled.span<{ green?: boolean }>`
	width: 10px;
	height: 10px;
	background-color: ${props => (props.green ? "#2ed794" : "#000000")};
	margin-right: 15px;
	border-radius: 15px;
`;

const Items = styled.div`
	margin-top: 10px;

	> *:not(:last-child) {
		margin-bottom: 20px;
	}
`;

const Link = styled.div`
	position: absolute;
	top: 100%;
	left: 3.5px;
	width: 2px;
	height: 20px;
	border-radius: 1.5px;
	background-color: #000000;
`;
