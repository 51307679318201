import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { accountStatementService } from "../../../../../shared/core/service/services";
import { AccountStatement } from "../../../../../shared/domains/account-statements/account-statement";
import { Account } from "../../../../../shared/domains/account/account";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { DownloadIcon } from "../../../common/svg/download-icon";
import { theme } from "../../../styles/theme";

interface StatementRowProps extends HTMLAttributes<HTMLDivElement> {
	statement: AccountStatement;
	account: Account;
	withAccountId?: boolean;
	variant?: "normal" | "fee";
}

export const StatementRow = (props: StatementRowProps) => {
	const { statement, account, variant = "normal", withAccountId, ...rest } = props;
	const { formatMessage, formatDate } = useIntl();
	return (
		<Row {...rest}>
			<Column>
				<Title>
					{formatDate(statement.fromDate, { year: "numeric", month: "long", day: "numeric" })} -{" "}
					{formatDate(statement.toDate, { year: "numeric", month: "long", day: "numeric" })}
				</Title>
				{withAccountId && <Value>{formatMessage("accountsStatements.accountLabel", { id: account.id })}</Value>}
			</Column>
			<GreyRoundedSquareButton
				size={34}
				onClick={() => accountStatementService.downloadStatement(account.id, statement.id, variant)}>
				<DownloadIcon />
			</GreyRoundedSquareButton>
		</Row>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-self: stretch;
	justify-content: space-between;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.span`
	${theme.boldText};
	font-size: 0.9375rem;
`;

const Value = styled.span`
	${theme.text};
	color: #b1b1b1;
	font-size: 0.9375rem;
`;

const GreyRoundedSquareButton = styled(RoundedSquareButton)`
	background-color: #f4f4f4;
`;
