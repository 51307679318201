import React, { IframeHTMLAttributes } from "react";
import styled from "styled-components";
import { Config } from "../../../shared/core/config/config";
import { logger } from "../../../shared/core/logging/logger";
import { i18NManager } from "../../../shared/core/service/services";
import { Geolocation } from "../../../shared/domains/accounting-transaction/transaction/transaction";
import { useObservable } from "../../../shared/utils/observable";

interface MapIframeProps extends IframeHTMLAttributes<HTMLIFrameElement> {
	location: Geolocation;
}

export const MapIframe: React.FC<MapIframeProps> = ({ location, ...otherProps }) => {
	const userLanguage = useObservable(i18NManager.localeTag);
	if (!Config.GOOGLE_MAP_API_KEY) {
		logger.debug("GOOGLE_MAP_API_KEY is not defined in the config file");
		return null;
	}

	return (
		<Map
			{...otherProps}
			src={`https://www.google.com/maps/embed/v1/place?key=${Config.GOOGLE_MAP_API_KEY}
    &q=${location.latitude},${location.longitude}&language=${userLanguage}`}
		/>
	);
};

const Map = styled.iframe`
	width: 100%;
	height: 250px;
	border: 0;
	border-radius: 10px;
`;
