import React, { SVGProps } from "react";

export function DownloadIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
			<g>
				<path
					fill="#000"
					fillRule="nonzero"
					d="M2 13c0-.5.5-1 1-1s1 .5 1 1v5c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2v-5c0-.552.448-1 1-1s1 .448 1 1v5c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4v-5z"
				/>
				<rect width="2" height="14" x="11" y="1" fill="#000" fillRule="nonzero" rx="1" transform="rotate(-180 12 8)" />
				<path
					fill="#000"
					fillRule="nonzero"
					d="M7.707 15.707c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l5-5c.376-.376.98-.392 1.376-.036l5 4.5c.41.37.444 1.001.074 1.412-.37.41-1.001.444-1.412.074l-4.295-3.865-4.329 4.33z"
					transform="rotate(-180 12 12.5)"
				/>
			</g>
		</svg>
	);
}
