import { i18NManager } from "../../../shared/core/service/services";
import { clientMailName } from "../../../shared/domains/client/client";
import { ClientManager } from "../../../shared/domains/client/client-manager";

export class AccountDeleteService {
	public constructor(private clientManager: ClientManager) {}

	public buildLinkEmail(email: string | undefined) {
		let url = `mailto:${email}`;

		const client = this.clientManager.client.get();

		if (client) {
			const username = clientMailName(client);
			const subject = i18NManager.formatMessage("accountDeleteMail.subject", { username: username });
			const intl = i18NManager.intl.get();
			let body = intl.formatDate(Date(), { year: "numeric", month: "long", day: "numeric" }) + "\n";
			body += "\n" + i18NManager.formatMessage("accountDeleteMail.body") + "\n";
			body += "\n" + username;
			if (client.contactphone) {
				body += "\n" + client.contactphone;
			}
			if (client.email) {
				body += "\n" + client.email;
			}

			if (subject) {
				url += "?subject=" + subject;
			}

			if (body) {
				url += "&body=" + encodeURIComponent(body);
			}
		}
		return url;
	}
}
