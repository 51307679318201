import React, { HTMLAttributes } from "react";
import {
	AccountingTransaction,
	Transaction,
} from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { shadows, theme } from "../../../styles/theme";

import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { AccountingEntryType } from "../../../../../shared/domains/accounting-transaction/accounting-entry";
import { AccountingTransactionType } from "../../../../../shared/domains/accounting-transaction/accounting-transaction-type";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import { AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import { CashTransfer } from "../../../../../shared/domains/transactions/cash-transfer/cash-transfer";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AmountText } from "../../../common/amount-text";
import { Circle } from "../../../common/shape/circle";
import { TransactionIcon } from "../../../common/transaction-icon";
import { UIConstants } from "../../../styles/uiConstants";
import { useAuthorizationHoldLabel } from "../hooks/use-authorization-hold-label";

interface AccountTransactionCardProps extends HTMLAttributes<HTMLDivElement> {
	transaction: AccountingTransaction | AuthorizationHold | CashTransfer;
	received?: boolean;
	showUnjustifiedTag?: boolean;
}

export function AccountTransactionCard(props: AccountTransactionCardProps) {
	const { transaction, received, showUnjustifiedTag = false, ...rest } = props;
	const { formatDate } = useIntl();
	const { labelForType } = useAuthorizationHoldLabel();
	const { canShowJustifyLabel } = useTransactionLinks();
	const { isRTL } = useRTL();
	const intl = useIntl();
	const { formatMessage } = intl;

	const detailedAccountingTransfer = (transaction as AccountingTransaction)?.detailedAccounting?.find(
		accounting => accounting.type === AccountingEntryType.Transfer
	);
	const transactionDescription = hasObjectType(transaction)
		? transaction.objectType === AccountingTransactionType.Transaction
			? transaction.shortDescription || detailedAccountingTransfer?.description || transaction.type.label
			: labelForType((transaction as AuthorizationHold).type)
		: transaction.label;

	return (
		<Card $isRTL={isRTL} {...rest}>
			<IconLabelContainer>
				<Label $isRTL={isRTL}>{transactionDescription}</Label>
			</IconLabelContainer>
			<Date $isRTL={isRTL}>
				{formatDate(transaction.date, {
					month: "short",
					day: "numeric",
					year: "numeric",
				})}
			</Date>
			{canShowJustifyLabel(transaction as Transaction) && showUnjustifiedTag ? (
				<StyledUnjustifiedLabel>
					<Dot size={6} />
					<UnjustifiedMention>{formatMessage("accountTransactionScreen.expenseUnjustified")}</UnjustifiedMention>
				</StyledUnjustifiedLabel>
			) : null}
			{transaction.amount && <Amount amount={transaction.amount} $isRTL={isRTL} />}
		</Card>
	);
}

export function hasObjectType(
	transaction: AccountingTransaction | AuthorizationHold | CashTransfer
): transaction is AccountingTransaction | AuthorizationHold {
	return (transaction as AccountingTransaction | AuthorizationHold)?.objectType !== undefined;
}

const Card = styled.div<{ $isRTL: boolean }>`
	display: flex;
	align-items: center;
	padding: ${props => (props.$isRTL ? "18px 15px 18px 27px" : "18px 27px 18px 15px")};
	border-radius: 13px;
	${shadows.medium};
	background-color: #ffffff;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const IconLabelContainer = styled.div`
	flex: 1 0 45%;
	display: flex;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		flex: 0 1 auto;
	}
`;

const Icon = styled(TransactionIcon)`
	flex: 0 0 auto;
`;

const Label = styled.span<{ $isRTL: boolean }>`
	font-size: 1.125rem;
	line-height: 1.2rem;
	${theme.boldText};
	margin: ${props => (props.$isRTL ? "auto 27px auto 0" : "auto 0 auto 27px")};
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		margin: ${props => (props.$isRTL ? "auto 27px 10px 0" : "auto 0 10px 27px")};
	}
`;

const Date = styled.span<{ $isRTL: boolean }>`
	${theme.text};
	font-size: 0.9375rem;
	margin: 0 30px;
	width: 7.5rem;
	text-align: right;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		margin: ${props => (props.$isRTL ? "0 67px 8px 0" : "0 0 8px 67px")};
		width: auto;
	}
`;

const Amount = styled(AmountText)<{ $isRTL: boolean }>`
	font-size: 1.125rem;
	${theme.boldText};
	justify-content: flex-end;
	text-align: ${props => (props.$isRTL ? "start" : "end")};
	flex: 1 1 30%;
	@media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
		margin: ${props => (props.$isRTL ? "0 67px 0 0" : "0 0 0 67px")};
		flex: 0 1 auto;
	}
`;

const Dot = styled(Circle)`
	background-color: #b1b1b1;
	margin: 0 8px;
`;

const UnjustifiedMention = styled.div`
	${theme.boldText};
	font-size: 12px;
	color: ${theme.colors.warning[500]};
`;

const StyledUnjustifiedLabel = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex: 1 1 10%;
`;
