import { DisableValueText } from "./disable-value-text";
import { FormTextInput } from "../../../../shared/core/data-forms/form-input-types";
import React from "react";
import { TextInput } from "../forms/text-input";
import { escapeHtmlChars } from "../../../../shared/utils/string";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";

export interface TextFieldProps {
	input: FormTextInput;
	hasError?: boolean;
	onChange?: (value: string) => void;
	disabled?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({ input, disabled, hasError, onChange }) => {
	const { formatMessage } = useIntl();

	const handleOnChange = (value: string) => {
		const text = escapeHtmlChars(value);
		onChange?.(text);
	};

	return input.disabled || disabled ? (
		<DisableValueText>{input.value}</DisableValueText>
	) : (
		<StyledTextInput
			required={input.required}
			value={input.value ?? ""}
			onChange={e => handleOnChange(e.target.value)}
			placeholder={formatMessage("billFormScreen.textInputPlaceholder")}
			hasError={hasError ? hasError : null}
		/>
	);
};

const StyledTextInput = styled(TextInput)``;
