export enum Scope {
	Payment = "payment",
	BillPayment = "bill_payment",
	P2PTransfer = "p2p_transfer",
	P2PSimpleTransfer = "p2p_simple_transfer",
	P2PCashTransfer = "p2p_cash_transfer",
	P2PDedicatedTransfer = "p2p_transfer_dedicated",
	RetrieveCashTransfer = "retrieve_cash_transfer",
	Payout = "payout",
	TransactionCode = "transaction_code",
	SubscribeLoan = "subscribe_loan",
	ConsultLoans = "consult_loans",
	WalletToBank = "wallet_to_bank",
	BankToWallet = "bank_to_wallet",
	ConsultBankAccount = "consult_bank_account",
	CardsManagement = "cards_management",
	CardsVirtualCreation = "cards_virtual_creation",
	MobilesManagement = "mobiles_management",
	Notifications = "notifications",
	KYCView = "kyc_view",
	KYCUpdate = "kyc_update",
	PincodeUpdate = "pincode_update",
	AccountsView = "accounts_view",
	ConsultAuthorizationHolds = "consult_authorization_holds",
	PincodeCheck = "pincode_check",
	ClientOnboarding = "client_onboarding",
	SdaCustomerOnboardingCreate = "sda_customer_onboarding_create",
	SdaCustomerOnboardingView = "sda_customer_onboarding_view",
	SdaCustomerOnboardingUpdate = "sda_customer_onboarding_update",
	SdaCustomerOnboardingSubmit = "sda_customer_onboarding_submit",
	SdaCustomerOnboardingActionsInitiate = "sda_customer-actions_initiate",
	AcceptorSearch = "acceptor_search",
	Configuration = "configuration",
	OtpCheck = "otp_check",
	Withdraw = "withdraw",
	WalletRechargeByCard = "wallet_recharge_by_card",
	ManageQrCode = "qr_code_management",
	ManageCategorizationSpendings = "spendings_categorization_management",
	BicReferential = "bic_referential_view",
	ExternalAccountsManagement = "external_account_management",
	ExternalAccountsUsage = "external_account_usage",
	BeneficiariesManagement = "beneficiaries_management",
	BeneficiariesUsage = "beneficiaries_usage",
	StrongCustomerAuthentication = "strong_customer_authentication",
	CardSensitiveDataView = "card_sensitive_data_view",
}

export enum SharedServiceDomainScope {
	PaymentNetwork = "payment_network",
	CustomerInstructionView = "customer-instruction_view",
	CustomerInstructionInitiation = "customer-instruction_initiation",
	CustomerInstructionUpdate = "customer-instruction_update",
	CustomerInstructionDeletion = "customer-instruction_deletion",
	CustomerInstructionSubmit = "customer-instruction_submit",
	SdaAuthenticationsSessionsView = "sda_authentications-sessions_view",
	SdaAuthenticationsSessionsAuth = "sda_authentications-sessions_auth",
	// SdaCardView = "sda_card_view",
}

export enum ServiceDomainScope {
	DocumentSetupView = "document_setup_view",
	AdditionalDataRead = "additional-data_read",
	FormRead = "form_read",
}

export enum CreditScope {
	CdtProductRead = "cdt_product_read",
	CdtProductWrite = "cdt_product_write",
	CdtCreditRequestRead = "cdt_credit_request_read",
	CdtCreditRequestWrite = "cdt_credit_request_write",
	CdtPrivilegeRead = "cdt_privilege_read",
	CdtPrivilegeWrite = "cdt_privilege_write",
	CdtSimulationRead = "cdt_simulation_read",
	CdtPersonRead = "cdt_person_read",
	CdtCreditServicingRead = "cdt_credit_servicing_read",
	CdtCreditServicingWrite = "cdt_credit_servicing_write",
}

export const ApplicationScopes = [...Object.values(Scope), ...Object.values(SharedServiceDomainScope)];

export const NotAuthenticatedScopes = [
	Scope.PincodeCheck,
	Scope.ClientOnboarding,
	Scope.AcceptorSearch,
	Scope.Configuration,
	Scope.SdaCustomerOnboardingCreate,
	Scope.SdaCustomerOnboardingView,
];

export const RegisterOtpScopes = [Scope.PincodeCheck, Scope.OtpCheck];
