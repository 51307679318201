import { TransactionGeolocationManager } from "../../../core/geolocalisation/transaction-geolocalisation-manager";
import { runAfterInteractions } from "../../../core/interaction/interaction-manager";
import { AccountManager } from "../../account/account-manager";
import { accountsAffectedByTransaction } from "../../accounting-transaction/transaction/accounts-affected-by-transaction";
import { Transaction } from "../../accounting-transaction/transaction/transaction";
import { TransactionsManager } from "../../accounting-transaction/transaction/transactions-manager";
import { PincodeSubmission } from "../../pincode/pincode";
import { ConfirmationMode } from "../transaction-request";
import { CashTransferService } from "./cash-transfer-service";

export class CashTransferManager {
	public constructor(
		private cashTransferService: CashTransferService,
		private geolocationManager: TransactionGeolocationManager,
		private accountManager: AccountManager,
		private transactionsManager: TransactionsManager
	) {}

	public async startRetrieveCashTransfer(cashTransferCode: string) {
		const location = await this.geolocationManager.updatePosition();
		return await this.cashTransferService.startRetrieveCashTransfer(cashTransferCode, location);
	}

	public async confirmRetrieveCashTransfer(
		confirmationMode: ConfirmationMode,
		cashTransferCode: string,
		pincode?: PincodeSubmission
	) {
		const location = this.geolocationManager.getPosition();
		const result = await this.cashTransferService.confirmRetrieveCashTransfer(
			confirmationMode,
			cashTransferCode,
			pincode,
			location
		);
		this.refreshAccountAndTransactions(result.metadata.transaction);
		return result;
	}

	private refreshAccountAndTransactions(transaction: Transaction) {
		runAfterInteractions(async () => {
			const accountIds = accountsAffectedByTransaction(transaction);
			await this.accountManager.refresh();
			await this.transactionsManager.refresh(accountIds);
		});
	}
}
