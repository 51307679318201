import { Card, CardStatus, OpposeCardReason } from "../../../../../../shared/domains/cards/card";
import { RadioSelector, RadioSelectorType } from "../../../../common/forms/radio-selector";

import { ActionMenu } from "../action-menu";
import { Path } from "../../../../../core/routing/path";
import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";

interface OutstandingsButtonProps {
	card: Card;
	className?: string;
}

export const OutstandingsButton: React.FC<OutstandingsButtonProps> = ({ card, className }) => {
	const { formatMessage } = useIntl();
    const history = useHistory();    

	return (
		<ActionMenu
			title={formatMessage("cardOptions.outstandings.navigateButton")}
			className={className}
			onClick={() => {
				history.push(`${Path.CardOutstandings}?card_id=${card.id}`);
			}}
		/>
	);
};
