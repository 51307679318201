import { useCallback, useMemo } from "react";
import { TransactionDirection, TransactionFilters } from "./transaction-filter";

import { CacheStatus } from "../../../core/cache/cache-status";
import { transactionsManager } from "../../../core/service/services";
import { useObservable } from "../../../utils/observable";
import { useAsyncEffect } from "../../../utils/utils";
import { Account } from "../../account/account";
import { useTransactionLinks } from "../use-transaction-links";
import { AccountingTransaction } from "./transaction";
import { TransactionsLoader } from "./transaction-loader";

export interface AccountingTransactions {
	data: AccountingTransaction[];
	unjustifiedTransactions: AccountingTransaction[];
	unjustifiedTransactions2: {
		total: number;
		items: AccountingTransaction[];
	};
	unqualifiedTransactions: {
		total: number;
		items: AccountingTransaction[];
	};
	loading: boolean;
	error: Error | null;
	canLoadMore: boolean;
	loadingMore: boolean;
	loadingMoreError: Error | null;
	refreshing: boolean;
	cacheStatus: CacheStatus | null;
	loadMore: () => void;
	refresh: () => void;
}

export function useTransactions(
	account: Account | null,
	transactionDirection: TransactionDirection,
	transactionFilters?: TransactionFilters,
	loadAtStartup = true
): AccountingTransactions {
	const accountId = useMemo(() => (account !== null ? account.id : null), [account]);

	const loader: TransactionsLoader = useMemo(() => {
		if (!accountId) {
			return transactionsManager.getDefaultLoader();
		}
		if (transactionFilters) {
			return transactionsManager.getSearchTransactionsLoader(accountId, transactionDirection, transactionFilters);
		}
		return transactionsManager.getTransactionsLoader(accountId, transactionDirection);
	}, [accountId, transactionDirection, transactionFilters]);

	const data = useObservable(loader.data);
	const unjustifiedData = useObservable(loader.unjustifiedData);
	const unqualifiedData = useObservable(loader.unqualifiedData);
	const initializing = useObservable(loader.initializing);
	const loading = useObservable(loader.loading);
	const refreshing = useObservable(loader.refreshing);
	const error = useObservable(loader.error);
	const loadingMore = useObservable(loader.loadingMore);
	const loadingMoreError = useObservable(loader.loadingMoreError);
	const cacheStatus = useObservable(loader.cacheStatus);
	const { canJustify, canShowJustifyLabel } = useTransactionLinks();

	useAsyncEffect(async () => {
		if (loadAtStartup) {
			await loader.load();
		}
	}, [loader]);

	const refresh = useCallback(async () => {
		await loader.refresh();
	}, [loader]);

	const canLoadMore = useMemo(() => data.offset + data.limit < data.total, [data.offset, data.limit, data.total]);

	const loadMore = useCallback(async () => {
		await loader.loadMore();
	}, [loader]);

	const unjustifiedTransactions = useMemo(() => {
		if (!data.items) {
			return [];
		}
		return data.items.filter(transaction => canShowJustifyLabel(transaction));
	}, [data.items]);

	const unjustifiedTransactions2 = useMemo(() => {
		if (!unjustifiedData.items) {
			return {
				total: 0,
				items: [],
			};
		}
		return {
			total: unjustifiedData.total,
			items: unjustifiedData.items,
		};
	}, [unjustifiedData.items]);

	const unqualifiedTransactions = useMemo(() => {
		if (!unqualifiedData.items) {
			return {
				total: 0,
				items: [],
			};
		}
		return {
			total: unqualifiedData.total,
			items: unqualifiedData.items,
		};
	}, [unqualifiedData.items]);

	return {
		data: data.items,
		unjustifiedTransactions,
		unjustifiedTransactions2,
		unqualifiedTransactions,
		loading: initializing || loading,
		error,
		canLoadMore,
		loadMore,
		loadingMore,
		loadingMoreError,
		refresh,
		refreshing,
		cacheStatus,
	};
}
