import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { authExecutor } from "../../../../../shared/core/service/services";
import { InitialOnboarding } from "../../../../../shared/domains/onboarding/onboarding";
import { Path } from "../../../../core/routing/path";
import { OnboardingState, useOnboardingMachine } from "../../../../machine/onboarding-machine";
import { DefaultButton } from "../../../common/buttons/default-button";
import { useQuitBlocking } from "../../../common/nav/use-quit-blocking";
import { theme } from "../../../styles/theme";
import EnrollmentLayout from "../shared/components/enrollment-layout";
import OnboardingInitialForm from "./components/onboarding-initial-form";
import { OnboardingInitialFormSuccess } from "./components/onboarding-success";

const centeredStates = [OnboardingState.InitiateOnboardingSuccess];

export const OnboardingScreen = () => {
	const { formatMessage } = useIntl();
	const history = useHistory();

	const [data, setData] = React.useState<InitialOnboarding>({
		distributorConfigurationCode: "",
		phoneNumber: undefined,
		email: "",
		firstName: "",
		lastName: "",
	});

	const { state, context, start } = useOnboardingMachine(OnboardingState.WaitingForData);

	const { redirectIfAllowed } = useQuitBlocking(false, formatMessage("registerScreen.quitConfirmMessage"));

	const connect = () => {
		if (authExecutor.withPhoneNumberAndPinCode()) {
			history.push(`${Path.Connect}?phone=${data.phoneNumber?.nationalNumber}`);
		} else {
			authExecutor.connect();
		}
	};

	const renderRegisterMachine = () => {
		switch (state) {
			case OnboardingState.InitiateOnboarding:
			case OnboardingState.WaitingForData:
				return (
					<>
						<OnboardingInitialForm
							value={data}
							onChange={setData}
							onSubmit={data => {
								start(data);
							}}
							onCancel={() => redirectIfAllowed(Path.Root)}
							loading={state === OnboardingState.InitiateOnboarding}
							errorMessage={context.error}
						/>
						<BottomContainer>
							<ConnectQuestion>{formatMessage("registerScreen.connectQuestion")}</ConnectQuestion>
							<ConnectButton onClick={connect}>{formatMessage("registerScreen.connectButton")}</ConnectButton>
						</BottomContainer>
					</>
				);
			case OnboardingState.InitiateOnboardingSuccess:
				return (
					<>
						<OnboardingInitialFormSuccess onConnect={connect} />
					</>
				);
		}
	};

	return <EnrollmentLayout centered={centeredStates.includes(state)}>{renderRegisterMachine()}</EnrollmentLayout>;
};

const BottomContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 48px;
	white-space: pre;
	align-items: baseline;
`;

const ConnectQuestion = styled.span`
	${theme.mediumText}
	font-size: 0.9375rem;
	color: black;
`;

const ConnectButton = styled(DefaultButton)`
	${theme.boldText}
	font-size: 0.9375rem;
	padding: 0;
	color: ${theme.mainColor};
`;
