import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError,
} from "../../shared/domains/pincode/pincode-error";
import { AdditionalData, ValidateBillPayment } from "../../shared/domains/bills/bill-service";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";
import { Machine, assign, send } from "xstate";
import { billIssuerSelectionManager, billManager, pincodeKeyboardService } from "../../shared/core/service/services";

import { Amount } from "../../shared/core/amount/amount";
import { BillIssuer } from "../../shared/domains/bills/bill";
import { ConfirmationMode } from "../../shared/domains/transactions/transaction-request";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import { Transaction } from "../../shared/domains/accounting-transaction/transaction/transaction";
import { useMachine } from "@xstate/react";

export const usePaybillMachine = () => {
	const [state, sendEvent] = useMachine(paybillMachine);

	const startPaybillConfirmation = (
		billIssuer: BillIssuer,
		sessionId: string,
		confirmationMode: ConfirmationMode,
		transaction: Transaction,
		additionalData: AdditionalData | undefined
	) => sendEvent("FORM_ALL_FILLED", { billIssuer, sessionId, confirmationMode, transaction, additionalData });

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};

	const userConfirmPayment = () => {
		sendEvent("USER_CONFIRM_PAYMENT");
	};
	
	return {
		state: state.value as PaybillState | PincodeState,
		context: state.context,
		startPaybillConfirmation,
		submitPincode,
		userConfirmPayment,
	};
};

export interface PaybillMachineContext {
	sessionId?: string;
	confirmationMode?: ConfirmationMode;
	transaction?: Transaction;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	billPaymentResult?: ValidateBillPayment;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError;
}

export enum PaybillState {
	FillingForm = "FillingForm",
	UserConfirmPayment = 'UserConfirmPayment',
	SubmitPayment = "SubmitPayment",
	SubmitPaymentError = "SubmitPaymentError",
	Done = "Done",
}
type PaybillEvent =
	| {
			type: "FORM_ALL_FILLED";
			billIssuer: BillIssuer;
			sessionId: string;
			confirmationMode: ConfirmationMode;
			transaction: Transaction;
			additionalData: AdditionalData | undefined;
	  }
	| { type: "CONFIRM" }
	| { type: "PAYMENT_DONE" }
	| { type: "PAYMENT_CONFIRMATION_PINCODE_ERROR" }
	| { type: "PAYMENT_CONFIRMATION_ERROR" }
	| { type: "USER_CONFIRM_PAYMENT" }
	| KeyboardEvent;

enum PaybillInvokeName {
	FetchKeyboard = "FetchKeyboard",
	SubmitPayment = "SubmitPayment",
}


const paybillMachine = Machine<PaybillMachineContext, PaybillEvent>({
	id: "paybill",
	initial: PaybillState.FillingForm,
	states: {
		[PaybillState.FillingForm]: {
			on: {
				FORM_ALL_FILLED: {
					actions: [
						assign({
							sessionId: (_, event) => event.sessionId,
							confirmationMode: (_, event) => event.confirmationMode,
							transaction: (_, event) => event.transaction,
						}),
						send({type: "USER_CONFIRM_PAYMENT"}),
					],
				},
				USER_CONFIRM_PAYMENT: PaybillState.UserConfirmPayment,
			},
		},
		[PaybillState.UserConfirmPayment]: {
			on: {
				USER_CONFIRM_PAYMENT: {
					target: PincodeState.PincodeConfirmation,
				},
			},
		},
		[PincodeState.PincodeConfirmation]: {
			invoke: {
				id: PaybillInvokeName.FetchKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PincodeState.PromptingKeyboard]: {
			on: {
				SUBMIT_PINCODE: {
					target: PaybillState.SubmitPayment,
					actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
				},
			},
		},
		[PaybillState.SubmitPayment]: {
			invoke: {
				id: PaybillInvokeName.SubmitPayment,
				src: context =>
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					billManager.validateBillPayment(context.sessionId!, context.confirmationMode!, context.pincodeSubmission),
				onDone: {
					actions: [
						assign<PaybillMachineContext, { type: string; data: ValidateBillPayment | undefined }>({
							billPaymentResult: (_, event) => event.data,
							error: undefined,
						}),
						() => billIssuerSelectionManager.clear(),
						send({ type: "PAYMENT_DONE" }),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send(ctx =>
							ctx.confirmationMode === ConfirmationMode.PinCode
								? { type: "PAYMENT_CONFIRMATION_PINCODE_ERROR" }
								: { type: "PAYMENT_CONFIRMATION_ERROR" }
						),
					],
				},
			},
			on: {
				PAYMENT_DONE: PaybillState.Done,
				PAYMENT_CONFIRMATION_PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
				PAYMENT_CONFIRMATION_ERROR: PaybillState.SubmitPaymentError,
			},
		},
		[PincodeState.FetchKeyboardAfterError]: {
			invoke: {
				id: PaybillInvokeName.FetchKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PaybillState.SubmitPaymentError]: {
			exit: assign<PaybillMachineContext, PaybillEvent>({ error: undefined }),
			on: {
				FORM_ALL_FILLED: {
					actions: [
						assign({
							sessionId: (_, event) => event.sessionId,
							confirmationMode: (_, event) => event.confirmationMode,
						}),
						send(context =>
							context.confirmationMode === ConfirmationMode.PinCode ? { type: "PINCODE_CONFIRM" } : { type: "CONFIRM" }
						),
					],
				},
				PINCODE_CONFIRM: PincodeState.PincodeConfirmation,
				CONFIRM: PaybillState.SubmitPayment,
			},
		},
		[PaybillState.Done]: {
			type: "final",
		},
	},
});
