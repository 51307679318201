import { GeoPosition } from "react-native-geolocation-service";
import { logger } from "../../../core/logging/logger";
import { ConnectedApiService } from "../../../core/net/connected-api-service";
import { Paginated, PaginationOptions } from "../../../utils/pagination";
import { PincodeSubmission } from "../../pincode/pincode";
import { AccountBlockedErrorFromErrorResponse, isAccountBlockedErrorResponse } from "../../pincode/pincode-error";
import { ConfirmationMode, TransactionCallType, TransactionRequest } from "../transaction-request";
import { CashTransfer, CashTransferType } from "./cash-transfer";
import { CashTransferCodeNotFound } from "./retreive-cash-transfer-error";

export class CashTransferService {
	public constructor(private apiService: ConnectedApiService) {}

	public async fetchCashTransfer(pagination?: PaginationOptions) {
		try {
			const response = await this.apiService.instance.get<Paginated<CashTransfer>>(`/cash-transfers`, {
				params: { ...pagination },
			});
			return {
				...response.data,
				items: response.data.items.map(transfer => ({
					...transfer,
					type: transfer.code ? CashTransferType.Sent : CashTransferType.Received,
				})),
			};
		} catch (e) {
			logger.debug("CashTransferService", "Fetch cash transfer failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async startRetrieveCashTransfer(cashTransferCode: string, location?: GeoPosition | null) {
		try {
			const response = await this.apiService.instance.post<TransactionRequest>(`/transactions/retrieve-cash-transfer`, {
				metadata: {
					mode: TransactionCallType.PreAuth,
					location: location
						? {
								latitude: location.coords.latitude,
								longitude: location.coords.longitude,
						  }
						: undefined,
				},
				data: { cashTransferCode },
			});
			return response.data;
		} catch (e) {
			logger.debug("CashTransferService", "Start retrieve cash transfer failed", e);
			if (e?.response && e?.response?.status === 404) {
				throw new CashTransferCodeNotFound("Cash transactions code is not valid");
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async confirmRetrieveCashTransfer(
		confirmationMode: ConfirmationMode,
		cashTransferCode: string,
		pincode?: PincodeSubmission,
		location?: GeoPosition | null
	) {
		try {
			const response = await this.apiService.instance.post<TransactionRequest>(`/transactions/retrieve-cash-transfer`, {
				metadata: {
					mode: TransactionCallType.Transaction,
					confirmationMode,
					pincode,
					location: location
						? {
								latitude: location.coords.latitude,
								longitude: location.coords.longitude,
						  }
						: undefined,
				},
				data: {
					cashTransferCode,
				},
			});
			return response.data;
		} catch (e) {
			logger.debug("CashTransferService", "Confirm retrieve cash transfer failed", e);
			if (isAccountBlockedErrorResponse(e)) {
				throw AccountBlockedErrorFromErrorResponse(e);
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
