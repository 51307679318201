import { ProductCardActivationMode, ProductCardCreationProcess } from "../products/product";
import { cardManager, cardService, productManager } from "../../core/service/services";
import { useCallback, useState } from "react";

import { CREATE_PINCODE } from "./card-manager";
import { Card } from "./card";
import { isDefined } from "../../utils/assert";
import { useObservable } from "../../utils/observable";

export enum CardActivationStep {
	ActivationCode = "ACTIVATION_CODE",
	DefinePincode = "DEFINE_PINCODE",
	Success = "CARD_ACTIVATION_SUCCESS",
	Error = "CARD_ACTIVATION_ERROR",
	SCAActivationCheck = "SCA_ACTIVATION_CHECK", //custom VQ step
	SCAPincodeCheck = "SCA_PINCODE_CHECK", //custom VQ step
}

export const useCardActivation = () => {
	const [step, setStep] = useState<CardActivationStep | undefined>(undefined);
	const [scaActivationToken, setScaActivationToken] = useState<string>('');
	const [scaPincodeToken, setScaPincodeToken] = useState<string>('');

	const [activationCode, setActivationCode] = useState<string>("");
	const [activationError, setActivationError] = useState<string | undefined>(undefined);

	const debitCardProducts = useObservable(productManager.debitCardProducts);

	const resetStepToActivation = () => {
		setStep(CardActivationStep.ActivationCode)
	}
	const resetStepToPincode = () => {
		setStep(CardActivationStep.DefinePincode)
	}

	const productForCard = useCallback(
		(selectedCard: Card) => {
			const cardProcess = debitCardProducts.filter(product => product.id === selectedCard.productId);
			if (cardProcess.length == 0) {
				throw new Error("missing card product information");
			}

			return cardProcess[0];
		},
		[debitCardProducts]
	);

	const activateCard = useCallback(
		async (cardToActivate: Card, activationCodeInput?: string, scaSessionToken?: string) => {
			try {
				if (!isDefined(cardToActivate)) {
					throw new Error("missing card");
				}

				const productCard = productForCard(cardToActivate);
				if (productCard.cardActivationMode === ProductCardActivationMode.withCode) {
					if (!isDefined(activationCodeInput) || activationCodeInput.length == 0) {
						throw new Error("missing activation code");
					}
				}
				setActivationError(undefined);

				await cardManager.activate(cardToActivate.id, activationCodeInput, scaSessionToken);
				setStep(getStepFromCardCreationProcess(productCard.cardCreationProcess));
			} catch (e) {
				//SCA use case, must send and verify the given token
				if(e.response?.data?.error === 'Unauthorized' && e.response?.data?.new_token) {
					setScaActivationToken(e.response.data.new_token)
					setStep(CardActivationStep.SCAActivationCheck)
					cardService.scaAuthenticationSendToken(e.response.data.new_token)
				} else {
					setActivationError(e.response?.data?.error?.message || e.toString());
					setStep(CardActivationStep.Error);
				}
			}
		},
		[productForCard]
	);

	const startActivationFlow = useCallback(
		async (cardToActivate: Card) => {
			const productCard = productForCard(cardToActivate);
			if (productCard.cardActivationMode === ProductCardActivationMode.withCode) {
				setStep(CardActivationStep.ActivationCode);
			} else {
				await activateCard(cardToActivate);
			}
		},
		[setStep]
	);

	const reissuePincodeOnCard = useCallback(
		async (cardToUse: Card, newPincode: string, scaToken?: string) => {
			try {
				if (!isDefined(activationCode)) {
					throw new Error("missing activation code");
				}
				setActivationError(undefined);
				await cardManager.reissuePincode(cardToUse.id, newPincode, CREATE_PINCODE, scaToken || '');
				setStep(CardActivationStep.Success);
			} catch (e) {
				if(e.response?.data?.error === 'Unauthorized' && e.response?.data?.new_token) {
					setScaPincodeToken(e.response.data.new_token)
					setStep(CardActivationStep.SCAPincodeCheck)
					cardService.scaAuthenticationSendToken(e.response.data.new_token)
				} else {
					setActivationError(e.response?.data?.error?.message || e.toString());
					setStep(CardActivationStep.Error);
				}
			}
		},
		[setStep, setActivationError]
	);

	const validatePincodeOnCard = useCallback(
		async (card: Card, pincode: string, scaSessionToken?: string) => {
			await reissuePincodeOnCard(card, pincode, scaSessionToken);
		},
		[reissuePincodeOnCard]
	);

	const getStepFromCardCreationProcess = (process?: ProductCardCreationProcess) => {
		if (process?.isPincodeSelectedByClient) {
			return CardActivationStep.DefinePincode;
		}
		return CardActivationStep.Success;
	};

	return {
		startActivationFlow,
		step,
		activationCode,
		setActivationCode,
		activateCard,
		validatePincode: validatePincodeOnCard,
		activationError,
		scaActivationToken,
		scaPincodeToken,
		resetStepToActivation,
		resetStepToPincode,
	};
};
