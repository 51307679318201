import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../../../shared/core/logging/logger";
import { Card, CardFeature, CardFeatureType, CardStatus } from "../../../../../../shared/domains/cards/card";
import { isDefined } from "../../../../../../shared/utils/assert";
import { SwitchContainer } from "../../../../common/buttons/switch-button";
import { ErrorMessage } from "../../../../common/error-message";
import { PadlockLocked } from "../../../../common/svg/padlock";
import { theme } from "../../../../styles/theme";
import { cardFeatures } from "./card-features";

interface CardFeaturesSwitchesProps {
	card: Card;
	loading: boolean;
	enableFeature: (feature: CardFeatureType, isEnabled: boolean) => Promise<void>;
	className?: string;
	disabled?: boolean;
}

export function CardFeaturesSwitches(props: CardFeaturesSwitchesProps) {
	const { card, disabled, ...otherProps } = props;

	const { formatMessage } = useIntl();

	const isVisible = useMemo(
		() => card.status === CardStatus.Active || card.status === CardStatus.Blocked,
		[card.status]
	);

	if (!isVisible || card.features === undefined || card.features.length == 0) {
		return null;
	}

	return (
		<CardFeaturesWrapper>
			<CardFeaturesContainer
				onClick={e => e.stopPropagation()}
				available={card.status === CardStatus.Active && !disabled}>
				{card.features.map((value, index) => (
					<StyledCardFeatureSwitch key={index} card={card} feature={value} {...otherProps} />
				))}
			</CardFeaturesContainer>
			{card.status === CardStatus.Blocked ? (
				<OverlayContainer>
					<Overlay />
					<LockContainer>
						<Lock>
							<LockImage />
						</Lock>
						<LockText>{formatMessage("cardOptions.featuresSwitchesOverlay.cardLocked")}</LockText>
					</LockContainer>
				</OverlayContainer>
			) : null}
		</CardFeaturesWrapper>
	);
}

interface CardFeatureSwitchProps extends CardFeaturesSwitchesProps {
	feature: CardFeature;
}

function CardFeatureSwitch(props: CardFeatureSwitchProps) {
	const { card, feature, loading, enableFeature, className } = props;
	const { formatMessage } = useIntl();
	const [error, setError] = useState<string | null>(null);
	const [isFeatureEnabled, setIsFeatureEnabled] = useState(feature.enabled);

	const featureLabel = cardFeatures[feature.id];

	useEffect(() => {
		setError(null);
	}, [card]);

	useEffect(() => {
		setIsFeatureEnabled(feature.enabled);
	}, [feature.enabled, setIsFeatureEnabled]);

	const enableFeatureWithError = async (isEnabled: boolean) => {
		if (!loading && card.status === CardStatus.Active) {
			try {
				setError(null);
				setIsFeatureEnabled(isEnabled);
				await enableFeature(feature.id, isEnabled);
			} catch (e) {
				setIsFeatureEnabled(!isEnabled);
				logger.debug(e);
				setError(e?.response?.data?.error?.message);
			}
		}
	};

	return (
		<>
			{isDefined(featureLabel) && (
				<SwitchContainer
					className={className}
					getSwitchLabel={() => formatMessage(featureLabel)}
					value={isFeatureEnabled}
					onSwitch={enableFeatureWithError}
				/>
			)}
			{error && <StyledErrorMessage>{error}</StyledErrorMessage>}
		</>
	);
}

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 0px;
`;

const StyledCardFeatureSwitch = styled(CardFeatureSwitch)`
	width: 100%;
`;

const CardFeaturesWrapper = styled.div`
	position: relative;
`;

const CardFeaturesContainer = styled.div<{ available: boolean }>`
	opacity: ${props => (!props.available ? 0.3 : null)};
	pointer-events: ${props => (!props.available ? "none" : null)};
	margin-top: 20px;
`;

const OverlayContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const Overlay = styled.div`
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	opacity: 0.8;
	border-radius: 13px;
	padding: 10px 15px;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const LockContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 2;
`;

const Lock = styled.div`
	background-color: #000000;
	width: 42px;
	height: 42px;
	border-radius: 21px;
`;

const LockImage = styled(PadlockLocked)`
	position: relative;
	top: 13px;
	left: 13px;
	width: 16px;
	height: 16px;
	fill: #ffffff;
`;

const LockText = styled.div`
	${theme.boldText}
	font-size: 0.8125rem;
	color: #000000;
	margin-top: 12px;
`;
