import { Client } from "./client";
import { ClientLinks } from "./client-links";

export const useClientLinks = () => {
	const canGetOnboardinFile = (client?: Client) => {
		if (!client?.links) {
			return false;
		}
		return client.links.findIndex(link => link.rel === ClientLinks.GetOngoingOnboardingFile) > -1;
	};

  const canUpdateEmailAddress = (client?: Client) => {
		if (!client?.links) {
			return false;
		}
		return client.links.findIndex(link => link.rel === ClientLinks.UpdateEmailAddress) > -1;
	};

  const updateEmailAddressLink = (client?: Client) => {
    if (!client?.links) {
      return "";
    }
    const link = client.links.find(link => link.rel === ClientLinks.UpdateEmailAddress);
    return link?.href || "";
  };

	return { updateEmailAddressLink, canGetOnboardinFile, canUpdateEmailAddress };
};
