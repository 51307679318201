import { useCallback, useState } from "react";
import { logger } from "../../core/logging/logger";
import { cardManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";
import { CardFeatureType, OpposeCardReason } from "./card";

export const useCards = () => {
	const cards = useObservable(cardManager.cards);
	const [loading, setLoading] = useState(true);

	useAsyncEffect(async () => {
		try {
			setLoading(true);
			await cardManager.refresh();
		} catch (e) {
			logger.debug("error fetching cards", e);
		} finally {
			setLoading(false);
		}
	}, []);
	return { cards, loading };
};

export const useCardOperation = (cardId?: string | null) => {
	const [loading, setLoading] = useState(false);

	const operationWrapper = useCallback(async (operation: () => Promise<void>) => {
		try {
			setLoading(true);
			await operation();
		} catch (e) {
			throw e;
		} finally {
			setLoading(false);
		}
	}, []);

	return {
		loading,
		oppose: (reason: OpposeCardReason) =>
			operationWrapper(async () => {
				cardId && (await cardManager.oppose(cardId, reason));
			}),
		deactivate: () =>
			operationWrapper(async () => {
				cardId && (await cardManager.deactivate(cardId));
			}),
		block: () =>
			operationWrapper(async () => {
				cardId && (await cardManager.block(cardId));
			}),
		unblock: () =>
			operationWrapper(async () => {
				cardId && (await cardManager.unblock(cardId));
			}),
		activate: (cardIdentifier: string) =>
			operationWrapper(async () => {
				cardId && (await cardManager.activate(cardId, cardIdentifier));
			}),
		enableFeature: (feature: CardFeatureType, isEnabled: boolean) =>
			operationWrapper(async () => {
				cardId && (await cardManager.enableFeature(cardId, feature, isEnabled));
			}),
	};
};
