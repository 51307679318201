import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { accountStatementService } from "../../../../shared/core/service/services";
import { AccountStatement } from "../../../../shared/domains/account-statements/account-statement";
import { Account, AccountStatus, AccountType } from "../../../../shared/domains/account/account";
import { statusColors } from "../../../../shared/domains/account/account-status-labels-and-colors";
import { accountTypeLabels } from "../../../../shared/domains/account/account-types-labels";
import { FeesStatement } from "../../../../shared/domains/fees-statements/fees-statement";
import { isDefined } from "../../../../shared/utils/assert";
import { Paginated } from "../../../../shared/utils/pagination";
import { ACCOUNT_DETAIL_MODAL_ID } from "../../../core/modal/modal-id";
import { AccountStatusView } from "../../common/account-status-view";
import { AmountText } from "../../common/amount-text";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { ScrollableDiv } from "../../common/modal/scrollable-div";
import { Spinner } from "../../common/spinner";
import { shadows, theme } from "../../styles/theme";
import { ClipboardInformationRow } from "./components/clipboard-information-row";
import { StatementRow } from "./components/statement-row";

export interface AccountDetailsModalProps {
	account: Account;
}

export const AccountDetailsModal = (props: AccountDetailsModalProps) => {
	const { account } = props;
	const { formatMessage } = useIntl();

	const statusMessage = statusMessageKey(account.status);

	return (
		<RoundedModalContainer closeButton id={ACCOUNT_DETAIL_MODAL_ID}>
			<ContentContainer>
				<AccountTypeText>{formatMessage(`account.${accountTypeLabels[account.type]}`)}</AccountTypeText>
				<ScrollableDiv>
					<StyledAccountStatus account={account} />
					<AccountAvailableBalanceContainer>
						<AccountAvailableBalanceTitle>
							{formatMessage("accountDetails.availableBalanceTitle")}
						</AccountAvailableBalanceTitle>
						<AccountAvailableBalance amount={account.availableBalance} />
					</AccountAvailableBalanceContainer>
					{statusMessage && (
						<StatusMessage color={statusColors[account.status]}>{formatMessage(statusMessage)}</StatusMessage>
					)}
					{account.type === AccountType.Dedicated && isDefined(account.merchant) ? (
						<DedicatedAccountContainer>
							<DedicatedAccountMessage>{formatMessage("accountDetails.dedicatedMessage")}</DedicatedAccountMessage>
							<DedicatedAccountMerchant>{account.merchant}</DedicatedAccountMerchant>
						</DedicatedAccountContainer>
					) : null}
					<AccountBalanceContainer>
						<AccountBalanceTitleContainer>
							<AccountBalanceTitle>{formatMessage("accountDetails.balanceTitle")}</AccountBalanceTitle>
							<AccountBalance amount={account.balance}></AccountBalance>
						</AccountBalanceTitleContainer>
						<AccountBalanceMessage>{formatMessage("accountDetails.balanceMessage")}</AccountBalanceMessage>
					</AccountBalanceContainer>
					<InformationContainer>
						<StyledClipboardInformationRow title={formatMessage("accountDetails.accountIdTitle")} value={account.id} />
						{isDefined(account.iban) && (
							<StyledClipboardInformationRow title={formatMessage("accountDetails.ibanTitle")} value={account.iban} />
						)}
						{isDefined(account.bic) && (
							<StyledClipboardInformationRow title={formatMessage("accountDetails.bicTitle")} value={account.bic} />
						)}
						{isDefined(account.externalData) &&
							Object.entries(account.externalData).map(([title, value], index) => (
								<StyledClipboardInformationRow key={index} title={title} value={value} />
							))}
					</InformationContainer>
					<Statements account={account} />
					<FeesStatements account={account} />
				</ScrollableDiv>
			</ContentContainer>
		</RoundedModalContainer>
	);
};

interface StatementsProps {
	account: Account;
}
export const Statements = (props: StatementsProps) => {
	const { account } = props;
	const [statements, setStatements] = useState<Paginated<AccountStatement> | null>(null);
	useEffect(() => {
		accountStatementService.fetchStatements(account.id).then(result => setStatements(result));
	}, [account.id]);
	const { formatMessage } = useIntl();

	if (statements === null) {
		return (
			<StatementContainer>
				<StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			</StatementContainer>
		);
	} else if (statements.items.length > 0) {
		return (
			<StatementContainer>
				<StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
				{statements.items.map(it => (
					<StyledStatementRow key={it.id} statement={it} account={account} />
				))}
			</StatementContainer>
		);
	}
	return (
		<StatementContainer>
			<StatementTitle>{formatMessage("accountsStatements.title")}</StatementTitle>
			<NoStatementAvailable>{formatMessage("accountsStatements.emptyStatementList")}</NoStatementAvailable>
		</StatementContainer>
	);
};

interface FeesStatementsProps {
	account: Account;
}
export const FeesStatements = (props: FeesStatementsProps) => {
	const { account } = props;
	const [statements, setStatements] = useState<Paginated<FeesStatement> | null>(null);
	useEffect(() => {
		accountStatementService.fetchFeesStatements(account.id).then(result => setStatements(result));
	}, [account.id]);
	const { formatMessage } = useIntl();

	if (statements === null) {
		return (
			<StatementContainer>
				<StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
				<SpinnerContainer>
					<Spinner />
				</SpinnerContainer>
			</StatementContainer>
		);
	} else if (statements.items.length > 0) {
		return (
			<StatementContainer>
				<StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
				{statements.items.map(it => (
					<StyledStatementRow key={it.id} variant="fee" statement={it} account={account} />
				))}
			</StatementContainer>
		);
	}
	return (
		<StatementContainer>
			<StatementTitle>{formatMessage("feesStatements.title")}</StatementTitle>
			<NoStatementAvailable>{formatMessage("feesStatements.emptyStatementList")}</NoStatementAvailable>
		</StatementContainer>
	);
};

const statusMessageKey = (status: AccountStatus) => {
	switch (status) {
		case AccountStatus.Blocked:
			return `accountDetails.accountStatus.blocked`;
		case AccountStatus.CreditBlocked:
			return `accountDetails.accountStatus.creditBlocked`;
		case AccountStatus.DebitBlocked:
			return `accountDetails.accountStatus.debitBlocked`;
		default:
			return undefined;
	}
};

const ContentContainer = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
	height: 530px;
`;

const AccountTypeText = styled.div`
	${theme.boldText};
	font-size: 1.375rem;
	margin-left: 30px;
	margin-bottom: 20px;
`;

const StyledAccountStatus = styled(AccountStatusView)`
	position: absolute;
	right: 15px;
	top: 0px;
`;

const AccountAvailableBalanceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	align-self: stretch;
`;

const AccountAvailableBalanceTitle = styled.span`
	${theme.mediumText};
	font-size: 1rem;
	color: #b1b1b1;
`;

const AccountAvailableBalance = styled(AmountText)`
	font-size: 2rem;
	${theme.boldText};
	margin-top: 5px;
`;

const StatusMessage = styled.div<{ color: string }>`
	color: ${props => props.color};
	text-align: center;
	font-size: 0.875rem;
	${theme.mediumText}
	margin: 30px 30px 0px 30px;
`;

const DedicatedAccountContainer = styled.div`
	display: flex;
	flex-direction: column;
	${shadows.medium};
	margin: 15px 20px 15px 20px;
	padding: 25px 15px 25px 15px;
	border-radius: 13px;
	align-items: center;
`;
const DedicatedAccountMessage = styled.div`
	${theme.text};
	font-size: 1rem;
	color: #b1b1b1;
	text-align: center;
`;

const DedicatedAccountMerchant = styled.div`
	${theme.boldText};
	font-size: 0.875rem;
	color: ${theme.mainColor};
`;

const AccountBalanceContainer = styled(DedicatedAccountContainer)``;
const AccountBalanceTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: stretch;
	margin-bottom: 10px;
`;
const AccountBalanceTitle = styled.span`
	${theme.boldText};
`;
const AccountBalance = styled(AmountText)`
	${theme.boldText};
	color: ${theme.mainColor};
`;
const AccountBalanceMessage = styled.span`
	text-align: center;
	color: #b1b1b1;
	font-size: 0.875rem;
`;

const InformationContainer = styled.div`
	padding: 0px 30px 40px 30px;
`;

const StyledClipboardInformationRow = styled(ClipboardInformationRow)`
	&:not(:last-of-type) {
		margin-bottom: 15px;
	}
`;

const StatementContainer = styled.div`
	padding: 0px 30px 40px 30px;
`;

const StatementTitle = styled.div`
	${theme.boldText};
	font-size: 1.125rem;
	margin-bottom: 15px;
`;
const StyledStatementRow = styled(StatementRow)`
	padding: 15px 15px 15px 15px;
	${shadows.medium};
	border-radius: 13px;
	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
`;

const NoStatementAvailable = styled.span`
	font-size: 0.9375rem;
	color: #b1b1b1;
`;

const SpinnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
