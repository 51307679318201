import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import {
	Card,
	CardPendingOperation,
	CardPendingOperationType,
	CardStatus,
} from "../../../../../../shared/domains/cards/card";
import { useRTL } from "../../../../../domain/language/use-rtl";
import { DefaultButton } from "../../../../common/buttons/default-button";
import { ResetIcon } from "../../../../common/svg/reset-icon";
import { theme } from "../../../../styles/theme";
import { getCardConfigurations } from "../card-configurations";
import { cardFeatures } from "../options/card-features";

interface AdditionalInfosSectionProps extends HTMLAttributes<HTMLBaseElement> {
	card: Card;
	onClick: () => void;
}

export function AdditionalInfosSection(props: AdditionalInfosSectionProps) {
	const { card, onClick } = props;
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();

	if (card.pendingManagementOperation === undefined && card.status != CardStatus.Opposed) {
		return null;
	}

	const pendingOperationMessage = (infos: CardPendingOperation | undefined) => {
		switch (infos?.operationType) {
			case CardPendingOperationType.Refabrication:
				return formatMessage("card.pendingOperation.refabrication");
			case CardPendingOperationType.CodeReissuing:
				return formatMessage("card.pendingOperation.codeReissuing");
			case CardPendingOperationType.Opposition:
				return formatMessage("card.pendingOperation.opposition");
			case CardPendingOperationType.Blocking:
				return formatMessage("card.pendingOperation.blocking");
			case CardPendingOperationType.Unblocking:
				return formatMessage("card.pendingOperation.unblocking");
			case CardPendingOperationType.Activation:
				return formatMessage("card.pendingOperation.activation");
			case CardPendingOperationType.Creation:
				return formatMessage("card.pendingOperation.creation");
			case CardPendingOperationType.LimitsUpdate:
				return formatMessage("card.pendingOperation.limitsUpdate");
			case CardPendingOperationType.FeaturesUpdate:
				const feature = infos.featureId ? formatMessage(cardFeatures[infos.featureId]) : undefined;
				return feature
					? formatMessage("card.pendingOperation.featuresUpdate", { feature })
					: formatMessage("card.pendingOperation.unknownFeatureUpdate");
		}
	};

	const { statusColor } = getCardConfigurations(card.status);

	return (
		<GlobalContainer>
			<LabelContainer $isRTL={isRTL}>
				<Title>{formatMessage("cardsList.infos.title")}</Title>
				<InfosContainer>
					<InfosLabel>
						{card.status === CardStatus.Opposed
							? formatMessage("cardOptions.cardOpposedMessage")
							: pendingOperationMessage(card.pendingManagementOperation)}
					</InfosLabel>
					{card.pendingManagementOperation !== undefined ? (
						<RefreshButton onClick={onClick} $isRTL={isRTL}>
							<ResetIcon />
						</RefreshButton>
					) : null}
				</InfosContainer>
				<StatusDot color={statusColor} $isRTL={isRTL} />
			</LabelContainer>
		</GlobalContainer>
	);
}

const GlobalContainer = styled.div`
	flex: 1;
	width: 100%;
`;

const LabelContainer = styled.div<{ $isRTL: boolean }>`
	flex: 1 0 auto;
	position: relative;
	flex-direction: column;
	background-color: #f4f4f4;
	border-radius: 13px;
	padding: ${props => (props.$isRTL ? `2px 16px 16px 48px` : `2px 48px 16px 16px`)};
`;

const Title = styled.h2`
	${theme.boldText};
	font-size: 0.9375rem;
	color: #000000;
`;

const InfosContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const InfosLabel = styled.span`
	${theme.text}
	font-size: 0.875rem;
	color: #aaaaaa;
`;

const StatusDot = styled.div<{ color?: string; $isRTL: boolean }>`
	position: absolute;
	display: flex;
	top: 18px;
	right: ${props => (props.$isRTL ? `unset` : `16px`)};
	left: ${props => (props.$isRTL ? `16px` : `unset`)};
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: ${props => props.color};
	opacity: 1;
`;

const RefreshButton = styled(DefaultButton)<{ $isRTL: boolean }>`
	left: ${props => (props.$isRTL ? 0 : 32)}px;
	right: ${props => (props.$isRTL ? 32 : 0)}px;
	top: 2px;
	height: 19px;
	width: 24px;
	svg {
		width: 19px;
		height: 24px;
		object-fit: contain;
		fill: #aaaaaa;
	}
`;
