export interface CountryData {
	englishName: string;
	localeName: string;
	phoneCode: string;
	flag: any;
}

export const countries: { [key: string]: CountryData } = {
	AD: { englishName: "Andorra", localeName: "Andorra", phoneCode: "376", flag: require("./AD.png") },
	AE: {
		englishName: "United Arab Emirates",
		localeName: "دولة الإمارات العربيّة المتّحدة",
		phoneCode: "971",
		flag: require("./AE.png"),
	},
	AF: { englishName: "Afghanistan", localeName: "جمهوری اسلامی افغانستان", phoneCode: "93", flag: require("./AF.png") },
	AG: {
		englishName: "Antigua and Barbuda",
		localeName: "Antigua and Barbuda",
		phoneCode: "1-268",
		flag: require("./AG.png"),
	},
	AI: { englishName: "Anguilla", localeName: "Anguilla", phoneCode: "1-264", flag: require("./AI.png") },
	AL: { englishName: "Albania", localeName: "Shqipëria", phoneCode: "355", flag: require("./AL.png") },
	AM: { englishName: "Armenia", localeName: "Հայաստան", phoneCode: "374", flag: require("./AM.png") },
	AO: { englishName: "Angola", localeName: "Angola", phoneCode: "244", flag: require("./AO.png") },
	AQ: {
		englishName: "Antarctica",
		localeName: "Antarctica, Antártico, Antarctique, Антарктике",
		phoneCode: "672",
		flag: undefined,
	},
	AR: { englishName: "Argentina", localeName: "Argentina", phoneCode: "54", flag: require("./AR.png") },
	AS: { englishName: "American Samoa", localeName: "American Samoa", phoneCode: "1-684", flag: require("./AS.png") },
	AT: { englishName: "Austria", localeName: "Österreich", phoneCode: "43", flag: require("./AT.png") },
	AU: { englishName: "Australia", localeName: "Australia", phoneCode: "61", flag: require("./AU.png") },
	AW: { englishName: "Aruba", localeName: "Aruba", phoneCode: "297", flag: require("./AW.png") },
	AX: { englishName: "Aland Islands", localeName: "Åland", phoneCode: "297", flag: require("./AX.png") },
	AZ: { englishName: "Azerbaijan", localeName: "Azərbaycan", phoneCode: "994", flag: require("./AZ.png") },
	BA: {
		englishName: "Bosnia and Herzegovina",
		localeName: "Bosna i Hercegovina",
		phoneCode: "387",
		flag: require("./BA.png"),
	},
	BB: { englishName: "Barbados", localeName: "Barbados", phoneCode: "1-246", flag: require("./BB.png") },
	BD: { englishName: "Bangladesh", localeName: "গণপ্রজাতন্ত্রী বাংলাদেশ", phoneCode: "880", flag: require("./BD.png") },
	BE: { englishName: "Belgium", localeName: "België, Belgique, Belgien", phoneCode: "32", flag: require("./BE.png") },
	BF: { englishName: "Burkina Faso", localeName: "Burkina Faso", phoneCode: "226", flag: require("./BF.png") },
	BG: { englishName: "Bulgaria", localeName: "България", phoneCode: "359", flag: require("./BG.png") },
	BH: { englishName: "Bahrein", localeName: "البحرين", phoneCode: "973", flag: require("./BH.png") },
	BI: { englishName: "Burundi", localeName: "Burundi", phoneCode: "257", flag: require("./BI.png") },
	BJ: { englishName: "Benin", localeName: "Bénin", phoneCode: "229", flag: require("./BJ.png") },
	BL: { englishName: "Saint-Barthélemy", localeName: "Saint-Barthélemy", phoneCode: "590", flag: require("./BL.png") },
	BM: { englishName: "Bermuda", localeName: "Bermuda", phoneCode: "1-441", flag: require("./BM.png") },
	BN: {
		englishName: "Brunei Darussalam",
		localeName: "Brunei Darussalam",
		phoneCode: "673",
		flag: require("./BN.png"),
	},
	BO: {
		englishName: "Bolivia",
		localeName: "Bolivia, Bulibiya, Volívia, Wuliwya",
		phoneCode: "591",
		flag: require("./BO.png"),
	},
	BQ: {
		englishName: "Caribbean Netherlands",
		localeName: "Caribisch Nederland",
		phoneCode: "591",
		flag: undefined,
	},
	BR: { englishName: "Brazil", localeName: "Brasil", phoneCode: "55", flag: require("./BR.png") },
	BS: { englishName: "Bahamas", localeName: "Bahamas", phoneCode: "1-242", flag: require("./BS.png") },
	BT: { englishName: "Bhutan", localeName: "འབྲུག་ཡུལ", phoneCode: "975", flag: require("./BT.png") },
	BV: { englishName: "Bouvet Island", localeName: "Bouvetøya", phoneCode: "975", flag: require("./BV.png") },
	BW: { englishName: "Botswana", localeName: "Botswana", phoneCode: "267", flag: require("./BW.png") },
	BY: { englishName: "Belarus", localeName: "Беларусь", phoneCode: "375", flag: require("./BY.png") },
	BZ: { englishName: "Belize", localeName: "Belize", phoneCode: "501", flag: require("./BZ.png") },
	CA: { englishName: "Canada", localeName: "Canada", phoneCode: "1", flag: require("./CA.png") },
	CC: {
		englishName: "Cocos (Keeling) Islands",
		localeName: "Cocos (Keeling) Islands",
		phoneCode: "61",
		flag: require("./CC.png"),
	},
	CD: {
		englishName: "Democratic Republic of the Congo",
		localeName: "République Démocratique du Congo",
		phoneCode: "243",
		flag: require("./CD.png"),
	},
	CF: {
		englishName: "Centrafrican Republic",
		localeName: "République centrafricaine, Ködörösêse tî Bêafrîka",
		phoneCode: "236",
		flag: require("./CF.png"),
	},
	CG: {
		englishName: "Republic of the Congo (Congo-Brazzaville)",
		localeName: "République du Congo",
		phoneCode: "242",
		flag: require("./CG.png"),
	},
	CH: {
		englishName: "Switzerland",
		localeName: "Schweiz, Suisse, Svizzera, Svizra",
		phoneCode: "41",
		flag: require("./CH.png"),
	},
	CI: { englishName: "Côte d'Ivoire", localeName: "Côte d'Ivoire", phoneCode: "225", flag: require("./CI.png") },
	CK: {
		englishName: "Cook Islands",
		localeName: "Cook Islands, Kūki ʻĀirani",
		phoneCode: "682",
		flag: require("./CK.png"),
	},
	CL: { englishName: "Chile", localeName: "Chile", phoneCode: "56", flag: require("./CL.png") },
	CM: { englishName: "Cameroon", localeName: "Cameroun, Cameroon", phoneCode: "237", flag: require("./CM.png") },
	CN: { englishName: "China", localeName: "中国", phoneCode: "86", flag: require("./CN.png") },
	CO: { englishName: "Colombia", localeName: "Colombia", phoneCode: "57", flag: require("./CO.png") },
	CR: { englishName: "Costa Rica", localeName: "Costa Rica", phoneCode: "506", flag: require("./CR.png") },
	CU: { englishName: "Cuba", localeName: "Cuba", phoneCode: "53", flag: require("./CU.png") },
	CV: { englishName: "Cabo Verde", localeName: "Cabo Verde", phoneCode: "238", flag: require("./CV.png") },
	CW: { englishName: "Curaçao", localeName: "Curaçao", phoneCode: "599", flag: require("./CW.png") },
	CX: { englishName: "Christmas Island", localeName: "Christmas Island", phoneCode: "61", flag: require("./CX.png") },
	CY: { englishName: "Cyprus", localeName: "Κύπρος, Kibris", phoneCode: "357", flag: require("./CY.png") },
	CZ: { englishName: "Czech Republic", localeName: "Česká republika", phoneCode: "420", flag: require("./CZ.png") },
	DE: { englishName: "Germany", localeName: "Deutschland", phoneCode: "49", flag: require("./DE.png") },
	DJ: {
		englishName: "Djibouti",
		localeName: "Djibouti, جيبوتي, Jabuuti, Gabuutih",
		phoneCode: "253",
		flag: require("./DJ.png"),
	},
	DK: { englishName: "Denmark", localeName: "Danmark", phoneCode: "45", flag: require("./DK.png") },
	DM: { englishName: "Dominica", localeName: "Dominica", phoneCode: "1-767", flag: require("./DM.png") },
	DO: {
		englishName: "Dominican Republic",
		localeName: "República Dominicana",
		phoneCode: "1",
		flag: require("./DO.png"),
	},
	DZ: { englishName: "Algeria", localeName: "الجزائر", phoneCode: "213", flag: require("./DZ.png") },
	EC: { englishName: "Ecuador", localeName: "Ecuador", phoneCode: "593", flag: require("./EC.png") },
	EE: { englishName: "Estonia", localeName: "Eesti", phoneCode: "372", flag: require("./EE.png") },
	EG: { englishName: "Egypt", localeName: "مصر", phoneCode: "20", flag: require("./EG.png") },
	EH: { englishName: "Western Sahara", localeName: "Sahara Occidental", phoneCode: "212", flag: undefined },
	ER: { englishName: "Eritrea", localeName: "ኤርትራ, إرتريا, Eritrea", phoneCode: "291", flag: require("./ER.png") },
	ES: { englishName: "Spain", localeName: "España", phoneCode: "34", flag: require("./ES.png") },
	ET: { englishName: "Ethiopia", localeName: "ኢትዮጵያ, Itoophiyaa", phoneCode: "251", flag: require("./ET.png") },
	FI: { englishName: "Finland", localeName: "Suomi", phoneCode: "358", flag: require("./FI.png") },
	FJ: { englishName: "Fiji", localeName: "Fiji", phoneCode: "679", flag: require("./FJ.png") },
	FK: { englishName: "Falkland Islands", localeName: "Falkland Islands", phoneCode: "500", flag: require("./FK.png") },
	FM: {
		englishName: "Micronesia (Federated States of)",
		localeName: "Micronesia",
		phoneCode: "691",
		flag: require("./FM.png"),
	},
	FO: { englishName: "Faroe Islands", localeName: "Føroyar, Færøerne", phoneCode: "298", flag: require("./FO.png") },
	FR: { englishName: "France", localeName: "France", phoneCode: "33", flag: require("./FR.png") },
	GA: { englishName: "Gabon", localeName: "Gabon", phoneCode: "241", flag: require("./GA.png") },
	GB: { englishName: "United Kingdom", localeName: "United Kingdom", phoneCode: "44", flag: require("./GB.png") },
	GD: { englishName: "Grenada", localeName: "Grenada", phoneCode: "1-473", flag: require("./GD.png") },
	GE: { englishName: "Georgia", localeName: "საქართველო", phoneCode: "995", flag: require("./GE.png") },
	GF: { englishName: "French Guiana", localeName: "Guyane française", phoneCode: "594", flag: require("./GF.png") },
	GG: { englishName: "Guernsey", localeName: "Guernsey", phoneCode: "44-1481", flag: require("./GG.png") },
	GH: { englishName: "Ghana", localeName: "Ghana", phoneCode: "233", flag: require("./GH.png") },
	GI: { englishName: "Gibraltar", localeName: "Gibraltar", phoneCode: "350", flag: require("./GI.png") },
	GL: {
		englishName: "Greenland",
		localeName: "Kalaallit Nunaat, Grønland",
		phoneCode: "299",
		flag: require("./GL.png"),
	},
	GM: { englishName: "The Gambia", localeName: "The Gambia", phoneCode: "220", flag: require("./GM.png") },
	GN: { englishName: "Guinea", localeName: "Guinée", phoneCode: "224", flag: require("./GN.png") },
	GP: { englishName: "Guadeloupe", localeName: "Guadeloupe", phoneCode: "590", flag: require("./GP.png") },
	GQ: {
		englishName: "Equatorial Guinea",
		localeName: "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
		phoneCode: "240",
		flag: require("./GQ.png"),
	},
	GR: { englishName: "Greece", localeName: "Ελλάδα", phoneCode: "30", flag: require("./GR.png") },
	GS: {
		englishName: "South Georgia and the South Sandwich Islands",
		localeName: "South Georgia and the South Sandwich Islands",
		phoneCode: "30",
		flag: require("./GS.png"),
	},
	GT: { englishName: "Guatemala", localeName: "Guatemala", phoneCode: "502", flag: require("./GT.png") },
	GU: { englishName: "Guam", localeName: "Guam, Guåhån", phoneCode: "1-671", flag: require("./GU.png") },
	GW: { englishName: "Guinea Bissau", localeName: "Guiné-Bissau", phoneCode: "245", flag: require("./GW.png") },
	GY: { englishName: "Guyana", localeName: "Guyana", phoneCode: "592", flag: require("./GY.png") },
	HK: {
		englishName: "Hong Kong (SAR of China)",
		localeName: "香港, Hong Kong",
		phoneCode: "852",
		flag: require("./HK.png"),
	},
	HM: {
		englishName: "Heard Island and McDonald Islands",
		localeName: "Heard Island and McDonald Islands",
		phoneCode: "852",
		flag: require("./HM.png"),
	},
	HN: { englishName: "Honduras", localeName: "Honduras", phoneCode: "504", flag: require("./HN.png") },
	HR: { englishName: "Croatia", localeName: "Hrvatska", phoneCode: "385", flag: require("./HR.png") },
	HT: { englishName: "Haiti", localeName: "Haïti, Ayiti", phoneCode: "509", flag: require("./HT.png") },
	HU: { englishName: "Hungary", localeName: "Magyarország", phoneCode: "36", flag: require("./HU.png") },
	ID: { englishName: "Indonesia", localeName: "Indonesia", phoneCode: "62", flag: require("./ID.png") },
	IE: { englishName: "Ireland", localeName: "Ireland, Éire", phoneCode: "353", flag: require("./IE.png") },
	IL: { englishName: "Israel", localeName: "ישראל", phoneCode: "972", flag: require("./IL.png") },
	IM: { englishName: "Isle of Man", localeName: "Isle of Man", phoneCode: "44-1624", flag: require("./IM.png") },
	IN: { englishName: "India", localeName: "भारत, India", phoneCode: "91", flag: require("./IN.png") },
	IO: {
		englishName: "British Indian Ocean Territory",
		localeName: "British Indian Ocean Territory",
		phoneCode: "246",
		flag: require("./IO.png"),
	},
	IQ: { englishName: "Iraq", localeName: "العراق, Iraq", phoneCode: "964", flag: require("./IQ.png") },
	IR: { englishName: "Iran", localeName: "ایران", phoneCode: "98", flag: require("./IR.png") },
	IS: { englishName: "Iceland", localeName: "Ísland", phoneCode: "354", flag: require("./IS.png") },
	IT: { englishName: "Italy", localeName: "Italia", phoneCode: "39", flag: require("./IT.png") },
	JE: { englishName: "Jersey", localeName: "Jersey", phoneCode: "44-1534", flag: require("./JE.png") },
	JM: { englishName: "Jamaica", localeName: "Jamaica", phoneCode: "1-876", flag: require("./JM.png") },
	JO: { englishName: "Jordan", localeName: "الأُرْدُن", phoneCode: "962", flag: require("./JO.png") },
	JP: { englishName: "Japan", localeName: "日本", phoneCode: "81", flag: require("./JP.png") },
	KE: { englishName: "Kenya", localeName: "Kenya", phoneCode: "254", flag: require("./KE.png") },
	KG: { englishName: "Kyrgyzstan", localeName: "Кыргызстан, Киргизия", phoneCode: "996", flag: require("./KG.png") },
	KH: { englishName: "Cambodia", localeName: "កម្ពុជា", phoneCode: "855", flag: require("./KH.png") },
	KI: { englishName: "Kiribati", localeName: "Kiribati", phoneCode: "686", flag: require("./KI.png") },
	KM: { englishName: "Comores", localeName: "ﺍﻟﻘﻤﺮي, Comores, Komori", phoneCode: "269", flag: require("./KM.png") },
	KN: {
		englishName: "Saint Kitts and Nevis",
		localeName: "Saint Kitts and Nevis",
		phoneCode: "1-869",
		flag: require("./KN.png"),
	},
	KP: { englishName: "North Korea", localeName: "북조선", phoneCode: "850", flag: require("./KP.png") },
	KR: { englishName: "South Korea", localeName: "대한민국", phoneCode: "82", flag: require("./KR.png") },
	KW: { englishName: "Kuweit", localeName: "الكويت", phoneCode: "965", flag: require("./KW.png") },
	KY: { englishName: "Cayman Islands", localeName: "Cayman Islands", phoneCode: "1-345", flag: require("./KY.png") },
	KZ: { englishName: "Kazakhstan", localeName: "Қазақстан, Казахстан", phoneCode: "7", flag: require("./KZ.png") },
	LA: { englishName: "Laos", localeName: "ປະຊາຊົນລາວ", phoneCode: "856", flag: require("./LA.png") },
	LB: { englishName: "Lebanon", localeName: "لبنان, Liban", phoneCode: "961", flag: require("./LB.png") },
	LC: { englishName: "Saint Lucia", localeName: "Saint Lucia", phoneCode: "1-758", flag: require("./LC.png") },
	LI: { englishName: "Liechtenstein", localeName: "Liechtenstein", phoneCode: "423", flag: require("./LI.png") },
	LK: { englishName: "Sri Lanka", localeName: "ශ්‍රී ලංකා, இலங்கை", phoneCode: "94", flag: require("./LK.png") },
	LR: { englishName: "Liberia", localeName: "Liberia", phoneCode: "231", flag: require("./LR.png") },
	LS: { englishName: "Lesotho", localeName: "Lesotho", phoneCode: "266", flag: require("./LS.png") },
	LT: { englishName: "Lithuania", localeName: "Lietuva", phoneCode: "370", flag: require("./LT.png") },
	LU: {
		englishName: "Luxembourg",
		localeName: "Lëtzebuerg, Luxembourg, Luxemburg",
		phoneCode: "352",
		flag: require("./LU.png"),
	},
	LV: { englishName: "Latvia", localeName: "Latvija", phoneCode: "371", flag: require("./LV.png") },
	LY: { englishName: "Libya", localeName: "ليبيا", phoneCode: "218", flag: require("./LY.png") },
	MA: { englishName: "Morocco", localeName: "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب", phoneCode: "212", flag: require("./MA.png") },
	MC: { englishName: "Monaco", localeName: "Monaco", phoneCode: "377", flag: require("./MC.png") },
	MD: { englishName: "Moldova", localeName: "Moldova, Молдавия", phoneCode: "373", flag: require("./MD.png") },
	ME: { englishName: "Montenegro", localeName: "Crna Gora, Црна Гора", phoneCode: "382", flag: require("./ME.png") },
	MF: {
		englishName: "Saint Martin (French part)",
		localeName: "Saint-Martin",
		phoneCode: "590",
		flag: require("./MF.png"),
	},
	MG: {
		englishName: "Madagascar",
		localeName: "Madagasikara, Madagascar",
		phoneCode: "261",
		flag: require("./MG.png"),
	},
	MH: { englishName: "Marshall Islands", localeName: "Marshall Islands", phoneCode: "692", flag: require("./MH.png") },
	MK: {
		englishName: "Macedonia (Former Yugoslav Republic of)",
		localeName: "Македонија",
		phoneCode: "389",
		flag: require("./MK.png"),
	},
	ML: { englishName: "Mali", localeName: "Mali", phoneCode: "223", flag: require("./ML.png") },
	MM: { englishName: "Myanmar", localeName: "မြန်မာ", phoneCode: "95", flag: require("./MM.png") },
	MN: { englishName: "Mongolia", localeName: "Монгол Улс", phoneCode: "976", flag: require("./MN.png") },
	MO: { englishName: "Macao (SAR of China)", localeName: "澳門, Macau", phoneCode: "853", flag: require("./MO.png") },
	MP: {
		englishName: "Northern Mariana Islands",
		localeName: "Northern Mariana Islands",
		phoneCode: "1-670",
		flag: require("./MP.png"),
	},
	MQ: { englishName: "Martinique", localeName: "Martinique", phoneCode: "596", flag: require("./MQ.png") },
	MR: { englishName: "Mauritania", localeName: "موريتانيا, Mauritanie", phoneCode: "222", flag: require("./MR.png") },
	MS: { englishName: "Montserrat", localeName: "Montserrat", phoneCode: "1-664", flag: require("./MS.png") },
	MT: { englishName: "Malta", localeName: "Malta", phoneCode: "356", flag: require("./MT.png") },
	MU: { englishName: "Mauritius", localeName: "Maurice, Mauritius", phoneCode: "230", flag: require("./MU.png") },
	MV: { englishName: "Maldives", localeName: "", phoneCode: "960", flag: require("./MV.png") },
	MW: { englishName: "Malawi", localeName: "Malawi", phoneCode: "265", flag: require("./MW.png") },
	MX: { englishName: "Mexico", localeName: "México", phoneCode: "52", flag: require("./MX.png") },
	MY: { englishName: "Malaysia", localeName: "", phoneCode: "60", flag: require("./MY.png") },
	MZ: { englishName: "Mozambique", localeName: "Mozambique", phoneCode: "258", flag: require("./MZ.png") },
	NA: { englishName: "Namibia", localeName: "Namibia", phoneCode: "264", flag: require("./NA.png") },
	NC: { englishName: "New Caledonia", localeName: "Nouvelle-Calédonie", phoneCode: "687", flag: require("./FR.png") },
	NE: { englishName: "Niger", localeName: "Niger", phoneCode: "227", flag: require("./NE.png") },
	NF: { englishName: "Norfolk Island", localeName: "Norfolk Island", phoneCode: "227", flag: require("./NF.png") },
	NG: { englishName: "Nigeria", localeName: "Nigeria", phoneCode: "234", flag: require("./NG.png") },
	NI: { englishName: "Nicaragua", localeName: "Nicaragua", phoneCode: "505", flag: require("./NI.png") },
	NL: { englishName: "The Netherlands", localeName: "Nederland", phoneCode: "31", flag: require("./NL.png") },
	NO: { englishName: "Norway", localeName: "Norge, Noreg", phoneCode: "47", flag: require("./NO.png") },
	NP: { englishName: "Nepal", localeName: "", phoneCode: "977", flag: require("./NP.png") },
	NR: { englishName: "Nauru", localeName: "Nauru", phoneCode: "674", flag: require("./NR.png") },
	NU: { englishName: "Niue", localeName: "Niue", phoneCode: "683", flag: require("./NU.png") },
	NZ: { englishName: "New Zealand", localeName: "New Zealand", phoneCode: "64", flag: require("./NZ.png") },
	OM: { englishName: "Oman", localeName: "سلطنة عُمان", phoneCode: "968", flag: require("./OM.png") },
	PA: { englishName: "Panama", localeName: "Panama", phoneCode: "507", flag: require("./PA.png") },
	PE: { englishName: "Peru", localeName: "Perú", phoneCode: "51", flag: require("./PE.png") },
	PF: {
		englishName: "French Polynesia",
		localeName: "Polynésie française",
		phoneCode: "689",
		flag: require("./PF.png"),
	},
	PG: { englishName: "Papua New Guinea", localeName: "Papua New Guinea", phoneCode: "675", flag: require("./PG.png") },
	PH: { englishName: "Philippines", localeName: "Philippines", phoneCode: "63", flag: require("./PH.png") },
	PK: { englishName: "Pakistan", localeName: "پاکستان", phoneCode: "92", flag: require("./PK.png") },
	PL: { englishName: "Poland", localeName: "Polska", phoneCode: "48", flag: require("./PL.png") },
	PM: {
		englishName: "Saint Pierre and Miquelon",
		localeName: "Saint-Pierre-et-Miquelon",
		phoneCode: "508",
		flag: require("./PM.png"),
	},
	PN: { englishName: "Pitcairn", localeName: "Pitcairn", phoneCode: "64", flag: require("./PN.png") },
	PR: { englishName: "Puerto Rico", localeName: "Puerto Rico", phoneCode: "1", flag: require("./PR.png") },
	PS: {
		englishName: "Palestinian Territory",
		localeName: "Palestinian Territory",
		phoneCode: "970",
		flag: require("./PS.png"),
	},
	PT: { englishName: "Portugal", localeName: "Portugal", phoneCode: "351", flag: require("./PT.png") },
	PW: { englishName: "Palau", localeName: "Palau", phoneCode: "680", flag: require("./PW.png") },
	PY: { englishName: "Paraguay", localeName: "Paraguay", phoneCode: "595", flag: require("./PY.png") },
	QA: { englishName: "Qatar", localeName: "قطر", phoneCode: "974", flag: require("./QA.png") },
	RE: { englishName: "Reunion", localeName: "La Réunion", phoneCode: "262", flag: require("./RE.png") },
	RO: { englishName: "Romania", localeName: "România", phoneCode: "40", flag: require("./RO.png") },
	RS: { englishName: "Serbia", localeName: "Србија", phoneCode: "381", flag: require("./RS.png") },
	RU: { englishName: "Russia", localeName: "Россия", phoneCode: "7", flag: require("./RU.png") },
	RW: { englishName: "Rwanda", localeName: "Rwanda", phoneCode: "250", flag: require("./RW.png") },
	SA: { englishName: "Saudi Arabia", localeName: "السعودية", phoneCode: "966", flag: require("./SA.png") },
	SB: { englishName: "Solomon Islands", localeName: "Solomon Islands", phoneCode: "677", flag: require("./SB.png") },
	SC: { englishName: "Seychelles", localeName: "Seychelles", phoneCode: "248", flag: require("./SC.png") },
	SD: { englishName: "Sudan", localeName: "السودان", phoneCode: "249", flag: require("./SD.png") },
	SE: { englishName: "Sweden", localeName: "Sverige", phoneCode: "46", flag: require("./SE.png") },
	SG: { englishName: "Singapore", localeName: "Singapore", phoneCode: "65", flag: require("./SG.png") },
	SH: { englishName: "Saint Helena", localeName: "Saint Helena", phoneCode: "290", flag: require("./SH.png") },
	SI: { englishName: "Slovenia", localeName: "Slovenija", phoneCode: "386", flag: require("./SI.png") },
	SJ: {
		englishName: "Svalbard and Jan Mayen",
		localeName: "Svalbard and Jan Mayen",
		phoneCode: "47",
		flag: require("./SJ.png"),
	},
	SK: { englishName: "Slovakia", localeName: "Slovensko", phoneCode: "421", flag: require("./SK.png") },
	SL: { englishName: "Sierra Leone", localeName: "Sierra Leone", phoneCode: "232", flag: require("./SL.png") },
	SM: { englishName: "San Marino", localeName: "San Marino", phoneCode: "378", flag: require("./SM.png") },
	SN: { englishName: "Sénégal", localeName: "Sénégal", phoneCode: "221", flag: require("./SN.png") },
	SO: { englishName: "Somalia", localeName: "Somalia, الصومال", phoneCode: "252", flag: require("./SO.png") },
	SR: { englishName: "Suriname", localeName: "Suriname", phoneCode: "597", flag: require("./SR.png") },
	ST: {
		englishName: "São Tomé and Príncipe",
		localeName: "São Tomé e Príncipe",
		phoneCode: "239",
		flag: require("./ST.png"),
	},
	SS: { englishName: "South Sudan", localeName: "South Sudan", phoneCode: "211", flag: require("./SS.png") },
	SV: { englishName: "El Salvador", localeName: "El Salvador", phoneCode: "503", flag: require("./SV.png") },
	SX: {
		englishName: "Saint Martin (Dutch part)",
		localeName: "Sint Maarten",
		phoneCode: "1-721",
		flag: require("./SX.png"),
	},
	SY: { englishName: "Syria", localeName: "سوريا, Sūriyya", phoneCode: "963", flag: require("./SY.png") },
	SZ: { englishName: "Swaziland", localeName: "Swaziland", phoneCode: "268", flag: require("./SZ.png") },
	TC: {
		englishName: "Turks and Caicos Islands",
		localeName: "Turks and Caicos Islands",
		phoneCode: "1-649",
		flag: require("./TC.png"),
	},
	TD: { englishName: "Chad", localeName: "Tchad, تشاد", phoneCode: "235", flag: require("./TD.png") },
	TF: {
		englishName: "French Southern and Antarctic Lands",
		localeName: "Terres australes et antarctiques françaises",
		phoneCode: "235",
		flag: require("./TF.png"),
	},
	TG: { englishName: "Togo", localeName: "Togo", phoneCode: "228", flag: require("./TG.png") },
	TH: { englishName: "Thailand", localeName: "ประเทศไทย", phoneCode: "66", flag: require("./TH.png") },
	TJ: { englishName: "Tajikistan", localeName: ",", phoneCode: "992", flag: require("./TJ.png") },
	TK: { englishName: "Tokelau", localeName: "Tokelau", phoneCode: "690", flag: require("./TK.png") },
	TL: {
		englishName: "Timor-Leste",
		localeName: "Timor-Leste, Timor Lorosa'e",
		phoneCode: "670",
		flag: require("./TL.png"),
	},
	TM: { englishName: "Turkmenistan", localeName: "Türkmenistan", phoneCode: "993", flag: require("./TM.png") },
	TN: { englishName: "Tunisia", localeName: "تونس, Tunisie", phoneCode: "216", flag: require("./TN.png") },
	TO: { englishName: "Tonga", localeName: "Tonga", phoneCode: "676", flag: require("./TO.png") },
	TR: { englishName: "Turkey", localeName: "Türkiye", phoneCode: "90", flag: require("./TR.png") },
	TT: {
		englishName: "Trinidad and Tobago",
		localeName: "Trinidad and Tobago",
		phoneCode: "1-868",
		flag: require("./TT.png"),
	},
	TV: { englishName: "Tuvalu", localeName: "Tuvalu", phoneCode: "688", flag: require("./TV.png") },
	TW: { englishName: "Taiwan", localeName: "Taiwan", phoneCode: "886", flag: require("./TW.png") },
	TZ: { englishName: "Tanzania", localeName: "Tanzania", phoneCode: "255", flag: require("./TZ.png") },
	UA: { englishName: "Ukraine", localeName: "Україна", phoneCode: "380", flag: require("./UA.png") },
	UG: { englishName: "Uganda", localeName: "Uganda", phoneCode: "256", flag: require("./UG.png") },
	UM: {
		englishName: "United States Minor Outlying Islands",
		localeName: "United States Minor Outlying Islands",
		phoneCode: "256",
		flag: require("./UM.png"),
	},
	US: {
		englishName: "United States of America",
		localeName: "United States of America",
		phoneCode: "1",
		flag: require("./US.png"),
	},
	UY: { englishName: "Uruguay", localeName: "Uruguay", phoneCode: "598", flag: require("./UY.png") },
	UZ: { englishName: "Uzbekistan", localeName: "", phoneCode: "998", flag: require("./UZ.png") },
	VA: {
		englishName: "City of the Vatican",
		localeName: "Città del Vaticano",
		phoneCode: "379",
		flag: require("./VA.png"),
	},
	VC: {
		englishName: "Saint Vincent and the Grenadines",
		localeName: "Saint Vincent and the Grenadines",
		phoneCode: "1-784",
		flag: require("./VC.png"),
	},
	VE: { englishName: "Venezuela", localeName: "Venezuela", phoneCode: "58", flag: require("./VE.png") },
	VG: {
		englishName: "British Virgin Islands",
		localeName: "British Virgin Islands",
		phoneCode: "1-284",
		flag: require("./VG.png"),
	},
	VI: {
		englishName: "United States Virgin Islands",
		localeName: "United States Virgin Islands",
		phoneCode: "1-340",
		flag: require("./VI.png"),
	},
	VN: { englishName: "Vietnam", localeName: "Việt Nam", phoneCode: "84", flag: require("./VN.png") },
	VU: { englishName: "Vanuatu", localeName: "Vanuatu", phoneCode: "678", flag: require("./VU.png") },
	WF: { englishName: "Wallis and Futuna", localeName: "Wallis-et-Futuna", phoneCode: "681", flag: require("./WF.png") },
	WS: { englishName: "Samoa", localeName: "Samoa", phoneCode: "685", flag: require("./WS.png") },
	YE: { englishName: "Yemen", localeName: "اليَمَن", phoneCode: "967", flag: require("./YE.png") },
	YT: { englishName: "Mayotte", localeName: "Mayotte", phoneCode: "269", flag: require("./YT.png") },
	ZA: { englishName: "South Africa", localeName: "South Africa", phoneCode: "27", flag: require("./ZA.png") },
	ZM: { englishName: "Zambia", localeName: "Zambia", phoneCode: "260", flag: require("./ZM.png") },
	ZW: { englishName: "Zimbabwe", localeName: "Zimbabwe", phoneCode: "263", flag: require("./ZW.png") },
};

export type Country = {
	name: string;
	"alpha-2": string;
	"iso-numeric": string;
};

export const countriesArray: Country[] = [
	{
		name: "Afghanistan",
		"alpha-2": "AF",
		"iso-numeric": "004",
	},
	{
		name: "Åland Islands",
		"alpha-2": "AX",
		"iso-numeric": "248",
	},
	{
		name: "Albania",
		"alpha-2": "AL",
		"iso-numeric": "008",
	},
	{
		name: "Algeria",
		"alpha-2": "DZ",
		"iso-numeric": "012",
	},
	{
		name: "American Samoa",
		"alpha-2": "AS",
		"iso-numeric": "016",
	},
	{
		name: "Andorra",
		"alpha-2": "AD",
		"iso-numeric": "020",
	},
	{
		name: "Angola",
		"alpha-2": "AO",
		"iso-numeric": "024",
	},
	{
		name: "Anguilla",
		"alpha-2": "AI",
		"iso-numeric": "660",
	},
	{
		name: "Antarctica",
		"alpha-2": "AQ",
		"iso-numeric": "010",
	},
	{
		name: "Antigua and Barbuda",
		"alpha-2": "AG",
		"iso-numeric": "028",
	},
	{
		name: "Argentina",
		"alpha-2": "AR",
		"iso-numeric": "032",
	},
	{
		name: "Armenia",
		"alpha-2": "AM",
		"iso-numeric": "051",
	},
	{
		name: "Aruba",
		"alpha-2": "AW",
		"iso-numeric": "533",
	},
	{
		name: "Australia",
		"alpha-2": "AU",
		"iso-numeric": "036",
	},
	{
		name: "Austria",
		"alpha-2": "AT",
		"iso-numeric": "040",
	},
	{
		name: "Azerbaijan",
		"alpha-2": "AZ",
		"iso-numeric": "031",
	},
	{
		name: "Bahamas",
		"alpha-2": "BS",
		"iso-numeric": "044",
	},
	{
		name: "Bahrain",
		"alpha-2": "BH",
		"iso-numeric": "048",
	},
	{
		name: "Bangladesh",
		"alpha-2": "BD",
		"iso-numeric": "050",
	},
	{
		name: "Barbados",
		"alpha-2": "BB",
		"iso-numeric": "052",
	},
	{
		name: "Belarus",
		"alpha-2": "BY",
		"iso-numeric": "112",
	},
	{
		name: "Belgium",
		"alpha-2": "BE",
		"iso-numeric": "056",
	},
	{
		name: "Belize",
		"alpha-2": "BZ",
		"iso-numeric": "084",
	},
	{
		name: "Benin",
		"alpha-2": "BJ",
		"iso-numeric": "204",
	},
	{
		name: "Bermuda",
		"alpha-2": "BM",
		"iso-numeric": "060",
	},
	{
		name: "Bhutan",
		"alpha-2": "BT",
		"iso-numeric": "064",
	},
	{
		name: "Bolivia (Plurinational State of)",
		"alpha-2": "BO",
		"iso-numeric": "068",
	},
	{
		name: "Bonaire, Sint Eustatius and Saba",
		"alpha-2": "BQ",
		"iso-numeric": "535",
	},
	{
		name: "Bosnia and Herzegovina",
		"alpha-2": "BA",
		"iso-numeric": "070",
	},
	{
		name: "Botswana",
		"alpha-2": "BW",
		"iso-numeric": "072",
	},
	{
		name: "Bouvet Island",
		"alpha-2": "BV",
		"iso-numeric": "074",
	},
	{
		name: "Brazil",
		"alpha-2": "BR",
		"iso-numeric": "076",
	},
	{
		name: "British Indian Ocean Territory",
		"alpha-2": "IO",
		"iso-numeric": "086",
	},
	{
		name: "Brunei Darussalam",
		"alpha-2": "BN",
		"iso-numeric": "096",
	},
	{
		name: "Bulgaria",
		"alpha-2": "BG",
		"iso-numeric": "100",
	},
	{
		name: "Burkina Faso",
		"alpha-2": "BF",
		"iso-numeric": "854",
	},
	{
		name: "Burundi",
		"alpha-2": "BI",
		"iso-numeric": "108",
	},
	{
		name: "Cabo Verde",
		"alpha-2": "CV",
		"iso-numeric": "132",
	},
	{
		name: "Cambodia",
		"alpha-2": "KH",
		"iso-numeric": "116",
	},
	{
		name: "Cameroon",
		"alpha-2": "CM",
		"iso-numeric": "120",
	},
	{
		name: "Canada",
		"alpha-2": "CA",
		"iso-numeric": "124",
	},
	{
		name: "Cayman Islands",
		"alpha-2": "KY",
		"iso-numeric": "136",
	},
	{
		name: "Central African Republic",
		"alpha-2": "CF",
		"iso-numeric": "140",
	},
	{
		name: "Chad",
		"alpha-2": "TD",
		"iso-numeric": "148",
	},
	{
		name: "Chile",
		"alpha-2": "CL",
		"iso-numeric": "152",
	},
	{
		name: "China",
		"alpha-2": "CN",
		"iso-numeric": "156",
	},
	{
		name: "Christmas Island",
		"alpha-2": "CX",
		"iso-numeric": "162",
	},
	{
		name: "Cocos (Keeling) Islands",
		"alpha-2": "CC",
		"iso-numeric": "166",
	},
	{
		name: "Colombia",
		"alpha-2": "CO",
		"iso-numeric": "170",
	},
	{
		name: "Comoros",
		"alpha-2": "KM",
		"iso-numeric": "174",
	},
	{
		name: "Congo",
		"alpha-2": "CG",
		"iso-numeric": "178",
	},
	{
		name: "Congo, Democratic Republic of the",
		"alpha-2": "CD",
		"iso-numeric": "180",
	},
	{
		name: "Cook Islands",
		"alpha-2": "CK",
		"iso-numeric": "184",
	},
	{
		name: "Costa Rica",
		"alpha-2": "CR",
		"iso-numeric": "188",
	},
	{
		name: "Côte d'Ivoire",
		"alpha-2": "CI",
		"iso-numeric": "384",
	},
	{
		name: "Croatia",
		"alpha-2": "HR",
		"iso-numeric": "191",
	},
	{
		name: "Cuba",
		"alpha-2": "CU",
		"iso-numeric": "192",
	},
	{
		name: "Curaçao",
		"alpha-2": "CW",
		"iso-numeric": "531",
	},
	{
		name: "Cyprus",
		"alpha-2": "CY",
		"iso-numeric": "196",
	},
	{
		name: "Czechia",
		"alpha-2": "CZ",
		"iso-numeric": "203",
	},
	{
		name: "Denmark",
		"alpha-2": "DK",
		"iso-numeric": "208",
	},
	{
		name: "Djibouti",
		"alpha-2": "DJ",
		"iso-numeric": "262",
	},
	{
		name: "Dominica",
		"alpha-2": "DM",
		"iso-numeric": "212",
	},
	{
		name: "Dominican Republic",
		"alpha-2": "DO",
		"iso-numeric": "214",
	},
	{
		name: "Ecuador",
		"alpha-2": "EC",
		"iso-numeric": "218",
	},
	{
		name: "Egypt",
		"alpha-2": "EG",
		"iso-numeric": "818",
	},
	{
		name: "El Salvador",
		"alpha-2": "SV",
		"iso-numeric": "222",
	},
	{
		name: "Equatorial Guinea",
		"alpha-2": "GQ",
		"iso-numeric": "226",
	},
	{
		name: "Eritrea",
		"alpha-2": "ER",
		"iso-numeric": "232",
	},
	{
		name: "Estonia",
		"alpha-2": "EE",
		"iso-numeric": "233",
	},
	{
		name: "Eswatini",
		"alpha-2": "SZ",
		"iso-numeric": "748",
	},
	{
		name: "Ethiopia",
		"alpha-2": "ET",
		"iso-numeric": "231",
	},
	{
		name: "Falkland Islands (Malvinas)",
		"alpha-2": "FK",
		"iso-numeric": "238",
	},
	{
		name: "Faroe Islands",
		"alpha-2": "FO",
		"iso-numeric": "234",
	},
	{
		name: "Fiji",
		"alpha-2": "FJ",
		"iso-numeric": "242",
	},
	{
		name: "Finland",
		"alpha-2": "FI",
		"iso-numeric": "246",
	},
	{
		name: "France",
		"alpha-2": "FR",
		"iso-numeric": "250",
	},
	{
		name: "French Guiana",
		"alpha-2": "GF",
		"iso-numeric": "254",
	},
	{
		name: "French Polynesia",
		"alpha-2": "PF",
		"iso-numeric": "258",
	},
	{
		name: "French Southern Territories",
		"alpha-2": "TF",
		"iso-numeric": "260",
	},
	{
		name: "Gabon",
		"alpha-2": "GA",
		"iso-numeric": "266",
	},
	{
		name: "Gambia",
		"alpha-2": "GM",
		"iso-numeric": "270",
	},
	{
		name: "Georgia",
		"alpha-2": "GE",
		"iso-numeric": "268",
	},
	{
		name: "Germany",
		"alpha-2": "DE",
		"iso-numeric": "276",
	},
	{
		name: "Ghana",
		"alpha-2": "GH",
		"iso-numeric": "288",
	},
	{
		name: "Gibraltar",
		"alpha-2": "GI",
		"iso-numeric": "292",
	},
	{
		name: "Greece",
		"alpha-2": "GR",
		"iso-numeric": "300",
	},
	{
		name: "Greenland",
		"alpha-2": "GL",
		"iso-numeric": "304",
	},
	{
		name: "Grenada",
		"alpha-2": "GD",
		"iso-numeric": "308",
	},
	{
		name: "Guadeloupe",
		"alpha-2": "GP",
		"iso-numeric": "312",
	},
	{
		name: "Guam",
		"alpha-2": "GU",
		"iso-numeric": "316",
	},
	{
		name: "Guatemala",
		"alpha-2": "GT",
		"iso-numeric": "320",
	},
	{
		name: "Guernsey",
		"alpha-2": "GG",
		"iso-numeric": "831",
	},
	{
		name: "Guinea",
		"alpha-2": "GN",
		"iso-numeric": "324",
	},
	{
		name: "Guinea-Bissau",
		"alpha-2": "GW",
		"iso-numeric": "624",
	},
	{
		name: "Guyana",
		"alpha-2": "GY",
		"iso-numeric": "328",
	},
	{
		name: "Haiti",
		"alpha-2": "HT",
		"iso-numeric": "332",
	},
	{
		name: "Heard Island and McDonald Islands",
		"alpha-2": "HM",
		"iso-numeric": "334",
	},
	{
		name: "Holy See",
		"alpha-2": "VA",
		"iso-numeric": "336",
	},
	{
		name: "Honduras",
		"alpha-2": "HN",
		"iso-numeric": "340",
	},
	{
		name: "Hong Kong",
		"alpha-2": "HK",
		"iso-numeric": "344",
	},
	{
		name: "Hungary",
		"alpha-2": "HU",
		"iso-numeric": "348",
	},
	{
		name: "Iceland",
		"alpha-2": "IS",
		"iso-numeric": "352",
	},
	{
		name: "India",
		"alpha-2": "IN",
		"iso-numeric": "356",
	},
	{
		name: "Indonesia",
		"alpha-2": "ID",
		"iso-numeric": "360",
	},
	{
		name: "Iran (Islamic Republic of)",
		"alpha-2": "IR",
		"iso-numeric": "364",
	},
	{
		name: "Iraq",
		"alpha-2": "IQ",
		"iso-numeric": "368",
	},
	{
		name: "Ireland",
		"alpha-2": "IE",
		"iso-numeric": "372",
	},
	{
		name: "Isle of Man",
		"alpha-2": "IM",
		"iso-numeric": "833",
	},
	{
		name: "Israel",
		"alpha-2": "IL",
		"iso-numeric": "376",
	},
	{
		name: "Italy",
		"alpha-2": "IT",
		"iso-numeric": "380",
	},
	{
		name: "Jamaica",
		"alpha-2": "JM",
		"iso-numeric": "388",
	},
	{
		name: "Japan",
		"alpha-2": "JP",
		"iso-numeric": "392",
	},
	{
		name: "Jersey",
		"alpha-2": "JE",
		"iso-numeric": "832",
	},
	{
		name: "Jordan",
		"alpha-2": "JO",
		"iso-numeric": "400",
	},
	{
		name: "Kazakhstan",
		"alpha-2": "KZ",
		"iso-numeric": "398",
	},
	{
		name: "Kenya",
		"alpha-2": "KE",
		"iso-numeric": "404",
	},
	{
		name: "Kiribati",
		"alpha-2": "KI",
		"iso-numeric": "296",
	},
	{
		name: "Korea (Democratic People's Republic of)",
		"alpha-2": "KP",
		"iso-numeric": "408",
	},
	{
		name: "Korea, Republic of",
		"alpha-2": "KR",
		"iso-numeric": "410",
	},
	{
		name: "Kuwait",
		"alpha-2": "KW",
		"iso-numeric": "414",
	},
	{
		name: "Kyrgyzstan",
		"alpha-2": "KG",
		"iso-numeric": "417",
	},
	{
		name: "Lao People's Democratic Republic",
		"alpha-2": "LA",
		"iso-numeric": "418",
	},
	{
		name: "Latvia",
		"alpha-2": "LV",
		"iso-numeric": "428",
	},
	{
		name: "Lebanon",
		"alpha-2": "LB",
		"iso-numeric": "422",
	},
	{
		name: "Lesotho",
		"alpha-2": "LS",
		"iso-numeric": "426",
	},
	{
		name: "Liberia",
		"alpha-2": "LR",
		"iso-numeric": "430",
	},
	{
		name: "Libya",
		"alpha-2": "LY",
		"iso-numeric": "434",
	},
	{
		name: "Liechtenstein",
		"alpha-2": "LI",
		"iso-numeric": "438",
	},
	{
		name: "Lithuania",
		"alpha-2": "LT",
		"iso-numeric": "440",
	},
	{
		name: "Luxembourg",
		"alpha-2": "LU",
		"iso-numeric": "442",
	},
	{
		name: "Macao",
		"alpha-2": "MO",
		"iso-numeric": "446",
	},
	{
		name: "Madagascar",
		"alpha-2": "MG",
		"iso-numeric": "450",
	},
	{
		name: "Malawi",
		"alpha-2": "MW",
		"iso-numeric": "454",
	},
	{
		name: "Malaysia",
		"alpha-2": "MY",
		"iso-numeric": "458",
	},
	{
		name: "Maldives",
		"alpha-2": "MV",
		"iso-numeric": "462",
	},
	{
		name: "Mali",
		"alpha-2": "ML",
		"iso-numeric": "466",
	},
	{
		name: "Malta",
		"alpha-2": "MT",
		"iso-numeric": "470",
	},
	{
		name: "Marshall Islands",
		"alpha-2": "MH",
		"iso-numeric": "584",
	},
	{
		name: "Martinique",
		"alpha-2": "MQ",
		"iso-numeric": "474",
	},
	{
		name: "Mauritania",
		"alpha-2": "MR",
		"iso-numeric": "478",
	},
	{
		name: "Mauritius",
		"alpha-2": "MU",
		"iso-numeric": "480",
	},
	{
		name: "Mayotte",
		"alpha-2": "YT",
		"iso-numeric": "175",
	},
	{
		name: "Mexico",
		"alpha-2": "MX",
		"iso-numeric": "484",
	},
	{
		name: "Micronesia (Federated States of)",
		"alpha-2": "FM",
		"iso-numeric": "583",
	},
	{
		name: "Moldova, Republic of",
		"alpha-2": "MD",
		"iso-numeric": "498",
	},
	{
		name: "Monaco",
		"alpha-2": "MC",
		"iso-numeric": "492",
	},
	{
		name: "Mongolia",
		"alpha-2": "MN",
		"iso-numeric": "496",
	},
	{
		name: "Montenegro",
		"alpha-2": "ME",
		"iso-numeric": "499",
	},
	{
		name: "Montserrat",
		"alpha-2": "MS",
		"iso-numeric": "500",
	},
	{
		name: "Morocco",
		"alpha-2": "MA",
		"iso-numeric": "504",
	},
	{
		name: "Mozambique",
		"alpha-2": "MZ",
		"iso-numeric": "508",
	},
	{
		name: "Myanmar",
		"alpha-2": "MM",
		"iso-numeric": "104",
	},
	{
		name: "Namibia",
		"alpha-2": "NA",
		"iso-numeric": "516",
	},
	{
		name: "Nauru",
		"alpha-2": "NR",
		"iso-numeric": "520",
	},
	{
		name: "Nepal",
		"alpha-2": "NP",
		"iso-numeric": "524",
	},
	{
		name: "Netherlands",
		"alpha-2": "NL",
		"iso-numeric": "528",
	},
	{
		name: "New Caledonia",
		"alpha-2": "NC",
		"iso-numeric": "540",
	},
	{
		name: "New Zealand",
		"alpha-2": "NZ",
		"iso-numeric": "554",
	},
	{
		name: "Nicaragua",
		"alpha-2": "NI",
		"iso-numeric": "558",
	},
	{
		name: "Niger",
		"alpha-2": "NE",
		"iso-numeric": "562",
	},
	{
		name: "Nigeria",
		"alpha-2": "NG",
		"iso-numeric": "566",
	},
	{
		name: "Niue",
		"alpha-2": "NU",
		"iso-numeric": "570",
	},
	{
		name: "Norfolk Island",
		"alpha-2": "NF",
		"iso-numeric": "574",
	},
	{
		name: "North Macedonia",
		"alpha-2": "MK",
		"iso-numeric": "807",
	},
	{
		name: "Northern Mariana Islands",
		"alpha-2": "MP",
		"iso-numeric": "580",
	},
	{
		name: "Norway",
		"alpha-2": "NO",
		"iso-numeric": "578",
	},
	{
		name: "Oman",
		"alpha-2": "OM",
		"iso-numeric": "512",
	},
	{
		name: "Pakistan",
		"alpha-2": "PK",
		"iso-numeric": "586",
	},
	{
		name: "Palau",
		"alpha-2": "PW",
		"iso-numeric": "585",
	},
	{
		name: "Palestine, State of",
		"alpha-2": "PS",
		"iso-numeric": "275",
	},
	{
		name: "Panama",
		"alpha-2": "PA",
		"iso-numeric": "591",
	},
	{
		name: "Papua New Guinea",
		"alpha-2": "PG",
		"iso-numeric": "598",
	},
	{
		name: "Paraguay",
		"alpha-2": "PY",
		"iso-numeric": "600",
	},
	{
		name: "Peru",
		"alpha-2": "PE",
		"iso-numeric": "604",
	},
	{
		name: "Philippines",
		"alpha-2": "PH",
		"iso-numeric": "608",
	},
	{
		name: "Pitcairn",
		"alpha-2": "PN",
		"iso-numeric": "612",
	},
	{
		name: "Poland",
		"alpha-2": "PL",
		"iso-numeric": "616",
	},
	{
		name: "Portugal",
		"alpha-2": "PT",
		"iso-numeric": "620",
	},
	{
		name: "Puerto Rico",
		"alpha-2": "PR",
		"iso-numeric": "630",
	},
	{
		name: "Qatar",
		"alpha-2": "QA",
		"iso-numeric": "634",
	},
	{
		name: "Réunion",
		"alpha-2": "RE",
		"iso-numeric": "638",
	},
	{
		name: "Romania",
		"alpha-2": "RO",
		"iso-numeric": "642",
	},
	{
		name: "Russian Federation",
		"alpha-2": "RU",
		"iso-numeric": "643",
	},
	{
		name: "Rwanda",
		"alpha-2": "RW",
		"iso-numeric": "646",
	},
	{
		name: "Saint Barthélemy",
		"alpha-2": "BL",
		"iso-numeric": "652",
	},
	{
		name: "Saint Helena, Ascension and Tristan da Cunha",
		"alpha-2": "SH",
		"iso-numeric": "654",
	},
	{
		name: "Saint Kitts and Nevis",
		"alpha-2": "KN",
		"iso-numeric": "659",
	},
	{
		name: "Saint Lucia",
		"alpha-2": "LC",
		"iso-numeric": "662",
	},
	{
		name: "Saint Martin (French part)",
		"alpha-2": "MF",
		"iso-numeric": "663",
	},
	{
		name: "Saint Pierre and Miquelon",
		"alpha-2": "PM",
		"iso-numeric": "666",
	},
	{
		name: "Saint Vincent and the Grenadines",
		"alpha-2": "VC",
		"iso-numeric": "670",
	},
	{
		name: "Samoa",
		"alpha-2": "WS",
		"iso-numeric": "882",
	},
	{
		name: "San Marino",
		"alpha-2": "SM",
		"iso-numeric": "674",
	},
	{
		name: "Sao Tome and Principe",
		"alpha-2": "ST",
		"iso-numeric": "678",
	},
	{
		name: "Saudi Arabia",
		"alpha-2": "SA",
		"iso-numeric": "682",
	},
	{
		name: "Senegal",
		"alpha-2": "SN",
		"iso-numeric": "686",
	},
	{
		name: "Serbia",
		"alpha-2": "RS",
		"iso-numeric": "688",
	},
	{
		name: "Seychelles",
		"alpha-2": "SC",
		"iso-numeric": "690",
	},
	{
		name: "Sierra Leone",
		"alpha-2": "SL",
		"iso-numeric": "694",
	},
	{
		name: "Singapore",
		"alpha-2": "SG",
		"iso-numeric": "702",
	},
	{
		name: "Sint Maarten (Dutch part)",
		"alpha-2": "SX",
		"iso-numeric": "534",
	},
	{
		name: "Slovakia",
		"alpha-2": "SK",
		"iso-numeric": "703",
	},
	{
		name: "Slovenia",
		"alpha-2": "SI",
		"iso-numeric": "705",
	},
	{
		name: "Solomon Islands",
		"alpha-2": "SB",
		"iso-numeric": "090",
	},
	{
		name: "Somalia",
		"alpha-2": "SO",
		"iso-numeric": "706",
	},
	{
		name: "South Africa",
		"alpha-2": "ZA",
		"iso-numeric": "710",
	},
	{
		name: "South Georgia and the South Sandwich Islands",
		"alpha-2": "GS",
		"iso-numeric": "239",
	},
	{
		name: "South Sudan",
		"alpha-2": "SS",
		"iso-numeric": "728",
	},
	{
		name: "Spain",
		"alpha-2": "ES",
		"iso-numeric": "724",
	},
	{
		name: "Sri Lanka",
		"alpha-2": "LK",
		"iso-numeric": "144",
	},
	{
		name: "Sudan",
		"alpha-2": "SD",
		"iso-numeric": "729",
	},
	{
		name: "Suriname",
		"alpha-2": "SR",
		"iso-numeric": "740",
	},
	{
		name: "Svalbard and Jan Mayen",
		"alpha-2": "SJ",
		"iso-numeric": "744",
	},
	{
		name: "Sweden",
		"alpha-2": "SE",
		"iso-numeric": "752",
	},
	{
		name: "Switzerland",
		"alpha-2": "CH",
		"iso-numeric": "756",
	},
	{
		name: "Syrian Arab Republic",
		"alpha-2": "SY",
		"iso-numeric": "760",
	},
	{
		name: "Taiwan, Province of China",
		"alpha-2": "TW",
		"iso-numeric": "158",
	},
	{
		name: "Tajikistan",
		"alpha-2": "TJ",
		"iso-numeric": "762",
	},
	{
		name: "Tanzania, United Republic of",
		"alpha-2": "TZ",
		"iso-numeric": "834",
	},
	{
		name: "Thailand",
		"alpha-2": "TH",
		"iso-numeric": "764",
	},
	{
		name: "Timor-Leste",
		"alpha-2": "TL",
		"iso-numeric": "626",
	},
	{
		name: "Togo",
		"alpha-2": "TG",
		"iso-numeric": "768",
	},
	{
		name: "Tokelau",
		"alpha-2": "TK",
		"iso-numeric": "772",
	},
	{
		name: "Tonga",
		"alpha-2": "TO",
		"iso-numeric": "776",
	},
	{
		name: "Trinidad and Tobago",
		"alpha-2": "TT",
		"iso-numeric": "780",
	},
	{
		name: "Tunisia",
		"alpha-2": "TN",
		"iso-numeric": "788",
	},
	{
		name: "Turkey",
		"alpha-2": "TR",
		"iso-numeric": "792",
	},
	{
		name: "Turkmenistan",
		"alpha-2": "TM",
		"iso-numeric": "795",
	},
	{
		name: "Turks and Caicos Islands",
		"alpha-2": "TC",
		"iso-numeric": "796",
	},
	{
		name: "Tuvalu",
		"alpha-2": "TV",
		"iso-numeric": "798",
	},
	{
		name: "Uganda",
		"alpha-2": "UG",
		"iso-numeric": "800",
	},
	{
		name: "Ukraine",
		"alpha-2": "UA",
		"iso-numeric": "804",
	},
	{
		name: "United Arab Emirates",
		"alpha-2": "AE",
		"iso-numeric": "784",
	},
	{
		name: "United Kingdom of Great Britain and Northern Ireland",
		"alpha-2": "GB",
		"iso-numeric": "826",
	},
	{
		name: "United States of America",
		"alpha-2": "US",
		"iso-numeric": "840",
	},
	{
		name: "United States Minor Outlying Islands",
		"alpha-2": "UM",
		"iso-numeric": "581",
	},
	{
		name: "Uruguay",
		"alpha-2": "UY",
		"iso-numeric": "858",
	},
	{
		name: "Uzbekistan",
		"alpha-2": "UZ",
		"iso-numeric": "860",
	},
	{
		name: "Vanuatu",
		"alpha-2": "VU",
		"iso-numeric": "548",
	},
	{
		name: "Venezuela (Bolivarian Republic of)",
		"alpha-2": "VE",
		"iso-numeric": "862",
	},
	{
		name: "Viet Nam",
		"alpha-2": "VN",
		"iso-numeric": "704",
	},
	{
		name: "Virgin Islands (British)",
		"alpha-2": "VG",
		"iso-numeric": "092",
	},
	{
		name: "Virgin Islands (U.S.)",
		"alpha-2": "VI",
		"iso-numeric": "850",
	},
	{
		name: "Wallis and Futuna",
		"alpha-2": "WF",
		"iso-numeric": "876",
	},
	{
		name: "Western Sahara",
		"alpha-2": "EH",
		"iso-numeric": "732",
	},
	{
		name: "Yemen",
		"alpha-2": "YE",
		"iso-numeric": "887",
	},
	{
		name: "Zambia",
		"alpha-2": "ZM",
		"iso-numeric": "894",
	},
	{
		name: "Zimbabwe",
		"alpha-2": "ZW",
		"iso-numeric": "716",
	},
];
