import { logger } from "../../../core/logging/logger";
import { Observable } from "../../../utils/observable";
import { AccountingTransaction } from "./transaction";
import { TransactionService } from "./transaction-service";

export class TransactionManager {
	public transaction = new Observable<AccountingTransaction | undefined>(undefined);

	public constructor(private transactionService: TransactionService) {}

	public clear() {
		this.transaction.set(undefined);
	}

	public async fetch(
		accountId: string,
		transactionId: string,
	): Promise<void> {
    try {
		  const transaction = await this.transactionService.fetchTransaction(accountId, transactionId);
      this.transaction.set(transaction);
    } catch (e) {
      logger.debug("TransactionManager", "Fetch transaction failed", e);
      throw e;
    }
	}
}
