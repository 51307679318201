import { Observable } from "../../utils/observable";
import { CardStatus } from "./card-filter";

export class CardFilterManager {
	public statusSelection = new Observable<CardStatus>(CardStatus.ALL);

	setStatusSelection(status: CardStatus) {
		this.statusSelection.set(status);
	}

	public clear() {
		this.statusSelection.set(CardStatus.ALL);
	}
}
