import { AxiosResponse } from "axios";
import { logger } from "../../logging/logger";
import { Interceptor } from "./interceptor";

export const logResponseInterceptor: Interceptor<AxiosResponse> = {
	onRejected: error => {
		logger.trace(
			"LogResponseInterceptor",
			"Error",
			error?.response?.status,
			error?.config?.method,
			error?.config?.url,
			error?.config?.data,
			error?.response?.data
		);
		return Promise.reject(error);
	},
};
