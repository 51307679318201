/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import { useMemo } from "react";
import { assign, Machine, send } from "xstate";
import { onboardingManager } from "../../shared/core/service/services";
import { InitialOnboarding } from "../../shared/domains/onboarding/onboarding";

export const useOnboardingMachine = (
	initialState: OnboardingState.WaitingForData | OnboardingState.InitiateOnboardingSuccess
) => {
	const initialOnboardingMachine = useMemo(() => initialOnboardingMachineBuilder(initialState), [initialState]);
	const [machine, sendEvent] = useMachine(initialOnboardingMachine);
	const { value: state, context } = machine as { value: OnboardingState; context: OnboardingMachineContext };

	const start = (data: InitialOnboarding) => {
		sendEvent("ONBOARDING_CREATE", { data });
	};

	return {
		state,
		context,
		start,
		error: context.error,
	};
};

interface OnboardingMachineContext {
	initialData?: InitialOnboarding;
	error?: string;
	otp?: string;
}

export enum OnboardingState {
	WaitingForData = "WAITING_FOR_DATA",
	InitiateOnboarding = "INITIATE_ONBOARDING",
  InitiateOnboardingSuccess = "INITIATE_ONBOARDING_SUCCESS",
}

type OnboardingEvent =
	| { type: "ONBOARDING_CREATE"; data: InitialOnboarding }
  | { type: "INITIATE_ONBOARDING_SUCCESS"; data: InitialOnboarding }
	| { type: "START_COMPLETING" }

const initialOnboardingMachineBuilder = (initialState: OnboardingState) =>
	Machine<OnboardingMachineContext, OnboardingEvent>({
		id: "register",
		initial: initialState,
		states: {
			[OnboardingState.WaitingForData]: {
				on: {
					ONBOARDING_CREATE: {
						target: OnboardingState.InitiateOnboarding,
						actions: assign({
							initialData: (_, event) => event.data,
						}),
					},
				},
			},
			[OnboardingState.InitiateOnboarding]: {
				invoke: {
					id: "initiateOnboarding",
					src: ctx => onboardingManager.initiate(ctx.initialData!),
					onDone: {
						actions: [
							assign({
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								error: _ => undefined,
							}),
							send({ type: "INITIATE_ONBOARDING_SUCCESS" }),
						],
					},
					onError: {
						target: OnboardingState.WaitingForData,
						actions: assign({
							error: (_, event) => event?.data,
						}),
					},
				},
				on: {
					INITIATE_ONBOARDING_SUCCESS: OnboardingState.InitiateOnboardingSuccess,
				},
			},
      [OnboardingState.InitiateOnboardingSuccess]: {
        type: "final",
      },
		},
	});
