import React from "react";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { theme } from "../../../../styles/theme";
import { repeat } from "../../../../../../shared/utils/utils";
import styled from "styled-components";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { useRTL } from "../../../../../domain/language/use-rtl";

const EnrollmentProgress: React.FC<{ step?: number; stepCount?: number; label?: string }> = ({
	step,
	stepCount,
	label,
}) => {
	const { formatMessage } = useIntl();
	const { isRTL } = useRTL();

	return step !== undefined && step >= 0 && stepCount ? (
		<ProgressContainer>
			<ProgressText>
				{formatMessage("registerScreen.progressLabel", { step: step + 1, count: stepCount ? stepCount : 0, label })}
			</ProgressText>
			<ProgressParts $isRTL={isRTL}>
				{repeat(step + 1, index => (
					<FilledStep key={`filled ${index}`} bigger={index === step} />
				))}
				{repeat(stepCount - (step + 1), index => (
					<EmptyStep key={`empty ${index}`} />
				))}
			</ProgressParts>
		</ProgressContainer>
	) : null;
};

export default EnrollmentProgress;

const ProgressContainer = styled.div`
	max-width: 500px;
	margin-bottom: 40px;
`;

const ProgressText = styled(MarkdownText)`
	${theme.boldText};
	font-size: 0.875rem;
`;

const ProgressParts = styled.div<{ $isRTL: boolean }>`
	display: flex;
	flex-direction: row;
	height: 5px;
	margin-top: 15px;

	> *:not(:first-child) {
		margin-left: ${props => (props.$isRTL ? 0 : 10)}px;
		margin-right: ${props => (props.$isRTL ? 10 : 0)}px;
	}
`;

const EmptyStep = styled.div`
	display: flex;
	width: 5px;
	height: 5px;
	flex-grow: 2;
	flex-shrink: 0;
	border-radius: 5px;
	background: #b1b1b1;
`;

const FilledStep = styled(EmptyStep)<{ bigger?: boolean }>`
	flex-grow: ${props => (props.bigger ? 3 : 2)};
	background: #81c241;
`;
