import React from "react";
import Select, { ValueType } from "react-select";
import styled from "styled-components";
import { shadows, theme } from "../../styles/theme";

interface CommonProps {
	id: string | number;
}

export interface MultiSelectInputProps<T extends CommonProps> {
	innerId: string;
	selectedValues: T[];
	options: readonly T[];
	disabledOptions?: readonly T[];
	onChange: (v: T[]) => void;
	label?: string;
	placeholder?: string;
	style?: React.CSSProperties;
	disabled?: boolean;
	required?: boolean;
	autoFocus?: boolean;
	className?: string;
	itemRenderer?: (item: T) => string;
}
export type MultiSelectInputType<K extends CommonProps> = React.FC<MultiSelectInputProps<K>>;
export function MultiSelectInput<T extends CommonProps>({
	innerId: id,
	label,
	onChange,
	placeholder,
	options,
	disabledOptions,
	selectedValues,
	className,
	style,
	itemRenderer = v => `${v}`,
	...rest
}: MultiSelectInputProps<T>): JSX.Element {
	const castOptions = options.map(option => ({
		value: option.id.toString(),
		label: itemRenderer(option),
		isDisabled: !!disabledOptions?.find(opt => opt.id === option.id),
	}));
	const castSelectedValues = selectedValues.map(option => ({
		value: option.id.toString(),
		label: itemRenderer(option),
	}));

	const handleOnChange = (values: ValueType<MultiSelectOptionType, true>) => {
		onChange(
			values
				.map(val => options.find(option => option.id.toString() === (val.value !== "" ? val.value.trim() : "")))
				.filter(v => v !== undefined) as T[]
		);
	};

	return (
		<SelectContainer className={className} hasLabel={!!label} style={style}>
			{label && <Label htmlFor={id}>{label}</Label>}
			<SelectWrapper>
				<Select
					{...rest}
					id={id}
					className="multi-select"
					menuPortalTarget={document.body}
					styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
					placeholder={placeholder}
					defaultValue={castSelectedValues}
					isMulti
					onChange={handleOnChange}
					options={castOptions}
				/>
			</SelectWrapper>
		</SelectContainer>
	);
}

interface MultiSelectOptionType {
	value: string;
	label: string;
}

const SelectContainer = styled.div<{ hasLabel: boolean }>`
	display: inline-flex;
	flex-direction: ${props => (props.hasLabel ? "column" : "row")};
	flex-shrink: 1;
	flex-grow: 1;
	background-color: #ffffff;
	border-radius: 10px;
	${shadows.medium};
`;
const Label = styled.label`
	font-weight: 600;
	color: #000000;
	margin-bottom: 10px;
	font-size: 1rem;
`;
const SelectWrapper = styled.div`
	position: relative;
	flex-shrink: 1;
	flex-grow: 1;

	.multi-select {
		font-family: inherit;
		font-size: 0.875rem;
		${theme.mediumText};
		color: black;
		flex-shrink: 0;
		background-color: white;
		width: 100%;
	}
`;
