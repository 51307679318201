import React, { HTMLAttributes, useMemo } from "react";
import { useWindowWidth } from "../../../utils/useWindowWidth";
import { UIConstants } from "../../styles/uiConstants";
import { Carousel } from "./carousel";

interface SectionCarouselProps<T> extends HTMLAttributes<HTMLDivElement> {
	items: T[];
	loading?: boolean;
	getItemWidth: (isMobile: boolean) => number;
	renderItem: (item: T, index?: number) => React.ReactNode;
	renderSkeleton?: (index?: number) => React.ReactNode;
}

export function SectionCarousel<T>(props: SectionCarouselProps<T>) {
	const { items, loading, getItemWidth, renderItem, renderSkeleton, ...rest } = props;
	const windowWidth = useWindowWidth();
	const isDesktop = useMemo(() => windowWidth >= UIConstants.DESKTOP_SMALL_BREAKPOINT, [windowWidth]);
	const isDesktopLarge = useMemo(() => windowWidth >= UIConstants.DESKTOP_LARGE_BREAKPOINT, [windowWidth]);
	const isMobile = useMemo(() => windowWidth < UIConstants.TABLET_BREAKPOINT, [windowWidth]);
	const horizontalPadding = useMemo(
		() =>
			isDesktopLarge
				? UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE
				: isDesktop
				? UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL
				: UIConstants.CONTENT_HORIZONTAL_PADDING_NOT_DESKTOP,
		[isDesktopLarge, isDesktop]
	);
	const faderWidth = useMemo(() => (isDesktopLarge ? 100 : isDesktop ? 80 : 40), [isDesktopLarge, isDesktop]);

	return (
		<Carousel
			items={items}
			loading={loading}
			itemWidth={getItemWidth(isMobile)}
			renderItem={renderItem}
			renderSkeleton={renderSkeleton}
			horizontalPadding={horizontalPadding}
			spacingBetweenItems={isMobile ? 15 : 28}
			slideNumber={isDesktop ? 2 : 1}
			faderWidth={faderWidth}
			{...rest}
		/>
	);
}
