import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../../shared/core/service/services";
import { redirectionHandledOnAccountBlocked } from "../../../../../shared/domains/account/account";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import {
	CashTransfer,
	CashTransferType,
	defaultTransferTitles,
} from "../../../../../shared/domains/transactions/cash-transfer/cash-transfer";
import { isCashTransferCodeNotFoundError } from "../../../../../shared/domains/transactions/cash-transfer/retreive-cash-transfer-error";
import { useObservable } from "../../../../../shared/utils/observable";
import { CASH_TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { CashTransferState, useRetrieveCashTransferMachine } from "../../../../machine/retrieve-cash-transfer-machine";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { OtpInput } from "../../../common/forms/otp-input";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../../common/spinner";
import { theme } from "../../../styles/theme";
import { TransferSummary } from "../../transfer/components/transfer-summary";

interface CashTransferModalProps {
	cashTransfer: CashTransfer;
	onComplete?: () => void;
}

export const CashTransferModal: React.FC<CashTransferModalProps> = ({ cashTransfer, onComplete }) => {
	const { formatMessage } = useIntl();
	const closeModal = useCallback(() => {
		Modal.dismiss(CASH_TRANSFER_MODAL_ID);
	}, []);
	const { state, context, error, start, submitCashCode, submitPincode } = useRetrieveCashTransferMachine();

	const history = useHistory();

	useEffect(() => {
		if (redirectionHandledOnAccountBlocked(context.error, history.push)) {
			closeModal();
		}
	}, [context.error, history.push, closeModal]);

	const errorMessage = isAccountBlocked(error)
		? undefined
		: isCashTransferCodeNotFoundError(error)
		? formatMessage("cashTransferScreen.invalidCode")
		: error;

	const renderCashTransferState = () => {
		switch (state) {
			case CashTransferState.WaitingToRetrieveOrCancel:
			case CashTransferState.RequestingOrCancellingTransfer:
			case PincodeState.PincodeConfirmation:
			case CashTransferState.ConfirmTransferError: {
				return context.cashCode ? (
					<CashCode
						onSubmit={submitCashCode}
						errorMessage={errorMessage}
						loading={
							state === CashTransferState.RequestingOrCancellingTransfer || state === PincodeState.PincodeConfirmation
						}
					/>
				) : (
					<CashTransferMainComponent
						cashTransfer={cashTransfer}
						start={start}
						errorMessage={errorMessage}
						loading={
							state === CashTransferState.RequestingOrCancellingTransfer || state === PincodeState.PincodeConfirmation
						}
					/>
				);
			}
			case CashTransferState.EnteringCashCode:
				return <CashCode onSubmit={submitCashCode} errorMessage={errorMessage} />;
			case PincodeState.FetchKeyboardAfterError:
			case PincodeState.PromptingKeyboard: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const keyboard = context.keyboard!;
				return (
					<PincodeKeyboard
						keyboard={keyboard}
						onSubmit={submitPincode}
						title={formatMessage("pincodeConfirmation.label")}
						errorMessage={errorMessage}
						loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
					/>
				);
			}
			case CashTransferState.Done: {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const transactionResult = context.transactionResult!;
				return (
					<TransferSummary
						title={
							cashTransfer.type === CashTransferType.Received
								? formatMessage("cashTransferScreen.summaryTitle.receivedCashTransfer")
								: formatMessage("cashTransferScreen.summaryTitle.sentCashTransfer")
						}
						subtitle={""}
						middleStepLabel={formatMessage("transferSummary.summaryMiddleStep")}
						transaction={transactionResult.metadata.transaction}
						toSelf={true}
						sender={""}
						onDone={() => {
							onComplete?.();
							closeModal();
						}}
					/>
				);
			}
			default: {
				return (
					<LoadingContainer>
						<MainColorSpinner size="30px" />
					</LoadingContainer>
				);
			}
		}
	};

	return (
		<RoundedModalContainer closeButton id={CASH_TRANSFER_MODAL_ID}>
			{renderCashTransferState()}
		</RoundedModalContainer>
	);
};

const CashCode: React.FC<{ errorMessage?: string; onSubmit: (cashCode: string) => void; loading?: boolean }> = ({
	onSubmit,
	errorMessage,
	loading,
}) => {
	const { formatMessage } = useIntl();
	const [cashCode, setCashCode] = useState("");
	const configuration = useObservable(configurationManager.configuration);
	return (
		<Container>
			<Form
				onSubmit={e => {
					e?.preventDefault();
					!loading && onSubmit(cashCode);
				}}
			>
				<Description>{formatMessage("cashTransferScreen.cashCodeTitle")}</Description>
				<StyledOtpInput length={configuration.p2pCodeLength.maxLength} onOtpChange={otp => setCashCode(otp)} />
				{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
				<Button
					type="submit"
					disabled={
						!(
							cashCode &&
							cashCode.length <= configuration.p2pCodeLength.maxLength &&
							cashCode.length >= configuration.p2pCodeLength.minLength
						)
					}
					size="S"
					showSpinner={loading}
				>
					{formatMessage("cashTransferScreen.nextButton")}
				</Button>
			</Form>
		</Container>
	);
};
const CashTransferMainComponent: React.FC<{
	cashTransfer: CashTransfer;
	errorMessage?: string;
	start: (cashTransfer: CashTransfer) => void;
	loading?: boolean;
}> = ({ cashTransfer, start, errorMessage, loading }) => {
	const { formatMessage, formatDate } = useIntl();
	return (
		<Container>
			<Header>
				<Title>
					{cashTransfer.label ? cashTransfer.label : formatMessage(defaultTransferTitles[cashTransfer.type])}
				</Title>
			</Header>
			{cashTransfer.type === CashTransferType.Received
				? !!cashTransfer.sender && (
						<DescriptionLabel>
							{formatMessage("cashTransferScreen.sender")}
							<BoldDescription>{cashTransfer.sender}</BoldDescription>
						</DescriptionLabel>
				  )
				: !!cashTransfer.recipient && (
						<DescriptionLabel>
							{formatMessage("cashTransferScreen.recipient")}
							<BoldDescription>{cashTransfer.recipient}</BoldDescription>
						</DescriptionLabel>
				  )}
			<DataContainer>
				<DateLabel>
					{formatDate(cashTransfer.date, {
						month: "long",
						day: "2-digit",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</DateLabel>
				{cashTransfer.amount && <Amount amount={cashTransfer.amount} />}
			</DataContainer>
			{cashTransfer.code && <Code>{formatMessage("cashTransferScreen.codeLabel", { code: cashTransfer.code })}</Code>}
			{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
			<Button size="S" showSpinner={loading} onClick={() => start(cashTransfer)}>
				{formatMessage(buttonTitles[cashTransfer.type])}
			</Button>
		</Container>
	);
};

const buttonTitles = {
	[CashTransferType.Received]: "cashTransferScreen.retrieveCashTransferButton",
	[CashTransferType.Sent]: "cashTransferScreen.cancelCashTransferButton",
};

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

const Title = styled.h2`
	${theme.boldText};
	font-size: 1.375rem;
	margin: 0;
	flex-shrink: 1;
	flex-grow: 1;
`;

const DataContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px 0;
`;

const DescriptionLabel = styled.span`
	${theme.text};
	font-size: 0.9375rem;
`;

const BoldDescription = styled(DescriptionLabel)`
	${theme.boldText};
`;

const DateLabel = styled.div`
	flex: 1;
	${theme.text};
	font-size: 0.9375rem;
`;

const Amount = styled(AmountText)`
	font-size: 1.125rem;
	${theme.boldText};
`;

const Code = styled.div`
	${theme.boldText};
	margin-bottom: 30px;
	font-size: 0.9375rem;
`;

const Button = styled(PrimaryButton)`
	align-self: center;
`;

const StyledOtpInput = styled(OtpInput)`
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin-bottom: 10px;
	text-align: center;
`;

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 30vh;
`;

const Description = styled.div`
	margin-top: 30px;
	${theme.text};
	font-size: 0.9375rem;
	text-align: center;
`;

const Form = styled.form`
	display: flex;
	flex-direction: column;
	margin: 0;
`;
