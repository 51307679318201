import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated, PaginationOptions } from "../../utils/pagination";
import { Recipient } from "./recipient";

export type ExternalAccountData = { phone: string } | { iban: string } | { accountReference: string; bic: string };

export class ExternalAccountService {
	public constructor(private apiService: ConnectedApiService) {}

	public async fetchRecipients(pagination?: PaginationOptions) {
		try {
			const response = await this.apiService.instance.get<Paginated<Recipient>>("/external-accounts", {
				params: pagination,
			});
			return response.data;
		} catch (e) {
			logger.debug("ExternalAccountService", "Fetch beneficiaries failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async requestNewRecipient(name: string, identity: ExternalAccountData) {
		try {
			const response = await this.apiService.instance.post<Recipient>('/external-accounts/auth', {
				name,
				...identity
			});
			return response.data;
		} catch (e) {
			logger.debug("ExternalAccountService", "Request new recipient failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async confirmNewRecipient(
		name: string,
		identity: ExternalAccountData,
		otp: string,
	) {
		try {
			const response = await this.apiService.instance.post<Recipient>("/external-accounts", {
				name,
				...identity,
				otp,
			});
			return response.data;
		} catch (e) {
			logger.debug("ExternalAccountService", "Confirm new recipient failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async updateRecipient(id: string, name: string) {
		try {
			const response = await this.apiService.instance.patch<Recipient>(`/external-accounts/${id}`, {
				name,
			});
			return response.data;
		} catch (e) {
			logger.debug("BeneficiaryService", "Update recipient failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async deleteRecipient(id: string) {
		try {
			await this.apiService.instance.delete<void>(`/external-accounts/${id}`);
		} catch (e) {
			logger.debug("ExternalAccountService", "Delete recipient failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
