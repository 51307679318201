import React from "react";
import styled from "styled-components";
import { useRTL } from "../../../domain/language/use-rtl";
import { UIConstants } from "../../styles/uiConstants";
import { DefaultButton } from "../buttons/default-button";
import { SmallCrossIcon } from "../svg/small-cross-icon";
import { Modal } from "./modal";

interface RoundedModalContainerProps {
	id: string;
	closeButton?: boolean;
	closeModal?: () => void;
	children?: React.ReactNode;
	className?: string;
}

export const RoundedModalContainer = (props: RoundedModalContainerProps) => {
	const { closeButton, closeModal = () => Modal.dismiss(id), id, children, className } = props;
	const { isRTL } = useRTL();

	return (
		<>
			<Container className={className}>
				{closeButton && (
					<CloseContainer $isRTL={isRTL}>
						<CloseButton onClick={closeModal}>
							<SmallCrossIcon />
						</CloseButton>
					</CloseContainer>
				)}
				<ContentContainer>{children}</ContentContainer>
			</Container>
			<Offset />
		</>
	);
};
const Offset = styled.div`
	width: 100%;
	height: 5vh;
`;
const Container = styled.div`
	position: relative;
	background-color: #fafafa;
	border-radius: 20px;
	padding: 20px;
	margin-top: 15vh;
`;
const ContentContainer = styled.div`
	max-width: calc(100vw - 60px);
	width: ${UIConstants.MODAL_WIDTH}px;
	overflow: visible;
`;
const CloseContainer = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 20px;
	right: ${props => (props.$isRTL ? `unset` : `20px`)};
	left: ${props => (props.$isRTL ? `20px` : `unset`)};
	z-index: 2000;
`;
const CloseButton = styled(DefaultButton)`
	padding: 0px;
`;
