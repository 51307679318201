import { CreditScope, ServiceDomainScope, SharedServiceDomainScope } from "../authentication/scope";

export const CREDIT_SCOPES: CreditScope[] = [
	CreditScope.CdtProductRead,
	CreditScope.CdtProductWrite,
	CreditScope.CdtCreditRequestRead,
	CreditScope.CdtCreditRequestWrite,
	CreditScope.CdtPrivilegeRead,
	CreditScope.CdtPrivilegeWrite,
	CreditScope.CdtSimulationRead,
	CreditScope.CdtPersonRead,
	CreditScope.CdtCreditServicingRead,
	CreditScope.CdtCreditServicingWrite,
];

export const SERVICE_DOMAIN_SCOPES: (ServiceDomainScope | SharedServiceDomainScope)[] = [
	ServiceDomainScope.DocumentSetupView,
	ServiceDomainScope.AdditionalDataRead,
	ServiceDomainScope.FormRead,
	SharedServiceDomainScope.PaymentNetwork,
	SharedServiceDomainScope.CustomerInstructionView,
	SharedServiceDomainScope.CustomerInstructionInitiation,
	SharedServiceDomainScope.CustomerInstructionUpdate,
	SharedServiceDomainScope.CustomerInstructionDeletion,
	SharedServiceDomainScope.CustomerInstructionSubmit,
	SharedServiceDomainScope.SdaAuthenticationsSessionsAuth,
	SharedServiceDomainScope.SdaAuthenticationsSessionsView,
];
