import { useEffect } from "react";
import { useHistory } from "react-router";

export function useQuitBlocking(block: boolean, confirmMessage: string) {
	const history = useHistory();

	useEffect(() => {
		const showConfirm = (ev: BeforeUnloadEvent) => {
			if (block) {
				ev.returnValue = confirmMessage;
				return confirmMessage;
			}
			return null;
		};
		window.addEventListener("beforeunload", showConfirm);
		return () => window.removeEventListener("beforeunload", showConfirm);
	}, [block, confirmMessage]);

	const redirectIfAllowed = (path: string) => {
		if (block) {
			if (window.confirm(confirmMessage)) {
				history.push(path);
			}
		} else {
			history.push(path);
		}
	};

	const isAllowedToQuit = async () => !block || (await window.confirm(confirmMessage));

	return { redirectIfAllowed, isAllowedToQuit };
}
