import React, { InputHTMLAttributes } from "react";
import { RadioButtonsInput } from "./radio-buttons-input";

export interface RadioSelectorProps<T> extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
	innerId: string;
	value: T;
	values: Map<T, string>;
	onChange: (v: T) => void;
	orientation?: "left" | "right";
}

export type RadioSelectorType<K> = React.FC<RadioSelectorProps<K>>;

export function RadioSelector<T>(props: RadioSelectorProps<T>) {
	const { className, values, value, onChange, ...rest } = props;
	return (
		<RadioButtonsInput
			className={className}
			value={value}
			onChange={v => onChange?.(v)}
			options={Array.from(values.keys())}
			itemRenderer={item => values.get(item) ?? ""}
			{...rest}
		/>
	);
}
