import { FormCollectionInput } from "../../core/data-forms/form-input-types";
import { PhoneNumber } from "libphonenumber-js";
import { UrlLink } from "../BaseUrl";

export type InitialOnboarding = {
	distributorConfigurationCode?: string;
	email: string;
	phoneNumber?: PhoneNumber;
	firstName: string;
	lastName: string;
	termsAcceptance: boolean;
	privacyPolicyAcceptance?: boolean;
};

export enum OnboardingStatus {
    INITIATED = "INITIATED",
    COMPLETION_PENDING = "COMPLETION_PENDING",
    READY_TO_SUBMIT = "READY_TO_SUBMIT",
    SUBMITTED = "SUBMITTED",
    PENDING_VERIFICATION = "PENDING_VERIFICATION"
}

export const MUST_RETAKE_ONBOARDING_FILE_STATUSES = [
	OnboardingStatus.INITIATED,
	OnboardingStatus.READY_TO_SUBMIT,
	OnboardingStatus.COMPLETION_PENDING
]

export interface OnboardingFile {
	status: OnboardingStatus;
	links?: UrlLink[];
	termsOfService?: string;
	form: FormCollectionInput & {
		inputs: FormCollectionInput[];
	};
}
