import { GeoPosition } from "react-native-geolocation-service";
import { Amount } from "../../../core/amount/amount";
import { logger } from "../../../core/logging/logger";
import { ConnectedApiService } from "../../../core/net/connected-api-service";
import { PincodeSubmission } from "../../pincode/pincode";
import { AccountBlockedErrorFromErrorResponse, isAccountBlockedErrorResponse } from "../../pincode/pincode-error";
import { ConfirmationMode, TransactionCallType } from "../transaction-request";
import { PspTransactionRequest } from "./psp-transaction-request";
import { RtpTransactionRequest } from "./rpt-transaction-request";

export class BankToWalletService {
	constructor(private apiService: ConnectedApiService) {}

	async startBankToWallet(
		amount: Amount,
		creditorReference: string,
		debtorReference?: string,
		location?: GeoPosition | null
	) {
		try {
			const response = await this.apiService.instance.post<RtpTransactionRequest>(`/transactions/bank-to-wallet`, {
				metadata: {
					mode: TransactionCallType.PreAuth,
					location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
				},
				data: { amount, creditorReference, debtorReference },
			});
			return response.data;
		} catch (e) {
			logger.debug("BankToWalletService", "Failed to start transfer", e);
			if (isAccountBlockedErrorResponse(e)) {
				throw AccountBlockedErrorFromErrorResponse(e);
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	async confirmBankToWallet(
		confirmationMode: ConfirmationMode,
		amount: Amount,
		creditorReference: string,
		debtorReference?: string,
		pincode?: PincodeSubmission,
		location?: GeoPosition | null
	) {
		try {
			const response = await this.apiService.instance.post<RtpTransactionRequest>(`/transactions/bank-to-wallet`, {
				metadata: {
					mode: TransactionCallType.Transaction,
					confirmationMode,
					pincode,
					location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
				},
				data: { amount, creditorReference, debtorReference },
			});
			return response.data;
		} catch (e) {
			logger.debug("BankToWalletService", "Failed to confirm transfer", e);
			if (isAccountBlockedErrorResponse(e)) {
				throw AccountBlockedErrorFromErrorResponse(e);
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async confirmRechargeByCard(
		amount: Amount,
		accountId: string,
		returnUrl: string,
		location?: GeoPosition | null
	) {
		try {
			const response = await this.apiService.instance.post<PspTransactionRequest>(`/transactions/recharge-by-card`, {
				metadata: {
					mode: TransactionCallType.Transaction,
					confirmationMode: ConfirmationMode.Confirm,
					webviewReturnUrl: returnUrl,
					location: location
						? {
								latitude: location.coords.latitude,
								longitude: location.coords.longitude,
						  }
						: undefined,
				},
				data: {
					amount: amount,
					accountId: accountId,
				},
			});
			return response.data;
		} catch (e) {
			logger.debug("BankToWalletService", "Confirm recharge by card failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
