import React, { useRef } from "react";
import { shadows, theme } from "../../../styles/theme";

import { OutstandingCard } from "../../../../../mobile/ui/screens/cards/components/outstandings/outstanding-card";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";

export interface OutstandingsGaugeViewProps {
	outstanding: OutstandingCard;
	onUpdateClick?: () => void;
	canUpdate?: boolean;
}

export const OutstandingsGaugeView: React.FC<OutstandingsGaugeViewProps> = props => {
	const { outstanding, onUpdateClick, canUpdate = false } = props;
	const { formatMessage } = useIntl();

	const usedColor = theme.mainColor();
	const availableColor = theme.mainColorLightBackground();

	const shouldReduceSize = (amountText: string) => amountText.length > 10;

	const maxAmountSmallSize = shouldReduceSize(outstanding.maxAmountLabel);

	return (
		<StyledCard>
			<CardContainer>
				{outstanding.title ? <Title>{outstanding.title}</Title> : null}
				{outstanding.period ? <Subtitle>{outstanding.period}</Subtitle> : null}
				<GaugeContainer size={100}>
					<GaugeArea>
						<div style={{ marginTop: 12 }}>
							<OutstandingsGauge
								percentage={outstanding.percentage}
								size={240}
								availableColor={availableColor}
								usedColor={usedColor}
							/>
						</div>
						<MaxContainer>
							<MaxValue offsetTop={100 * 0.3} isSmallSize={maxAmountSmallSize}>
								{outstanding.maxAmountLabel}
							</MaxValue>
							<MaxLabel>{outstanding.operationType}</MaxLabel>
						</MaxContainer>
					</GaugeArea>
					{outstanding.usedAmountLabel && outstanding.availableAmountLabel && (
						<LegendArea>
							<LegendView
								smallSize={false}
								amount={outstanding.usedAmountLabel}
								label={formatMessage("cardOptions.outstandings.used")}
								color={usedColor}
							/>
							<LegendView
								smallSize={false}
								amount={outstanding.availableAmountLabel}
								label={formatMessage("cardOptions.outstandings.available")}
								color={availableColor}
							/>
						</LegendArea>
					)}
					<StyledButtonContainer outstanding={outstanding} onClick={() => onUpdateClick(outstanding)}>
						<ModifyButton>{formatMessage("cardOptions.outstandings.updateButtonLabel")}</ModifyButton>
					</StyledButtonContainer>
				</GaugeContainer>
			</CardContainer>
		</StyledCard>
	);
};

const StyledCard = styled.div`
    marginTop: 20;
    width: 300;
    marginHorizontal: 24;
    border-radius: 13px;
	padding: 16px;
    background-color: white;
    ${shadows.medium};
`;

const CardContainer = styled.div`
	background-color: white;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

const GaugeContainer = styled.div<{ size: number }>`
	margin-top: 24px;
	width: ${props => props.size + 150}px;
	height: ${props => props.size + 150}px;
`;

const Title = styled.div`
	${theme.boldText};
    margin-bottom: 2px;
    text-align: center;
	font-size: 17px;
	color: black;
`;

const Subtitle = styled.div`
	${theme.text};
    text-align: center;
	font-size: 15px;
	color: black;
`;

const GaugeArea = styled.div`
    position: relative;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

const LegendArea = styled.div`
	display: flex;
	justify-content: space-around;
	flex-direction: row;
`;

const MaxContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 80%;
    top: 20%;
    left: 52%;
    transform: translate(-50%);
	align-items: center;
	justify-content: center;
`;

const MaxValue = styled.div<{ offsetTop: number; isSmallSize: boolean }>`
	${theme.boldText};
	font-size: ${props => (props.isSmallSize ? 28 : 32)}px;
	color: black;
	text-align: center;
	margin-top: ${props => props.offsetTop}px;
`;

const MaxLabel = styled.div`
	${theme.mediumText};
    font-size: 14px;
	color: #626262;
	text-align: center;
`;


const ModifyButton = styled.p`
	${theme.bodyGreyRegular};
	color: #626262;
	text-align: center;
	padding-left: 10px;
	cursor: pointer;
`;

const StyledButtonContainer = styled.div<{outstanding: OutstandingCard}>`
	padding: 10px 0px 0px 0px;
	margin-top: ${props => (props.outstanding.usedAmountLabel ? '-30px': '-60px')};
	position: relative;
	z-index: 100000;
`




export interface OutstandingsGaugeProps {
	percentage: number;
	size: number;
	strokeWidth?: number;
	availableColor: string;
	usedColor: string;
}

export const OutstandingsGauge: React.FC<OutstandingsGaugeProps> = props => {
	const { percentage = 0, size, strokeWidth = 14, availableColor: backColor, usedColor: color } = props;

	const radius = size / 2;
	const circleRef = useRef(null);
	const halfCircle = radius + strokeWidth;
	const circleCircumference = 2 * Math.PI * radius;
	const halfCircumference = circleCircumference / 2.4;

	return (
		<div style={{ transform: 'rotate(165deg)'}}>
			<svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>
				<g>
					<circle
						cx="50%"
						cy="50%"
						stroke={backColor}
						strokeWidth={strokeWidth}
						r={radius}
						fill="transparent"
						strokeDasharray={circleCircumference}
						strokeDashoffset={halfCircumference}
						strokeLinecap="round"
					/>

					<circle
						ref={circleRef}
						cx="50%"
						cy="50%"
						stroke={color}
						strokeWidth={strokeWidth}
						r={radius}
						fill="transparent"
						strokeDasharray={circleCircumference}
						strokeDashoffset={circleCircumference - halfCircumference * 1.4 * percentage}
						strokeLinecap="round"
					/>
				</g>
			</svg>
		</div>
	);
};

export interface LegendViewProps {
	amount: string;
	label: string;
	color?: string;
	smallSize?: boolean;
}

export const LegendView: React.FC<LegendViewProps> = props => {
	const { amount, label, color, smallSize = false } = props;

	return (
		<div
			style={{
                marginTop: -60;
				paddingVertical: 20,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Amount isSmallSize={smallSize}>{amount}</Amount>
			<Label>{label}</Label>
			<Dot color={color?.toString()} size={8} />
		</div>
	);
};

const Amount = styled.div<{ isSmallSize: boolean }>`
	${theme.boldText};
	font-size: ${props => (props.isSmallSize ? 16 : 18)}px;
	color: black;
	line-height: ${props => (props.isSmallSize ? 20 : 22)}px;
	text-align: center;
	margin: 0 7px 1px 6px;
	max-width: 120px;
`;

const Label = styled.div`
	${theme.text};
	font-size: 12px;
	color: black;
	line-height: 16px;
	margin: 1px 0 0;
	text-align: left;
`;

const Dot = styled.div<{ color: string, size: number }>`
	background-color: ${props => props.color};
	margin: 5px 28px 0 29px;
    border-radius: 10px;
    width: ${props => props.size};
    height: ${props => props.size};
`;

