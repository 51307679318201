/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import { assign, createMachine, send } from "xstate";
import { Amount } from "../../shared/core/amount/amount";
import { dedicatedTransferManager, pincodeKeyboardService } from "../../shared/core/service/services";
import { Account } from "../../shared/domains/account/account";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError,
} from "../../shared/domains/pincode/pincode-error";
import { Recipient } from "../../shared/domains/recipients/recipient";
import { ConfirmationMode, TransactionRequest } from "../../shared/domains/transactions/transaction-request";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

export const useDedicatedTransferMachine = () => {
	const [state, sendEvent] = useMachine(dedicatedTransferMachine);

	const selectAcceptorId = (acceptorIdShortCode: string) => {
		sendEvent("SELECT_ACCEPTOR_ID", { acceptorIdShortCode });
	};

	const goBack = () => {
		sendEvent("GO_BACK");
	};

	const selectAmount = (recipient:Recipient, amount: Amount, sourceAccounts: Account[], description?: string) => {
		const sourceAccount = sourceAccounts.find(account => account.balance.currency === amount.currency);
		sendEvent("SELECT_AMOUNT", { recipientId: recipient.id, amount, sourceAccount, label: description });
	};

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};

	return {
		state: state.value as DedicatedTransferState | PincodeState,
		context: state.context,
		selectAcceptorId,
		goBack,
		selectAmount,
		submitPincode,
	};
};

interface DedicatedTransferMachineContext {
	recipientId?: string;
	acceptorIdShortCode?: string;
	amount?: Amount;
	sourceAccount?: Account;
	label?: string;
	transactionRequest?: TransactionRequest;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	transactionResult?: TransactionRequest;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError;
}

export enum DedicatedTransferState {
	EnterAcceptorId = "EnterAcceptorId",
	SelectingAmount = "SelectingAmount",
	RequestingTransfer = "RequestingTransfer",
	RequestingTransferError = "RequestingTransferError",
	Confirmation = "Confirmation",
	ConfirmTransferError = "ConfirmTransferError",
	Done = "Done",
}

type DedicatedTransferEvent =
	| {
			type: "SELECT_ACCEPTOR_ID";
			acceptorIdShortCode: string;
	  }
	| {
			type: "SELECT_AMOUNT";
			recipientId: string;
			amount: Amount;
			sourceAccount: Account;
			label?: string;
	  }
	| { type: "GO_BACK" }
	| { type: "CONFIRM" }
	| { type: "DEDICATED_TRANSFER_DONE" }
	| { type: "DEDICATED_TRANSFER_CONFIRMATION_PINCODE_ERROR" }
	| { type: "DEDICATED_TRANSFER_CONFIRMATION_ERROR" }
	| KeyboardEvent;

enum DedicatedTransferInvokeName {
	StartDedicatedTransfer = "StartTransfer",
	FetchDedicatedKeyboard = "FetchKeyboard",
	ConfirmDedicatedTransfer = "ConfirmDedicatedTransfer",
}

export const dedicatedTransferMachine = createMachine<DedicatedTransferMachineContext, DedicatedTransferEvent>({
	id: "dedicated-transfer",
	initial: DedicatedTransferState.EnterAcceptorId,
	states: {
		[DedicatedTransferState.EnterAcceptorId]: {
			on: {
				SELECT_ACCEPTOR_ID: {
					target: DedicatedTransferState.SelectingAmount,
					actions: [
						assign({
							acceptorIdShortCode: (_, event) => event.acceptorIdShortCode,
						}),
					],
				},
			},
		},
		[DedicatedTransferState.SelectingAmount]: {
			on: {
				SELECT_AMOUNT: {
					target: DedicatedTransferState.RequestingTransfer,
					actions: [
						assign({
							recipientId: (_, event) => event.recipientId,
							amount: (_, event) => event.amount,
							sourceAccount: (_, event) => event.sourceAccount,
							label: (_, event) => event.label,
						}),
					],
				},
				GO_BACK: {
					target: DedicatedTransferState.EnterAcceptorId,
				},
			},
		},
		[DedicatedTransferState.RequestingTransfer]: {
			invoke: {
				id: DedicatedTransferInvokeName.StartDedicatedTransfer,
				src: context =>
					dedicatedTransferManager.startTransfer(
						context.recipientId!,
						context.acceptorIdShortCode!,
						context.amount!,
						context.label
					),
				onDone: {
					actions: [
						assign({
							transactionRequest: (_, event) => event.data,
						}),
						send(context =>
							context.transactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
								? { type: "PINCODE_CONFIRM" }
								: { type: "CONFIRM" }
						),
					],
				},
				onError: {
					target: DedicatedTransferState.RequestingTransferError,
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
					],
				},
			},
			on: {
				PINCODE_CONFIRM: PincodeState.PincodeConfirmation,
				CONFIRM: DedicatedTransferState.Confirmation,
			},
		},
		[DedicatedTransferState.RequestingTransferError]: {
			exit: assign<DedicatedTransferMachineContext, DedicatedTransferEvent>({ error: undefined }),
			on: {
				SELECT_AMOUNT: { target: DedicatedTransferState.RequestingTransfer },
			},
		},
		[PincodeState.PincodeConfirmation]: {
			invoke: {
				id: DedicatedTransferInvokeName.FetchDedicatedKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PincodeState.PromptingKeyboard]: {
			on: {
				SUBMIT_PINCODE: {
					target: DedicatedTransferState.Confirmation,
					actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
				},
				GO_BACK: {
					target: DedicatedTransferState.SelectingAmount,
				},
			},
		},
		[DedicatedTransferState.Confirmation]: {
			invoke: {
				id: DedicatedTransferInvokeName.ConfirmDedicatedTransfer,
				src: context =>
					dedicatedTransferManager.confirmSendDedicatedTransfer(
						context.transactionRequest!.metadata.confirmationMode,
						{
							date: Date.now().toString(),
							amount: context.amount!,
							recipientId: context.recipientId!,
							acceptorId: context.acceptorIdShortCode!,
							label: context.label ?? "",
						},
						context.pincodeSubmission
					),
				onDone: {
					actions: [
						assign<DedicatedTransferMachineContext, { type: string; data: TransactionRequest | undefined }>({
							transactionResult: (_, event) => event.data,
							error: undefined,
						}),
						send({ type: "DEDICATED_TRANSFER_DONE" }),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send(ctx =>
							ctx.transactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
								? { type: "TRANSFER_CONFIRMATION_PINCODE_ERROR" }
								: { type: "TRANSFER_CONFIRMATION_ERROR" }
						),
					],
				},
			},
			on: {
				DEDICATED_TRANSFER_DONE: DedicatedTransferState.Done,
				DEDICATED_TRANSFER_CONFIRMATION_PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
				DEDICATED_TRANSFER_CONFIRMATION_ERROR: DedicatedTransferState.ConfirmTransferError,
			},
		},
		[PincodeState.FetchKeyboardAfterError]: {
			invoke: {
				id: DedicatedTransferInvokeName.FetchDedicatedKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[DedicatedTransferState.ConfirmTransferError]: {
			exit: assign<DedicatedTransferMachineContext, DedicatedTransferEvent>({ error: undefined }),
			on: {
				SELECT_AMOUNT: {
					target: DedicatedTransferState.RequestingTransfer,
					actions: assign({
						recipientId: (_, event) => event.recipientId,
						amount: (_, event) => event.amount,
						label: (_, event) => event.label,
						sourceAccount: (_, event) => event.sourceAccount,
					}),
				},
			},
		},
		[DedicatedTransferState.Done]: {
			type: "final",
		},
	},
});
