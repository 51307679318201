import React, { useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { trustManager } from "../../../../../shared/core/service/services";
import { configurationManager } from "../../../../../shared/core/service/services";
import { TRUST_CHOICE_MODAL_ID } from "../../../../core/modal/modal-id";
import { DefaultButton } from "../../../common/buttons/default-button";
import { PrimaryButton, WhitePrimaryButton } from "../../../common/buttons/primary-button";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { Modal } from "../../../common/modal/modal";
import { Circle } from "../../../common/shape/circle";
import { ShieldIcon } from "../../../common/svg/shield-icon";
import { StaticAssets } from "../../../styles/custom-assets";
import { shadows, theme } from "../../../styles/theme";

export const TrustChoiceModal = () => {
	const { formatMessage } = useIntl();
	const nbDaysTrustDuration = configurationManager.getNbDaysTrustDuration();

	const close = useCallback(() => {
		Modal.dismiss(TRUST_CHOICE_MODAL_ID);
	}, []);

	const later = useCallback(() => {
		trustManager.setUnknown();
		close();
	}, [close]);

	const trust = useCallback(() => {
		trustManager.setTrust(nbDaysTrustDuration);
		close();
	}, [close, nbDaysTrustDuration]);

	const doNotTrust = useCallback(() => {
		trustManager.setUntrust(nbDaysTrustDuration);
		close();
	}, [close, nbDaysTrustDuration]);

	return (
		<ModalContainer id={TRUST_CHOICE_MODAL_ID}>
			<Logo src={StaticAssets.logo} />
			<MainContainer>
				<ShieldContainer size={124}>
					<ShieldIcon color={theme.mainColor()} />
				</ShieldContainer>
				<Title>{formatMessage("trustChoice.title")}</Title>
				<Explanation>{formatMessage("trustChoice.explanation")}</Explanation>
				<ButtonsContainer>
					<LaterButton onClick={later}>{formatMessage("trustChoice.laterButton")}</LaterButton>
					<ChoiceContainer>
						<WhitePrimaryButton size="S" onClick={doNotTrust}>
							{formatMessage("trustChoice.dontTrustButton")}
						</WhitePrimaryButton>
						<PrimaryButton size="S" onClick={trust}>
							{formatMessage("trustChoice.trustButton")}
						</PrimaryButton>
					</ChoiceContainer>
				</ButtonsContainer>
			</MainContainer>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fbfbfb;
	width: 536px;
	height: 100%;
	padding: 0;
	margin: 0;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-bottom: 244px;
`;

const Logo = styled.img`
	width: 112px;
	height: 112px;
	object-fit: contain;
	margin-top: 44px;
	margin-bottom: 88px;
`;

const ShieldContainer = styled(Circle)`
	background-color: white;
	${shadows.medium}
`;

const Title = styled(MarkdownText)`
	${theme.boldText};
	font-size: 1.5rem;
	text-align: center;
	margin-top: 36px;
	margin-bottom: 20px;
`;

const Explanation = styled.span`
	${theme.text};
	font-size: 1.125rem;
	line-height: 1.625rem;
	text-align: center;
	padding: 0 8px;
	margin-bottom: 36px;
`;

const ButtonsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
`;

const ChoiceContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
`;

const LaterButton = styled(DefaultButton)`
	${theme.mediumText}
	font-size: 0.875rem;
	opacity: 0.4;
	text-decoration: underline;
`;
