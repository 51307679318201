import { cardManager, cardService } from "../../core/service/services";
import { useCallback, useState } from "react";

import { Card } from "./card";
import { CardLinks } from "./card-links";
import { getUrlFromLink } from "../BaseUrl";
import { logger } from "../../core/logging/logger";
import { useAsyncEffect } from "../../utils/utils";
import { useObservable } from "../../utils/observable";

export enum CardUpdateOutstandingStep {
	UpdateLimit = "CARD_UPDATE_LIMIT",
	Success = "CARD_UPDATE_LIMIT_SUCCESS",
	Error = "CARD_UPDATE_LIMIT_ERROR",
	SCACheck = "SCA_CHECK", //custom VQ step
}

export const useOutstandings = (card: Card) => {
	const outstandings = useObservable(cardManager.outstandings);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [step, setStep] = useState<CardUpdateOutstandingStep>(CardUpdateOutstandingStep.UpdateLimit);
	const [scaToken, setScaToken] = useState<string>("");

	const resetStep = () => {
		setStep(CardUpdateOutstandingStep.UpdateLimit);
	};

	const refresh = useCallback(async () => {
		try {
			setError("");
			setLoading(true);
			const url = getUrlFromLink(card.links, CardLinks.GetOutstandings);
			await cardManager.getOutstandingsCard(card.id, url);
		} catch (err) {
			setError(err);
			logger.debug("UseOustandings: error getting outstanding", err);
		} finally {
			setLoading(false);
		}
	}, [card]);

	useAsyncEffect(async () => {
		await refresh();
	}, []);

	const updateOutstanding = useCallback(
		async (updateOutstandingParams, scaToken?) => {
			try {
				setError("");
				setLoading(true);
				await cardManager.updateOutstanding(card.id, updateOutstandingParams, scaToken);
				setStep(CardUpdateOutstandingStep.Success);
			} catch (e) {
				//SCA use case, must send and verify the given token
				if (e.response?.data?.error === "Unauthorized" && e.response?.data?.new_token) {
					setScaToken(e.response.data.new_token);
					setStep(CardUpdateOutstandingStep.SCACheck);
					cardService.scaAuthenticationSendToken(e.response.data.new_token);
				} else {
					setError(e.response?.data?.error?.message);
					setStep(CardUpdateOutstandingStep.Error);
				}
			} finally {
				setLoading(false);
			}
		},
		[card, setStep, setScaToken]
	);

	return { outstandings, refresh, loading, error, scaToken, resetStep, updateOutstanding, step };
};
