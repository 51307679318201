import React, { SVGProps } from "react";

export function ChevronDown(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" {...props}>
			<g fill="none" fillRule="nonzero">
				<path
					d="M0 0L18 0 18 18 0 18z"
					transform="translate(-1123 -70) translate(90 33) translate(909 33) rotate(-90 73 -51)"
				/>
				<path
					fill={props.fill ?? "#252525"}
					d="M14.418 13.232c-.392.003-.77-.143-1.058-.407L8.785 8.497l-4.574 4.328c-.597.55-1.516.55-2.112 0-.28-.257-.438-.62-.438-.998 0-.38.158-.742.438-.998l5.63-5.326c.595-.552 1.516-.552 2.11 0l5.633 5.32c.28.257.438.62.438.998 0 .38-.158.742-.438.998-.287.265-.664.41-1.054.408"
					transform="translate(-1123 -70) translate(90 33) translate(909 33) rotate(-90 73 -51) rotate(-90 8.786 9.163)"
				/>
			</g>
		</svg>
	);
}
