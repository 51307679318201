import styled from "styled-components";

export const Circle = styled.div<{ size: number }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: ${props => props.size}px;
	width: ${props => props.size}px;
	border-radius: ${props => props.size / 2}px;
`;
