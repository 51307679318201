import React, { useState } from "react";
import styled from "styled-components";
import CardMoreMenu from "../../../common/card-more-menu";
import { shadows, theme } from "../../../styles/theme";
import { SpendingIcon } from "../../../common/svg/spending-icon";
import { AttachmentIcon } from "../../../common/svg/attachment-icon";
type JustificationCardProps = {
	variant?: "attachment" | "spending";
	children?: React.ReactNode;
	isRTL?: boolean;
	onClick?: () => void;
	onDelete?: () => void;
	onEdit?: () => void;
	onOpen?: () => void;
};

export default function JustificationCard(props: JustificationCardProps) {
	const { children, variant = "attachment", isRTL, onClick, onEdit, onOpen, onDelete } = props;
	const [openMenu, setOpenMenu] = useState(false);

    const handleEdit = () => {
        setOpenMenu(false);
        onEdit?.();
    }

    const handleDelete = () => {
        setOpenMenu(false);
        onDelete?.();
    }

    const handleOpen = () => {
        setOpenMenu(false);
        onOpen?.();
    }

	return (
		<Card onClick={onClick}>
			<IconWrapper>
				{variant === "spending" && <SpendingIcon fill={theme.mainColor()} />}
				{variant === "attachment" && <AttachmentIcon fill={theme.mainColor()} />}
			</IconWrapper>
			{children}
			<CardMoreMenu
                alwaysOpened
				isRTL={isRTL}
				onToggle={() => setOpenMenu(!openMenu)}
				isOpen={openMenu}
				onEdit={onEdit ? handleEdit : undefined}
                onOpen={onOpen ? handleOpen : undefined}
				onDelete={onDelete ? handleDelete : undefined}
			/>
		</Card>
	);
}

const Card = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 13px 32px;
	border-radius: 13px;
	${shadows.medium};
	background-color: #ffffff;
	align-self: stretch;
	margin-bottom: 15px;
	:hover {
		cursor: pointer;
	}
	:disabled {
		cursor: auto;
	}
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${theme.mainColorLightBackground};
	margin-right: 15px;
    flex-shrink: 0;
`;
