import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../../shared/core/service/services";
import { isDefined } from "../../../../../shared/utils/assert";
import { buildLinkMailTo, buildLinkPhoneTo } from "../../../../../shared/utils/link-builder";
import { useObservable } from "../../../../../shared/utils/observable";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { ContactEmailIcon } from "../../../common/svg/contact-email-icon";
import { ContactPhoneIcon } from "../../../common/svg/contact-phone-icon";
import { theme } from "../../../styles/theme";

export const ConnectContactSupport = () => {
	const { formatMessage } = useIntl();
	const configuration = useObservable(configurationManager.configuration);
	const { contactEmail, contactPhone } = configuration;

	return (
		<Container>
			<Title>{formatMessage("connectScreen.contactSupport.title")}</Title>
			<Message>{formatMessage("connectScreen.contactSupport.message")}</Message>
			<ButtonsContainer>
				{isDefined(contactPhone) && (
					<Button type="phone" href={buildLinkPhoneTo(contactPhone)}>
						<Logo>
							<ContactPhoneIcon />
						</Logo>
						<ButtonLegend>{formatMessage("connectScreen.contactSupport.byPhone")}</ButtonLegend>
					</Button>
				)}
				{isDefined(contactEmail) && (
					<Button type="email" href={buildLinkMailTo(contactEmail)}>
						<Logo>
							<ContactEmailIcon />
						</Logo>
						<ButtonLegend>{formatMessage("connectScreen.contactSupport.byEmail")}</ButtonLegend>
					</Button>
				)}
			</ButtonsContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Title = styled(MarkdownText)`
	${theme.boldText}
	font-size: 1.875rem;
	margin-bottom: 12px;
`;

const Message = styled.span`
	${theme.text}
	font-size: 0.9375rem;
	color: #b1b1b1;
	margin-bottom: 42px;
`;

const ButtonsContainer = styled.span`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 12px;
`;

const Button = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 44px;
`;

const Logo = styled.div`
	display: flex;
	width: 37px;
	height: 37px;
	border-radius: 27px;
	background-color: #ececec;
	align-items: center;
	justify-content: center;
	margin-right: 22px;
`;

const ButtonLegend = styled.span`
	${theme.mediumText}
	font-size: 0.9375rem;
	color: black;
	text-decoration: underline;
`;
