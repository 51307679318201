import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Keyboard } from "../../../../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../../../../shared/domains/pincode/pincode";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { theme } from "../../../styles/theme";
import { ModalBackButton } from "./modal-back-button";

interface TransactionCodePincodeProps {
	keyboard: Keyboard;
	backMethod: () => void;
	submitMethod: (submission: PincodeSubmission) => void;
	errorMessage?: string;
	loadingKeyboard?: boolean;
}

export const TransactionCodePincode = (props: TransactionCodePincodeProps) => {
	const { keyboard, backMethod, submitMethod, errorMessage, loadingKeyboard } = props;
	const { formatMessage } = useIntl();

	return (
		<Container>
			<TopLeft>
				<ModalBackButton onClick={backMethod} />
			</TopLeft>
			<Title>{formatMessage("transactionCode.pincode.title")}</Title>
			<PincodeKeyboard
				keyboard={keyboard}
				onSubmit={submitMethod}
				title={formatMessage("transactionCode.pincode.description")}
				errorMessage={errorMessage}
				loadingKeyboard={loadingKeyboard}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 32px;
	padding-bottom: 32 px;
	position: relative;
`;

const TopLeft = styled.div`
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 100;
`;

const Title = styled.span`
	${theme.boldText};
	font-size: 1.375rem;
	color: #000000;
	margin-bottom: 13px;
	align-self: center;
`;
