import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Acceptor } from "../../../../../shared/domains/acceptors/acceptor";
import { Account } from "../../../../../shared/domains/account/account";
import { Transaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { SuccessCircle } from "../../../common/success-circle";
import { theme } from "../../../styles/theme";

interface DedicatedTransferSummaryProps {
	title: string;
	subtitle?: string;
	recipient?: Recipient;
	transaction: Transaction;
	sourceAccount?: Account;
	acceptor?: Acceptor;
	onDone?: () => void;
	color?: string;
	className?: string;
}

export const DedicatedTransferSummary = (props: DedicatedTransferSummaryProps) => {
	const { title, subtitle, recipient, acceptor, sourceAccount, transaction, onDone, className } = props;
	const { formatMessage, formatAmount } = useIntl();

	return (
		<Container className={className}>
			<GreenCheckCircle />
			<Centered>
				<SuccessText>{title}</SuccessText>
				{subtitle && <Subtitle>{subtitle}</Subtitle>}
				{sourceAccount && (
					<InfoRow>
						<InfoLabel>{formatMessage("dedicatedTransferSummary.account")}</InfoLabel>
						<InfoValue>{sourceAccount.id}</InfoValue>
					</InfoRow>
				)}
				{recipient && (
					<InfoRow>
						<InfoLabel>{formatMessage("dedicatedTransferSummary.recepient")}</InfoLabel>
						<InfoValue>{recipient.name}</InfoValue>
					</InfoRow>
				)}
				{transaction.amount && (
					<InfoRow>
						<InfoLabel>{formatMessage("dedicatedTransferSummary.amount")}</InfoLabel>
						<InfoValue>{formatAmount(transaction.amount)}</InfoValue>
					</InfoRow>
				)}
				{acceptor?.code && (
					<InfoRow>
						<InfoLabel>{formatMessage("dedicatedTransferSummary.transactionPoint")}</InfoLabel>
						<InfoValue>{acceptor.code}</InfoValue>
					</InfoRow>
				)}
				{transaction.date && (
					<InfoRow>
						<InfoLabel>{formatMessage("dedicatedTransferSummary.expirationDate")}</InfoLabel>
						<InfoValue>{transaction.date}</InfoValue>
					</InfoRow>
				)}
			</Centered>
			<DoneButton size="M" onClick={onDone}>
				{formatMessage("dedicatedTransferSummary.done")}
			</DoneButton>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`;

const InfoRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const InfoLabel = styled.span`
	font-size: 1rem;
	${theme.mediumText};
	color: #b1b1b1;
	margin-top: 10px;
`;

const InfoValue = styled.span`
	font-size: 1rem;
	${theme.mediumText};
`;

const GreenCheckCircle = styled(SuccessCircle)`
	margin-top: 28px;
`;

const Centered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	margin: 46px 17px 36px;
	padding: 33px 26px 40px;
	border-radius: 30px;
	box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
`;

const Subtitle = styled.div`
	font-size: 1rem;
	${theme.boldText};
	align-self: center;
	padding-bottom: 25px;
	text-align: center;
`;

const SuccessText = styled.div`
	font-size: 1.375rem;
	${theme.boldText};
	padding-bottom: 25px;
	padding-top: 30px;
	align-self: center;
`;

const DoneButton = styled(PrimaryButton)`
	align-self: center;
	padding: 10px 40px;
`;
