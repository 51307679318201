import moment from "moment";
import { useCallback, useEffect, useState } from "react";

function addZeroIfNeeded(n: number) {
	return n > 9 ? "" + n : "0" + n;
}

export function useCountdown(date: string) {
	const getValue = useCallback(() => {
		const expirationDate = moment(date);
		const now = moment();
		const duration = moment.duration(expirationDate.diff(now));
		if (duration.milliseconds() < 0) {
			return `00:00:00`;
		}
		return `${addZeroIfNeeded(duration.get("hours"))}:${addZeroIfNeeded(duration.get("minutes"))}:${addZeroIfNeeded(
			duration.get("seconds")
		)}`;
	}, [date]);

	const [value, setValue] = useState(getValue());

	useEffect(() => {
		const interval = setInterval(() => setValue(getValue()), 1000);
		return () => clearInterval(interval);
	}, [date, getValue]);

	return value;
}
