import { useState } from "react";
import { logger } from "../../../shared/core/logging/logger";
import { clientManager } from "../../../shared/core/service/services";
import { useObservable } from "../../../shared/utils/observable";
import { useAsyncEffect } from "../../../shared/utils/utils";

export const useClient = () => {
	const client = useObservable(clientManager.client);
	const [loading, setLoading] = useState(true);

	useAsyncEffect(async () => {
		try {
			setLoading(true);
			await clientManager.updateClient();
		} catch (e) {
			logger.debug("client fetch error", e);
		} finally {
			setLoading(false);
		}
	}, [clientManager]);
	return { client, loading };
};
