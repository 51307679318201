export function getInitials(name = '') {
	const parts = name.trim().split(" ");
	if (parts.length < 2) {
		return capitalize(name.trim().substring(0, 2));
	}
	const firstPart = parts[0].charAt(0);
	const lastPart = parts[parts.length - 1].charAt(0);
	return (firstPart + lastPart).toUpperCase();
}
function capitalize(str: string) {
	return str.charAt(0).toUpperCase() + str.charAt(1).toLowerCase();
}
