import React, { useMemo } from "react";
import styled from "styled-components";
import { configurationManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import { getInitials } from "../../../utils/name-formatter";
import { fonts } from "../../styles/theme";

export interface AvatarProps {
	size: number;
	name: string;
	fontSize?: string;
	className?: string;
}

export const Avatar = (props: AvatarProps) => {
	const { size, name, fontSize = "1rem", className } = props;
	const color = useMemo(() => configurationManager.computeAvatarColor(name), [name]);
	return (
		<RoundContainer size={size} className={className} backgroundColor={color}>
			<Initials fontSize={fontSize} theme={isDefined(color) ? "dark" : "light"}>
				{getInitials(name)}
			</Initials>
		</RoundContainer>
	);
};

const RoundContainer = styled.div<{ size: number; backgroundColor?: string }>`
	display: flex;
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	border-radius: ${props => props.size}px;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#f4f4f4")};
	justify-content: center;
	align-items: center;
`;

const Initials = styled.div<{ fontSize: string; theme?: "dark" | "light" }>`
	${fonts.bold}
	font-size: ${props => props.fontSize};
	color: ${props => (props.theme === "dark" ? "#ffffff" : "#000000")};
`;
