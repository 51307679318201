import { logger } from "../../../core/logging/logger";
import { ObservableArray } from "../../../utils/observable-array";
import { getUrlFromLink } from "../../BaseUrl";
import { Attachment } from "./attachment";
import { AttachmentsLinks } from "./attachments-links";
import { AttachmentsService } from "./attachments-service";

export class AttachmentsManager {
	public attachments = new ObservableArray<Attachment>([]);

	public constructor(private attachmentsService: AttachmentsService) {}

	public clear() {
		this.attachments.set([]);
	}

	private removeAttachment(attachmentId: string) {
		this.attachments.set(this.attachments.get().filter(spending => spending.id !== attachmentId));
	}

	public async deleteAll(transactionId: string) {
		const deletedAttachments = this.attachments.get();
		for (const attachment of deletedAttachments) {
			const url = getUrlFromLink(attachment.links, AttachmentsLinks.Delete);
			this.deleteAttachment(transactionId, attachment.id, url);
		}
		this.clear();
	}

	public async addAttachment(
		transactionId: string,
		content: string,
		description?: string,
		url: string | undefined = undefined
	) {
		try {
			const newAttachment = await this.attachmentsService.addAttachment(transactionId, content, description, url);
			this.attachments.set([...this.attachments.get(), newAttachment]);
		} catch (e) {
			logger.debug("AttachmentsManager", "Add attachment failed", e);
			throw e;
		}
	}

	public async refresh(transactionId: string, url: string | undefined = undefined): Promise<void> {
		try {
			const updatedAttachments = await this.attachmentsService.getAttachments(transactionId, url);
			this.attachments.set(updatedAttachments);
		} catch (e) {
			logger.debug("AttachmentsManager", "Refresh attachments failed", e);
			throw e;
		}
	}

	public async deleteAttachment(transactionId: string, attachmentId: string, url: string | undefined = undefined) {
		try {
			await this.attachmentsService.deleteAttachment(transactionId, attachmentId, url);
			this.removeAttachment(attachmentId);
		} catch (e) {
			logger.debug("AttachmentsManager", "Delete attachment failed", e);
			throw e;
		}
	}

	public async getContent(transactionId: string, attachmentId: string, url: string | undefined = undefined) {
		try {
			return await this.attachmentsService.getContent(transactionId, attachmentId, url);
		} catch (e) {
			logger.debug("AttachmentsManager", "Get content attachment failed", e);
			throw e;
		}
	}
}
