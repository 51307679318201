import React, { useEffect, useState } from "react";
import {
	Amount,
	getAmountValueString,
	sanitizeAmountValue,
	switchCurrency,
} from "../../../../shared/core/amount/amount";
import { Currencies, CurrencyIdentifier } from "../../../../shared/core/currency/currency";
import { theme } from "../../styles/theme";

import styled from "styled-components";
import { useRTL } from "../../../domain/language/use-rtl";
import { SelectInput } from "./select-input";
import { TextInput } from "./text-input";

interface AmountInputProps {
	value: Amount | null;
	id?: string;
	variant?: "default" | "big";
	onChange?: (amount: Amount) => void;
	required?: boolean;
	currencies?: CurrencyIdentifier[];
	className?: string;
	disabled?: boolean;
}

export const AmountInput = (props: AmountInputProps) => {
	const { variant, value, onChange, currencies, required, className } = props;
	const initialAmountString = value
		? sanitizeAmountValue(getAmountValueString(value), Currencies[value.currency].decimal_number)
		: "0";
	const [textValue, setTextValue] = useState(initialAmountString === "0" ? "" : initialAmountString);

	const setAmount = (str: string) => {
		if (value) {
			const safeValue = sanitizeAmountValue(str, Currencies[value.currency].decimal_number);
			setTextValue(safeValue);
			if (onChange) {
				onChange(new Amount(parseFloat(safeValue.replace(",", ".")), value.currency));
			}
		}
	};

	const { isRTL } = useRTL();

	useEffect(() => {
		setTextValue(initialAmountString);
	}, [initialAmountString]);

	return (
		<Row className={className}>
			{value ? (
				<>
					{currencies ? (
						<CurrencyContainer $isRTL={isRTL}>
							<SelectInput
								disabled={props.disabled}
								innerId={"currencies"}
								value={value.currency}
								options={currencies}
								onChange={currency => {
									if (onChange) {
										onChange(switchCurrency(value, currency));
									}
								}}
								disabledArrow={true}
								style={{
									position: "absolute",
									alignItems: "center",
									justifyContent: "center",
								}}
								withPointer={true}
							/>
						</CurrencyContainer>
					) : (
						<CurrencyContainer $isRTL={isRTL}>
							<CurrencyDisplay big={Currencies[value.currency].symbol.length === 1}>
								{Currencies[value.currency].symbol}
							</CurrencyDisplay>
						</CurrencyContainer>
					)}
				</>
			) : (
				<CurrencyContainer />
			)}
			<StyledTextInput
				id={props.id}
				variant={variant}
				disabled={props.disabled}
				required={required}
				value={textValue}
				onChange={event => setAmount(event.target.value)}
				autoComplete="off"
			/>
		</Row>
	);
};

const CurrencyContainer = styled.div<{ $isRTL?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 80px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	background-color: #ffffff;
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		bottom: 0;
		right: ${props => (props.$isRTL ? "auto" : 0)};
		left: ${props => (props.$isRTL ? 0 : "auto")};
		width: 1px;
		height: calc(100% - 10px);
		background-color: ${theme.colors.gray[500]};
		transform: translateY(-50%);
	}
`;

const CurrencyDisplay = styled.span<{ big: boolean }>`
	${theme.mediumText};
	color: ${theme.colors.gray[500]};
	${({ big }) =>
		big &&
		`
	font-size: 26px;`}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
`;

const StyledTextInput = styled(TextInput)`
	width: 100%;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
`;
