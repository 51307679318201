import React, { useState } from "react";
import { ValueType } from "react-select";
import AsyncSelect from "react-select/async";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger";
import { autocompleteService } from "../../../../shared/core/service/services";
import { shadows, theme } from "../../styles/theme";

interface AutocompleteInputProps {
	inputId: string;
	title?: string;
	onChange: (value?: string) => void;
	className?: string;
}

export const AutocompleteInput = (props: AutocompleteInputProps) => {
	const { inputId, className, onChange } = props;
	const { formatMessage } = useIntl();

	const [selected, setSelected] = useState<SelectOptionType | null>(null);

	const onSearchChange = (search: string) => {
		if (search.length < 3) {
			return Promise.resolve([] as SelectOptionType[]);
		}

		return autocompleteService
			.fieldAutocomplete(inputId, search)
			.then(result => result.possibleValues)
			.catch(error => {
				logger.error("Autocomplete error: " + error);
				return Promise.resolve([] as SelectOptionType[]);
			});
	};

	// handle selection
	const handleChange = (value: ValueType<SelectOptionType, false>) => {
		setSelected(value);
		onChange(value?.value);
	};

	const handleNoResultMessage = ({ inputValue }: InputValueType) =>
		inputValue && inputValue.length >= 3
			? formatMessage("common.autocompletePicker.noResults")
			: formatMessage("common.autocompletePicker.searchPlaceholder");

	const style = {
		control: (base: any) => ({
			...base,
			border: 0,
			boxShadow: "none",
		}),
		input: (base: any) => ({
			...base,
			...theme.boldText,
		}),
		placeholder: (base: any) => ({
			...base,
			color: "#b1b1b1",
		}),
		dropdownIndicator: (base: any) => ({
			...base,
			color: "#252525",
		}),
	};

	return (
		<Container className={className}>
			<SelectWrapper>
				<AsyncSelect
					className="autocomplete-select"
					styles={style}
					cacheOptions
					value={selected}
					loadOptions={onSearchChange}
					onChange={handleChange}
					menuPlacement={"auto"}
					maxMenuHeight={160}
					components={{ IndicatorSeparator: () => null }}
					noOptionsMessage={handleNoResultMessage}
					placeholder={formatMessage("common.autocompletePicker.placeholder")}
				/>
			</SelectWrapper>
		</Container>
	);
};

interface SelectOptionType {
	value: string;
	label: string;
}

interface InputValueType {
	inputValue: string;
}

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: stretch;
	padding: 5px 0 5px 9px;
	border-radius: 10px;
	${shadows.medium};
	background-color: #ffffff;
`;

const SelectWrapper = styled.div`
	position: relative;
	flex-shrink: 1;
	flex-grow: 1;

	.autocomplete-select {
		font-family: inherit;
		font-size: 0.875rem;
		${theme.boldText};
		color: black;
		flex-shrink: 0;
		background-color: white;
		width: 100%;
	}
`;
