import React from "react";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { RechargeMethod } from "../../../../machine/recharge-machine";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { SuccessCircle } from "../../../common/success-circle";
import { shadows, theme } from "../../../styles/theme";

interface RechargeSummaryProps {
	amount: Amount;
	accountId: string;
	onDone: () => void;
	rechargeMethod: RechargeMethod;
}

export const RechargeSummary = (props: RechargeSummaryProps) => {
	const { amount, accountId, onDone, rechargeMethod } = props;
	const { formatMessage } = useIntl();

	const rechargeMethodLabel =
		rechargeMethod == RechargeMethod.BANK_TO_WALLET
			? "rechargeSummary.bankToWalletMethod"
			: "rechargeSummary.cardMethod";

	return (
		<Container>
			<GreenCheckCircle />
			<Card>
				<SuccessText>{formatMessage("rechargeSummary.title")}</SuccessText>
				<Content>
					<Row>
						<Label>{formatMessage("rechargeSummary.rechargedAccount")}</Label>
						<Value>{accountId}</Value>
					</Row>
					<Row>
						<Label>{formatMessage("rechargeSummary.rechargeMethod")}</Label>
						<Value>{formatMessage(rechargeMethodLabel)}</Value>
					</Row>
					<Row>
						<Label>{formatMessage("rechargeSummary.rechargeAmount")}</Label>
						<Value>
							<AmountText amount={amount} />
						</Value>
					</Row>
				</Content>
			</Card>
			<Footer>
				<DoneButton size="M" onClick={onDone}>
					{formatMessage("rechargeSummary.done")}
				</DoneButton>
			</Footer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`;

const GreenCheckCircle = styled(SuccessCircle)`
	margin-top: 28px;
`;

const SuccessText = styled.div`
	font-size: 1.375rem;
	${theme.boldText};
	padding-bottom: 25px;
	padding-top: 12px;
	align-self: center;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	align-self: stretch;
`;

const Label = styled.div`
	font-size: 0.9375rem;
	${theme.mediumText};
	color: #aaaaaa;
	align-self: center;
	text-align: center;
`;

const Value = styled.div`
	${theme.mediumText}
	font-size: 0.9375rem;
	color: #000000;
	white-space: pre-line;
`;

const DoneButton = styled(PrimaryButton)``;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	margin-left: 10px;
	margin-right: 10px;
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px;
	background-color: #ffffff;
	border-radius: 13px;
	${shadows.medium};
	margin-bottom: 16px;
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 40px;
`;

const Footer = styled.div`
	display: flex;
	margin-top: 20px;
	justify-content: center;
`;
