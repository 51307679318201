import { logger } from "../../core/logging/logger";
import { ApiService } from "../../core/net/api-service";
import { getPixelSizeForLayoutSize } from "../../core/pixel-ratio/pixel-ratio";
import { Keyboard } from "./keyboard";

export class PincodeKeyboardService {
	public constructor(private apiService: ApiService) {}

	public async fetchKeyboard(touchSize: number, clientPhone?: string, fontSize?: number, fontFamily = "DMSans", fontWeight = "Medium") {
		try {
			const pixelSize = getPixelSizeForLayoutSize(touchSize);
			const requestedFontSize = fontSize ? fontSize : pixelSize * 0.5;
			// const requestedFontFamily = `${fontFamily}-${fontWeight}`;
			const requestedFontFamily = 'DMSans-Medium';
			const response = await this.apiService.instance.get<Keyboard>(
				clientPhone
					? `/keyboard/${clientPhone}?font=${requestedFontFamily}&width=${pixelSize}&fontSize=${requestedFontSize}`
					: `/keyboard?font=${requestedFontFamily}&width=${pixelSize}&fontSize=${requestedFontSize}`
			);

			return response.data;
		} catch (e) {
			logger.debug("PincodeService", "Fetch keyboard failed", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
