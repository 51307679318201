import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { Circle } from "./shape/circle";
import { CheckIcon } from "./svg/check-icon";

interface SuccessCircleProps extends HTMLAttributes<HTMLDivElement> {
	theme?: "plain" | "shallow";
}
export const SuccessCircle = (props: SuccessCircleProps) => (
	<GreenCircle {...props} size={116}>
		<CheckIcon fill={props.theme === "shallow" ? "#2ed794" : "#ffffff"} />
	</GreenCircle>
);

const GreenCircle = styled(Circle)<{ theme?: "plain" | "shallow" }>`
	align-self: center;
	background-color: ${props => (props.theme === "shallow" ? "#ffffff" : "#2ed794")};
	box-shadow: 0 6px 44px 0 rgba(46, 215, 148, 0.87);
`;
