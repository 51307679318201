import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { ChevronIcon } from "../../../common/svg/chevron";

interface CategoryOpenerProps extends HTMLAttributes<HTMLDivElement> {
	isOpened: boolean;
}

export const CategoryOpener: React.FC<CategoryOpenerProps> = ({ isOpened, ...otherProps }) => (
	<CategoryIcon {...otherProps}>
		<StyledChevronIcon style={{ transform: isOpened ? "rotate(-90deg)" : "rotate(90deg)" }} />
	</CategoryIcon>
);

const CategoryIcon = styled.div`
	display: flex;
	width: 32px;
	height: 32px;
	border-radius: 9px;
	background-color: #f4f4f4;
	margin: 16px;
	align-items: center;
	justify-content: center;
`;

const StyledChevronIcon = styled(ChevronIcon)`
	width: 20px;
	height: 20px;
	transition: transform linear 0.2s;
`;
