interface EnvironmentConfiguration {
	APP_NAME: string;
	APP_PRIMARY_COLOR: string;
	APP_AVATAR_COLORS: string[];
	APP_MORE_MENU_COLORS: string[];
	APP_BILL_ISSUERS_COLORS: string[];
	APP_APPLE_STORE_ID?: string;
	APP_ID?: string;

	BASE_URL: string;
	API_URL: string;
	CREDIT_API_URL: string;
	SERVICE_DOMAIN_API_URL: string;
	
	REGISTER_URL: string;
	API_CLIENT_ID: string;
	API_CLIENT_SECRET: string;
	AUTH_REDIRECT_WEB_CLIENT_SCHEME: string;
	UBBLE_REDIRECT_WEB_CLIENT_SCHEME: string;
	CARD_PSP_REDIRECT_WEB_CLIENT_SCHEME: string;
	APP_CAROUSEL_IMAGES: string[];
	APP_REGISTER_IMAGE: string;
	APP_LOGO_IMAGE: string;
	COMPANY_PORTAL_URL?: string;
	PARTNERS_PORTAL_URL?: string;
	AGENT_PORTAL_URL?: string;

	GOOGLE_MAP_API_KEY: string;

	CONFIGURATION_CACHE_TTL: number;
	TRANSLATIONS_CACHE_TTL: number;
	CLIENT_CACHE_TTL: number;
	BILL_ISSUERS_CACHE_TTL: number;
	ACCOUNTS_CACHE_TTL: number;
	RECIPIENTS_CACHE_TTL: number;
	TRANSACTIONS_CACHE_TTL: number;
	AUTHORIZATION_HOLDS_CACHE_TTL: number;

	LOGGING_ENABLED: boolean;
	FALLBACK_LANGUAGE: string;
	FALLBACK_TRANSLATIONS_ENABLED: boolean;

	EXTERNAL_LOAN_MANAGEMENT: boolean;
	EXTERNAL_LOAN_URL: string;
	APP_VERSION: string;

	DEBUG_SCREEN: boolean;
	WEB_CLIENT_TITLE?: string;

	MODULE_CREDIT_ENABLED: boolean;
	SERVICE_DOMAIN_API_ID: string;
	SERVICE_DOMAIN_API_SECRET: string;
	BYPASS_ROOTED_CHECK: string;
}
/*
 * Config is loaded before ReactDOM.render(), so it is ready to use anywhere in the App.
 */
export let Config: EnvironmentConfiguration;

export const setConfiguration = (externalConfiguration: EnvironmentConfiguration) => {
	Config = externalConfiguration;
};
