import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { Amount } from "../../../shared/core/amount/amount";
import { useIntl } from "../../../shared/core/i18n/use-intl";

interface AmountTextProps extends HTMLAttributes<HTMLDivElement> {
	amount: Amount;
	absolute?: boolean;
	forceSign?: boolean;
}

export const AmountText = (props: AmountTextProps) => {
	const { amount, absolute, forceSign, ...rest } = props;
	const { formatAmount } = useIntl();
	const formattedAmount = formatAmount(amount, absolute, forceSign);

	return <Container {...rest}>{formattedAmount}</Container>;
};

const Container = styled.div`
	direction: ltr;
`;
