import { Amount } from "../../core/amount/amount";

export enum StrongAuthenticationRequestType {
	Payment = "PAYMENT",
	Recurring = "RECURRING",
	Installment = "INSTALLMENT",
}

export enum StrongAuthenticationRequestState {
	Initiated = "INITIATED",
	IsNotValid = "IS_NOT_VALID",
	IsValid = "IS_VALID",
	AuthAccepted = "AUTH_ACCEPTED",
	AuthFailed = "AUTH_FAILED",
	AuthRefused = "AUTH_REFUSED",
	AppNotificationSent = "APP_NOTIFICATION_SENT",
}

export type StrongAuthenticationError = {
	code: number;
	message: string;
};

export type StrongAuthenticationRequest = {
	id: number;
	acsTransactionId: string;
	authenticationType: "OUT_OF_BAND";
	cardId: string;
	creationDateTime: string;
	authenticationRequestType: StrongAuthenticationRequestType;
	maxResponseTime: number;
	messageVersion: string;
	amount: Amount;
	seller: string;
	state: StrongAuthenticationRequestState;
	error: StrongAuthenticationError;
};

export enum VerifyAuthenticationStatus {
	INITIATED = "INITIATED",
	STARTED = "STARTED",
	DONE = "DONE",
	CANCELLED = "CANCELLED",
	FAILED = "FAILED",
	REFUSED = "REFUSED",
}

export interface VerifyAuthenticationSMSResponse {
	status: VerifyAuthenticationStatus;
}
