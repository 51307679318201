import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme";

type SharedProps = {
	accept?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
interface DropZoneProps extends SharedProps {
	children: React.ReactNode;
}
interface EmptyZoneProps extends SharedProps {
	icon: React.ReactNode;
	title: string;
	subtitle: string;
	isDropZone?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

function DropZone(props: DropZoneProps) {
	const { children, accept, onChange } = props;
	const [isDropping, setIsDropping] = React.useState(false);
	const uniqueId = React.useMemo(
		() =>
			Math.random()
				.toString(36)
				.substring(7),
		[]
	);
	return (
		<DropZoneLabel htmlFor={uniqueId} isDropping={isDropping}>
			{children}
			<DropZoneInput
				onDragEnter={() => setIsDropping(true)}
				onDragLeave={() => setIsDropping(false)}
				id={uniqueId}
				accept={accept}
				onChange={event => onChange?.(event)}
				type="file"
			/>
		</DropZoneLabel>
	);
}

export default function EmptyZone(props: EmptyZoneProps) {
	const { icon, title, subtitle, isDropZone, disabled, accept = "application/pdf,image/jpg,image/jpeg,image/png", onChange, onClick } = props;
	const Content = (
		<>
			{icon}
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
		</>
	);

	return (
		<ComponentWrapper disabled={disabled}>
			{isDropZone ? (
				<DropZone accept={accept} onChange={event => onChange?.(event)}>{Content}</DropZone>
			) : (
				<Box onClick={() => onClick?.()}>{Content}</Box>
			)}
		</ComponentWrapper>
	);
}

const ComponentWrapper = styled.div<{ disabled?: boolean }>`
	width: 100%;
	${({ disabled }) => (disabled ? "opacity: 0.5;" : "")}
	${({ disabled }) => (disabled ? "point-event: none" : "")}
`;

const WrapperStyle = `
cursor: pointer;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 30px;
border-radius: 13px;
background-color: ${theme.colors.gray[100]};
&:hover {
	background-color: ${theme.colors.gray[200]};
}
`;

const Box = styled.div`
	${WrapperStyle}
`;

const Title = styled.span`
	font-size: 1.125rem;
	font-weight: 500;
	text-align: center;
	margin-top: 20px;
`;

const Subtitle = styled(Title)`
	margin-top: 10px;
	font-weight: 400;
	font-size: 1rem;
	color: ${theme.colors.gray[700]};
`;

const DropZoneLabel = styled.label<{ isDropping?: boolean }>`
	position: relative;
	display: block;
	width: 100%;
	border: dashed 2px transparent;
	${WrapperStyle}
	${props =>
		props.isDropping &&
		`
		background-color: ${theme.colors.gray[200]};
		border-color: ${theme.colors.gray[500]};
		`}
`;

const DropZoneInput = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
`;
