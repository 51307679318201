import moment, { Moment } from "moment";

export interface CacheEntry<T> {
	creation: Moment;
	ttl: number;
	data: T;
}

export function isCacheValid<T>(cache: CacheEntry<T> | undefined) {
	if (!cache) {
		return false;
	}
	const dataAge = moment().diff(cache.creation, "seconds");
	return dataAge < cache.ttl;
}
