import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated, PaginationOptions } from "../../utils/pagination";
import { AccountStatement } from "./account-statement";

export interface AccountStatementsService {
	fetchStatements(accountId: string, pagination?: PaginationOptions): Promise<Paginated<AccountStatement>>;
	downloadStatement(accountId: string, statementId: string): Promise<string>;
}
export class BaseAccountStatementsService {
	public constructor(protected apiService: ConnectedApiService) {}

	public async fetchStatements(accountId: string, pagination?: PaginationOptions) {
		try {
			const response = await this.apiService.instance.get<Paginated<AccountStatement>>(
				`/accounts/${accountId}/statements`,
				{
					params: { ...pagination },
				}
			);

			return response.data;
		} catch (e) {
			logger.debug("AccountStatementsService", "Fetch statements failed", e);
			throw e;
		}
	}

	public async fetchFeesStatements(accountId: string, pagination?: PaginationOptions) {
		try {
			const response = await this.apiService.instance.get<Paginated<AccountStatement>>(
				`/accounts/${accountId}/fees-statements`,
				{
					params: { ...pagination },
				}
			);

			return response.data;
		} catch (e) {
			logger.debug("AccountFeesStatementsService", "Fetch fees statements failed", e);
			throw e;
		}
	}
}
