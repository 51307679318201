import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { accountDeleteService, configurationManager } from "../../../../../shared/core/service/services";
import { useObservable } from "../../../../../shared/utils/observable";
import { ACCOUNT_DELETE_MODAL_ID } from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { useRTL } from "../../../../domain/language/use-rtl";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { IconCommunication } from "../../../common/svg/icon-communication";
import { IconKeypad } from "../../../common/svg/icon-keypad";
import { theme } from "../../../styles/theme";
import { OptionCard } from "./option-card";

export const AccountDeleteModal = () => {
	const { formatMessage } = useIntl();
	const dismissModal = () => Modal.dismiss(ACCOUNT_DELETE_MODAL_ID);
	const { isRTL } = useRTL();
	const history = useHistory();
	const { contactEmail } = useObservable(configurationManager.configuration);

	const goToContact = () => {
		history.replace(Path.BankContact);
	};

	return (
		<ModalContainer closeButton id={ACCOUNT_DELETE_MODAL_ID}>
			<>
				<TopRightBackgroundImage $isRTL={isRTL} />
				<Title>{formatMessage("accountDeleteScreen.title")}</Title>
				<Subtitle>{formatMessage("accountDeleteScreen.subtitle")}</Subtitle>
				<OptionsContainer>
					<OptionCard
						title={formatMessage("accountDeleteScreen.options.changeProfile.title")}
						subtitle={formatMessage("accountDeleteScreen.options.changeProfile.description")}
						onClick={() => dismissModal()}>
						<IconKeypad color={theme.mainColor()} />
					</OptionCard>
					<OptionCard
						title={formatMessage("accountDeleteScreen.options.unHappy.title")}
						subtitle={formatMessage("accountDeleteScreen.options.unHappy.description")}
						onClick={() => goToContact()}>
						<IconCommunication color={theme.mainColor()} />
					</OptionCard>
				</OptionsContainer>
				<TextButton>
					<Link href={accountDeleteService.buildLinkEmail(contactEmail)}>
						{formatMessage("accountDeleteScreen.button")}
					</Link>
				</TextButton>
				<Explanation>{formatMessage("accountDeleteScreen.explanation")}</Explanation>
			</>
		</ModalContainer>
	);
};

const ModalContainer = styled(RoundedModalContainer)`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	padding: 16px;
	padding-top: 100px;
	min-width: 400px;
`;

const BackgroundSrc = require("../../../../assets/images/svg/delete-account-background.svg");

const TopRightBackgroundImage = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 0;
	right: ${props => (props.$isRTL ? `unset` : 0)};
	left: ${props => (props.$isRTL ? 0 : `unset`)};
	background-image: url(${BackgroundSrc});
	width: 263px;
	height: 190px;
	-webkit-transform: scaleX(${props => (props.$isRTL ? -1 : 1)});
	transform: scaleX(${props => (props.$isRTL ? -1 : 1)});
`;

const Title = styled.div`
	${theme.boldText};
	color: black;
	font-size: 1.5rem;
	line-height: 2.125rem;
	text-align: center;
	margin: 86px 0 12px 0;
`;

const Subtitle = styled.div`
	${theme.text};
	color: #b1b1b1;
	font-size: 0.9375rem;
	line-height: 1.4375rem;
	text-align: center;
	margin: 8px 72px 16px 72px;
`;

const TextButton = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	justify-content: center;
	:hover {
		cursor: pointer;
	}
	margin: 32px 66px 0 66px;
`;

const Link = styled.a`
	${theme.boldText};
	font-size: 1rem;
	line-height: 1.5625rem;
	text-align: center;
	color: black;
	text-decoration: underline;
`;

const Explanation = styled.div`
	${theme.text};
	color: #aaaaaa;
	font-size: 0.8125rem;
	line-height: 1.3125rem;
	text-align: center;
	margin: 20px 66px 16px 66px;
`;

const OptionsContainer = styled.div``;
