import { RefObject, useEffect } from "react";

export function useClickOutsideHandler<T extends HTMLElement>(ref: RefObject<T>, handler: () => void) {
	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (ref.current && !ref.current.contains(event.target as Node | null)) {
				handler();
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handler, ref]);
}
