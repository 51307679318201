import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../../../shared/core/logging/logger";
import { Card, CardStatus, OpposeCardReason } from "../../../../../../shared/domains/cards/card";
import { CARD_DEACTIVATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { PrimaryButton, WhitePrimaryButton } from "../../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../../common/error-message";
import { Modal } from "../../../../common/modal/modal";
import { RoundedModalContainer } from "../../../../common/modal/rounded-modal-container";
import { theme } from "../../../../styles/theme";
import { UIConstants } from "../../../../styles/uiConstants";
import { ActionMenu } from "../action-menu";

interface DeactivateCardProps {
	card: Card;
	loading: boolean;
	deactivate: (reason: OpposeCardReason) => Promise<void>;
	className?: string;
}

export const DeactivateButton: React.FC<DeactivateCardProps> = ({ card, className, ...otherOptions }) => {
	const { formatMessage } = useIntl();

	const canDeactivate = useMemo(() => {
		switch (card.status) {
			case CardStatus.Pending:
			case CardStatus.Active:
			case CardStatus.Blocked:
				return true;
			default:
				return false;
		}
	}, [card.status]);

	if (!canDeactivate) {
		return null;
	}

	return (
		<ActionMenu
			title={formatMessage("cardOptions.deactivateCard.deactivateButton")}
			className={className}
			onClick={() => {
				Modal.present(CARD_DEACTIVATION_MODAL_ID, () => <DeactivateModal card={card} {...otherOptions} />, {
					canBeDismissed: false,
				});
			}}
		/>
	);
};

const DeactivateModal = (props: DeactivateCardProps) => (
	<RoundedModalContainer closeButton id={CARD_DEACTIVATION_MODAL_ID}>
		<DeactivateCard {...props} />
	</RoundedModalContainer>
);

const DeactivateCard: React.FC<DeactivateCardProps> = props => {
	const { card, loading, deactivate, className } = props;
	const { formatMessage } = useIntl();
	const [error, setError] = useState<string | null>(null);
	const [selectedOpposeReason, setSelectedOpposeReason] = useState(OpposeCardReason.Lost);

	useEffect(() => {
		setSelectedOpposeReason(OpposeCardReason.Lost);
	}, [card]);

	const deactivateCard = async () => {
		try {
			setError(null);
			await deactivate(selectedOpposeReason);
			Modal.dismiss(CARD_DEACTIVATION_MODAL_ID);
		} catch (e) {
			logger.debug(e);
			setError(e?.response?.data?.error?.message);
		}
	};

	return (
		<Container className={className}>
			<MessageTitle>{formatMessage("cardOptions.deactivateCard.deactivatePromptTitle")}</MessageTitle>
			<MessageLabel>{formatMessage("cardOptions.deactivateCard.title")}</MessageLabel>
			{error && <StyledErrorMessage>{error}</StyledErrorMessage>}
			<ConfirmButtonsContainer>
				<WhitePrimaryButton size="S" onClick={() => Modal.dismiss(CARD_DEACTIVATION_MODAL_ID)}>
					{formatMessage("cardOptions.deactivateCard.deactivateCancelButton")}
				</WhitePrimaryButton>
				<PrimaryButton disabled={loading} showSpinner={loading} size="S" onClick={() => !loading && deactivateCard()}>
					{formatMessage("cardOptions.deactivateCard.deactivateConfirmButton")}
				</PrimaryButton>
			</ConfirmButtonsContainer>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 20px;
`;

const MessageTitle = styled.span`
	${theme.bodyBlackBold}
	margin-bottom: 10px;
`;

const MessageLabel = styled.span`
	${theme.mediumText}
	font-size: 14px;
`;

const ConfirmButtonsContainer = styled.div`
	display: flex;
	align-self: stretch;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 36px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;

		> * {
			width: 100%;
			max-width: 200px;
		}

		> *:not(:first-child) {
			margin-top: 10px;
		}
	}
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 0px;
`;
