import { Amount } from "../../core/amount/amount";
import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated } from "../../utils/pagination";
import { PincodeSubmission } from "../pincode/pincode";
import { TransactionCode } from "./transaction-code";

export class TransactionCodeService {
	public constructor(private apiService: ConnectedApiService) {}

	public async fetchTransactionCodes() {
		try {
			const response = await this.apiService.instance.get<Paginated<TransactionCode>>(`/transaction-codes`);
			return response.data.items;
		} catch (e) {
			logger.debug("TransactionCodeService", "Failed to fetch transaction codes", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async createTransactionCode(amount: Amount, acceptorShortCode: string, pincode?: PincodeSubmission) {
		try {
			const response = await this.apiService.instance.post<TransactionCode>(`/transaction-codes`, {
				amount,
				acceptorShortCode,
				pincode,
			});
			return response.data;
		} catch (e) {
			logger.debug("TransactionCodeService", "Failed to create transaction codes", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
