import { Link, useLocation } from "react-router-dom";
import { NavigationSubItem, SubMenu } from "./component/sub-menu";
import React, { useMemo } from "react";
import { authenticationManager, configurationManager, featuresManager } from "../../../../shared/core/service/services";

import { BottomNav } from "./bottom-nav";
import { NavButton } from "./nav-button";
import { NavType } from "./nav-type";
import { OnboardingStatus } from "../../../../shared/domains/onboarding/onboarding";
import { Path } from "../../../core/routing/path";
import { StaticAssets } from "../../styles/custom-assets";
import { UIConstants } from "../../styles/uiConstants";
import { shadows } from "../../styles/theme";
import { sideBarColors } from "./component/colors";
import styled from "styled-components";
import { useClient } from "../../../domain/authentication/use-client";
import { useObservable } from "../../../../shared/utils/observable";
import { useOnboardingFile } from "../../../../shared/domains/onboarding/use-onboarding";
import { useRTL } from "../../../domain/language/use-rtl";

interface NavigationItem {
	path: Path;
	type: NavType;
	subItems?: NavigationSubItem[];
	isEnabled: boolean;
}

function Menu(props: { onItemClick?: () => void }) {
	const { onItemClick } = props;
	const { pathname } = useLocation();
	const features = useObservable(featuresManager.features);
	const { onboardingFile } = useOnboardingFile();
	const { client } = useClient();

	const transfertSubItems: NavigationSubItem[] = useMemo(() => {
		const subItems: NavigationSubItem[] = [];
		subItems.push({ title: "transfertsSubmenu.allTransfer", path: Path.Transfer });
		if (configurationManager.isSimplePayment() && features.p2pSimpleTransfer) {
			subItems.push({ title: "transfertsSubmenu.simpleTransfer", path: Path.SimpleTransfer });
		}
		if (features.p2PCashTransfer) {
			subItems.push({ title: "transfertsSubmenu.cashTransfer", path: Path.CashTransfers });
		}
		if (features.bankToWallet || features.walletRechargeByCard) {
			subItems.push({ title: "transfertsSubmenu.recharge", path: Path.Recharge });
		}
		if (features.transactionCode) {
			subItems.push({ title: "transfertsSubmenu.transactionCode", path: Path.TransactionCodes });
		}
		if (features.externalAccountsManagement || features.beneficiariesManagement) {
			subItems.push({ title: "transfertsSubmenu.recipients", path: Path.Recipients });
		}

		return subItems;
	}, [features]);

	const canShowTransferMenu =
		features.p2PCashTransfer || features.bankToWallet || features.walletRechargeByCard || features.transactionCode;

	const navigationItems: NavigationItem[] = [
		{ path: Path.AccountTransactions, type: "account", isEnabled: true },
		{ path: Path.TransferMenu, type: "transfers", isEnabled: canShowTransferMenu, subItems: transfertSubItems },
		{ path: Path.BillIssuers, type: "services", isEnabled: features.billPayment },
		{ path: Path.Cards, type: "cards", isEnabled: features.cards },
		{ path: Path.Loans, type: "loan", isEnabled: features.consultLoans },
	];

	const { isRTL } = useRTL();
	return (
		<MenuContainer role="menu" $isRTL={isRTL}>
			<Logo role="presentation" src={StaticAssets.logo} />
			{onboardingFile?.status !== OnboardingStatus.PENDING_VERIFICATION && (
				<>
					{navigationItems.map(item => {
						const isActive = pathname.startsWith(item.path);
						return item.isEnabled ? (
							item.subItems && item.subItems.length > 0 ? (
								<SubMenu key={item.type} items={item.subItems} isActive={isActive} onClick={() => onItemClick?.()}>
									<StyledNavButton linkType={item.type} isActive={isActive} withBackground={false} />
								</SubMenu>
							) : (
								<StyledLink key={item.type} to={item.path} onClick={() => onItemClick?.()}>
									<StyledNavButton linkType={item.type} isActive={isActive} />
								</StyledLink>
							)
						) : null;
					})}
				</>
			) }
			<Spacer />
			<ContactLink to={Path.BankContact} onClick={() => props.onItemClick?.()}>
				<StyledNavButton linkType={"contact"} isActive={pathname.startsWith(Path.BankContact)} />
			</ContactLink>
			<BottomNav
				client={client}
				onDisconnectClick={() => {
					authenticationManager.logout();
				}}
				profilePath={Path.Settings}
				onProfileClick={() => props.onItemClick?.()}
			/>
		</MenuContainer>
	);
}

export function Nav() {
	return <Menu />;
}

const MenuContainer = styled.nav<{ $isRTL: boolean }>`
	flex: 0 0 auto;
	position: fixed;
	z-index: 100;
	top: 10px;
	bottom: 10px;
	left: ${props => (props.$isRTL ? `unset` : `${UIConstants.NAV_LEFT_MARGIN_DESKTOP_SMALL}px`)};
	right: ${props => (props.$isRTL ? `${UIConstants.NAV_LEFT_MARGIN_DESKTOP_SMALL}px` : `unset`)};
	display: flex;
	flex-direction: column;
	padding: 19px 0 0 0;
	border-radius: 11px;
	background-color: ${sideBarColors.background};
	height: auto;
	min-width: ${UIConstants.NAV_WIDTH}px;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		min-width: ${UIConstants.NAV_WIDTH_REDUCED}px;
		width: ${UIConstants.NAV_WIDTH_REDUCED}px;
	}
	${shadows.medium};
`;

const Logo = styled.img`
	align-self: center;
	width: auto;
	height: 100px;
	object-fit: fill;
	border-radius: 13px;
	margin-bottom: 24px;
	@media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
		margin-bottom: 10px;
		display: none;
	}
`;

const StyledNavButton = styled(NavButton)`
	&:not(:last-of-type) {
		margin-bottom: 2px;
	}
`;

const StyledLink = styled(Link)`
	width: 100%;
	display: flex;
	justify-content: stretch;
	margin-bottom: 2px;
`;

const ContactLink = styled(Link)`
	margin: 16px 0;
`;

const Spacer = styled.div`
	flex: 1 1 auto;
`;
