export enum CardLinks {
	Refabricate = "refabricateCard",
	ReissueCode = "reissueCardCode",
	Oppose = "opposeCard",
	Block = "blockCard",
	Unblock = "unblockCard",
	Activate = "activateCard",
	ActivateWithCode = "activateCardWithCode",
	UpdateFeatures = "updateCardFeatures",
	UpdateLimits = "updateCardLimits",
	Deactivate = "deactivateCard",
	GetOutstandings = "getOutstandings",
}
