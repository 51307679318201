import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated } from "../../utils/pagination";
import { Account, AccountStatus } from "./account";

export class AccountService {
	public constructor(private apiService: ConnectedApiService) {}

	public async fetchAccounts() {
		try {
			const response = await this.apiService.instance.get<Paginated<Account>>(`/accounts`, {
				params: {
					status: [
						AccountStatus.Opened,
						AccountStatus.Blocked,
						AccountStatus.DebitBlocked,
						AccountStatus.CreditBlocked,
					],
				},
			});
			return response.data;
		} catch (e) {
			logger.debug("AccountService", "Failed to fetch accounts", e);
			throw e;
		}
	}
}
