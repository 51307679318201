import { useMachine } from "@xstate/react";
import { assign, Machine, send } from "xstate";
import { clientManager, pincodeKeyboardService, pincodeService } from "../../shared/core/service/services";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import { FONT_SIZE, TOUCH_SIZE } from "./keyboard-machine-type";

export const useChangePincodeMachine = (skipExplaination = true) => {
	const [state, sendEvent] = useMachine(changePincodeMachine(skipExplaination));
	const submitCurrentPincode = (pincode: PincodeSubmission) => {
		sendEvent({ type: "SUBMIT_CURRENT_PINCODE", pincode });
	};
	const submitNewPincode = (pincode: PincodeSubmission) => {
		sendEvent({ type: "SUBMIT_NEW_PINCODE", pincode });
	};
	const confirmNewPincode = (pincode: PincodeSubmission) => {
		sendEvent({ type: "CONFIRM_NEW_PINCODE", pincode });
	};
	const startPincodeChange = () => {
		sendEvent({ type: "CHANGE_PINCODE" });
	};
	const goBack = () => {
		sendEvent({ type: "GO_BACK" });
	};
	return {
		state: state.value as ChangePincodeState,
		context: state.context,
		submitCurrentPincode,
		submitNewPincode,
		confirmNewPincode,
		startPincodeChange,
		goBack,
	};
};

interface ChangePincodeMachineContext {
	errorMessage?: string | undefined;
	keyboard?: Keyboard | undefined;
	currentCode?: PincodeSubmission | undefined;
	newCode?: PincodeSubmission | undefined;
}

export enum ChangePincodeState {
	Explaining = "Explaining",
	FetchingCurrentPincodeKeyboard = "FetchingCurrentPincodeKeyboard",
	PromptingCurrentPincodeKeyboard = "PromptingCurrentPincodeKeyboard",
	FetchingCurrentPincodeKeyboardAfterError = "FetchingCurrentPincodeKeyboardAfterError",
	FetchingChangePincodeKeyboard = "FetchingChangePincodeKeyboard",
	PromptingChangePincodeKeyboard = "PromptingChangePincodeKeyboard",
	PromptingConfirmPincodeKeyboard = "PromptingConfirmPincodeKeyboard",
	PincodePromptNotMatch = "PincodePromptNotMatch",
	RequestingPincodeChange = "RequestingPincodeChange",
	Done = "Done",
}
type ChangePincodeEvent =
	| { type: "CHANGE_PINCODE" }
	| { type: "PROMPT_KEYBOARD" }
	| { type: "SUBMIT_CURRENT_PINCODE"; pincode: PincodeSubmission }
	| { type: "SUBMIT_NEW_PINCODE"; pincode: PincodeSubmission }
	| { type: "CONFIRM_NEW_PINCODE"; pincode: PincodeSubmission }
	| { type: "REQUEST_PINCODE_CHANGE" }
	| { type: "PINCODE_DONT_MATCH" }
	| { type: "GO_BACK" };

export const changePincodeMachine = (skipExplaination = true) =>
	Machine<ChangePincodeMachineContext, ChangePincodeEvent>({
		id: "ChangePincode",
		initial: skipExplaination ? ChangePincodeState.FetchingCurrentPincodeKeyboard : ChangePincodeState.Explaining,
		states: {
			[ChangePincodeState.Explaining]: {
				on: {
					CHANGE_PINCODE: { target: ChangePincodeState.FetchingCurrentPincodeKeyboard },
				},
			},
			[ChangePincodeState.FetchingCurrentPincodeKeyboard]: {
				invoke: {
					id: "fetchKeyboard",
					src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
					onDone: {
						actions: [
							assign({
								keyboard: (_, event) => event.data,
							}),
							send({ type: "PROMPT_KEYBOARD" }),
						],
					},
				},
				on: {
					PROMPT_KEYBOARD: ChangePincodeState.PromptingCurrentPincodeKeyboard,
				},
			},
			[ChangePincodeState.FetchingCurrentPincodeKeyboardAfterError]: {
				invoke: {
					id: "fetchKeyboard",
					src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
					onDone: {
						actions: [
							assign({
								keyboard: (_, event) => event.data,
							}),
							send({ type: "PROMPT_KEYBOARD" }),
						],
					},
				},
				on: {
					PROMPT_KEYBOARD: ChangePincodeState.PromptingCurrentPincodeKeyboard,
				},
			},
			[ChangePincodeState.PromptingCurrentPincodeKeyboard]: {
				on: {
					SUBMIT_CURRENT_PINCODE: {
						target: ChangePincodeState.FetchingChangePincodeKeyboard,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						actions: assign<ChangePincodeMachineContext, any>({
							currentCode: (_, event) => event.pincode,
							errorMessage: () => undefined,
						}),
					},
				},
			},
			[ChangePincodeState.FetchingChangePincodeKeyboard]: {
				invoke: {
					id: "fetchKeyboard",
					src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
					onDone: {
						actions: [
							assign({
								keyboard: (_, event) => event.data,
							}),
							send({ type: "PROMPT_KEYBOARD" }),
						],
					},
				},
				on: {
					PROMPT_KEYBOARD: ChangePincodeState.PromptingChangePincodeKeyboard,
				},
			},
			[ChangePincodeState.PromptingChangePincodeKeyboard]: {
				on: {
					SUBMIT_NEW_PINCODE: {
						target: ChangePincodeState.PromptingConfirmPincodeKeyboard,
						actions: assign({ newCode: (_, event) => event.pincode }),
					},
					GO_BACK: {
						target: ChangePincodeState.FetchingCurrentPincodeKeyboard,
					},
				},
			},
			[ChangePincodeState.PromptingConfirmPincodeKeyboard]: {
				on: {
					CONFIRM_NEW_PINCODE: {
						actions: send((context, event) =>
							event.pincode.value.join() === context.newCode?.value.join()
								? { type: "REQUEST_PINCODE_CHANGE" }
								: { type: "PINCODE_DONT_MATCH" }
						),
					},
					PINCODE_DONT_MATCH: { target: ChangePincodeState.PincodePromptNotMatch },
					REQUEST_PINCODE_CHANGE: { target: ChangePincodeState.RequestingPincodeChange },
					GO_BACK: {
						target: ChangePincodeState.FetchingCurrentPincodeKeyboard,
					},
				},
			},
			[ChangePincodeState.RequestingPincodeChange]: {
				invoke: {
					id: "RequestPincodeChange",
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					src: context => pincodeService.changePincode(context.currentCode!, context.newCode!),
					onDone: {
						target: ChangePincodeState.Done,
					},
					onError: {
						target: ChangePincodeState.FetchingCurrentPincodeKeyboardAfterError,
						actions: assign({
							errorMessage: (_, event) => event.data,
						}),
					},
				},
			},
			[ChangePincodeState.PincodePromptNotMatch]: {
				on: {
					SUBMIT_NEW_PINCODE: {
						target: ChangePincodeState.PromptingConfirmPincodeKeyboard,
						actions: assign({ newCode: (_, event) => event.pincode }),
					},
					GO_BACK: {
						target: ChangePincodeState.FetchingCurrentPincodeKeyboard,
					},
				},
			},
			[ChangePincodeState.Done]: {
				entry: () => {
					clientManager.updateClient(true);
				},
				type: "final",
			},
		},
	});
