import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { DefaultButton, DefaultLink } from "./default-button";

const commonStyle = css`
	padding: 6px 17px;
	border-radius: 25px;
	background-color: #ececec;
	font-size: 0.8125rem;
	${theme.boldText};
	line-height: normal;
	color: #000000;

	:focus,
	:active,
	:hover {
		background-color: #e2e2e2;
	}
	:disabled {
		opacity: 0.5;
	}
`;

export const TertiaryButton = styled(DefaultButton)`
	${commonStyle};
`;

export const TertiaryLink = styled(DefaultLink)`
	${commonStyle};
`;

export const TertiaryDiv = styled.div`
	${commonStyle};
`;
