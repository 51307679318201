import { Paginated, createEmptyPaginatedData } from "../../../utils/pagination";
import { TransactionDirection, TransactionFilters } from "./transaction-filter";

import { CacheLoader } from "../../../core/cache/cache-loader";
import { CacheStatus } from "../../../core/cache/cache-status";
import { logger } from "../../../core/logging/logger";
import { Observable } from "../../../utils/observable";
import { ObservablePaginated } from "../../../utils/observable-paginated";
import { AccountingTransaction } from "./transaction";
import { TransactionService } from "./transaction-service";

export class TransactionsLoader {
	private cacheKey: string;
	private isSearchLoader: boolean;

	public data = new ObservablePaginated<AccountingTransaction>(createEmptyPaginatedData<AccountingTransaction>());
	public unjustifiedData = new ObservablePaginated<AccountingTransaction>(
		createEmptyPaginatedData<AccountingTransaction>()
	);
	public unqualifiedData = new ObservablePaginated<AccountingTransaction>(
		createEmptyPaginatedData<AccountingTransaction>()
	);
	public cacheStatus = new Observable<CacheStatus | null>(null);

	public initializing = new Observable<boolean>(true);
	public loading = new Observable<boolean>(false);
	public refreshing = new Observable<boolean>(false);
	public error = new Observable<Error | null>(null);

	public loadingMore = new Observable<boolean>(false);
	public loadingMoreError = new Observable<Error | null>(null);

	public constructor(
		private accountId: string | null,
		private accountingTransactionService: TransactionService,
		private cacheLoader: CacheLoader<Paginated<AccountingTransaction>>,
		private direction: TransactionDirection,
		private filters?: TransactionFilters
	) {
		this.isSearchLoader = this.filters ? true : false;
		this.cacheKey = this.isSearchLoader ? `${accountId}_search` : `${accountId}_${this.direction}`;
	}

	public async load(): Promise<void> {
		if (!this.accountId || this.loading.get() || this.refreshing.get()) {
			return;
		}
		this.loading.set(true);
		this.initializing.set(false);
		await this.loadOrRefresh(this.isSearchLoader);
		this.loading.set(false);
	}

	public async refresh(): Promise<void> {
		if (!this.accountId || this.refreshing.get() || this.loading.get()) {
			return;
		}
		this.refreshing.set(true);
		await this.loadOrRefresh(true);
		this.refreshing.set(false);
	}

	private async loadOrRefresh(forceRefresh?: boolean) {
		if (this.accountId) {
			const accountId: string = this.accountId;
			try {
				this.error.set(null);
				const transactions = await this.cacheLoader.load(
					() => this.accountingTransactionService.fetchTransactions(accountId, undefined, this.direction, this.filters),
					forceRefresh,
					this.cacheKey
				);
				const unjustifiedTransactions = await this.cacheLoader.load(
					() =>
						this.accountingTransactionService.fetchTransactions(
							accountId,
							undefined,
							this.direction,
							this.filters ? { ...this.filters, isJustified: 0, isLocked: 0, direction: "debit" } : undefined
						),
					forceRefresh,
					this.cacheKey
				);
				const unqualifiedTransactions = await this.cacheLoader.load(
					() =>
						this.accountingTransactionService.fetchTransactions(
							accountId,
							undefined,
							this.direction,
							this.filters ? { ...this.filters, isQualified: 0, isLocked: 0, direction: "debit" } : undefined
						),
					forceRefresh,
					this.cacheKey
				);

				if (!transactions) {
					throw Error("Failed to fetch transactions");
				}
				if (!unjustifiedTransactions) {
					throw Error("Failed to fetch unjustified transactions");
				}
				if (!unqualifiedTransactions) {
					throw Error("Failed to fetch unqualified transactions");
				}
				await this.updateCacheStatus();
				this.data.set(transactions);
				this.unjustifiedData.set(unjustifiedTransactions);
				this.unqualifiedData.set(unqualifiedTransactions);
			} catch (e) {
				logger.debug("TransactionsLoader", "Failed to load or refresh transactions", e);
				this.error.set(e);
				this.data.set(createEmptyPaginatedData<AccountingTransaction>());
			}
		}
	}

	public async loadMore(): Promise<void> {
		if (this.accountId) {
			const accountId: string = this.accountId;
			if (!this.accountId || this.loading.get() || this.loadingMore.get()) {
				return;
			}
			try {
				this.loadingMore.set(true);
				this.loadingMoreError.set(null);

				const additional = await this.accountingTransactionService.fetchTransactions(
					accountId,
					{
						offset: this.data.get().offset + this.data.get().limit,
						limit: this.data.get().limit,
						sort: this.data.get().sort,
					},
					this.direction,
					this.filters
				);

				this.data.add(additional);

				this.cacheLoader.store(this.data.get(), this.cacheStatus.get()?.creation, this.cacheKey);
			} catch (e) {
				logger.debug("TransactionsLoader", "Failed to load more transactions", e);
				this.loadingMoreError.set(e);
			} finally {
				this.loadingMore.set(false);
			}
		}
	}

	private async updateCacheStatus() {
		if (this.accountId) {
			const cacheStatus = await this.cacheLoader.readStatus(this.cacheKey);
			this.cacheStatus.set(cacheStatus);
		}
	}

	public async clear() {
		if (this.accountId) {
			await this.cacheLoader.clear(this.cacheKey);
			this.data.set(createEmptyPaginatedData<AccountingTransaction>());
			this.cacheStatus.set(null);
		}
	}
}
