import { OAuth2AuthCodePKCE, RECOMMENDED_STATE_LENGTH } from "@bity/oauth2-auth-code-pkce";
import { Config } from "../../../shared/core/config/config";
import { OauthHttpService } from "../../../shared/core/net/oauth-http-service";
import {
	AuthConfiguration,
	OAuthService,
	RefreshConfiguration,
	RefreshResult,
	TokenResultDto,
} from "../../../shared/domains/authentication/oauth-service";
import { LocalStorage } from "../../core/cache/local-storage";
import { TrustManager } from "../trust/trust-manager";

const CODE_VERIFIER_KEY = "oauth_code_verifier";
const STATE_KEY = "oauth_state";

export class WebOAuthService implements OAuthService {
	constructor(
		private oAuthHttpService: OauthHttpService,
		private localStorage: LocalStorage<string>,
		private trustManager: TrustManager
	) {
		this.trustManager.onRemoveState.add(() => this.removeState());
	}

	async refresh(configuration: AuthConfiguration, refreshConfig: RefreshConfiguration): Promise<RefreshResult> {
		const result = await this.oAuthHttpService.instance.post<TokenResultDto>(
			"/oauth2/token",
			{
				grant_type: "refresh_token",
				client_id: configuration.clientId,
				refresh_token: refreshConfig.refreshToken,
				client_secret: configuration.clientSecret,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		const { refresh_token, access_token, expires_in, token_type } = result.data;
		return {
			refreshToken: refresh_token ?? null,
			accessToken: access_token,
			accessTokenExpirationDate: new Date(Date.now() + parseInt(expires_in.toString()) * 1000).toString(),
			tokenType: token_type,
		};
	}
	async authorize(configuration: AuthConfiguration): Promise<void> {
		this.redirectToAuthorizationGrant(configuration);
	}

	private async redirectToAuthorizationGrant(configuration: AuthConfiguration) {
		const { codeChallenge, codeVerifier } = await OAuth2AuthCodePKCE.generatePKCECodes();
		this.localStorage.store(codeVerifier, CODE_VERIFIER_KEY);

		let stateQueryParam: string;
		const previousState = await this.localStorage.read(STATE_KEY);
		if (previousState) {
			stateQueryParam = previousState;
		} else {
			stateQueryParam = OAuth2AuthCodePKCE.generateRandomState(RECOMMENDED_STATE_LENGTH);
			this.localStorage.store(stateQueryParam, STATE_KEY);
		}

		const additionalParametersText = Object.entries(configuration.additionalParameters ?? {}).reduce(
			(acc, attribute) => {
				const [key, value] = attribute;
				return acc + `&${key}=${value}`;
			},
			""
		);

		const url =
			configuration.serviceConfiguration.authorizationEndpoint +
			`?response_type=code&` +
			`client_id=${encodeURIComponent(configuration.clientId)}&` +
			`redirect_uri=${encodeURIComponent(configuration.redirectUrl)}&` +
			`scope=${encodeURIComponent(configuration.scopes.join(" "))}&` +
			`state=${stateQueryParam}&` +
			`code_challenge=${encodeURIComponent(codeChallenge)}&` +
			`code_challenge_method=S256` +
			additionalParametersText;
		location.replace(url);
	}

	async completeAuthorization(
		code: string,
		paramState: string,
		configuration: AuthConfiguration
	): Promise<TokenResultDto> {
		const codeVerifier = await this.localStorage.read(CODE_VERIFIER_KEY);
		const state = await this.localStorage.read(STATE_KEY);
		if (paramState !== state) {
			throw `OAuth State mismatch (authorize request and url parameters). ${state} ${paramState}`;
		}

		const result = await this.oAuthHttpService.instance.post<TokenResultDto>(
			"/oauth2/token",
			{
				grant_type: "authorization_code",
				client_id: configuration.clientId,
				client_secret: Config.API_CLIENT_SECRET,
				redirect_uri: configuration.redirectUrl,
				code_verifier: codeVerifier,
				scopes: configuration.scopes,
				code,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		this.localStorage.clear(CODE_VERIFIER_KEY);
		return result.data;
	}

	private removeState() {
		this.localStorage.clear(STATE_KEY);
	}
}
