import React, { SVGProps } from "react";

export function PinIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 26 26" {...props}>
			<path
				fillRule="nonzero"
				d="M13 3.25c4.188 0 7.583 3.395 7.583 7.583 0 3.367-2.193 6.221-5.23 7.211L13 22.75l-2.352-4.705-.221-.076c-2.922-1.054-5.01-3.85-5.01-7.136C5.417 6.645 8.812 3.25 13 3.25zm0 5.417c-1.197 0-2.167.97-2.167 2.166 0 1.197.97 2.167 2.167 2.167 1.197 0 2.167-.97 2.167-2.167 0-1.196-.97-2.166-2.167-2.166z"
				transform="translate(17 17)"
			/>
		</svg>
	);
}
