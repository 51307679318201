import { AxiosRequestConfig } from "axios";
import { SecuredCookiesService } from "../secured-cookies-service";
import { Interceptor } from "./interceptor";

export const addSecuredCookieInterceptor: (
	securedCookiesService: SecuredCookiesService
) => Interceptor<AxiosRequestConfig> = securedCookiesService => ({
	// X-TagPay-OAuth2-Cookie muste be set to a number not a string
	// @ts-ignore
	onFulfilled: config =>
		securedCookiesService.getSecuredCookies()
			? {
					...config,
					headers: { ...config.headers, "X-TagPay-OAuth2-Cookie": 1 },
					withCredentials: true,
			  }
			: {
					...config,
			  },
});
