import moment from "moment";

export const getLocaleDateFromUtcDateString = (utcIsoDate: string): Date => moment(utcIsoDate).toDate();

export const getMidnightLocaleDateFromUtc = (utcIsoDate: Date): Date => {
    const localeDate = new Date(utcIsoDate);
    localeDate.setFullYear(utcIsoDate.getUTCFullYear(), utcIsoDate.getUTCMonth(), utcIsoDate.getUTCDate());
    localeDate.setHours(0, 0, 0, 0);

    return localeDate;
}

export const getMidnightUtcDateFromLocale = (localeDate: Date): Date => {
    const utcIsoDate = new Date(localeDate);
    utcIsoDate.setUTCFullYear(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate());
    utcIsoDate.setUTCHours(0, 0, 0, 0);

    return localeDate;
}
