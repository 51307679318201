import { useCallback, useEffect, useRef, useState } from "react";
import { logger } from "../core/logging/logger";

export function delay(time: number) {
	return new Promise<void>(resolve => setTimeout(resolve, time));
}

export function useAsyncEffect(
	effect: () => Promise<void>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deps?: readonly any[]
) {
	useEffect(() => {
		effect().then();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}

export function useAsyncFunction<T>(
	fun: (forceRefresh?: boolean) => Promise<T>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deps: readonly any[] = []
) {
	const [loading, setLoading] = useState(false);
	const [redoLoading, setRedoLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [result, setResult] = useState<T | null>(null);
	const [tries, setTries] = useState<number>(0);

	useAsyncEffect(async () => {
		try {
			setTries(0);
			setResult(null);
			setError(null);
			setLoading(true);
			const res = await fun();
			setResult(res);
		} catch (e) {
			setError(e);
		} finally {
			setLoading(false);
		}
	}, [setError, setLoading, setResult, ...deps]);

	useAsyncEffect(async () => {
		if (tries !== 0) {
			try {
				setResult(null);
				setError(null);
				const res = await fun(true);
				setResult(res);
			} catch (e) {
				setError(e);
			} finally {
				setRedoLoading(false);
			}
		}
	}, [setError, setLoading, setResult, tries]);

	const redo = useCallback(() => {
		setRedoLoading(true);
		setTries(tries => tries + 1);
	}, []);

	return {
		loading,
		error,
		result,
		redo,
		redoLoading,
	};
}

export function repeat<T>(count: number, factory: (index: number) => T) {
	return new Array(count).fill(null).map((_, index) => factory(index));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAnNotEmptyArray(value: any) {
	return Array.isArray(value) && value.length > 0;
}

export function useMounted() {
	const mounted = useRef(false);
	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);
	return mounted;
}

type AsyncOperationResult<T> = [() => Promise<T | undefined>, boolean, string | null];

export function useAsyncOperation<T>(operation: () => Promise<T>): AsyncOperationResult<T> {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const isCancelled = useRef(false);
	useEffect(
		() => () => {
			isCancelled.current = true;
		},
		[]
	);
	const operationWrapper = useCallback(async () => {
		try {
			setLoading(true);
			const result = await operation();
			if (!isCancelled.current) {
				setError(null);
				setLoading(false);
			}
			return result;
		} catch (err) {
			logger.debug("useAsyncOperation", operation.name, err);
			if (!isCancelled.current) {
				setError(err?.response?.data?.error?.message || err.toString());
			}
		} finally {
			if (!isCancelled.current) {
				setLoading(false);
			}
		}
	}, [operation]);
	return [operationWrapper, loading, error];
}
