import copy from "copy-to-clipboard";
import React, { HTMLAttributes, useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { getRandomID } from "../../../../../shared/utils/random";
import { useTooltip } from "../../../../utils/use-tooltip";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { ClipboardIcon } from "../../../common/svg/clipboard-icon";
import { theme } from "../../../styles/theme";

interface ClipboardInformationRowProps extends HTMLAttributes<HTMLDivElement> {
	value: string;
	title: string;
}

export const ClipboardInformationRow = (props: ClipboardInformationRowProps) => {
	const { title, value, ...rest } = props;
	const { elementRef, showTooltip } = useTooltip<HTMLButtonElement>();
	const { formatMessage } = useIntl();
	const [uid] = useState(getRandomID());
	const copyToClipboard = () => {
		copy(value);
		showTooltip();
	};
	return (
		<Row {...rest}>
			<Column>
				<Title>{title}</Title>
				<Value>{value}</Value>
			</Column>
			<GreyRoundedSquare
				data-for={uid}
				ref={elementRef}
				size={34}
				data-tip={formatMessage("accountDetails.clipboard", { name: title })}
				onClick={copyToClipboard}>
				<ClipboardIcon />
			</GreyRoundedSquare>
			<ReactTooltip id={uid} place="top" type="dark" effect="solid" event={"none"} />
		</Row>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-self: stretch;
	justify-content: space-between;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const Title = styled.span`
	${theme.text};
	font-size: 0.9375rem;
	color: #b1b1b1;
`;

const Value = styled.span`
	${theme.boldText};
	font-size: 0.9375rem;
`;

const GreyRoundedSquare = styled(RoundedSquareButton)`
	background-color: #f4f4f4;
`;
