import React, { HTMLAttributes, useMemo } from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account } from "../../../../../shared/domains/account/account";
import { AccountingEntryType } from "../../../../../shared/domains/accounting-transaction/accounting-entry";
import { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useSpendings } from "../../../../../shared/domains/accounting-transaction/transaction/use-spendings";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import { ACCOUNT_TRANSACTION_DETAILS_MODAL_ID } from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { MapIframe } from "../../../common/map-iframe";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { theme } from "../../../styles/theme";

interface AccountTransactionDetailsProps {
	transaction: AccountingTransaction;
	account: Account;
}

const accountEntryLabels = {
	[AccountingEntryType.Transfer]: "accountingTransactionDetailsModal.transferLabel",
	[AccountingEntryType.Fee]: "accountingTransactionDetailsModal.feeLabel",
	[AccountingEntryType.Commission]: "accountingTransactionDetailsModal.commissionLabel",
	[AccountingEntryType.Tax]: "accountingTransactionDetailsModal.taxLabel",
	[AccountingEntryType.Discount]: "accountingTransactionDetailsModal.discountLabel",
	[AccountingEntryType.Interest]: "accountingTransactionDetailsModal.interestLabel",
};

export const AccountTransactionDetailsModal = (props: AccountTransactionDetailsProps) => (
	<RoundedModalContainer closeButton id={ACCOUNT_TRANSACTION_DETAILS_MODAL_ID}>
		<AccountTransactionDetails transaction={props.transaction} account={props.account} />
	</RoundedModalContainer>
);

const AccountTransactionDetails: React.FC<AccountTransactionDetailsProps> = ({ transaction, account }) => {
	const { formatMessage, formatDate } = useIntl();
	const { canJustify, canEditJustification, canViewJustification } = useTransactionLinks();
	const { push } = useHistory();
	const { amountJustified, spendings } = useSpendings(transaction);

	const detailedAccountingTransfer = transaction?.detailedAccounting?.find(
		accounting => accounting.type === AccountingEntryType.Transfer
	);
	const transactionDescription =
		transaction.shortDescription || detailedAccountingTransfer?.description || transaction.type.label;

	const additionalInformation = useMemo(() => {
		let externalDataValue: string | undefined = undefined;
		let externalId: string | undefined = undefined;
		if (transaction) {
			externalId = transaction.externalId;
			if (transaction.externalData) {
				const externalData = transaction.externalData;
				externalDataValue = Object.keys(externalData)
					.map(key => `${key} : ${externalData[key]}`)
					.join("\n");
			}
			if (transaction.additional) {
				externalDataValue = externalDataValue
					? externalDataValue + "\n" + transaction.additional
					: transaction.additional;
			}
		}
		return { externalId: externalId, externalData: externalDataValue };
	}, [transaction]);

	if (!transaction.amount) {
		return null;
	}

	const handleJustifyTransaction = () => {
		push(Path.JustifyTransaction, { transaction, account });
		Modal.dismiss(ACCOUNT_TRANSACTION_DETAILS_MODAL_ID);
	};

	return (
		<Container>
			<Header>
				<Title>{transactionDescription}</Title>
			</Header>
			<DateLabel>
				{formatDate(transaction.date, {
					month: "long",
					day: "2-digit",
					year: "numeric",
					hour: "2-digit",
					minute: "2-digit",
				})}
			</DateLabel>
			<Description>{transaction.description}</Description>
			{!!transaction.label && (
				<Description style={{ marginTop: 10 }}>
					{formatMessage("accountingTransactionDetailsModal.description", { description: transaction.label })}
				</Description>
			)}
			{!!transaction.id && (
				<Description style={{ marginTop: 10 }}>
					{formatMessage("accountingTransactionDetailsModal.reference", { id: transaction.id })}
				</Description>
			)}
			<DetailsTitle>{formatMessage("accountingTransactionDetailsModal.detailsTitle")}</DetailsTitle>
			<Items>
				{transaction.detailedAccounting.map(value => (
					<TransactionEntryView
						key={value.id}
						label={formatMessage(accountEntryLabels[value.type])}
						amount={value.amount}
						bold={transaction.detailedAccounting.length == 1}
						hasLink
					/>
				))}
				{(!transaction.detailedAccounting || transaction.detailedAccounting.length > 1) && (
					<TransactionEntryView
						label={formatMessage("accountingTransactionDetailsModal.amountLabel")}
						amount={transaction.amount}
						hasLink
						bold
					/>
				)}
				<TransactionEntryView
					label={formatMessage("accountingTransactionDetailsModal.balanceLabel")}
					amount={transaction.balance}
					green
				/>
			</Items>
			{spendings.length > 0 ? (
				<>
					<DetailsTitle>{formatMessage("accountingTransactionDetailsModal.justificationFollowUpTitle")}</DetailsTitle>
					<Items>
						<TransactionEntryView
							label={formatMessage("accountingTransactionDetailsModal.documentsSubmitted")}
							amount={amountJustified()}
							hasLink={canEditJustification(transaction)}
							bold
						/>
						{canEditJustification(transaction) ? (
							<>
								<TransactionEntryView
									label={formatMessage("accountingTransactionDetailsModal.validationInProgress")}
									amount={null}
									grey
								/>
							</>
						) : null}
					</Items>
				</>
			) : null}

			{additionalInformation.externalId || additionalInformation.externalData ? (
				<>
					<DetailsTitle>{formatMessage("accountingTransactionDetailsModal.additionalInformationTitle")}</DetailsTitle>
					{additionalInformation.externalData ? <Description>{additionalInformation.externalData}</Description> : null}
					{additionalInformation.externalId ? (
						<ExternalId>
							{formatMessage("accountingTransactionDetailsModal.externalIdLabel", {
								externalId: additionalInformation.externalId,
							})}
						</ExternalId>
					) : null}
				</>
			) : null}
			{transaction.geolocation && (
				<>
					<Location>{formatMessage("accountingTransactionDetailsModal.geolocationTitle")}</Location>
					<MapIframe location={transaction.geolocation} allowFullScreen />
				</>
			)}
			{(canViewJustification(transaction) || canJustify(transaction)) && (
				<CanJustifyButton onClick={handleJustifyTransaction} size="S">
					{formatMessage(
						canJustify(transaction)
							? "accountingTransactionDetailsModal.justifyButtonLabel"
							: "accountingTransactionDetailsModal.verifyJustificationsButtonLabel"
					)}
				</CanJustifyButton>
			)}
		</Container>
	);
};

interface TransactionEntryViewProps extends HTMLAttributes<HTMLDivElement> {
	label: string;
	amount: Amount | undefined;
	green?: boolean;
	grey?: boolean;
	hasLink?: boolean;
	bold?: boolean;
}

export const TransactionEntryView: React.FC<TransactionEntryViewProps> = props => {
	const { label, amount, green, grey, hasLink, bold, ...viewProps } = props;
	const { isRTL } = useRTL();

	return (
		<Item {...viewProps}>
			<Bullet green={green} grey={grey} $isRTL={isRTL} />
			<ItemLabel grey={grey} bold={bold}>
				{label}
			</ItemLabel>
			{amount !== null ? (
				<ItemValue green={green} bold={bold}>
					<AmountText amount={amount} />
				</ItemValue>
			) : null}
			{hasLink && <Link $isRTL={isRTL} />}
		</Item>
	);
};

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

const Title = styled.h2`
	${theme.boldText};
	font-size: 1.375rem;
	margin: 0;
	flex-shrink: 1;
	flex-grow: 1;
`;

const DetailsTitle = styled.h3`
	${theme.boldText};
	font-size: 1.125rem;
	margin: 20px 0;
`;

const DateLabel = styled.span`
	${theme.text};
	font-size: 0.9375rem;
	margin: 20px 0;
`;

const Description = styled.span`
	${theme.text};
	color: #b1b1b1;
	font-size: 0.9375rem;
	margin: 10px 0;
`;

const ExternalId = styled.span`
	${theme.text}
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
`;

const ItemLabel = styled.span<{ bold?: boolean; green?: boolean; grey?: boolean }>`
	${props => (props.bold ? theme.boldText : theme.text)};
	color: ${props => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
	flex-shrink: 1;
	flex-grow: 1;
`;

const ItemValue = styled.span<{ bold?: boolean; green?: boolean }>`
	${props => (props.bold ? theme.boldText : theme.text)};
	color: ${props => (props.green ? "#2ed794" : "#000000")};
	margin-left: 20px;
`;

const Bullet = styled.span<{ green?: boolean; grey?: boolean; $isRTL: boolean }>`
	width: 10px;
	height: 10px;
	background-color: ${props => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
	margin-right: ${props => (props.$isRTL ? 0 : 15)}px;
	margin-left: ${props => (props.$isRTL ? 15 : 0)}px;
	border-radius: 15px;
`;

const Items = styled.div`
	margin-top: 10px;

	> *:not(:last-child) {
		margin-bottom: 20px;
	}
`;

const Link = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 100%;
	left: ${props => (props.$isRTL ? "unset" : "3.5px")};
	right: ${props => (props.$isRTL ? "3.5px" : "unset")};
	width: 2px;
	height: 20px;
	border-radius: 1.5px;
	background-color: #000000;
`;

const Location = styled(DetailsTitle)`
	margin-top: 30px;
`;

const CanJustifyButton = styled(PrimaryButton)`
	margin-top: 30px;
`;
