import { CacheLoader } from "../../core/cache/cache-loader";
import { CacheStatus } from "../../core/cache/cache-status";
import { Observable } from "../../utils/observable";
import { ObservableArray } from "../../utils/observable-array";
import { Paginated } from "../../utils/pagination";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { ClientManager } from "../client/client-manager";
import { Account, isSupportedAccountType } from "./account";
import { AccountService } from "./account-service";

export class AccountManager {
	public accounts = new ObservableArray<Account>([]);
	public loading = new Observable<boolean>(true);
	public error = new Observable<Error | null>(null);
	public refreshing = new Observable<boolean>(false);
	public cacheStatus = new Observable<CacheStatus | null>(null);

	public constructor(
		private accountService: AccountService,
		private authenticationManager: AuthenticationManager,
		private clientManager: ClientManager,
		private cacheLoader: CacheLoader<Paginated<Account>>
	) {
		this.authenticationManager.isConnected.onChange.add(async isConnected => {
			if (!isConnected) {
				await this.clear();
			}
		});
		this.clientManager.onLanguageChange.add(async () => {
			await this.clear();
			await this.load();
		});
	}

	public async load() {
		this.loading.set(true);
		await this.loadOrRefresh();
		this.loading.set(false);
	}

	public async refresh() {
		this.refreshing.set(true);
		await this.loadOrRefresh(true);
		this.refreshing.set(false);
	}

	private async loadOrRefresh(forceRefresh?: boolean) {
		try {
			this.error.set(null);

			const accounts = await this.cacheLoader.load(() => this.accountService.fetchAccounts(), forceRefresh);
			if (!accounts) {
				throw new Error("Failed to fetch accounts");
			}
			await this.updateCacheStatus();
			this.accounts.set(accounts.items.filter(account => isSupportedAccountType(account)));
		} catch (e) {
			this.error.set(e);
		}
	}

	private async clear() {
		await this.cacheLoader.clear();
		this.accounts.set([]);
		this.loading.set(true);
		this.error.set(null);
		this.cacheStatus.set(null);
	}

	private async updateCacheStatus() {
		const cacheStatus = await this.cacheLoader.readStatus();
		this.cacheStatus.set(cacheStatus);
	}
}
