import { AxiosRequestConfig } from "axios";
import { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import { ServiceDomainManager } from "../../../domains/service-domain/service-domain-manager";
import { SecuredCookiesService } from "../secured-cookies-service";
import { Interceptor } from "./interceptor";

export const addAuthorizationInterceptor: (
	authenticationManager: AuthenticationManager | ServiceDomainManager,
	securedCookiesService?: SecuredCookiesService
) => Interceptor<AxiosRequestConfig> = (authenticationManager, securedCookiesService) => ({
	onFulfilled: async config => {
		const accessToken = await authenticationManager.getAccessToken();
		const addAuthorization = !securedCookiesService?.getSecuredCookies();
		return {
			...config,
			headers: {
				...config.headers,
				...(accessToken && addAuthorization && { Authorization: `${accessToken.type} ${accessToken.value}` }),
			},
		};
	},
});
