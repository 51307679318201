export function isDefined<T>(data: T | null | undefined): data is T {
	if (data === null || data === undefined) {
		return false;
	}
	return true;
}

export function atLeastOneIsDefined<T>(list: (T | null | undefined)[]) {
	return list.filter(it => isDefined(it)).length > 0;
}
