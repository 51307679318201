import React from "react";
import { SVGProps } from "react";

export const ShieldIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="75" height="75" viewBox="0 0 75 75" {...props}>
		<g fill="none" fillRule="evenodd">
			<path
				d="M37.744 8h.52c.065.045.124.106.196.132 3.073 1.115 6.148 2.229 9.224 3.338 4.755 1.716 9.51 3.43 14.269 5.137.339.122.697.192 1.047.286V39.32c-.041.11-.114.22-.118.333-.047 1.286-.282 2.55-.574 3.794-1.042 4.442-3.124 8.362-6.198 11.754a31.94 31.94 0 0 1-4.536 4.134c-3.935 2.944-8.281 5.079-12.997 6.49-.151.046-.295.116-.443.175h-.26c-.02-.034-.035-.089-.063-.098-.944-.298-1.903-.553-2.83-.893-4.105-1.506-7.906-3.554-11.32-6.286-2.392-1.915-4.5-4.093-6.2-6.634-2.917-4.356-4.37-9.167-4.446-14.385-.03-2.083-.006-4.167-.006-6.25v-13.98c0-.507.005-.506.471-.674 3.222-1.161 6.441-2.327 9.664-3.485 4.58-1.645 9.164-3.283 13.744-4.929.294-.106.571-.256.856-.386"
				fill="#000"
			/>
			<path
				d="M37.744 8h.52c.065.045.124.106.196.132 3.073 1.115 6.148 2.229 9.224 3.338 4.755 1.716 9.51 3.43 14.269 5.137.339.122.697.192 1.047.286V39.32c-.041.11-.114.22-.118.333-.047 1.286-.282 2.55-.574 3.794-1.042 4.442-3.124 8.362-6.198 11.754a31.94 31.94 0 0 1-4.536 4.134c-3.935 2.944-8.281 5.079-12.997 6.49-.151.046-.295.116-.443.175h-.26c-.02-.034-.035-.089-.063-.098-.944-.298-1.903-.553-2.83-.893-4.105-1.506-7.906-3.554-11.32-6.286-2.392-1.915-4.5-4.093-6.2-6.634-2.917-4.356-4.37-9.167-4.446-14.385-.03-2.083-.006-4.167-.006-6.25v-13.98c0-.507.005-.506.471-.674 3.222-1.161 6.441-2.327 9.664-3.485 4.58-1.645 9.164-3.283 13.744-4.929.294-.106.571-.256.856-.386"
				fill={props.color ?? "#81C241"}
				opacity=".93"
			/>
			<path
				d="M37.947 66h-.259c-.02-.034-.034-.089-.063-.098-.936-.298-1.888-.553-2.809-.893-4.074-1.506-7.846-3.554-11.234-6.286-2.375-1.915-4.466-4.093-6.155-6.634-2.894-4.356-4.337-9.167-4.412-14.385-.03-2.083-.006-4.167-.006-6.25v-13.98c0-.507.005-.506.468-.674 3.197-1.161 6.393-2.327 9.592-3.485 4.546-1.645 9.095-3.283 13.64-4.929.292-.106.567-.256.85-.386h.255c.087 0 .279 57.941.133 58z"
				fill={props.color ?? "#81C241"}
			/>
			<path
				d="M26.702 36.705a1.52 1.52 0 0 0-2.092 0 1.388 1.388 0 0 0 0 2.015l7.397 7.126a1.52 1.52 0 0 0 2.093 0L50.374 30.17a1.388 1.388 0 0 0 0-2.016 1.52 1.52 0 0 0-2.092 0L33.053 42.823l-6.351-6.118z"
				fill="#FFF"
				fillRule="nonzero"
				stroke="#FFF"
				strokeWidth=".5"
			/>
		</g>
	</svg>
);
