import React from "react";
import { SVGProps } from "react";

export const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
		<path
			d="M11.38 10.403a.879.879 0 1 1-1.76 0V4.246a.88.88 0 1 1 1.76 0v6.157zm-5.718 0a4.838 4.838 0 1 0 7.93-3.721.88.88 0 1 1 1.124-1.353 6.581 6.581 0 0 1 2.38 5.05c0 3.666-2.954 6.621-6.596 6.621s-6.597-2.955-6.597-6.621c0-2.015.928-3.84 2.38-5.05a.88.88 0 0 1 1.24.114c.286.374.259.928-.115 1.239a4.832 4.832 0 0 0-1.746 3.721z"
			fill="#fff"
		/>
	</svg>
);
