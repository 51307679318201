import React, { SVGProps } from "react";

export function SmallChevronIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 12 12" width="12" height="12" {...props}>
			<g fill="none" fillRule="evenodd">
				<path d="M0 12V0h12v12z" />
				<path
					d="m4.516 10.615-.824-.84 3.47-3.544-3.47-3.544.824-.84L8.307 5.72a.73.73 0 0 1 0 1.022l-3.791 3.873z"
					fill={props.color ?? "#000"}
				/>
			</g>
		</svg>
	);
}
