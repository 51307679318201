import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../shared/utils/assert";
import { useRTL } from "../../../domain/language/use-rtl";
import { TextInput } from "../../common/forms/text-input";
import { SearchIcon } from "../../common/svg/search-icon";
import { theme } from "../../styles/theme";

interface SearchBarProps {
	value?: string;
	placeholder?: string;
	initialValue: string;
	onChange?: (searchText: string) => void;
	autoFocus?: boolean;
	withIcon?: boolean;
	className?: string;
	widthSize?: number;
}

export const SearchBar: React.FC<SearchBarProps> = ({
	value,
	placeholder,
	initialValue,
	onChange,
	autoFocus,
	className,
	withIcon = true,
	widthSize,
}) => {
	const { formatMessage } = useIntl();
	const [searchText, setSearchText] = useState(initialValue);

	const notifyTextChange = (text: string) => {
		setSearchText(text);
		onChange && onChange(text);
	};

	const active = isDefined(searchText) && searchText.length > 0;
	const { isRTL } = useRTL();

	useEffect(() => {
		setSearchText(value ?? "");
	}, [value])

	return (
		<SearchBarContainer className={className} $isRTL={isRTL}>
			{withIcon ? <SearchIcon fill={active ? theme.mainColor() : "#b1b1b1"} width={26} height={26} /> : null}
			<StyledTextInput
				autoFocus={autoFocus}
				value={value || searchText}
				onChange={e => notifyTextChange(e.target.value)}
				placeholder={placeholder ?? formatMessage("billIssuersScreen.searchInputPlaceholder")}
				widthSize={widthSize}
			/>
			<ActiveMark visible={active} $isRTL={isRTL} />
		</SearchBarContainer>
	);
};

const StyledTextInput = styled(TextInput)<{ widthSize?: number }>`
	width: ${props => props.widthSize ?? 250}px;
	background-color: #f4f4f4;
	box-shadow: unset;
	padding: 10px 5px;

	input {
		background-color: #f4f4f4;
		font-weight: normal;
	}
`;

const SearchBarContainer = styled.div<{ $isRTL: boolean }>`
	position: relative;
	background-color: #f4f4f4;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: ${props => (props.$isRTL ? 0 : 10)}px;
	padding-right: ${props => (props.$isRTL ? 10 : 0)}px;
	border-radius: 10px;
`;

const ActiveMark = styled.div<{ visible?: boolean; $isRTL: boolean }>`
	position: absolute;
	left: ${props => (props.$isRTL ? `unset` : `0px`)};
	right: ${props => (props.$isRTL ? `0px` : `unset`)};
	top: 10px;
	bottom: 10px;
	width: 3px;
	border-radius: 3.5px;
	background-color: ${theme.mainColor};
	opacity: ${props => (props.visible ? 1 : 0)};
`;
