import axios, { AxiosInstance } from "axios";
import { DebugInterface } from "../debug/debug-interface";
import { AuthenticationManager } from "../../domains/authentication/authentication-manager";
import { I18NStore } from "../i18n/i18n-store";
import { addAcceptLanguageInterceptor } from "./interceptors/add-accept-language-interceptor";
import { addAuthorizationInterceptor } from "./interceptors/add-authorization-interceptor";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import { SecuredCookiesService } from "./secured-cookies-service";
import { UserAgentService } from "./user-agent-service";
import { ErrorStore } from "../../error-store";

export class ApiService {
	public instance: AxiosInstance;

	public constructor(
		private errorStore: ErrorStore,
		private authenticationManager: AuthenticationManager,
		private customUserAgentService: UserAgentService | null,
		private i18NStore: I18NStore,
		private securedCookiesService?: SecuredCookiesService,
		private debugIntercept?: DebugInterface | undefined
	) {
		this.instance = axios.create();
		addRequestInterceptor(this.instance, addBaseUrlInterceptor());
		if (this.securedCookiesService) {
			addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
		}
		if (this.customUserAgentService) {
			addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
		}
		addRequestInterceptor(
			this.instance,
			addAuthorizationInterceptor(this.authenticationManager, this.securedCookiesService)
		);
		addRequestInterceptor(this.instance, addAcceptLanguageInterceptor(this.i18NStore));
		if (this.debugIntercept) {
			addResponseInterceptor(this.instance, debugResponseInterceptor(this.debugIntercept));
		}
		addResponseInterceptor(this.instance, logResponseInterceptor);

		this.instance.interceptors.response.use(
			response => response,
			error => {
				if ((error.response && error.response.status === 429) || error.code == "ERR_NETWORK") {
					this.errorStore.error.set(error);
				}
				return Promise.reject(error);
			}
		);
	}
}
