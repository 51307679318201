import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { theme } from "../../../../styles/theme";

export const VirtualCardDescrition = () => {
	const { formatMessage } = useIntl();

	return (
		<Container>
			<DescriptionTitle>{formatMessage("createCard.stepSelection.virtualCardTitle")}</DescriptionTitle>
			<DescriptionText>{formatMessage("createCard.stepSelection.virtualCardDescription")}</DescriptionText>
		</Container>
	);
};

const Container = styled.div`
	padding: 20px;
	margin: 20px 0;
	border-radius: 8px;
	background-color: ${theme.colors.gray[200]};
`;

const DescriptionTitle = styled.p`
	${theme.bodyBlackBold}
	margin: 0;
`;

const DescriptionText = styled.p`
	${theme.bodyGreyDarkRegular}
	margin: 10px 0 0;
`;
