import React from "react";
import styled from "styled-components";
import { useRTL } from "../../domain/language/use-rtl";
import { shadows, theme } from "../styles/theme";
import { DefaultButton } from "./buttons/default-button";

export interface SelectorOptions<T> {
	disabled?: boolean;
	value: T;
	text: string;
	key: string;
}

interface SelectorProps<T> {
	options: SelectorOptions<T>[];
	onChange: (value: T) => void;
	value: T;
	size?: "S" | "M" | "L";
	className?: string;
}

export enum SelectorHorizontalPadding {
	S = "10px",
	M = "20px",
	L = "20px",
}

export function Selector<T>(props: SelectorProps<T>) {
	const { options, value, onChange, className, size = "M" } = props;
	const { isRTL } = useRTL();

	return (
		<SelectorRow className={className}>
			{options.filter(it => !it.disabled).map(it => (
				<SelectorButton  key={it.key} onClick={() => onChange(it.value)} $isRTL={isRTL}>
					<SelectorItem value={it.value} text={it.text} selected={value === it.value} size={size} />
				</SelectorButton>
			))}
		</SelectorRow>
	);
}

const SelectorButton = styled(DefaultButton)<{ $isRTL: boolean }>`
	padding: 0 2px;
	white-space: normal;
	height: auto;
	${props =>
		props.$isRTL ? `&:not(:last-of-type) {margin-left: 10px;}` : `&:not(:last-of-type) {margin-right: 10px;}`};
`;

const SelectorRow = styled.div`
	display: flex;
	flex-direction: row;
`;

interface SelectorItemProps {
	selected: boolean;
	size: "S" | "M" | "L";
}
function SelectorItem<T>(props: Omit<SelectorOptions<T> & SelectorItemProps, "key">) {
	const { text, selected, size } = props;
	return (<RoundedSquare selected={selected} size={size}>
			<ItemLabel selected={selected}>{text}</ItemLabel>
			<AnimatedOverlap selected={selected}>
				<WhiteCircle />
				<ThemeCircle />
			</AnimatedOverlap>
		</RoundedSquare>
	);
}

const RoundedSquare = styled.div<{ selected: boolean; size: "M" | "S" | "L" }>`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${props => (props.selected ? "#ffffff" : "#f4f4f4")};
	border-radius: 8px;
	height: ${props => (props.size == "L" ? 40 : 32)}px;
	padding-left: ${({ size }) => SelectorHorizontalPadding[size]};
	padding-right: ${({ size }) => SelectorHorizontalPadding[size]};
	${props => (props.selected ? shadows.medium : undefined)};
`;

const ItemLabel = styled.span<{ selected: boolean }>`
	font-size: 0.9375rem;
	${props => (props.selected ? theme.boldText : theme.text)};
	text-align: center;
	color: ${props => (props.selected ? "#000000" : "#707070")};
`;

const ThemeCircle = styled.div`
	position: absolute;
	background-color: ${theme.mainColor};
	width: 10px;
	height: 10px;
	border-radius: 5px;
`;
const WhiteCircle = styled.div`
	position: absolute;
	background-color: #ffffff;
	width: 14px;
	height: 14px;
	border-radius: 7px;
`;

const AnimatedOverlap = styled.div<{ selected: boolean }>`
	display: flex;
	position: absolute;
	justify-content: center;
	bottom: -7px;
	height: 14px;
	width: 14px;
	align-items: center;
	transition: transform 0.4s;
	transform: ${props => (props.selected ? "scale(1)" : "scale(0)")};
	transform-origin: center;
`;
