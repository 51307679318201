import React, { SVGProps } from "react";

export function ServiceIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.075 20.334a.398.398 0 0 0 .297.127h.245a.398.398 0 0 0 .296-.127l1.549-1.565a.431.431 0 0 1 .6 0l1.532 1.532a.398.398 0 0 0 .296.127h.245a.398.398 0 0 0 .296-.127l1.532-1.532a.432.432 0 0 1 .576 0l1.531 1.532a.398.398 0 0 0 .296.127h.245a.398.398 0 0 0 .297-.127l1.616-1.532a.846.846 0 0 0 .245-.592V4.384a.846.846 0 0 0-.846-.846H6.077a.846.846 0 0 0-.846.846v13.759c.003.224.09.439.245.6l1.6 1.591zm5.771-7.065c0 .234-.19.423-.423.423H9.04a.423.423 0 0 1-.424-.423v-.846c0-.234.19-.423.424-.423h3.384c.234 0 .423.19.423.423v.846zm2.116-2.961c.233 0 .423-.19.423-.423v-.847a.423.423 0 0 0-.423-.423H9.039a.423.423 0 0 0-.424.423v.847c0 .233.19.423.424.423h5.923z"
			/>
		</svg>
	);
}
