export class ConfigurationInitializationFailedError extends Error {
	public constructor(message?: string) {
		super(message);
	}
}

export class ConfigurationNotLoadedError extends Error {
	public constructor(message?: string) {
		super(message);
	}
}
