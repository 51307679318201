import { CardStatus } from "./card-filter";

export const cardStatusLabels = {
	[CardStatus.ALL]: "card.status.all",
	[CardStatus.NEW]: "card.status.new",
	[CardStatus.PENDING]: "card.status.pending",
	[CardStatus.ACTIVE]: "card.status.active",
	[CardStatus.EXPIRED]: "card.status.expired",
	[CardStatus.REMOVED]: "card.status.removed",
	[CardStatus.BLOCKED]: "card.status.blocked",
	[CardStatus.OPPOSED]: "card.status.opposed",
	[CardStatus.CANCELLED]: "card.status.cancelled",
};
