export enum RecipientStateEnum {
	OPENED = 'opened',
	CLOSED = 'closed',
	PENDING = 'pending',
}
export interface Recipient {
	id: string;
	name: string;
	iban?: string;
	bankName?: string;
	bic?: string;
	phone?: string;
	accountReference?: string;
	counterparty?: boolean;
	state?: RecipientStateEnum;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	externalData?: { [key: string]: string };
}

export type SimpleRecipient = Partial<Recipient>


export type TransferRecipient = Recipient | SimpleRecipient
