import { ConfigurationManager } from "../../domains/configuration/configuration-manager";
import { I18NManager } from "../i18n/i18n-manager";
import { Contacts, ContactType } from "./contact";

export class ContactService {
	public constructor(private configurationManager: ConfigurationManager, private i18NManager: I18NManager) {}

	public getContacts(): Contacts {
		const contacts: Contacts = new Map([]);
		const configuration = this.configurationManager.configuration.get();
		if (configuration.contactPhone) {
			contacts.set(ContactType.Phone, configuration.contactPhone);
		}
		if (configuration.contactEmail) {
			contacts.set(ContactType.Email, configuration.contactEmail);
		}
		if (configuration.infoUrl) {
			contacts.set(ContactType.Url, configuration.infoUrl[this.i18NManager.localeTag.get()]);
		}
		return contacts;
	}
}
