import { flatten } from "flat";
import { DefaultCacheLoader } from "../../../shared/core/cache/default-cache-loader";
import { TranslationsNotLoadedError } from "../../../shared/core/i18n/i18n-error";
import { I18NService } from "../../../shared/core/i18n/i18n-service";
import { LanguageTranslations, Translations } from "../../../shared/core/i18n/translations";
import { TranslationsManager } from "../../../shared/core/i18n/translations-manager";
import { logger } from "../../../shared/core/logging/logger";
import { ConfigurationManager } from "../../../shared/domains/configuration/configuration-manager";

export class WebTranslationsManager implements TranslationsManager {
	configuredLanguages = this.configurationManager.configuration.get().langs;

	localTranslations = {
		en_US: () => flatten(require("../../assets/translations/en_US.json")) as LanguageTranslations,
		fr_FR: () => flatten(require("../../assets/translations/fr_FR.json")) as LanguageTranslations,
		es_ES: () => flatten(require("../../assets/translations/es_ES.json")) as LanguageTranslations,
		//NOT implemented, fallback to en_US
		ar_MA: () => flatten(require("../../assets/translations/ar_MA.json")) as LanguageTranslations,
		rn_BI: () => flatten(require("../../assets/translations/en_US.json")) as LanguageTranslations,
		af_NA: () => flatten(require("../../assets/translations/en_US.json")) as LanguageTranslations,
	};

	constructor(
		private configurationManager: ConfigurationManager,
		private cacheLoader: DefaultCacheLoader<Translations>,
		private i18NService: I18NService
	) {}

	async loadTranslations(localeTag: string): Promise<Translations> {
		logger.debug("TranslationsManager", "fetching remote translation for", localeTag);
		const remoteTranslations = await this.cacheLoader.load(
			() => this.i18NService.fetchTranslations(localeTag),
			false,
			localeTag
		);
		if (!remoteTranslations) {
			throw new TranslationsNotLoadedError("Fetch translations failed and not in cache");
		}
		logger.debug("I18NManager", "fetched translation for", localeTag);
		return remoteTranslations;
	}

	getConfiguredLanguages(): string[] {
		return this.configurationManager.configuration.get().langs;
	}
}
