import { FormImageInput, FormInput, FormInputType } from "../../../../shared/core/data-forms/form-input-types";

import React from "react";
import { UploadedImage } from "../../../../shared/utils/images-converter";
import { AmountField } from "./amount-field";
import { AutocompleteField } from "./autocomplete-field";
import { BoolField } from "./bool-field";
import { DateField } from "./date-field";
import { EmailField } from "./email-field";
import { FileField } from "./file-field";
import { ImageField } from "./image-field";
import { IntField } from "./int-field";
import { MultiSelectField } from "./multi-select-field";
import { NumberField } from "./number-field";
import { PhoneField } from "./phone-field";
import { SelectField } from "./select-field";
import { SelectWithAmountField } from "./select-with-amount-field";
import { TextField } from "./text-field";

export function FormField<T>({
	input,
	hasError,
	onChange,
	onMultiImagesChange,
	selectFieldType,
	disabled,
}: {
	input: FormInput;
	disabled?: boolean;
	hasError?: boolean;
	selectFieldType?: "combobox" | "radio";
	onChange?: (value: T) => void;
	onMultiImagesChange?: (values: UploadedImage[]) => void;
}) {
	const isMultiImageField =
		FormInputType.FileContent === input.type &&
		((input.minimumPageCount && input.minimumPageCount > 1) || (input.maximumPageCount && input.maximumPageCount > 1));

	return input.type === FormInputType.Text ? (
		<TextField key={input.id} input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Email ? (
		<EmailField key={input.id} input={input} onChange={onChange as any} disabled={disabled} />
	) : input.type === FormInputType.Int ? (
		<IntField key={input.id} input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Number ? (
		<NumberField key={input.id} input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Amount ? (
		<AmountField input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Select ? (
		<SelectField
			type={selectFieldType ?? "combobox"}
			input={input}
			onChange={onChange as any}
			disabled={disabled}
			hasError={hasError}
		/>
	) : input.type === FormInputType.SelectWithAmount ? (
		<SelectWithAmountField
			type={selectFieldType ?? "combobox"}
			input={input}
			onChange={onChange as any}
			disabled={disabled}
			hasError={hasError}
		/>
	) : [FormInputType.Image].includes(input.type) || isMultiImageField ? (
		<ImageField
			onChange={onChange as any}
			onMultiImagesChange={onMultiImagesChange as any}
			disabled={disabled}
			hasError={hasError}
			input={input as FormImageInput}
		/>
	) : [FormInputType.File, FormInputType.FileContent].includes(input.type) ? (
		<FileField onChange={onChange as any} disabled={disabled} hasError={hasError} input={input} />
	) : input.type === FormInputType.Date || input.type === FormInputType.DateTime ? (
		<DateField
			input={input}
			disabled={disabled}
			hasError={hasError}
			onChange={onChange as any}
			mode={input.type === FormInputType.DateTime ? "datetime-local" : "date"}
		/>
	) : input.type === FormInputType.MultiSelect ? (
		<MultiSelectField input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Autocomplete ? (
		<AutocompleteField
			key={input.id}
			input={input}
			onChange={onChange as any}
			disabled={disabled}
			hasError={hasError}
		/>
	) : input.type === FormInputType.Bool ? (
		<BoolField key={input.id} input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : input.type === FormInputType.Phone ? (
		<PhoneField key={input.id} input={input} onChange={onChange as any} disabled={disabled} hasError={hasError} />
	) : (
		<span>Can't find type {input.type}</span>
	);
}
