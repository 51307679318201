import { assign, createMachine, send } from "xstate";
import { pincodeKeyboardService, transferManager } from "../../shared/core/service/services";
import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError, RecipientNotFoundByInvalidPhoneRespondError,
} from "../../shared/domains/pincode/pincode-error";
import { Recipient, TransferRecipient } from "../../shared/domains/recipients/recipient";
import { ConfirmationMode, TransactionRequest } from "../../shared/domains/transactions/transaction-request";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

import { Amount } from "../../shared/core/amount/amount";
import { Account } from "../../shared/domains/account/account";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import { TransferMode } from "../../shared/domains/transactions/cash-transfer/transfer-mode";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import {
	AccountOrRecipient,
	CustomerInstructionResult,
	PaymentAddress,
	PaymentNetwork,
} from "../../shared/domains/transactions/customer-instruction";

export const useTransferMachine = (isP2PSimpleTransfer = false, isOpenLoop = false) => {
	const [state, sendEvent] = useMachine(transferMachine);

	const selectAmount = (
		recipient: TransferRecipient | AccountOrRecipient,
		amount: Amount,
		sourceAccounts: Account[],
		sourceAccount?: Account | null,
		paymentNetwork?: PaymentNetwork | null,
		transferMode?: TransferMode,
		description?: string,
		creditorAddress?: PaymentAddress
	) => {
		const srcAccount = sourceAccount || sourceAccounts.find(account => account.balance.currency === amount.currency);
		sendEvent(isOpenLoop ? "CREATE_CUSTOMER_INSTRUCTION" : "SELECT_AMOUNT", {
			recipient,
			amount,
			sourceAccount: srcAccount,
			label: description,
			paymentNetwork,
			transferMode,
			isP2PSimpleTransfer,
			isOpenLoop,
			creditorAddress,
		});
	};

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};

	const submitOtp = (otp: string) => {
		sendEvent("SUBMIT_OTP", { otp });
	};
	return {
		state: state.value as TransferState | PincodeState,
		context: state.context,
		selectAmount,
		submitPincode,
		submitOtp,
	};
};

interface TransferMachineContext {
	recipient?: Recipient;
	amount: Amount;
	sourceAccount?: Account;
	label?: string;
	transferType?: TransferType;
	paymentNetwork?: PaymentNetwork;
	transferMode?: TransferMode;
	transactionRequest?: TransactionRequest;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	transactionResult?: TransactionRequest | CustomerInstructionResult;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError | RecipientNotFoundByInvalidPhoneRespondError;
	isP2PSimpleTransfer?: boolean;
	isOpenLoop?: boolean;
	creditorAddress?: PaymentAddress;
	otp?: string;
}

export enum TransferState {
	SelectingAmount = "SelectingAmount",
	RequestingTransfer = "RequestingTransfer",
	RequestingTransferError = "RequestingTransferError",
	Confirmation = "Confirmation",
	ConfirmTransferError = "ConfirmTransferError",
	Done = "Done",
	StrongAuthentication = "StrongAuthentication",
	SubmitOtp = "SubmitOtp",
}
type TransferEvent =
	| {
			type: "SELECT_AMOUNT";
			recipient: Recipient;
			amount: Amount;
			sourceAccount: Account;
			label?: string;
			paymentNetwork?: PaymentNetwork;
			transferMode?: TransferMode;
			isP2PSimpleTransfer?: boolean;
			isOpenLoop?: boolean;
	  }
	| {
			type: "CREATE_CUSTOMER_INSTRUCTION";
			recipient: Recipient;
			amount: Amount;
			sourceAccount: Account;
			label?: string;
			paymentNetwork?: PaymentNetwork;
			transferMode?: TransferMode;
			isP2PSimpleTransfer?: boolean;
			isOpenLoop?: boolean;
			creditorAddress?: PaymentAddress;
	  }
	| { type: "SUBMIT_OTP"; otp: string }
	| { type: "CONFIRM" }
	| { type: "TRANSFER_DONE" }
	| { type: "TRANSFER_ERROR" }
	| { type: "TRANSFER_CONFIRMATION_PINCODE_ERROR" }
	| { type: "TRANSFER_CONFIRMATION_ERROR" }
	| { type: "STRONG_AUTHENTICATION" }
	| KeyboardEvent;

enum TransferInvokeName {
	StartTransfer = "StartTransfer",
	FetchKeyboard = "FetchKeyboard",
	ConfirmTransfer = "ConfirmTransfer",
	GetAuthenticationSession = "GetAuthenticationSession",
	SendAuthenticationCode = "SendAuthenticationCode",
}
export enum TransferType {
	IBAN = "IBAN",
	TRANSFER = "TRANSFER",
}
export const transferMachine = createMachine<TransferMachineContext, TransferEvent>({
	id: "transfer",
	initial: TransferState.SelectingAmount,
	states: {
		[TransferState.SelectingAmount]: {
			on: {
				SELECT_AMOUNT: {
					target: TransferState.RequestingTransfer,
					actions: [
						assign({
							recipient: (_, event) => event.recipient,
							amount: (_, event) => event.amount,
							label: (_, event) => event.label,
							sourceAccount: (_, event) => event.sourceAccount,
							transferType: (_, event) =>
								event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
							paymentNetwork: (_, event) => event.paymentNetwork,
							transferMode: (_, event) => event.transferMode,
							isP2PSimpleTransfer: (_, event) => event.isP2PSimpleTransfer,
							isOpenLoop: (_, event) => event.isOpenLoop,
						}),
					],
				},
				CREATE_CUSTOMER_INSTRUCTION: {
					target: PincodeState.PincodeConfirmation,
					actions: [
						assign({
							recipient: (_, event) => event.recipient,
							amount: (_, event) => event.amount,
							label: (_, event) => event.label,
							sourceAccount: (_, event) => event.sourceAccount,
							transferType: (_, event) =>
								event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
							paymentNetwork: (_, event) => event.paymentNetwork,
							transferMode: (_, event) => event.transferMode,
							isP2PSimpleTransfer: (_, event) => event.isP2PSimpleTransfer,
							isOpenLoop: (_, event) => event.isOpenLoop,
							creditorAddress: (_, event) => event.creditorAddress,
						}),
					],
				},
			},
		},
		[TransferState.RequestingTransfer]: {
			invoke: {
				id: TransferInvokeName.StartTransfer,
				src: context =>
					context.isP2PSimpleTransfer
						? // Simple transfer (transfer with phone number)
						  transferManager.startSimpleTransfer(context.amount, context.recipient!.phone!, context.label)
						: context.transferType === TransferType.TRANSFER
						? context.transferMode === TransferMode.CashTransfer
							? transferManager.startCashTransfer(context.recipient!.id, context.amount!, context.label)
							: transferManager.startTransfer(context.recipient!.id, context.amount!, context.label)
						: transferManager.startPayout(
								context.recipient!.id,
								context.amount!,
								context.sourceAccount!.id,
								context.label
						  ),
				onDone: {
					actions: [
						assign({
							transactionRequest: (_, event) => event.data,
						}),
						send(context => {
							if ((context.transactionRequest as TransactionRequest)?.metadata) {
								return (context.transactionRequest as TransactionRequest)?.metadata.confirmationMode ===
									ConfirmationMode.PinCode
									? { type: "PINCODE_CONFIRM" }
									: { type: "CONFIRM" };
							}
							return { type: "ERROR" };
						}),
					],
				},
				onError: {
					target: TransferState.RequestingTransferError,
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
					],
				},
			},
			on: {
				PINCODE_CONFIRM: PincodeState.PincodeConfirmation,
				CONFIRM: TransferState.Confirmation,
			},
		},
		[TransferState.RequestingTransferError]: {
			exit: assign<TransferMachineContext, TransferEvent>({ error: undefined }),
			on: {
				SELECT_AMOUNT: {
					target: TransferState.RequestingTransfer,
					actions: assign({
						recipient: (_, event) => event.recipient,
						amount: (_, event) => event.amount,
						label: (_, event) => event.label,
						sourceAccount: (_, event) => event.sourceAccount,
						paymentNetwork: (_, event) => event.paymentNetwork,
						transferType: (_, event) =>
							event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
						transferMode: (_, event) => event.transferMode,
					}),
				},
				CREATE_CUSTOMER_INSTRUCTION: {
					target: PincodeState.PincodeConfirmation,
					actions: [
						assign({
							recipient: (_, event) => event.recipient,
							amount: (_, event) => event.amount,
							label: (_, event) => event.label,
							sourceAccount: (_, event) => event.sourceAccount,
							transferType: (_, event) =>
								event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
							paymentNetwork: (_, event) => event.paymentNetwork,
							transferMode: (_, event) => event.transferMode,
							isP2PSimpleTransfer: (_, event) => event.isP2PSimpleTransfer,
							isOpenLoop: (_, event) => event.isOpenLoop,
							creditorAddress: (_, event) => event.creditorAddress,
						}),
					],
				},
			},
		},
		[TransferState.StrongAuthentication]: {
			invoke: {
				id: TransferInvokeName.GetAuthenticationSession,
				src: context =>
					transferManager.getAuthenticationSession(
						(context.transactionResult as CustomerInstructionResult).strongAuthenticationReference!
					),
				onDone: {},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send({ type: "TRANSFER_ERROR" }),
					],
				},
			},
			on: {
				SUBMIT_OTP: {
					target: TransferState.SubmitOtp,
					actions: [
						assign({
							otp: (_, event) => event.otp,
						}),
					],
				},
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
				TRANSFER_ERROR: TransferState.RequestingTransferError,
			},
		},
		[TransferState.SubmitOtp]: {
			invoke: {
				id: TransferInvokeName.SendAuthenticationCode,
				src: context =>
					transferManager.verifyAuthenticationSMS(
						(context.transactionResult as CustomerInstructionResult).strongAuthenticationReference!,
						(context.transactionResult as CustomerInstructionResult).id,
						context.otp!
					),
				onDone: {
					actions: [
						assign<TransferMachineContext, { type: string; data: TransactionRequest | undefined }>({
							transactionResult: (_, event) => event.data,
							error: undefined,
						}),
						send({ type: "TRANSFER_DONE" }),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send({ type: "TRANSFER_ERROR" }),
					],
				},
			},
			on: {
				TRANSFER_DONE: TransferState.Done,
				TRANSFER_ERROR: TransferState.RequestingTransferError,
			},
		},
		[PincodeState.PincodeConfirmation]: {
			invoke: {
				id: TransferInvokeName.FetchKeyboard,
				src: () => {
					console.log("fetching keyboard");
					return pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE);
				},
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PincodeState.PromptingKeyboard]: {
			on: {
				SUBMIT_PINCODE: {
					target: TransferState.Confirmation,
					actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
				},
			},
		},
		[TransferState.Confirmation]: {
			invoke: {
				id: TransferInvokeName.ConfirmTransfer,
				src: context =>
					context.isOpenLoop
						? // Open loop transfer (customer instruction unit transfer)
						  transferManager.startCustomerInstruction(
								context.paymentNetwork!,
								context.amount,
								context.sourceAccount!,
								context.recipient as AccountOrRecipient,
								context.pincodeSubmission!,
								context.label,
								context.creditorAddress
						  )
						: context.transferType === TransferType.TRANSFER
						? context.transferMode === TransferMode.CashTransfer
							? transferManager.confirmCashTransfer(
									context.transactionRequest!.metadata.confirmationMode,
									context.recipient!.id,
									context.amount!,
									context.label,
									context.pincodeSubmission
							  )
							: context.isP2PSimpleTransfer
							? transferManager.confirmSimpleTransfer(
									context.transactionRequest!.metadata.confirmationMode,
									context.amount!,
									context.recipient!.phone!,
									context.label,
									context.pincodeSubmission
							  )
							: transferManager.confirmTransfer(
									context.transactionRequest!.metadata.confirmationMode,
									context.recipient!.id,
									context.amount!,
									context.label,
									context.pincodeSubmission
							  )
						: transferManager.confirmPayout(
								context.transactionRequest!.metadata.confirmationMode,
								context.recipient!.id,
								context.amount!,
								context.sourceAccount!.id,
								context.label,
								context.pincodeSubmission
						  ),
				onDone: {
					actions: [
						assign<TransferMachineContext, { type: string; data: TransactionRequest | undefined }>({
							transactionResult: (_, event) => event.data,
							error: undefined,
						}),
						send(context => {
							if (
								context.isOpenLoop &&
								(context.transactionResult as CustomerInstructionResult).strongAuthenticationReference
							) {
								return { type: "STRONG_AUTHENTICATION" };
							} else {
								return { type: "TRANSFER_DONE" };
							}
						}),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send(ctx =>
							ctx.isOpenLoop
								? { type: "TRANSFER_CONFIRMATION_ERROR" }
								: ctx.transactionRequest?.metadata.confirmationMode === ConfirmationMode.PinCode
								? { type: "TRANSFER_CONFIRMATION_PINCODE_ERROR" }
								: { type: "TRANSFER_CONFIRMATION_ERROR" }
						),
					],
				},
			},
			on: {
				TRANSFER_DONE: TransferState.Done,
				STRONG_AUTHENTICATION: TransferState.StrongAuthentication,
				TRANSFER_ERROR: TransferState.RequestingTransferError,
				TRANSFER_CONFIRMATION_PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
				TRANSFER_CONFIRMATION_ERROR: TransferState.ConfirmTransferError,
			},
		},
		[PincodeState.FetchKeyboardAfterError]: {
			invoke: {
				id: TransferInvokeName.FetchKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[TransferState.ConfirmTransferError]: {
			exit: assign<TransferMachineContext, TransferEvent>({ error: undefined }),
			on: {
				SELECT_AMOUNT: {
					target: TransferState.RequestingTransfer,
					actions: assign({
						recipient: (_, event) => event.recipient,
						amount: (_, event) => event.amount,
						label: (_, event) => event.label,
						sourceAccount: (_, event) => event.sourceAccount,
						paymentNetwork: (_, event) => event.paymentNetwork,
						transferType: (_, event) =>
							event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
						transferMode: (_, event) => event.transferMode,
					}),
				},
				CREATE_CUSTOMER_INSTRUCTION: {
					target: PincodeState.PincodeConfirmation,
					actions: [
						assign({
							recipient: (_, event) => event.recipient,
							amount: (_, event) => event.amount,
							label: (_, event) => event.label,
							sourceAccount: (_, event) => event.sourceAccount,
							transferType: (_, event) =>
								event.recipient.iban || event.recipient.accountReference ? TransferType.IBAN : TransferType.TRANSFER,
							paymentNetwork: (_, event) => event.paymentNetwork,
							transferMode: (_, event) => event.transferMode,
							isP2PSimpleTransfer: (_, event) => event.isP2PSimpleTransfer,
							isOpenLoop: (_, event) => event.isOpenLoop,
							creditorAddress: (_, event) => event.creditorAddress,
						}),
					],
				},
			},
		},
		[TransferState.Done]: {
			type: "final",
		},
	},
});
