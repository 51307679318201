import React from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { Path } from "../../../core/routing/path";
import { theme } from "../../styles/theme";
import { SimpleTransferModal } from "./components/simple-transfer-modal";

export enum TransferDestination {
	Beneficiaries,
	ExternalAccounts,
}

export function SimpleTransferScreen() {
	const { formatMessage } = useIntl();
	const history = useHistory();

	return (
		<Container>
			<Title>{formatMessage("simpleTransfer.title")}</Title>
			<SimpleTransferModal
				closeModal={() => {
					history.push(`${Path.AccountTransactions}`);
				}}
			/>
		</Container>
	);
}

const Container = styled.div`
	max-width: 500px;
	width: 100%;
`;

const Title = styled.span`
	font-size: 1.5625rem;
	margin-bottom: 12px;
	${theme.boldText};
	color: ${theme.mainColor};
	white-space: pre;
`;
