import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { theme } from "../../../styles/theme";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Path } from "../../../../core/routing/path";
import EnrollmentLayout from "../shared/components/enrollment-layout";
import { useHistory } from "react-router-dom";
const SuccessLogo = require("../../../../assets/images/svg/big-success.svg");

export const OnboardingFileSubmittedScreen: React.FC = () => {
    const { push } = useHistory();
	const { formatMessage } = useIntl();

	return (
        <EnrollmentLayout centered>
            <SuccessLogoImage src={SuccessLogo} alt="" />
            <Title>{formatMessage("onboardingScreen.successIntroduction")}</Title>
            <Subtitle>{formatMessage("onboardingScreen.successTitle")}</Subtitle>
            <PrimaryButton size="S" onClick={() => push(Path.Root)}>{formatMessage("onboardingScreen.successButton")}</PrimaryButton>
        </EnrollmentLayout>
	);
};

const SuccessLogoImage = styled.img`
    align-self: center;
    width: 200px;
    height: 200px;
`;

const Title = styled(MarkdownText)`
	${theme.boldText};
	font-size: 2.5rem;
	line-height: 2.875rem;
	margin-bottom: 10px;
`;

const Subtitle = styled(MarkdownText)`
    color: ${theme.colors.gray[700]};
	line-height: 2.875rem;
    margin-bottom: 24px;
`;
