import React, { useEffect, useState } from "react";
import { TransferState, useTransferMachine } from "../../../../machine/transfer-machine";
import { Subtitle, TransferSummary } from "./transfer-summary";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account, redirectionHandledOnAccountBlocked } from "../../../../../shared/domains/account/account";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { TransferMode } from "../../../../../shared/domains/transactions/cash-transfer/transfer-mode";
import {
	AccountOrRecipient,
	CustomerInstructionResult,
} from "../../../../../shared/domains/transactions/customer-instruction";
import { TransactionRequest } from "../../../../../shared/domains/transactions/transaction-request";
import { useClient } from "../../../../domain/authentication/use-client";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { Avatar } from "../../../common/avatar/avatar";
import { WhitePrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { MainColorSpinner } from "../../../common/spinner";
import { AmountSelection } from "../../../common/transaction/amount-selection";
import { theme } from "../../../styles/theme";
import { OtpConfirm } from "../../recipient/otp-confirm";
import { SourceAndDestination, SourceAndDestinationEnum } from "./source-and-destination";
const SuccessLogo = require("../../../../assets/images/svg/big-success.svg");

export interface TransferTransactionProps {
	showTitle?: boolean;
	showAvatar?: boolean;
	isOpenLoop?: boolean;
	recipient: Recipient;
	closeModal?: () => void;
}

const loadingState = [TransferState.RequestingTransfer, PincodeState.PincodeConfirmation];

export const TransferTransaction = (props: TransferTransactionProps) => {
	const { recipient, closeModal } = props;
	const { context, state, submitOtp, selectAmount, submitPincode } = useTransferMachine(false, props.isOpenLoop);
	const history = useHistory();
	const { client } = useClient();
	const [editingSourceAndDestination, setEditingSourceAndDestination] = useState<SourceAndDestinationEnum | null>(null);
	const [SourceAndDestinationType, setSourceAndDestinationType] = React.useState<SourceAndDestinationEnum | null>(null);
	const [sourceAccount, setSourceAccount] = React.useState<Account | null>(null);
	const [destinationAccountOrRecipient, setDestinationAccountOrRecipient] = React.useState<AccountOrRecipient | null>(
		null
	);
	const [needCreditorPhoneNumber, setNeedCreditorPhoneNumber] = React.useState<boolean>(false);
	const [needCreditorAccountNumber, setNeedCreditorAccountNumber] = React.useState<boolean>(false);
	const [needCreditorAddress, setNeedCreditorAddress] = React.useState<boolean>(false);

	useEffect(() => {
		if (redirectionHandledOnAccountBlocked(context.error, history.push)) {
			closeModal?.();
		}
	}, [context.error, history.push, closeModal]);

	const handleSetSourceAccount = (account: Account) => {
		setSourceAccount(account);
		setDestinationAccountOrRecipient(null);
		setSourceAndDestinationType(null);
	};

	const handleSetDestinationAccountOrRecipient = (accountOrRecipient: AccountOrRecipient) => {
		setDestinationAccountOrRecipient(accountOrRecipient);
		setSourceAndDestinationType(null);
	};

	const { formatMessage } = useIntl();
	const errorMessage = !isAccountBlocked(context.error) ? context.error : undefined;
	switch (state) {
		case TransferState.SelectingAmount:
		case TransferState.RequestingTransfer:
		case TransferState.RequestingTransferError:
		case PincodeState.PincodeConfirmation:
		case TransferState.ConfirmTransferError: {
			return (
				<>
					<Centered>
						{!!props.showAvatar && recipient ? <Avatar size={72} name={recipient.name} /> : null}
						{!!props.showTitle && recipient ? (
							<Title>{formatMessage("recipient.transferTo", { name: recipient.name })}</Title>
						) : null}
						{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
					</Centered>
					<StyledAmountSelection
						sourceAccount={sourceAccount}
						editingSourceAndDestination={editingSourceAndDestination}
						destinationAccountOrRecipient={destinationAccountOrRecipient}
						isOpenLoop={props.isOpenLoop}
						recipient={recipient}
						submitAmount={selectAmount}
						loading={loadingState.includes(state)}
						onClearDestinationAccountOrRecipientSelection={() => {
							setDestinationAccountOrRecipient(null);
							setSourceAndDestinationType(null);
						}}
						onOpenSourceAccountSelection={() => {
							setEditingSourceAndDestination(SourceAndDestinationEnum.Source);
							setSourceAndDestinationType(SourceAndDestinationEnum.Source);
						}}
						onOpenDestinationAccountOrRecipientSelection={(
							needCreditorPhoneNumber,
							needCreditorAccountNumber,
							needCreditorAddress
						) => {
							setNeedCreditorPhoneNumber(needCreditorPhoneNumber || false);
							setNeedCreditorAccountNumber(needCreditorAccountNumber || false);
							setNeedCreditorAddress(needCreditorAddress || false);
							setEditingSourceAndDestination(SourceAndDestinationEnum.Destination);
							setSourceAndDestinationType(SourceAndDestinationEnum.Destination);
						}}>
						{props.isOpenLoop ? (
							<SourceAndDestination
								needCreditorPhoneNumber={needCreditorPhoneNumber}
								needCreditorAccountNumber={needCreditorAccountNumber}
								needCreditorAddress={needCreditorAddress}
								type={SourceAndDestinationType}
								sourceAccount={sourceAccount}
								onSourceAccountChange={handleSetSourceAccount}
								onDestinationAccountOrRecipientChange={handleSetDestinationAccountOrRecipient}
							/>
						) : (
							<></>
						)}
					</StyledAmountSelection>
				</>
			);
		}
		case PincodeState.FetchKeyboardAfterError:
		case PincodeState.PromptingKeyboard: {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const keyboard = context.keyboard!;
			return (
				<Container>
					<PincodeKeyboard
						keyboard={keyboard}
						onSubmit={submitPincode}
						title={formatMessage("pincodeConfirmation.label")}
						errorMessage={errorMessage}
						loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
					/>
				</Container>
			);
		}
		case TransferState.Done: {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const transactionResult = context.transactionResult!;
			return props.isOpenLoop ? (
				<Container>
					<Centered>
						<SuccessLogoImage src={SuccessLogo} alt="" />
						<SuccessMessage>
							{formatMessage("transferSummary.customerInstuctionSuccessMessage", {
								id: (transactionResult as CustomerInstructionResult)?.id,
								recipient: destinationAccountOrRecipient?.label || destinationAccountOrRecipient?.name,
							})}
						</SuccessMessage>
						<WhitePrimaryButton onClick={closeModal} size="S">
							{formatMessage("transferSummary.done")}
						</WhitePrimaryButton>
					</Centered>
				</Container>
			) : (
				<StyledTransferSummary
					recipient={recipient}
					title={formatMessage("transferSummary.summaryTitle")}
					subtitle={
						context.transferMode === TransferMode.CashTransfer
							? formatMessage("transferSummary.cashSummarySubtitle", { recipient: recipient.name })
							: formatMessage("transferSummary.accountSummarySubtitle")
					}
					middleStepLabel={formatMessage("transferSummary.summaryMiddleStep")}
					transaction={(transactionResult as TransactionRequest).metadata.transaction}
					onDone={closeModal}
					boldSubtitle={context.transferMode !== TransferMode.CashTransfer}
				/>
			);
		}
		case TransferState.StrongAuthentication: {
			return (
				<Container>
					<OtpConfirm submitOtp={submitOtp} phoneNumber={client?.contactphone} />
				</Container>
			);
		}
		default: {
			return (
				<LoadingContainer>
					<MainColorSpinner size="30px" />
				</LoadingContainer>
			);
		}
	}
};

const Centered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Container = styled.div`
	margin: 40px auto 0;
`;

const Title = styled.span`
	font-size: 1.125rem;
	${theme.boldText};
	text-align: center;
	margin-top: 20px;
`;

const StyledAmountSelection = styled(AmountSelection)`
	margin-top: 20px;
`;

const LoadingContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 30vh;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 50px;
	text-align: center;
`;

const StyledTransferSummary = styled(TransferSummary)<{ boldSubtitle?: boolean }>`
	${Subtitle} {
		${props => !props.boldSubtitle && theme.text}
	}
`;

const SuccessMessage = styled.div`
	${theme.boldText};
	font-size: 1.125rem;
	text-align: center;
	margin-bottom: 20px;
`;

const SuccessLogoImage = styled.img`
	align-self: center;
	width: 200px;
	height: 200px;
`;
