import { Cache } from "./cache";
import { CacheLoader } from "./cache-loader";
import { CacheStatus } from "./cache-status";

export class DefaultCacheLoader<T> implements CacheLoader<T> {
	public constructor(private cache: Cache<T>) {}

	public async clear(key = "default") {
		return await this.cache.clear(key);
	}

	public async isValid(key = "default") {
		return await this.cache.isValid(key);
	}

	public async read(key = "default"): Promise<T | null> {
		return await this.cache.read(key);
	}

	public async readStatus(key = "default"): Promise<CacheStatus | null> {
		return await this.cache.readStatus(key);
	}

	public async load(loadFunction: () => Promise<T>, forceRefresh = false, key = "default"): Promise<T | null> {
		const isCacheValid = await this.cache.isValid(key);
		if (isCacheValid && !forceRefresh) {
			return await this.cache.read(key);
		} else {
			try {
				const data = await loadFunction();
				this.cache.store(data, undefined, key);
				return data;
			} catch (e) {
				if (!forceRefresh) {
					return await this.cache.read(key);
				} else {
					throw e;
				}
			}
		}
	}

	public store(data: T, creation?: Date, key = "default"): void {
		this.cache.store(data, creation, key);
	}
}
