import React from "react";
import { SVGProps } from "react";

export const ContactEmailIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="19" height="19" viewBox="0 0 19 19" {...props}>
		<g fill="none" fillRule="evenodd">
			<path d="M.5.5h18v18H.5z" />
			<path
				d="M4.115 4.654h10.77c.85 0 1.538.689 1.538 1.538v6.923c0 .85-.689 1.539-1.538 1.539H4.115c-.85 0-1.538-.689-1.538-1.539V6.192c0-.85.689-1.538 1.538-1.538zm10.111 1.415L9.5 8.614 4.774 6.069a.577.577 0 0 0-.548 1.016l5 2.692c.171.092.377.092.548 0l5-2.692a.577.577 0 0 0-.548-1.016z"
				fill={props.color ?? "#000"}
				fillRule="nonzero"
			/>
		</g>
	</svg>
);
