import { AxiosRequestConfig } from "axios";
import { UserAgentService } from "../user-agent-service";
import { Interceptor } from "./interceptor";

export const addUserAgentInterceptor: (
	userAgentService: UserAgentService
) => Interceptor<AxiosRequestConfig> = userAgentService => ({
	onFulfilled: async config => {
		const userAgent = await userAgentService.getUserAgent();
		return {
			...config,
			headers: { "User-Agent": userAgent, ...config.headers },
		};
	},
});
