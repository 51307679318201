import React, { useMemo } from "react";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { CardStatus } from "../../../../../../shared/domains/cards/card";
import { CARD_ACTIVATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { Modal } from "../../../../common/modal/modal";
import { ActionMenu } from "../action-menu";
import { ActivationCardProps, CardActivationModal } from "./activation-modal";

export const ActivationButton: React.FC<ActivationCardProps> = ({ card, className, disabled }) => {
	const { formatMessage } = useIntl();
	const canActivate = useMemo(() => card.status === CardStatus.Pending, [card.status]);

	if (!canActivate) {
		return null;
	}

	return (
		<ActionMenu
			title={formatMessage("cardOptions.activationCard.activateButton")}
			disabled={disabled}
			className={className}
			onClick={() => {
				Modal.present(CARD_ACTIVATION_MODAL_ID, () => <CardActivationModal card={card} className={className} />, {
					canBeDismissed: false,
				});
			}}
		/>
	);
};
