import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../shared/core/service/services";
import { Recipient } from "../../../../shared/domains/recipients/recipient";
import {
	TransferMode,
	useAvailableTransferMode,
} from "../../../../shared/domains/transactions/cash-transfer/transfer-mode";
import { useObservable } from "../../../../shared/utils/observable";
import { shadows, theme } from "../../styles/theme";
import { TransfertToAccountIcon, TransfertToCashIcon } from "../svg/transfert-icon";

export const TransferModeSelection: React.FC<{
	recipient: Recipient;
	transferTypeSelected: (type: TransferMode) => void;
}> = props => {
	const { recipient, transferTypeSelected } = props;
	const features = useObservable(featuresManager.features);
	const availableTypes = useAvailableTransferMode(recipient, features);
	const [selectType, setSelectedType] = useState<TransferMode>(TransferMode.AccountTransfer);

	useEffect(() => {
		if (availableTypes) {
			setSelectedType(availableTypes[0]);
		}
	}, [availableTypes]);

	useEffect(() => {
		transferTypeSelected(selectType);
	}, [transferTypeSelected, selectType]);

	if (!availableTypes || availableTypes.length < 2) {
		return null;
	}

	return (
		<TransferModeSelectionContent>
			{availableTypes.map((type, index) => (
				<TransferModeView
					key={index}
					type={type}
					selected={selectType === type}
					onClick={() => setSelectedType(type)}
				/>
			))}
		</TransferModeSelectionContent>
	);
};

export const transferTypesLabels = {
	[TransferMode.AccountTransfer]: "transferSummary.accountTransferMode",
	[TransferMode.CashTransfer]: "transferSummary.cashTransferMode",
};

export const getTransferModesIcons = {
	[TransferMode.AccountTransfer]: (selected?: boolean) => <AccountIcon selected={selected} />,
	[TransferMode.CashTransfer]: (selected?: boolean) => <CashIcon selected={selected} />,
};

export const TransferModeView: React.FC<{ type: TransferMode; selected?: boolean; onClick: () => void }> = props => {
	const { type, selected, onClick } = props;
	const { formatMessage } = useIntl();
	return (
		<TransferModeContent>
			<ToggleButton onClick={onClick} selected={selected}>
				{getTransferModesIcons[type](selected)}
			</ToggleButton>
			<TransferModeTitle>{formatMessage(transferTypesLabels[type])}</TransferModeTitle>
		</TransferModeContent>
	);
};

const TransferModeSelectionContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 20px;
	margin-top: 40px;
	margin-bottom: 40px;
`;

const TransferModeContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TransferModeTitle = styled.span`
	${theme.boldText};
	padding-top: 15px;
`;

const ToggleButton = styled.div<{ selected?: boolean }>`
	display: flex;
	width: 85px;
	height: 85px;
	cursor: pointer;
	border-radius: 11px;
	background-color: #ffffff;
	border: ${({ selected }) => (selected ? `1px solid ${theme.mainColor}` : "1px solid #fff")};
	justify-content: center;
	align-items: center;
	${shadows.medium};
`;

const CashIcon = styled(TransfertToCashIcon)<{ selected?: boolean }>`
	path {
		fill: ${({ selected }) => (selected ? theme.mainColor : "#808080")};
	}
`;

const AccountIcon = styled(TransfertToAccountIcon)<{ selected?: boolean }>`
	path {
		fill: ${({ selected }) => (selected ? theme.mainColor : "#808080")};
	}
`;
