import { Amount, addAmounts } from "../../../../../shared/core/amount/amount";

import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { clientManager } from "../../../../../shared/core/service/services";
import { Transaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { BillIssuer } from "../../../../../shared/domains/bills/bill";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { useObservable } from "../../../../../shared/utils/observable";
import { useRTL } from "../../../../domain/language/use-rtl";
import { Avatar } from "../../../common/avatar/avatar";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { Circle } from "../../../common/shape/circle";
import { SuccessCircle } from "../../../common/success-circle";
import { RightArrow } from "../../../common/svg/right-arrow";
import { SideEntryLabel } from "../../../common/transaction/side-entry-label";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

interface TransferSummaryProps {
	toSelf?: boolean;
	sender?: string;
	recipient?: Recipient;
	transaction: Transaction;
	title: string;
	subtitle: string;
	middleStepLabel: string;
	billIssuer?: BillIssuer;
	onDone?: () => void;
	styledIssuerLogo?: boolean;
	color?: string;
	className?: string;
}

export const TransferSummary = (props: TransferSummaryProps) => {
	const {
		toSelf,
		sender,
		recipient,
		transaction,
		title,
		subtitle,
		middleStepLabel,
		billIssuer,
		onDone,
		styledIssuerLogo,
		color,
		className,
	} = props;
	const { formatMessage, formatAmount } = useIntl();
	const client = useObservable(clientManager.client);

	const transferEntry = transaction.detailedAccounting?.find(entry => entry.type === "TRANSFER");
	let transferAmount: Amount | undefined;
	if (!transferEntry) {
		transferAmount = transaction.amount;
	} else {
		transferAmount = addAmounts(...transaction.detailedAccounting?.map(entry => entry.amount));
	}

	const { isRTL } = useRTL();

	return (
		<Container className={className}>
			<GreenCheckCircle />
			<Centered>
				<SuccessText>{title}</SuccessText>
				<Subtitle>{subtitle}</Subtitle>
				<ScrollableDiv>
					<Details>
						<BlackCircle size={46} $isRTL={isRTL}>
							<Arrow width={14} height={14} $isRTL={isRTL} />
						</BlackCircle>
						<Line />
						<MiddleStepText>{middleStepLabel}</MiddleStepText>
						<Line />
						{recipient ? (
							<StyledAvatar size={46} name={recipient.name} $isRTL={isRTL} />
						) : billIssuer ? (
							<IssuerLogo
								style={styledIssuerLogo ? { backgroundColor: color, padding: 5, borderRadius: 9 } : {}}
								src={billIssuer.logoUrl}
								$isRTL={isRTL}
							/>
						) : toSelf && client && client.firstName ? (
							<StyledAvatar size={46} name={client.firstName} $isRTL={isRTL} />
						) : null}
					</Details>
					<NameDetails>
						<Name>
							{sender ?? formatMessage("transferSummary.you")}
							{"\n"}
							{transferAmount && formatAmount(transferAmount, toSelf)}
						</Name>
						<SideEntries>
							{transaction.detailedAccounting
								?.filter(entry => entry.type !== "TRANSFER")
								.map((entry, idx) => (
									<Name style={{ textAlign: "center" }} key={`${entry.type}_${idx}`}>
										<SideEntryLabel translationKeyPrefix="transferSummary" entry={entry} absolute={!toSelf} />
									</Name>
								))}
						</SideEntries>
						<RightAlignName>
							{recipient
								? recipient.name
								: billIssuer
								? billIssuer.name
								: toSelf
								? formatMessage("transferSummary.you")
								: ""}
							{"\n"}
							{transferEntry &&
								(toSelf
									? transferAmount
										? formatAmount(transferAmount)
										: null
									: formatAmount(transferEntry.amount, true))}
						</RightAlignName>
					</NameDetails>
				</ScrollableDiv>
				<DoneButton size="M" onClick={onDone}>
					{formatMessage("transferSummary.done")}
				</DoneButton>
			</Centered>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`;

const GreenCheckCircle = styled(SuccessCircle)`
	margin-top: 28px;
`;

const Centered = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
`;

const SuccessText = styled.div`
	font-size: 1.375rem;
	${theme.boldText};
	padding-bottom: 25px;
	padding-top: 30px;
	align-self: center;
`;

export const Subtitle = styled.div`
	font-size: 1rem;
	${theme.boldText};
	align-self: center;
	padding-bottom: 25px;
	text-align: center;
`;

const Details = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
`;
const BlackCircle = styled(Circle)<{ $isRTL: boolean }>`
	background-color: #000000;
	margin-right: ${props => (props.$isRTL ? 0 : 20)}px;
	margin-left: ${props => (props.$isRTL ? 20 : 0)}px;
`;

const Line = styled.div`
	background-color: #000000;
	height: 2px;
	flex-grow: 1;
`;

const MiddleStepText = styled(Subtitle)`
	padding: 0px 20px 0px 20px;
`;

const StyledAvatar = styled(Avatar)<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? 0 : 20)}px;
	margin-right: ${props => (props.$isRTL ? 20 : 0)}px;
`;

const IssuerLogo = styled.img<{ $isRTL: boolean }>`
	margin-left: ${props => (props.$isRTL ? 0 : 20)}px;
	margin-right: ${props => (props.$isRTL ? 20 : 0)}px;
	width: 46px;
	height: 46px;
	object-fit: contain;
`;

const NameDetails = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-self: stretch;
	margin-top: 15px;
`;

const Name = styled.div`
	${theme.mediumText}
	font-size: 0.875rem;
	color: #000000;
	white-space: pre-line;
`;

const RightAlignName = styled(Name)`
	text-align: right;
	max-width: 120px;

	@media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
		max-width: 90px;
	}
`;

const SideEntries = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DoneButton = styled(PrimaryButton)``;

const ScrollableDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	width: 100%;
	overflow: auto;
	padding-bottom: 15px;
	margin-bottom: 15px;
	margin-left: 10px;
	margin-right: 10px;
`;

const Arrow = styled(RightArrow)<{ $isRTL: boolean }>`
	transform: ${props => (props.$isRTL ? `rotate(180deg)` : `rotate(0deg)`)};
`;
