import React, { SVGProps } from "react";

export function TransfertToCashIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="16" height="26" viewBox="0 0 16 26" {...props}>
			<path
				fill="#000"
				fillRule="nonzero"
				d="M9.702 0c.867 0 1.574.701 1.574 1.574v8.587c.805.468 3.787 3.777 4.072 4 .702.55.67 1.486.582 1.995-.02.156-.072.301-.135.447-.39.903-1.054 6.852-1.226 8.452a.444.444 0 0 1-.441.394H9.322a.447.447 0 0 1-.446-.446v-.458c-1.476-1.542-2.01-3.771-2.01-3.771l-.479-4.11c-1.07-3.698.224-3.807.224-3.807.047-.005.088-.005.135-.005 1.709 0 2.01 3.371 2.01 3.371s.613 1.268 1.164 1.673V3.19a1.592 1.592 0 0 1-1.86-1.85H3.22a1.595 1.595 0 0 1-1.86 1.85v11.704a1.595 1.595 0 0 1 1.938 1.942h1.558l.125.11.145 1.236H1.593a1.573 1.573 0 0 1-1.574-1.574V1.574C.019.706.719 0 1.593 0zM5.645 5.745a3.456 3.456 0 0 1 2.363 5.974 2.658 2.658 0 0 0-1.532-.296c-.234.021-.925.156-1.434.9a2.445 2.445 0 0 0-.15.248A3.459 3.459 0 0 1 2.194 9.2a3.452 3.452 0 0 1 3.45-3.455z"
			/>
		</svg>
	);
}

export function TransfertToAccountIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
			<path
				fill="#81C241"
				d="M16 1H8C6.343 1 5 2.343 5 4v16c0 1.657 1.343 3 3 3h8c1.657 0 3-1.343 3-3V4c0-1.657-1.343-3-3-3zm0 20H8c-.552 0-1-.448-1-1V4c0-.552.448-1 1-1s1 .448 1 1 .448 1 1 1h4c.552 0 1-.448 1-1s.448-1 1-1 1 .448 1 1v16c0 .552-.448 1-1 1z"
				transform="translate(-114 -589) translate(83 558) translate(31 31)"
			/>
		</svg>
	);
}
