/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMachine } from "@xstate/react";
import { assign, Machine, send } from "xstate";
import { Amount } from "../../shared/core/amount/amount";
import { pincodeKeyboardService, transactionCodeManager } from "../../shared/core/service/services";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import {
	AccountBlockedAfterThreePincodeAttemptsError,
	DefaultAccountBlockedError,
} from "../../shared/domains/pincode/pincode-error";
import { TransactionCode } from "../../shared/domains/transactions-codes/transaction-code";
import { FONT_SIZE, KeyboardEvent, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

export const useTransactionCodeMachine = () => {
	const [state, sendEvent] = useMachine(transactionCodeMachine);

	const selectAcceptorId = (acceptorIdShortCode: string) => {
		sendEvent("SELECT_ACCEPTOR_ID", { acceptorIdShortCode });
	};

	const goBack = () => {
		sendEvent("GO_BACK");
	};

	const selectAmount = (amount: Amount) => {
		sendEvent("SELECT_AMOUNT", { amount });
	};

	const submitPincode = (submission: PincodeSubmission) => {
		sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
	};
	return {
		state: state.value as TransactionCodeState | PincodeState,
		context: state.context,
		selectAcceptorId,
		goBack,
		selectAmount,
		submitPincode,
	};
};

interface TransactionCodeMachineContext {
	acceptorIdShortCode?: string;
	amount?: Amount;
	keyboard?: Keyboard;
	pincodeSubmission?: PincodeSubmission;
	transactionResult?: TransactionCode;
	error?: string | AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError;
}

export enum TransactionCodeState {
	EnterAcceptorId = "EnterAcceptorId",
	SelectingAmount = "SelectingAmount",
	Confirmation = "Confirmation",
	Done = "Done",
}

type TransactionCodeEvent =
	| {
			type: "SELECT_ACCEPTOR_ID";
			acceptorIdShortCode: string;
	  }
	| {
			type: "SELECT_AMOUNT";
			amount: Amount;
	  }
	| { type: "GO_BACK" }
	| { type: "CONFIRM" }
	| { type: "TRANSACTION_CODE_CREATED" }
	| { type: "TRANSACTION_CODE_ERROR" }
	| KeyboardEvent;

enum TransactionCodeInvokeName {
	FetchKeyboard = "FetchKeyboard",
	ConfirmNewcode = "ConfirmNewcode",
}

export const transactionCodeMachine = Machine<TransactionCodeMachineContext, TransactionCodeEvent>({
	id: "transaction-code",
	initial: TransactionCodeState.EnterAcceptorId,
	states: {
		[TransactionCodeState.EnterAcceptorId]: {
			on: {
				SELECT_ACCEPTOR_ID: {
					target: TransactionCodeState.SelectingAmount,
					actions: [
						assign({
							acceptorIdShortCode: (_, event) => event.acceptorIdShortCode,
						}),
					],
				},
			},
		},
		[TransactionCodeState.SelectingAmount]: {
			on: {
				SELECT_AMOUNT: {
					target: PincodeState.PincodeConfirmation,
					actions: [
						assign({
							amount: (_, event) => event.amount,
						}),
					],
				},
				GO_BACK: {
					target: TransactionCodeState.EnterAcceptorId,
				},
			},
		},
		[PincodeState.PincodeConfirmation]: {
			entry: assign<TransactionCodeMachineContext, TransactionCodeEvent>({ error: undefined }),
			invoke: {
				id: TransactionCodeInvokeName.FetchKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[PincodeState.PromptingKeyboard]: {
			on: {
				SUBMIT_PINCODE: {
					target: TransactionCodeState.Confirmation,
					actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
				},
				GO_BACK: {
					target: TransactionCodeState.SelectingAmount,
				},
			},
		},
		[TransactionCodeState.Confirmation]: {
			invoke: {
				id: TransactionCodeInvokeName.ConfirmNewcode,
				src: context =>
					transactionCodeManager.createTransactionCode(
						context.amount!,
						context.acceptorIdShortCode!,
						context.pincodeSubmission
					),
				onDone: {
					actions: [
						assign<TransactionCodeMachineContext, { type: string; data: TransactionCode | undefined }>({
							transactionResult: (_, event) => event.data,
							error: undefined,
						}),
						send({ type: "TRANSACTION_CODE_CREATED" }),
					],
				},
				onError: {
					actions: [
						assign({
							error: (_, event) => event.data,
						}),
						send({ type: "TRANSACTION_CODE_ERROR" }),
					],
				},
			},
			on: {
				TRANSACTION_CODE_CREATED: TransactionCodeState.Done,
				TRANSACTION_CODE_ERROR: PincodeState.FetchKeyboardAfterError,
			},
		},
		[PincodeState.FetchKeyboardAfterError]: {
			invoke: {
				id: TransactionCodeInvokeName.FetchKeyboard,
				src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
				onDone: {
					actions: [
						assign({
							keyboard: (_, event) => event.data,
						}),
						send({ type: "PROMPT_KEYBOARD" }),
					],
				},
			},
			on: {
				PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
			},
		},
		[TransactionCodeState.Done]: {
			type: "final",
		},
	},
});
