import IBAN from "iban";
import React, { useState } from "react";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account, AccountIBAN } from "../../../../../shared/domains/account/account";
import { isDefined } from "../../../../../shared/utils/assert";
import { anonimyzeIban } from "../../../../../shared/utils/iban";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { AmountInput } from "../../../common/forms/amount-input";
import { BigBankToWalletIcon } from "../../../common/svg/big-bank-to-wallet-icon";
import { shadows, theme } from "../../../styles/theme";

interface SelectRechargeByBankAmountProps {
	account: Account;
	externalAccountIBAN: AccountIBAN;
	submitAmount: (amount: Amount) => void;
	loading?: boolean;
	errorMessage?: string;
}
export const SelectRechargeByBankAmount = (props: SelectRechargeByBankAmountProps) => {
	const { account, externalAccountIBAN, submitAmount, loading, errorMessage } = props;
	const [amount, setAmount] = useState<Amount>(new Amount(0, account.balance.currency));
	const { formatMessage } = useIntl();
	return (
		<ContentContainer>
			<Header>
				<HeaderLogo>
					<IconContainer>
						<BigBankToWalletIcon fill={theme.mainColor()} />
					</IconContainer>
				</HeaderLogo>
				<HeaderContentContainer>
					<Title>{formatMessage("rechargeBankToWallet.title")}</Title>
					<Subtitle>{formatMessage("rechargeBankToWallet.subtitle")}</Subtitle>
				</HeaderContentContainer>
			</Header>
			{isDefined(errorMessage) && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
			<Card>
				<CardTitle>{formatMessage("rechargeBankToWallet.accountWithdrawnTitle")}</CardTitle>
				<CardDescription>{formatMessage("rechargeBankToWallet.accountWithdrawnMessage")}</CardDescription>
				<IbanContainer>
					<TagContainer>
						<Tag>{formatMessage("rechargeBankToWallet.iban")}</Tag>
					</TagContainer>
					<Iban>{isDefined(externalAccountIBAN) ? anonimyzeIban(IBAN.printFormat(externalAccountIBAN)) : ""}</Iban>
				</IbanContainer>
			</Card>
			<CardTitle>{formatMessage("rechargeBankToWallet.amountToWithdraw")}</CardTitle>
			<StyledAmountInput value={amount} onChange={setAmount} />
			<Footer>
				<PrimaryButton
					size="S"
					onClick={() => submitAmount(amount)}
					showSpinner={loading}
					disabled={!(amount.value > 0)}>
					{formatMessage("rechargeBankToWallet.recharge")}
				</PrimaryButton>
			</Footer>
		</ContentContainer>
	);
};

const Header = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: 5px;
	padding-right: 5px;
	margin-top: 20px;
	margin-bottom: 40px;
`;
const HeaderLogo = styled.div`
	display: flex;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	align-items: center;
`;
const HeaderContentContainer = styled.div`
	flex-direction: column;
`;
const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 12px;
	background-color: ${() => theme.mainColorLightBackground()};
`;
const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	margin-right: 15px;
`;
const Title = styled.div`
	font-size: 1.125rem;
	${theme.boldText}
`;

const Subtitle = styled.div`
	font-size: 0.8125rem;
	${theme.text};
	color: #b1b1b1;
`;

const Card = styled.div`
	display: flex;
	flex-direction: column;
	padding: 18px;
	background-color: #ffffff;
	border-radius: 13px;
	${shadows.medium};
	margin-bottom: 16px;
`;

const CardTitle = styled.div`
	${theme.boldText};
	font-size: 0.9375rem;
	margin-bottom: 5px;
`;

const CardDescription = styled.div`
	font-size: 0.75rem;
	${theme.text};
	color: #b1b1b1;
	margin-bottom: 15px;
`;

const IbanContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const TagContainer = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${() => theme.mainColorLightBackground()};
	border-radius: 13px;
	height: 20px;
	align-items: center;
	align-self: flex-start;
	padding-left: 10px;
	padding-right: 10px;
	margin-right: 10px;
`;
const Tag = styled.div`
	font-size: 0.625rem;
	${theme.boldText};
	color: ${theme.mainColor};
`;

const Iban = styled.div`
	font-size: 0.9375rem;
	${theme.text};
`;

const StyledAmountInput = styled(AmountInput)`
	margin-top: 15px;
`;

const Footer = styled.div`
	display: flex;
	margin-top: 50px;
	justify-content: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
	margin: 10px 0px 10px 0px;
	text-align: center;
`;
