import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useClickOutsideHandler } from "../../../../../utils/use-outside-click";
import { shadows } from "../../../../styles/theme";
import { FilterDropdown } from "../../../account-transactions/components/filter-button";

export interface CreateCardSelectorProps<T> {
	values: T[];
	selectedValue?: T;
	onChange: (value: T) => void;
	getLabel: (value: T) => string;
}

export function CreateCardSelector<T>(props: CreateCardSelectorProps<T>) {
	const { values, selectedValue, onChange, getLabel } = props;
	const [showDropdown, setShowDropdown] = useState(false);
	const dropdownContentRef = useRef(null);
	useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
	const safeValue = selectedValue ?? values[0];

	useEffect(() => {
		if (values.length > 0) {
			onChange(safeValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FilterDropdown
			onClick={e => {
				if (e.target === e.currentTarget) {
					setShowDropdown(show => !show);
				}
			}}>
			{getLabel(safeValue)}
			<DropdownContent
				ref={dropdownContentRef}
				onClick={e => {
					e.stopPropagation();
				}}
				$isVisible={showDropdown}>
				{values.map((each, index) => (
					<PressableRow
						key={index}
						onClick={() => {
							onChange(each);
							setShowDropdown(false);
						}}>
						{getLabel(each)}
					</PressableRow>
				))}
			</DropdownContent>
		</FilterDropdown>
	);
}

const DropdownContent = styled.div<{ $isVisible: boolean }>`
	display: ${props => (props.$isVisible ? "block" : "none")};
	overflow: hidden;
	text-align: start;
	position: absolute;
	top: 120%;
	left: 0px;
	background-color: #f4f4f4;
	border-radius: 10px;
	border: 1px solid #e2e2e2;
	z-index: 1;
	min-width: 100%;
	${shadows.medium};
	:hover {
		cursor: auto;
	}
`;

const PressableRow = styled.div`
	padding: 5px 10px 5px 10px;
	:focus,
	:active,
	:hover {
		background-color: #e2e2e2;
		cursor: pointer;
	}
`;
