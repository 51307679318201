import React, { useEffect, useMemo } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { accountManager } from "../../../../shared/core/service/services";
import { Account } from "../../../../shared/domains/account/account";
import { isRechargeable } from "../../../../shared/domains/recharge/is-rechargeable";
import { useObservable } from "../../../../shared/utils/observable";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { RECHARGE_MODAL_ID } from "../../../core/modal/modal-id";
import { Path } from "../../../core/routing/path";
import { useRTL } from "../../../domain/language/use-rtl";
import { useClient } from "../../../domain/authentication/use-client";
import { AccountListGrid } from "../../common/account/account-list-grid";
import { Modal } from "../../common/modal/modal";
import { PageSectionDescription, PageSectionTitle } from "../../common/nav/page-section-title";
import { RechargeableAccountTile, SkeletonAccountTile } from "../account-transactions/components/account-tile";
import { NoAccountAvailableBlock } from "./components/no-account-available-block";
import { RechargeModal } from "./components/recharge-modal";

export function RechargeScreen() {
	const { formatMessage } = useIntl();
	const accounts = useObservable<Account[]>(accountManager.accounts);
	const currentAccounts = useMemo(() => accounts.filter(isRechargeable), [accounts]);
	const { client } = useClient();
	const accountsLoading = useObservable<boolean>(accountManager.loading);
	const { pathname } = useLocation();

	const { isRTL } = useRTL();

	useAsyncEffect(async () => {
		await accountManager.load();
	}, []);

	useEffect(() => {
		if (pathname.includes(Path.PspRedirect)) {
			Modal.present(RECHARGE_MODAL_ID, () => <RechargeModal completePspTransactionFlow />, {
				canBeDismissed: false,
			});
		}
	}, [pathname]);

	if (pathname.includes(Path.PspRedirect)) {
		return <Redirect to={{ pathname: Path.Recharge }} />;
	}
	return (
		<Section>
			<RechargeScreenHeader>
				<SectionTitle>{formatMessage("recharge.title")}</SectionTitle>
				<SectionDescription>{formatMessage("recharge.description")}</SectionDescription>
			</RechargeScreenHeader>
			{!accountsLoading && currentAccounts.length === 0 ? (
				<NoAccountAvailableBlock />
			) : (
				<AccountListGrid>
					{accountsLoading ? (
						<SkeletonAccountTile $isRTL={isRTL} />
					) : (
						currentAccounts.map(account => (
							<RechargeableAccountTile
								key={account.id}
								account={account}
								owner={client}
								onClick={() => {
									Modal.present(RECHARGE_MODAL_ID, () => <RechargeModal account={account} />, {
										canBeDismissed: false,
									});
								}}
							/>
						))
					)}
				</AccountListGrid>
			)}
		</Section>
	);
}

const Section = styled.section`
	align-self: stretch;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const SectionTitle = styled(PageSectionTitle)``;

const SectionDescription = styled(PageSectionDescription)``;

const RechargeScreenHeader = styled.div`
	flex-direction: column;
	margin-bottom: 30px;
`;
