import { LanguageTag } from "../../../shared/core/i18n/translations";
import { logger } from "../../../shared/core/logging/logger";
import { clientManager, i18NManager } from "../../../shared/core/service/services";

export const changeLanguage = async (language: string) => {
	const previousLocale = i18NManager.localeTag.get();

	try {
		await i18NManager.setLocale(language as LanguageTag);
		await clientManager.setLanguage(language as LanguageTag);
	} catch (e) {
		logger.debug("SettingsScreen", "Revert language change", e);
		await i18NManager.setLocale(previousLocale as LanguageTag);
	}
};
