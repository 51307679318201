import { AxiosResponse } from "axios";
import { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import { ServiceDomainManager } from "../../../domains/service-domain/service-domain-manager";
import { HttpService } from "../http-service";
import { SecuredCookiesService } from "../secured-cookies-service";
import { Interceptor } from "./interceptor";

export const renewTokenInterceptor: (
	httpService: HttpService,
	authenticationManager: AuthenticationManager | ServiceDomainManager,
	securedCookiesService?: SecuredCookiesService
) => Interceptor<AxiosResponse> = (httpService, authenticationManager, securedCookiesService) => ({
	onRejected: async error => {
		if (!error?.response || error?.response?.status != 401) {
			return Promise.reject(error);
		}
		try {
			const accessToken = await authenticationManager.getAccessToken(true);
			const addAuthorization = !securedCookiesService?.getSecuredCookies();
			if (accessToken && addAuthorization) {
				error.response.config.headers["Authorization"] = `${accessToken.type} ${accessToken.value}`;
			}
			return httpService.instance(error.response.config);
		} catch (e) {
			return Promise.reject(error);
		}
	},
});
