import React, { SVGProps } from "react";

export function ChevronIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 21 21" {...props}>
			<path
				fillRule="nonzero"
				d="M0 1.346L1.375 0l5.793 5.671L12.962 0l1.374 1.346-6.332 6.199c-.465.454-1.207.454-1.672 0L0 1.345z"
				transform="translate(-1344 -179) translate(453 171) translate(836) rotate(-180 41.5 17.855) rotate(90 9.81 17.355) translate(2.264 6.036)"
			/>
		</svg>
	);
}
