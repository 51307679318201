import moment from "moment";
import {
	AuthenticatedSessionExpiredError,
	NetworkErrorOnAuthentication,
} from "../../../mobile/domain/authentication/authentication-error";
import { WebOAuthService } from "../../../web/domain/authentication/web-oauth-service";
import { Config } from "../../core/config/config";
import { logger } from "../../core/logging/logger";
import { OauthHttpService } from "../../core/net/oauth-http-service";
import { PincodeSubmission } from "../pincode/pincode";
import { AuthenticationConfigurationService } from "./authentication-configuration-service";
import { AuthorizeResult, OAuthService, RefreshResult, TokenResultDto } from "./oauth-service";
import { ApplicationScopes, RegisterOtpScopes, Scope } from "./scope";

export interface RegisteringParameters {
	enrollment_id: string;
	enrollment_otp: string;
}

export interface TokenResult {
	accessToken: string;
	tokenType: string;
	expirationDate: Date;
	refreshToken?: string;
}

export class AuthenticationService {
	public constructor(
		private oauthHttpService: OauthHttpService,
		private authenticationConfigurationService: AuthenticationConfigurationService,
		private oauthService: OAuthService
	) {}

	public async requestNotConnectedAccessToken(notConnectedScopes: Scope[]): Promise<TokenResult> {
		try {
			const result = await this.oauthHttpService.instance.post<TokenResultDto>("/oauth2/token", {
				grant_type: "client_credentials",
				client_id: Config.API_CLIENT_ID,
				client_secret: Config.API_CLIENT_SECRET,
				scope: notConnectedScopes,
			});
			return AuthenticationService.tokenResultFromDto(result.data);
		} catch (e) {
			logger.debug("AuthenticationService", "Failed to request not connected access token", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async authorizeWithPincode(pincode: PincodeSubmission, enrollmentId?: string): Promise<TokenResult> {
		try {
			const pincodeValue = pincode.value.join(";");
			const password = enrollmentId ? [pincodeValue, enrollmentId].join("|") : pincodeValue;
			const scopes = enrollmentId ? ApplicationScopes : RegisterOtpScopes;

			const result = await this.oauthHttpService.instance.post<TokenResultDto>("/oauth2/token", {
				grant_type: "password",
				client_id: Config.API_CLIENT_ID,
				client_secret: Config.API_CLIENT_SECRET,
				scope: scopes,
				username: pincode.id,
				password: password,
			});
			return AuthenticationService.tokenResultFromDto(result.data);
		} catch (e) {
			logger.debug("AuthenticationService", "Failed to authorize with phone number and pincode", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async validateAuthorizationWithPincode(accessToken: string, otp: string): Promise<TokenResult> {
		try {
			const result = await this.oauthHttpService.instance.post<TokenResultDto>("/oauth2/otp", {
				grant_type: "password",
				client_id: Config.API_CLIENT_ID,
				client_secret: Config.API_CLIENT_SECRET,
				scope: ApplicationScopes,
				username: accessToken,
				password: otp,
			});
			return AuthenticationService.tokenResultFromDto(result.data);
		} catch (e) {
			logger.debug("AuthenticationService", "Failed to authorize with phone number and pincode", e);
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async authorize(
		phoneNumber?: string,
		registeringParameters?: RegisteringParameters
	): Promise<TokenResult | void> {
		try {
			const result = await this.oauthService.authorize(
				await this.authenticationConfigurationService.getConfiguration(phoneNumber, registeringParameters)
			);
			if (result) {
				return AuthenticationService.tokenResultFromAuthorizeOrRefreshResult(result);
			}
		} catch (e) {
			logger.debug("AuthenticationService", "Authorize failed", e);
			throw e;
		}
	}

	public async authorizeWithCodeGrant(code: string, state: string): Promise<TokenResult> {
		try {
			if (!(this.oauthService instanceof WebOAuthService)) {
				throw "Code Grant Authorization flow is Web specific";
			}
			const result = await this.oauthService.completeAuthorization(
				code,
				state,
				await this.authenticationConfigurationService.getConfiguration()
			);
			return AuthenticationService.tokenResultFromDto(result);
		} catch (e) {
			logger.debug("AuthenticationService", "Authorize failed", e);
			throw e;
		}
	}

	public async validateRegisterOtp(registeringParameters: RegisteringParameters): Promise<TokenResult> {
		try {
			const result = await this.oauthHttpService.instance.post<TokenResultDto>("/oauth2/otp", {
				grant_type: "password",
				client_id: Config.API_CLIENT_ID,
				client_secret: Config.API_CLIENT_SECRET,
				scope: RegisterOtpScopes,
				username: registeringParameters?.enrollment_id,
				password: registeringParameters?.enrollment_otp,
			});
			return AuthenticationService.tokenResultFromDto(result.data);
		} catch (e) {
			logger.debug("AuthenticationService", "Register validation failed", e);
			if (e.response) {
				// Request made and server responded
				logger.error("AuthenticationService", "Error content:", e.response.headers, e.response.status, e.response.data);
			}
			throw e?.response?.data?.error?.message || e.toString();
		}
	}

	public async refresh(refreshToken: string): Promise<TokenResult> {
		try {
			const result = await this.oauthHttpService.instance.post<TokenResultDto>("/oauth2/token", {
				grant_type: "refresh_token",
				client_id: Config.API_CLIENT_ID,
				client_secret: Config.API_CLIENT_SECRET,
				refresh_token: refreshToken,
			});
			return AuthenticationService.tokenResultFromDto(result.data);
		} catch (error) {
			logger.debug("AuthenticationService", "Refresh token failed", JSON.stringify(error));
			throw AuthenticationService.handleRefreshError(error);
		}
	}

	private static handleRefreshError(error: any): Error {
		if (error instanceof Error) {
			if ("Network error".toLowerCase() == error.message.toLowerCase()) {
				return new NetworkErrorOnAuthentication(error.message);
			}
			return new AuthenticatedSessionExpiredError(error.message);
		}

		return new Error("unable to handle refresh error: " + JSON.stringify(error));
	}

	public static tokenResultFromDto(dto: TokenResultDto): TokenResult {
		return {
			accessToken: dto.access_token,
			expirationDate: moment().add(dto.expires_in, "seconds").toDate(),
			tokenType: dto.token_type,
			refreshToken: dto.refresh_token ?? undefined,
		};
	}

	private static tokenResultFromAuthorizeOrRefreshResult(result: AuthorizeResult | RefreshResult): TokenResult {
		return {
			accessToken: result.accessToken,
			expirationDate: new Date(result.accessTokenExpirationDate),
			tokenType: result.tokenType,
			refreshToken: result.refreshToken ?? undefined,
		};
	}
}
