import { FormInput, FormInputType, FormPincodeInput, FormTextInput } from "./form-input-types";

const termOfServiceId = "termsUrl";

export interface ExtraStepFormInput {
	pincodeFormInput: (FormPincodeInput & { hierarchy: string }) | null;
	TOSUrlFormInput: (FormTextInput & { hierarchy: string }) | null;
}

export function parseExtraStepFormInput(input: (FormInput & { hierarchy: string }) | null): ExtraStepFormInput {
	let pincodeFormInput = null;
	let TOSUrlFormInput = null;

	function findConfigurationRecursively(input: any) {
		if (input.type === "COLLECTION") {
			return input.inputs.map((it: FormInput) => {
				findConfigurationRecursively(it);
			}, []);
		} else if (input.id === termOfServiceId) {
			TOSUrlFormInput = input;
		} else if (input.type === FormInputType.Pincode) {
			pincodeFormInput = input;
		}
		return [];
	}

	if (input !== null) {
		findConfigurationRecursively(input);
	}

	return { pincodeFormInput, TOSUrlFormInput };
}
