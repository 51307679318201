import React, { SVGProps } from "react";

export function DotsIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="26" height="26" viewBox="0 0 26 26" fill="#000" {...props}>
			<path
				fillRule="nonzero"
				d="M13 18c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm0-7c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm0-7c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
				transform="translate(-1320 -473) translate(453 264) translate(0 184) translate(863 21) rotate(90 13 17)"
			/>
		</svg>
	);
}
