import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardRefabricateMotive, CardStatus } from "../../../../../shared/domains/cards/card";
import {
	CardRefabricationStep,
	useCardRefabrication,
} from "../../../../../shared/domains/cards/use-card-refabrication";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../shared/utils/phone-number";
import { RadioSelector, RadioSelectorType } from "../../../common/forms/radio-selector";

import { CARD_REFABRICATION_MODAL_ID } from "../../../../core/modal/modal-id";
//import { CardRefabricateMotive } from "../../../../../mobile/ui/screens/cards/refabricate-card-screen";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { cardService } from "../../../../../shared/core/service/services";
import { VerifyAuthenticationStatus } from "../../../../../shared/domains/strong-customer-authentication/strong-customer-authentication";
import { useClient } from "../../../../domain/authentication/use-client";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../../common/spinner";
import { theme } from "../../../styles/theme";
import { OtpConfirm } from "../../recipient/otp-confirm";
import { ResultView } from "../../result/result-view";

export interface ICardRefabricateModalProps {
	card: Card;
}

export const CardRefabricateModal = (props: ICardRefabricateModalProps) => {
	const { card } = props;
	const [errorMessage, setErrorMessage] = useState<string>("");
	const { client } = useClient();
	const [motive, setMotive] = useState<CardRefabricateMotive>();
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (card.status !== CardStatus.Opposed) {
			// the only motive for not opposed card is DAMAGED
			setMotive(CardRefabricateMotive.Damaged);
		}
	}, []);

	const opposeReasons = useMemo(
		() =>
			new Map<CardRefabricateMotive, string>([
				[CardRefabricateMotive.Lost, formatMessage("cardOptions.refabricate.motivesOptions.LOST")],
				[CardRefabricateMotive.Stolen, formatMessage("cardOptions.refabricate.motivesOptions.STOLEN")],
				[CardRefabricateMotive.Abuse, formatMessage("cardOptions.refabricate.motivesOptions.ABUSE")],
			]),
		[formatMessage]
	);

	const { loading, error, scaToken, refabricateCard, step } = useCardRefabrication();

	const handleOTPValidation = async (otp, scaToken) => {
		if (scaToken) {
			try {
				const response = await cardService.scaAuthenticationVerifyToken(scaToken, otp);
				if (response.status === VerifyAuthenticationStatus.DONE) {
					await refabricateCard(card, motive, scaToken);
				}
			} catch (e) {
				setErrorMessage(e.response?.data?.error?.message || e.toString());
			}
		} else {
			throw new Error("Missing SCA Token");
		}
	};

	const handleSubmit = async () => {
		try {
			await refabricateCard(card, motive);
		} catch (e) {
			setErrorMessage(e.response.data.error.message);
		}
	};

	const closeModal = useCallback(() => Modal.dismiss(CARD_REFABRICATION_MODAL_ID), []);

	return (
		<RoundedModalContainer closeButton={true} id={CARD_REFABRICATION_MODAL_ID}>
			<StyledModalContainer>
				{step === CardRefabricationStep.Motive && (
					<>
						<div>
							<StyledModalTitle>{formatMessage("cardOptions.refabricate.modal.title")}</StyledModalTitle>
							<StyledInputLabel>
								{formatMessage("cardOptions.refabricate.modal.message")}
								<StyledCardNumber>
									{formatMessage("cardOptions.refabricate.modal.cardNumber", { cardNumber: card.panDisplay })}
								</StyledCardNumber>
							</StyledInputLabel>

							{card.status === CardStatus.Opposed && (
								<ReasonsContainer>
									<StyledRadioSelector
										innerId="opposition_button"
										values={opposeReasons}
										value={motive}
										onChange={setMotive}
									/>
								</ReasonsContainer>
							)}

							{loading && (
								<StyledModalInner>
									<StyledBlueSpinner key="spinner" />
								</StyledModalInner>
							)}
							{error && error?.length > 0 && <StyledErrorLabel>{error}</StyledErrorLabel>}
						</div>
						<StyledButton disabled={!motive} size="S" onClick={() => handleSubmit()}>
							{formatMessage("cardOptions.refabricate.modal.submitButtonLabel")}
						</StyledButton>
					</>
				)}
				{step === CardRefabricationStep.SCACheck && (
					<OtpConfirm
						submitOtp={(otp: string) => handleOTPValidation(otp, scaToken)}
						errorMessage={errorMessage}
						phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
						loading={loading}
					/>
				)}
				{step === CardRefabricationStep.Error && (
					<StyledResultView
						type={"error"}
						title={formatMessage("resultScreen.refabricate.failureTitle")}
						subtitle={formatMessage("resultScreen.refabricate.failureSubtitle")}
						description={formatMessage("resultScreen.refabricate.failureDescription")}
						error={error.response?.data?.error?.message || error.toString()}
						onConfirm={() => {
							closeModal();
						}}
					/>
				)}
				{step === CardRefabricationStep.Success && (
					<StyledResultView
						type={"success"}
						title={formatMessage("resultScreen.refabricate.successTitle")}
						subtitle={formatMessage("resultScreen.refabricate.successSubtitle")}
						onConfirm={() => {
							closeModal();
						}}
					/>
				)}
			</StyledModalContainer>
		</RoundedModalContainer>
	);
};

const StyledButton = styled(PrimaryButton)`
	margin-top: 32px;
	align-self: center;
`;

const StyledInputLabel = styled.div`
	${theme.bodyBlackRegular}
	font-size: 18px;
	margin-top: 18px;
`;

const StyledErrorLabel = styled.p`
	font-size: 14px;
	color: red;
	margin-top: 12px;
`;

const StyledCardNumber = styled.p`
	color: ${theme.mainColor};
`;

const StyledResultView = styled(ResultView)`
	margin-left: 20px;
	margin-right: 20px;
`;

const ReasonsContainer = styled.div`
	display: flex;
	margin-top: 25px;
	align-self: stretch;
`;

const StyledRadioSelector = styled<RadioSelectorType<CardRefabricateMotive>>(RadioSelector)`
	flex-direction: column;

	> *:not(:last-child) {
		margin-bottom: 20px;
	}
`;

const StyledModalTitle = styled.span`
	${theme.boldText}
	padding-bottom: 40px;
	font-size: 1.125rem;
	color: #000000;
`;

const StyledModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
`;
const StyledModalInner = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 12px;
`;

const StyledBlueSpinner = styled(MainColorSpinner)``;
