import { ConnectedApiService } from "../../core/net/connected-api-service";
import { UrlLink } from "../BaseUrl";

export class CustomerActionService {
	public constructor(private apiService: ConnectedApiService) {}

	public async makeCustomerAction(
		url: string,
		body: {
			[key: string]: string | number | boolean | null | undefined;
		}
	) {
		try {
			const initialResponse = await this.apiService.instance.put(url);
			const processLink = initialResponse.data.links.href;
			if (!processLink) {
				throw new Error("No process link");
			}
			try {
				const response = await this.apiService.instance.patch(processLink, body);
				const submitLink = response.data.links.find((link: UrlLink) => link.rel === "submitForValidation")?.href;
				if (!submitLink) {
					throw new Error("No submit link");
				}
				try {
					const submitResponse = await this.apiService.instance.post(submitLink, body);
					return submitResponse.data;
				} catch (e) {
					throw e;
				}
			} catch (e) {
				throw e;
			}
		} catch (e) {
			throw e;
		}
	}
}
