import { Card } from "../../../../../shared/domains/cards/card";
import { CardLinks } from "../../../../../shared/domains/cards/card-links";

export const useCardLinks = () => {
	const canGetOutstandings = (card: Card) => {
		if (!card.links) {
			return false;
		}
		return card.links.findIndex(link => link.rel === CardLinks.GetOutstandings) > -1;
	};

	const canUpdateLimits = (card: Card) => {
		if (!card.links) {
			return false;
		}
		return card.links.findIndex(link => link.rel === CardLinks.UpdateLimits) > -1;
	};

	const canRefabricateCard = (card: Card) => {
		if (!card.links) {
			return false;
		}
		return card.links.findIndex(link => link.rel === CardLinks.Refabricate) > -1 && !card.virtual;
	};

	return { canGetOutstandings, canUpdateLimits, canRefabricateCard };
};
