import { PincodeSubmission } from "../pincode/pincode";
import { ObservableArray } from "../../utils/observable-array";
import { TransactionCode } from "./transaction-code";
import { TransactionCodeService } from "./transaction-code-service";
import { Amount } from "../../core/amount/amount";

export class TransactionCodeManager {
	public transactionCodes = new ObservableArray<TransactionCode>([]);

	public constructor(private transactionCodeService: TransactionCodeService) {}

	public async refresh() {
		const transactionCodes = await this.transactionCodeService.fetchTransactionCodes();
		this.transactionCodes.set(transactionCodes);
	}

	public async createTransactionCode(amount: Amount, acceptorShortCode: string, pincode?: PincodeSubmission) {
		const newTransactionCode = await this.transactionCodeService.createTransactionCode(
			amount,
			acceptorShortCode,
			pincode
		);
		this.transactionCodes.set([...this.transactionCodes.get(), newTransactionCode]);
		return newTransactionCode;
	}
}
