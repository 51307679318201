import React, { useEffect } from "react";
import { PrimaryButton, WhitePrimaryButton } from "../../../common/buttons/primary-button";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { OnboardingStatus } from "../../../../../shared/domains/onboarding/onboarding";
import { useOnboardingFile } from "../../../../../shared/domains/onboarding/use-onboarding";
import { Path } from "../../../../core/routing/path";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { theme } from "../../../styles/theme";
import EnrollmentLayout from "../shared/components/enrollment-layout";
import EnrollmentProgress from "../shared/components/enrollment-progress";
import EnrollmentStepForm from "../shared/components/enrollment-step-form";

export const OnboardingFileScreen = () => {
	const { push } = useHistory();
	const { formatMessage } = useIntl();
	const {
		error,
		erroredFields,
		needSaveBeforeSubmit,
		isUpdating,
		onboardingFile,
		steps,
		canUpdate,
		canSubmit,
		currentStepIndex,
		currentStep,
		isLastStep,
		fillResponse,
		fillMultiImages,
		moveBackward,
		moveForward,
		submitOnboardingFile,
	} = useOnboardingFile();

	useEffect(() => {
		if (onboardingFile?.status === OnboardingStatus.SUBMITTED || onboardingFile?.status === OnboardingStatus.PENDING_VERIFICATION) {
			push(Path.OnboardingFileSubmitted);
		}
	}, [onboardingFile]);

	const handleSubmitOnboardingFIle = async () => {
		await submitOnboardingFile();
	};

	const handleMoveBackward = () => {
		moveBackward();
	};

	return (
		<EnrollmentLayout>
			<EnrollmentProgress step={currentStepIndex} stepCount={steps?.length} label={currentStep?.label} />
			<StepTitle>{formatMessage("onboardingScreen.resumptionStepTitle")}</StepTitle>
			{!!currentStep && onboardingFile && (
				<>
					<EnrollmentStepFormWrapper>
						<EnrollmentStepForm
							disabled={!canUpdate || isUpdating}
							step={currentStep}
							onInputValueChange={fillResponse}
							onInputMultiImagesChange={fillMultiImages}
							onSubmit={moveForward}
							errorMessage={error}
							erroredFields={erroredFields}
							overrideActions={
								<StyledMainButtonContainer>
									{currentStepIndex > 0 ? (
										<WhitePrimaryButton size="S" type="button" onClick={handleMoveBackward}>
											{formatMessage("onboardingScreen.back")}
										</WhitePrimaryButton>
									) : (
										<div />
									)}
									<StyledButtonContainer>
										<PrimaryButton size="S" type="submit" disabled={isUpdating || !canUpdate}>
											{formatMessage(isLastStep ? "onboardingScreen.save" : "onboardingScreen.next")}
										</PrimaryButton>
										{isLastStep && (
											<PrimaryButton
												size="S"
												type="button"
												onClick={handleSubmitOnboardingFIle}
												disabled={needSaveBeforeSubmit || !canSubmit}>
												{formatMessage("onboardingScreen.submit")}
											</PrimaryButton>
										)}
									</StyledButtonContainer>
								</StyledMainButtonContainer>
							}
						/>
					</EnrollmentStepFormWrapper>
				</>
			)}
		</EnrollmentLayout>
	);
};

const StepTitle = styled(MarkdownText)`
	${theme.boldText};
	font-size: 2.5rem;
	line-height: 2.875rem;
	align-self: flex-start;
	margin-bottom: 24px;
`;

const EnrollmentStepFormWrapper = styled.div`
	flex: 1;
	width: 100%;
	max-width: 600px;
`;

const StyledButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const StyledMainButtonContainer = styled(StyledButtonContainer)`
	margin-top: 24px;
`;
