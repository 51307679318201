import React, { useEffect, useState } from "react";
import { authenticationManager, errorStore, webAppStartManager } from "../shared/core/service/services";
import { Modal, ModalContainer } from "./ui/common/modal/modal";

import { useIdleTimer } from "react-idle-timer/dist/index.legacy.esm.js";
import { BrowserRouter } from "react-router-dom";
import { Config } from "../shared/core/config/config";
import { useObservable } from "../shared/utils/observable";
import { useAsyncEffect } from "../shared/utils/utils";
import { AppRouter } from "./core/routing/app-router";
import { MainColorSpinner } from "./ui/common/spinner";
import { ErrorScreen } from "./ui/screen/error/error-screen";

export function App() {
	const [initialized, setInitialized] = useState(false);
	const isConnected = useObservable(authenticationManager.isConnected);
	const isAuthenticated = useObservable(authenticationManager.isAuthenticated);
	const errorHandler = useObservable(errorStore.error);
	const [showErrorScreen, setShowErrorScreen] = useState(false);

	useEffect(() => {
		if (!errorHandler) {
			async function initializeWebApp() {
				try {
					if (webAppStartManager && webAppStartManager.startApp) {
						await webAppStartManager.startApp();
						setInitialized(true);
					} else {
						throw new Error("webAppStartManager or webAppStartManager.startApp is undefined");
					}
				} catch (error) {
					setShowErrorScreen(true);
				}
			}

			initializeWebApp();
		}
	}, []);

	useEffect(() => {
		if (errorHandler && showErrorScreen) {
			errorStore.error.set(null);
		}
	}, [errorHandler, showErrorScreen]);

	useIdleTimer({
		// 5 minutes
		timeout: 1000 * 60 * 5,
		onIdle: () => handleOnIdle(),
		debounce: 5000,
	});

	useAsyncEffect(async () => {
		if (isConnected || isAuthenticated) {
			//await onboardingManager.initialize();
			await webAppStartManager.loadServiceDomainToken();
		}
	}, [isConnected, isAuthenticated]);

	const handleOnIdle = async () => {
		if (isConnected && isAuthenticated) {
			await authenticationManager.logout(true);
			Modal.dismissAll();
		}
	};

	return (
		<>
			{showErrorScreen ? (
				<ErrorScreen />
			) : initialized ? (
				<BrowserRouter basename={Config.BASE_URL}>
					<AppRouter />
					<ModalContainer />
				</BrowserRouter>
			) : (
				<MainColorSpinner size="60px" style={{ margin: "auto" }} />
			)}
		</>
	);
}
