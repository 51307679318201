import { AxiosResponse } from "axios";
import { Config } from "../../config/config";
import { DebugInterface } from "../../debug/debug-interface";
import { logger } from "../../logging/logger";
import { Interceptor } from "./interceptor";

export const debugResponseInterceptor: (
	debugIntercept?: DebugInterface | undefined
) => Interceptor<AxiosResponse> = debugIntercept => ({
	onFulfilled: response => {
		if (debugIntercept && debugIntercept.isAvailable()) {
			const filterURL = Config.API_URL + debugIntercept.getURL();
			if (response.config?.url?.includes(filterURL)) {
				try {
					const strResponse = JSON.stringify(response.data, null, 2);

					const toRead = debugIntercept.getToRead();
					if (toRead) {
						debugIntercept.setJsonStr(strResponse);
						return response;
					}

					const toOverride = debugIntercept.getToOverride();
					const jsonData = debugIntercept.getJsonData() ?? null;
					if (toOverride && jsonData) {
						logger.debug(
							"Interceptor : ",
							`Update response for ${debugIntercept.getURL()} with ${JSON.stringify(jsonData)}`
						);
						return {
							...response,
							data: jsonData,
						};
					}
				} catch (error) {
					logger.error("Debug Error : " + error);
					return response;
				}
			}
		}
		return response;
	},
});
