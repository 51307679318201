import React, { SVGProps } from "react";

export function GBRDots(props: SVGProps<SVGSVGElement>) {
	return (
		<svg {...props} width="92" height="34">
			<defs>
				<filter x="-114.3%" y="-100%" width="328.6%" height="328.6%" filterUnits="objectBoundingBox" id="a">
					<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.101960784 0 0 0 0 0.349019608 0 0 0 0 0.866666667 0 0 0 0.301628059 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<filter x="-89.3%" y="-75%" width="278.6%" height="278.6%" filterUnits="objectBoundingBox" id="c">
					<feGaussianBlur stdDeviation="2.5" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite
						in="shadowOffsetInner1"
						in2="SourceAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
						result="shadowInnerInner1"
					/>
					<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-114.3%" y="-100%" width="328.6%" height="328.6%" filterUnits="objectBoundingBox" id="d">
					<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 1 0 0 0 0 0.529411765 0 0 0 0 0.529411765 0 0 0 0.304141171 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<filter x="-89.3%" y="-75%" width="278.6%" height="278.6%" filterUnits="objectBoundingBox" id="f">
					<feGaussianBlur stdDeviation="2.5" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite
						in="shadowOffsetInner1"
						in2="SourceAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
						result="shadowInnerInner1"
					/>
					<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" in="shadowInnerInner1" />
				</filter>
				<filter x="-114.3%" y="-100%" width="328.6%" height="328.6%" filterUnits="objectBoundingBox" id="g">
					<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
					<feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
					<feColorMatrix
						values="0 0 0 0 0.474509804 0 0 0 0 1 0 0 0 0 0.466666667 0 0 0 0.297994974 0"
						in="shadowBlurOuter1"
					/>
				</filter>
				<filter x="-89.3%" y="-75%" width="278.6%" height="278.6%" filterUnits="objectBoundingBox" id="i">
					<feGaussianBlur stdDeviation="2.5" in="SourceAlpha" result="shadowBlurInner1" />
					<feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
					<feComposite
						in="shadowOffsetInner1"
						in2="SourceAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
						result="shadowInnerInner1"
					/>
					<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" in="shadowInnerInner1" />
				</filter>
				<circle id="buniqid" cx="36" cy="7" r="7" />
				<circle id="euniqid" cx="65" cy="7" r="7" />
				<circle id="huniqid" cx="7" cy="7" r="7" />
			</defs>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(10 8)">
					<use fill="#000" filter="url(#a)" href="#buniqid" />
					<use fill="#1A59DD" href="#buniqid" />
					<use fill="#000" filter="url(#c)" href="#buniqid" />
				</g>
				<g transform="translate(10 8)">
					<use fill="#000" filter="url(#d)" href="#euniqid" />
					<use fill="#FF4646" href="#euniqid" />
					<use fill="#000" filter="url(#f)" href="#euniqid" />
				</g>
				<g transform="translate(10 8)">
					<use fill="#000" filter="url(#g)" href="#huniqid" />
					<use fill="#4DFF4A" href="#huniqid" />
					<use fill="#000" filter="url(#i)" href="#huniqid" />
				</g>
			</g>
		</svg>
	);
}
