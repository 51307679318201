import React, { SVGProps } from "react";

export function FlashIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="16" height="17" {...props}>
			<path
				d="M10.445 7.466a.165.165 0 0 0-.146-.096H8.103l.368-3.16c.008-.088-.038-.17-.111-.2-.074-.028-.155.003-.198.076l-2.67 5.257a.209.209 0 0 0-.004.191c.03.06.086.096.146.096h2.164l-.292 3.166c-.006.087.041.167.115.194.073.028.154-.005.195-.078l2.627-5.257a.209.209 0 0 0 .002-.19z"
				fill={props.fill}
				fillRule="nonzero"
			/>
		</svg>
	);
}
