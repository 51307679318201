import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated } from "../../utils/pagination";
import { BaseUrl } from "../BaseUrl";
import { BicReferential } from "./bic-referential";

export type ReferentialBicResult = Paginated<BicReferential> & BaseUrl;

export class BicReferentialService {
	public constructor(private apiService: ConnectedApiService) {}

	public async searchBic(search: string, url?: string | undefined) {
		try {
			const response = await this.apiService.instance.get<ReferentialBicResult>(url ?? `/bic-referential`, {
				params: { search, limit: 200 },
			});
			const result = response.data;
			return result;
		} catch (e) {
			logger.debug("BicReferentialService", "Search Bic failed", JSON.stringify(e));
			throw e?.response?.data?.error?.message || e.toString();
		}
	}
}
