import React from "react";
import styled from "styled-components";
import { ADD_OR_EDIT_SPENDING } from "../../../../core/modal/modal-id";
import { useRTL } from "../../../../domain/language/use-rtl";
import { DefaultButton } from "../../../common/buttons/default-button";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { SmallCrossIcon } from "../../../common/svg/small-cross-icon";
import { Modal } from "../../../common/modal/modal";

export interface AddSpendingModalProps {
	children: React.ReactNode;
}

export const AddSpendingModal = (props: AddSpendingModalProps) => {
	const { children } = props;
	const { isRTL } = useRTL();

	const closeModal = () => {
		Modal.dismiss(ADD_OR_EDIT_SPENDING);
	};

	return (
		<ModalContainer id={ADD_OR_EDIT_SPENDING}>
			<>
				<CloseContainer $isRTL={isRTL}>
					<CloseButton onClick={closeModal}>
						<SmallCrossIcon />
					</CloseButton>
				</CloseContainer>
				{children}
			</>
		</ModalContainer>
	);
};

const ModalContainer = styled(RoundedModalContainer)`
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	padding: 50px;
	min-width: 460px;
	border-radius: 22px;
	background-color: white;
`;

const CloseContainer = styled.div<{ $isRTL: boolean }>`
	position: absolute;
	top: 20px;
	right: ${props => (props.$isRTL ? `unset` : `20px`)};
	left: ${props => (props.$isRTL ? `20px` : `unset`)};
	z-index: 2000;
`;
const CloseButton = styled(DefaultButton)`
	padding: 0px;
`;
