import React, { SVGProps } from "react";

export function CheckIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg width="38" height="38" viewBox="0 0 38 38" {...props}>
			<g fill="none" fillRule="evenodd">
				<g transform="translate(-701 -453) translate(511 201) translate(113 175)">
					<path d="M0 0H36.496V36.496H0z" transform="translate(77.752 77.752)" />
					<path
						fill={props.fill ?? "#fff"}
						fillRule="nonzero"
						d="M8.046 27.728c-.636.748-1.758.84-2.507.203-.748-.636-.839-1.758-.203-2.506L20.45 7.645c.646-.76 1.79-.84 2.536-.177l8.001 7.112c.734.652.8 1.776.148 2.51-.653.734-1.776.8-2.51.147l-6.643-5.904L8.046 27.728z"
						transform="translate(77.752 77.752) rotate(-180 18.248 17.686)"
					/>
				</g>
			</g>
		</svg>
	);
}
