export class AuthenticatedSessionExpiredError extends Error {
	public constructor(message?: string) {
		super(message);
	}
}

export class NetworkErrorOnAuthentication extends Error {
	public constructor(message?: string) {
		super(message);
	}
}
