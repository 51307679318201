import { Attachment } from "./transaction/attachment";
import { AttachmentsLinks } from "./transaction/attachments-links";

export const useAttachmentLinks = () => {
	const canAddAnother = (attachment: Attachment) => {
		if (!attachment.links) {
			return false;
		}
		return attachment.links.findIndex(link => link.rel === AttachmentsLinks.AddAnother) > -1;
	};

	const canDelete = (attachment: Attachment) => {
		if (!attachment.links) {
			return false;
		}
		return attachment.links.findIndex(link => link.rel === AttachmentsLinks.Delete) > -1;
	};

	const canGetContent = (attachment: Attachment) => {
		if (!attachment.links) {
			return false;
		}
		return attachment.links.findIndex(link => link.rel === AttachmentsLinks.GetContent) > -1;
	};

	return { canAddAnother, canDelete, canGetContent };
};
